const LocalstorageGetItem = (key, initialState) => {
    const value = localStorage.getItem(key);
    try {
        if (!value) throw Error()
            return JSON.parse(value)
    } catch (error) {
        return initialState
    }
}

const localstorageToken = LocalstorageGetItem("token", { accessToken: "",expiresIn:"" })
const localstorageUserInfo = LocalstorageGetItem("userInfo", {
    userId: "",
    image: "",
    name: "",
    lastname: "",
    role: "",
    address: "",
})

export const initialState = {
    auth: !!localstorageToken.accessToken,
    token: localstorageToken,
    userInfo: localstorageUserInfo
}
