import React from "react";
import { Button, Col, Row } from "react-bootstrap";

function Weast({ resourceItem, handleEdit, handleAdd, auth }) {
  return (
    <>
      <Row>
        <Col md={10}>
          <h4>
            <b>{resourceItem.title}</b>
          </h4>
        </Col>
        <Col>
          {resourceItem.third.weast_resource.some(
            (data) => data.checked === true
          ) &&
            auth && (
              <Button onClick={() => handleEdit(resourceItem)}>แก้ไข</Button>
            )}
        </Col>
      </Row>
      <Row>
        {resourceItem.third.weast_resource.some(
          (data) => data.checked === true
        ) ? (
          resourceItem.third.weast_resource.map((item, inx) => {
            return (
              <React.Fragment key={inx}>
                <p>{item.unity_name}</p>
                <p>
                  {!!item.unity_amount || !!item.how_to ? (
                    <>
                      {item.detail ? item.detail : item.unity_type} :
                      {item.how_to
                        ? item.how_to
                        : ` ${item.unity_amount} แหล่ง`}
                    </>
                  ) : (
                    <>ไม่มี</>
                  )}
                </p>
                <hr />
              </React.Fragment>
            );
          })
        ) : (
          <>
            <h3>ไม่มีข้อมูลส่วนนี้</h3>
            {auth && (
              <Button onClick={() => handleAdd(resourceItem)}>
                เพิ่มข้อมูล
              </Button>
            )}
          </>
        )}
      </Row>
    </>
  );
}

export default Weast;
