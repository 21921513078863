import React from "react";
import { ApiFetch } from "../../../../../../config/api";
import { useAuthContextState } from "../../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../../enums/enum";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";
import BarChartSkill from "../../barChartSkill/barChartSkill";

function LanguageSkill({ LanguageSkillData, address }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  async function getDataTable(section, edulevel, title, datasetTitle) {
    if (!token.accessToken) return false;
    setTableData({ header: title, data: [] });
    const titleData = {
      title: title,
      edulevel: edulevel,
      datasetTitle: !!datasetTitle ? datasetTitle : "",
    };
    setModalShow(true);
    setTableData({ header: titleData, data: [] });
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/education-personal-table-info/${section}/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });
      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({ header: titleData, data: res.data });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }

  /* React.useImperativeHandle(ref, () => ({
    async getPersonalInfoAllageRage() {
      setModalShow(true);
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/education-personal-table-info/ageRange/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: {edulevel:'all'},
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: { datasetTitle: "ชายและแหญิง", title: "ทุกช่วงอายุ" },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    },
  })); */
  return (
    <>
      <div style={{ height: "50vh", overflowY: "auto" }}>
        <BarChartSkill
          data={LanguageSkillData}
          section={"languageSkill"}
          pathname={"skill-personal-table-info"}
          setModalShow={setModalShow}
          setTableData={setTableData}
          cutout={65}
          address={address}
          setTableDataForFilter={setTableDataForFilter}
        />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียดทักษะ ${tableData.header.title} ในระดับ ${tableData.header.datasetTitle} ใน ${address.addressName} จำนวน ${tableData?.data?.data?.length} คน`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
}

export default LanguageSkill;
