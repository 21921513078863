import React from 'react'
import { useParams, useLocation } from "react-router-dom";
import { Button, Modal, Form, Row, Col, Table, Spinner } from 'react-bootstrap';
import { WatDatePicker } from 'thaidatepicker-react'
import { ApiFetch } from '../../../../config/api';
import { FetchInterface, Method, ThaiMonth } from '../../../../enums/enum';
import { useAuthContextState } from '../../../../context/auth/authContext/authContextState'
import Swal from 'sweetalert2'
import styled from "styled-components";
import { BsFillTrash2Fill } from "react-icons/bs";


export default function AddAccountingEntrepreneur() {
    const params = useParams();
    const search = useLocation().search
    const { token } = useAuthContextState();

    const [accounting, setAccounting] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false)
    const [openModal, setOpenModal] = React.useState(false);
    const [payload, setPayload] = React.useState({
        date: null,
        amount: 0,
        type: "รายรับ"
    });
    const [year, setYear] = React.useState([])

    async function getData(personalId, condition) {
        try {
            // setLoading(true)
          const res = await ApiFetch({
            [FetchInterface.PATH]: `entrepreneur/accounting-list/${personalId}?date=${condition}`,
            [FetchInterface.METHOD]: Method.GET,
            [FetchInterface.TOKEN]: token.accessToken,
          });
            if (res.status === 200) {
                setAccounting(res.data)
                return res.data
            }
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
          console.log(error);
        }
    }

    async function checkAccounting(data) {
        try {
            // setLoading(true)
          const res = await ApiFetch({
            [FetchInterface.PATH]: `entrepreneur/check-accounting/${params.personalId}?date=${data.date}&type=${data.type}`,
            [FetchInterface.METHOD]: Method.GET,
            [FetchInterface.TOKEN]: token.accessToken,
          });
            console.log(res.status)
            if (res.status === 200) {
                if (Object.keys(res.data).length) {
                    let result = await Swal.fire({
                        icon: 'warning',
                        showDenyButton: true,
                        showCancelButton: true,
                        title: `มีข้อมูลอยู่แล้ว`,
                        text: 'ต้องการอัพเดตข้อมูลนี้ไหม',
                        showConfirmButton: true,
                        confirmButtonText: "ต้องการอัพเดต",
                        denyButtonText: `ไม่ต้องการ`
                    })

                    if (result.isConfirmed) {
                        setOpenModal(false)
                        handleUpdate(data, res.data.id)
                    } 
                    
                    return false
                } else {
                    return true
                }
            }
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
          console.log(error);
        }
    }

    async function handleUpdate(payload, id) {
        delete payload.dateThai
        delete payload.id
        try {
            // setLoading(true)
            const searchParams = new URLSearchParams(search)
          const res = await ApiFetch({
            [FetchInterface.PATH]: `entrepreneur/accounting/${id}`,
            [FetchInterface.METHOD]: Method.PATCH,
            [FetchInterface.DATA]: payload,
            [FetchInterface.TOKEN]: token.accessToken,
          });
            if (res.status === 200) {
                Swal.fire({
                    title: "บันทึกข้อมูลสำเร็จ?",
                    icon: "success"
                })
                setPayload({
                    date: null,
                    amount: 0,
                    type: "รายรับ"
                })
                setOpenModal(false)
                await getData(params.personalId, searchParams.get('date'))
               
            }
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
          console.log(error);
        }
    }
    
    async function handleSubmit(e) {
        e.preventDefault();
        setSubmitting(true)
        if (payload.date === null) {
            setSubmitting(false)
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน?",
                icon: "error"
            })
        }
        let data = {
            personal_id : params.personalId,
            amount: payload.amount,
            date: payload.date,
            type: payload.type,
            list_name: payload.list_name
        }

        let check = await checkAccounting({ ...data })
        if (check) {
            try {
                // setLoading(true)
            const searchParams = new URLSearchParams(search)
              const res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/accounting`,
                [FetchInterface.METHOD]: Method.POST,
                [FetchInterface.DATA]: data,
                [FetchInterface.TOKEN]: token.accessToken,
              });
                setSubmitting(false)
                if (res.status === 200) {
                    Swal.fire({
                        title: "บันทึกข้อมูลสำเร็จ?",
                        icon: "success"
                    })
                    setPayload({
                        date: null,
                        amount: 0,
                        type: "รายรับ"
                    })
                    await getData(params.personalId, searchParams.get('date'))
                    // addFilterYear(data)
                    setOpenModal(false)
                }
                // setLoading(false)
            } catch (error) {
                setSubmitting(false)
                // setLoading(false)
              console.log(error);
            }
        }
        setSubmitting(false)
        

    }

    async function handleDeleteAccountingById(id) {
        let result = await Swal.fire({
            icon: 'warning',
            showDenyButton: true,
            title: `ต้องการลบข้อมูล รายรับ-รายจ่าย นี้?`,
            showConfirmButton: true,
            confirmButtonText: "ต้องการลบ",
            denyButtonText: `ไม่ต้องการ`
        })
        if (result.isConfirmed) {
            try {
                // setLoading(true)
            const searchParams = new URLSearchParams(search)
              const res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/accounting/${id}`,
                [FetchInterface.METHOD]: Method.DELETE,
                [FetchInterface.TOKEN]: token.accessToken,
              });
                if (res.status === 200) {
                    Swal.fire({
                        title: "ลบข้อมูลมูลสำเร็จ?",
                        icon: "success"
                    })
                    getData(params.personalId, searchParams.get('date'))
                }
                // setLoading(false)
            } catch (error) {
                // setLoading(false)
              console.log(error);
            }
        } 
    }

    React.useEffect(async () => {
        const searchParams = new URLSearchParams(search)
        await getData(params.personalId, searchParams.get('date'))
        // // console.log(searchParams['date'])
        // addFilterYear(data)
    }, [])

    return (
        <Main>
            <div className="title">
                <div className='select'>
                    รายการ รายรับ-รายจ่าย
                </div>
                <div>
                    <Button variant="primary" onClick={() => setOpenModal(true)}>เพิ่มข้อมูล</Button>
                </div>
                <Modal
                show={openModal}
                onHide={() => setOpenModal(false)}
                >
                <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>เพิ่มข้อมูลรายรับ-รายจ่าย</Modal.Title>
                </Modal.Header>
                <Modal.Body>  
                        <Row className="justify-content-md-center p-3">
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>วัน/เดือน/ปี</Form.Label>
                                    <br></br>
                                        <WatDatePicker
                                        
                                        required
                                        value={payload.date}
                                        inputStyle={{ height: "38px", width: "auto" }}
                                        onChange={(christDate, buddhistDate) => {
                                            setPayload({ ...payload, date: christDate, date2: buddhistDate })
                                        }}
                                        dateFormat={"yyyy-MM-dd"}
                                        displayFormat={"DD MMMM YYYY"}
                                        clearable={true}
                                        maxDate={"2030-1"}
                                        yearBoundary={99}
                                        />
                                        <br></br>
                                    </Form.Group>
                                </Col>
                            <Col md='12'>
                                <Row className="justify-content-md-center">
                                
                                </Row>
                                <Row>
                                        <Col md='12'>
                                        <Form.Group className="mb-3">
                                            <Form.Label>ประเภท</Form.Label>
                                            <Form.Select
                                            required
                                            aria-label="Default select example"
                                            value={payload.type}
                                                    onChange={(e) => {
                                                console.log(e.target.value)
                                                setPayload({ ...payload, type: e.target.value })
                                            }} 
                                            >
                                            <option value="รายรับ">รายรับ</option>
                                            <option value="รายจ่าย">รายจ่าย</option>
                                            </Form.Select>
                                        </Form.Group>
                                        </Col>
                                        <Col md='12'>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label>รายการ</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={payload.list_name}
                                            onChange={(e) => {
                                                setPayload({ ...payload, list_name: e.target.value })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md='12'>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label>จำนวน</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            value={payload.amount}
                                            onChange={(e) => {
                                                setPayload({ ...payload, amount: e.target.value })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        
                        </Modal.Body>
                        <Modal.Footer>
                            <Button disabled={submitting} variant="primary" type="submit" className='w-100'>
                            {submitting===true? <><Spinner animation="border" role="status" variant="light">
                            </Spinner><span>กำลังบันทึกข้อมูล</span></>: <span>บันทึกข้อมูล</span>} 
                            </Button>
                        </Modal.Footer>      
                </Form>
                </Modal>
            </div>
            <div className="content">
            <TableUserData bordered responsive>
                <thead style={{ backgroundColor: "#38AFBA", color: "white" }}>
                <tr>
                    <th>ลำดับ</th>
                    <th>วัน/เดือน/ปี</th>
                    <th>ประเภท</th>
                    <th>รายการ</th>
                    <th>จำนวน (บาท)</th>
                    <th>จัดการ</th>
                </tr>
                </thead>
                    <tbody>
                        {accounting.map((value, key) => {
                            return (
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{new Date(value.date).getDate()} {ThaiMonth[new Date(value.date).getMonth()+1]} {new Date(value.date).getFullYear()+543}</td>
                                    <td>{value.type}</td>
                                    <td>{value.list_name}</td>
                                    <td>{value.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td style={{ textAlign: "center", width: "10%" }} >
                                            <Button variant="danger"
                                                onClick={() => handleDeleteAccountingById(value.id)}
                                            ><BsFillTrash2Fill /></Button>
                                    </td> 
                                </tr>
                            )
                        })}
                        {/* {accounting.map(value => {
                            return value.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{parseInt(value.year) + 543}</td>
                                        <td>{ThaiMonth[item.month]}</td>
                                        <td>{item.revenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        <td>{item.expenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        <td style={{ textAlign: "center", width: "10%" }} >
                                                <Button variant="danger"
                                                    onClick={() => handleDeleteAccountingById(item.id)}
                                                ><BsFillTrash2Fill /></Button>
                                        </td>
                                    </tr>
                                )
                            })
                        })} */}
                </tbody>
            </TableUserData>
            {/* {userData?.data?.length > 0 &&
                <PaginationSelftInput
                pagiData={userData}
                getDashboard={getUser}
                page={userData.current_page}
                />} */}
            </div>
        </Main>
    )
}

const Main = styled.div`
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .title {
    border-radius: 10px;
    background-color: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    padding: 0 15px 0 15px;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select{
      width: 50%;
    }
  }
  .content {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px;
    margin-top: 15px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }
`
const TableUserData = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  transition: all .2s ;
tr th,
tr td {
  border-right: 1px solid #38AFBA;
  border-bottom: 1px solid #38AFBA;
  padding: 5px;
}

tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th {
  background: #38AFBA;
  text-align: left;
  border-top: solid 1px #38AFBA;
}

/* top-left border-radius */
tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
`