import React, { useState } from 'react'
/* import { Stepper, Step, } from 'react-form-stepper'; */
import { ListGroup } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
function PersonalStepper({ page, list, setPage }) {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    const listTitle = list.map(e => {
        return {
            label: e,
        }
    })

    const onClick = (e, index) => {
        if (!(e === "ข้อมูลทั่วไป")) {
            setPage(index)
        }
    }

    const Step = () => {
        return (
            list.map((e, index) => {
                return (
                    <ListGroup.Item active={index === page} onClick={(e) => onClick(e, index)} key={index} style={{ cursor: "pointer" }} /* disabled={e === "ข้อมูลทั่วไป"} */  >{e}</ListGroup.Item>
                )
            })
        )
    }

    const mobile = ()=>{
        return list[page];
    }
    return (
        <div className="d-flex justify-content-center mb-4" >
            <ListGroup horizontal >
                {isTabletOrMobile ? null : <Step />}
            </ListGroup >
        </div>
    )
}

{/* <Stepper
          steps={listTitle}
          styleConfig={{ activeBgColor: '#38afba', completedBgColor:'#38afba'}}
          activeStep={page}
          
      /> */}
export default PersonalStepper