import React from 'react'
import Swal from 'sweetalert2'
import styled from 'styled-components';
import { ApiFetch } from '../../config/api';
import { useHistory } from 'react-router-dom';
import { FetchInterface, Method } from '../../enums/enum';
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useAuthContextDispatch } from '../../context/auth/authContext/authContextState';
import Loading from '../../component/loadingAnimation/loading';

function Login() {
  const [values, setValues] = React.useState({
    user_email: "",
    password: ""
  })
  const { _signIn } = useAuthContextDispatch()
  const [loading, setLoading] = React.useState(false)
  const history = useHistory();
  function handlechangValue(event) {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const login = async (event) => {
    event.preventDefault();
    try {
      setLoading(true)
      let res = await ApiFetch({
        [FetchInterface.PATH]: 'auth/login',
        [FetchInterface.METHOD]: Method.POST,
        [FetchInterface.DATA]: values,
      })
      if (!res) throw new Error('เกิดข้อผิดพลาดกับการเชื่อมต่อ')
      if (res.status === 200) {
        _signIn({
          token: {
            accessToken: res.data.access_token,
            expiresIn: res.data.expires_in
          },
          userInfo: {
            userId: res.data.user.id,
            role: res.data.user.user_type,
            email: res.data.user.user_email,
            image: res.data.user.url,
            name: res.data.user.first_name,
            lastname: res.data.user.last_name,
          }
        })
        history.push("/")
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'เข้าสู่ระบบสำเร็จ',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          setLoading(false)
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'เข้าสู่ระบบสำเร็จไม่สำเร็จ',
        text: error.message,
        showConfirmButton: true,
      }).then(() => console.log(error))
    }
  }
  return (
    <ContainerContent>
      <Container>
        <Row>
          <RightContent md={6}>
            <div style={{ display: "flex", width: '100%', height: '100%', justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>
              <div>
                <img
                  alt='loginIMG'
                  width={"100%"}
                  height={"auto"}
                  src={require('./../../assets/images/19199299.jpg')}
                />
              </div>
            </div>
          </RightContent>
          <Col xs={12} md={6}>
            <Row className="align-content-md-center justify-content-md-center" style={{ height: "80vh",marginTop:"20px" }}>
              <Col md={8} >
                <h1>
                  เข้าสู่ระบบ
                </h1>
                <Form method='post' onSubmit={(event) => login(event)}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Username</Form.Label>
                    <Form.Control name='user_email' onChange={(e) => handlechangValue(e)} type="email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>password</Form.Label>
                    <Form.Control type="password" name='password' onChange={(e) => handlechangValue(e)} />
                  </Form.Group>
                  <Button type='submit' style={{ width: "110px" }} disabled={loading}>
                    {!loading ? (
                     <>
                     เข้าสู่ระบบ
                     </> 
                    ) : (                                     
                        <Loading color={'white'} />                     
                    )}

                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ContainerContent>
  )
}
export default Login

const RightContent = styled(Col)`
  @media screen and (max-width:668px){
    display: none;
  }  
`
const ContainerContent = styled.div`
  background-size: 100%;
`