import React from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { PersonalContext } from "../../context/personal";
import PersonalConsent from '../consentForm/PersonalConsent'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { apiConfig } from '../../config/api';

function Welcome({ setPage}) {
  const[checkConsent, setCheckConsent] = React.useState(false)
  const { data, setData, setPersonalId } = React.useContext(PersonalContext);
  const [id, setId] = React.useState("")
  const [error, setError] = React.useState("")
  const handleChange = (event) => {
    setId(event.target.value)
  }

  const handleSubmit = async (event) => {
    if(id.length < 0 || id.length > 13 || id.length!==13){
      setError("กรุณากรอกรหัสประจำตัวประชาชนให้ถูกต้อง")
    }else{
      setError("")
      try{
        let response = await axios.get(`${apiConfig.url}/api/personal/get_one/${id}`)
        let updateData = { ...data }
        console.log(response.data)
        if (response.data.occupation.length > 0){
          updateData.first.occupation.status = "มีอาชีพ"
          updateData.first.occupation.main = response.data.occupation.find(item => item.job_status === "อาชีพหลัก")
          updateData.first.occupation.additional.data.status = true
          updateData.first.occupation.additional.data = response.data.occupation.filter(item => item.job_status === "อาชีพเสริม")
          if (updateData.first.occupation.additional.data.length > 0) updateData.first.occupation.additional.status = true
        }
        if (response.data.garden.length > 0){
          updateData.first.garden.have = true
          updateData.first.garden.data = response.data.garden
        }
        if (response.data.health.length > 0) {
          updateData.first.physicalImpairment.body = response.data.health.find(item => item.type_name === "ความบกพร่องทางด้านร่างกาย")
          if (updateData.first.physicalImpairment.body) updateData.first.physicalImpairment.body.status = true
          else{
            updateData.first.physicalImpairment.body = {
              status: false,
              name: '',
              date: '',
              description: '',
              hospitalName: '',
              hospitalId: ''
            }
          }
          updateData.first.physicalImpairment.other = response.data.health.find(item => item.type_name === "ความบกพร่องทางด้านอื่น ๆ")
          if (updateData.first.physicalImpairment.other) updateData.first.physicalImpairment.other.status = true
          else{
            updateData.first.physicalImpairment.other = {
              status: false,
              name: '',
              date: '',
              description: '',
              hospitalName: '',
              hospitalId: ''
            }
          }
        }
        if (response.data.role.length > 0) {
          updateData.first.communityRole = response.data.role.map(item=>{
            return {
              id: item.id,
              name: item.role_name,
              status: true,
             
            };
          })
        }
        if (response.data.sage.length > 0) {
          updateData.first.sage.have = true;
          updateData.first.sage.data = response.data.sage.map((item) => {
            return {
              id: item.id,
              name: item.sage_name,
              type: item.sage_type,
            };
          });
        }
        updateData.first.information = response.data.personal
        updateData.first.address = response.data.address
        setPersonalId(response.data.personal.id);
        setData(updateData)       
        setPage(0)
      }catch(err){
        Swal.fire({
          title: 'ไม่เจอข้อมูลในระบบ?',
          text: "ดำเนินการต่อหรือไม่!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ดำเนินการต่อ',
        }).then((result) => {
          if (result.isConfirmed) {
            setPage(0)
          }
        })
        
      }
    }
  }

  const handleConsentSubmit= (e, user)=>{
    let updateData = { ...data }
    let today = new Date()
    updateData.consentForm = {
      accepter_title: user.accepter_title,
      accepter_firstname: user.accepter_firstname,
      accepter_lastname: user.accepter_lastname,
      accept: e,
      date: today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate(),
      time: today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
    }
    setData(updateData)
    setCheckConsent(true)
  }

  React.useEffect(() => {
      setData({
        first: {
          information: {
            title: "",
            first_name: "",
            last_name: "",
            date_birth: "",
            religion: "",
            main_nation: "",
            gender: "",
            marital_status: "",
            father_id: "",
            father_name: "",
            father_surname: "",
            mothor_id: "",
            mother_name: "",
            mothor_surname: "",
            tell: "",
            email: "",
          },
          address: {
            house_number: "",
            address_no: "",
            address_mooban: "",
            address_group: "",
            tambol_id: null,
            amphur_id: null,
            province_id: null,
          },
          occupation: {
            main: {
              job: "",
              place: "",
              salary: 0,
            },
            additional: {
              status: false,
              data: [],
            },
          },
          garden: {
            have: false,
            data: [],
          },
          physicalImpairment: {
            body: {
              status: false,
              name: "",
              date: "",
              description: "",
              hospitalName: "",
              hospitalId: "",
            },
            other: {
              status: false,
              name: "",
              date: "",
              description: "",
              hospitalId: "",
            },
          },
          communityRole: [],
          sage: {
            have: false,
            data: [],
          },
          saved: false,
        },
        second: {
          education: [
            {
              id: 0,
              level: null,
              start: null,
              end: null,
              status: null,
              schoolName: null,
            },
          ],
          saved: false,
        },
        third: {
          experience: [
            {
              id: 0,
              occupation: null,
              job_start: null,
              job_end: null,
              job_detail: null,
              bussinessId: null,
              leaveReason: null,
            },
          ],
          saved: false,
        },
        fourth: {
          train: [],
          language: [],
          skill: [],
          saved: false,
        },
        fifth: {
          family: [],
          saved: false,
        },
        sixth: {
          now: [],
          before: [],
          other: [],
          saved: false,
        },
      });
  }, [])

  return (
    <Container>
      <Row >
        {!checkConsent ?
        <PersonalConsent handleConsentSubmit={handleConsentSubmit}/>
        : 
        <>
        <Col md={12}>
          <h1 className="text-center">กรอกรหัสประจำตัวประชาชน 13 หลัก</h1>
        </Col>
        {error!==""?
          <Col md={12}>
          <Alert variant="warning" className="w-50" style={{margin: '0 auto'}}>
            {error}
          </Alert> 
          </Col>
          :
          null
          }
        <Col md={12}>
          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
            <Form.Control type="text" placeholder="รหัสประจำตัวประชาชน 13 หลัก"  onChange={e => handleChange(e)} style={{width:'50%', margin:'0 auto'}}/>
          </Form.Group>
        </Col>
        <Col md={12}>
          <div className="text-center">
              <Button variant="success" className="w-50" onClick={()=>handleSubmit()}>ยืนยัน</Button>
          </div>
        </Col>
          </>
        }
      </Row>
    </Container>
  )
}

export default Welcome