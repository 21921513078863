import axios from "axios";
import React from "react";
import styled from "styled-components";
import { apiConfig } from "../../../../config/api";
function InfoSage({ communityId }) {
  const [data, setData] = React.useState("");
  console.log("InfoSage", data);
  async function getDataApi(communityId) {
    if (!communityId) {
      setData("");
      return false;
    }
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/community_dashboard/sage/${communityId}`
      );
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  React.useEffect(() => {
    getDataApi(communityId);
  }, [communityId]);
  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <div
        style={{
          width: "100%",
          background: "#fff",
          height: "auto",
          borderRadius: "10px",
          padding: "50px",
        }}
      >
        {!!data && (
          <EducationTable>
            <div className="head">
              <div className="table-title">
                <span className="text-title">ปราชน์ทางด้าน</span>
                <span className="text-title">ชื่อศาสตร์</span>
                <span className="text-title">ชื่อ-สกุลผู้เชี่ยวชาญ</span>
              </div>
            </div>
            <div className="body">
              <div className="table-content">
                {!!data &&
                  data.map((sage, inx) => (
                    <div className="education-status" key={inx}>
                      <div className="education-status-title">
                        {sage.sage_type}
                      </div>
                      <div className="education-type-list">
                        {!!sage?.values &&
                          sage.values.map((personal, inx) => (
                            <div className="educatio-type" key={inx}>
                              <div className="education-type-title">
                                {personal.sage_name}
                              </div>
                              <div className="education-type-title">
                                {personal.title +
                                  personal.first_name +
                                  "  " +
                                  personal.last_name}
                              </div>
                            </div>
                          ))}
                        {sage.values.length === 0 && (
                          <div className="educatio-type">
                            <div className="education-type-title">-</div>
                            <div className="education-type-title">-</div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </EducationTable>
        )}
      </div>
    </div>
  );
}
const EducationTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid 1.6px #aaaaaa;
  border-right: solid 1.6px #aaaaaa;
  border-top: solid 1.6px #aaaaaa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .head {
    background-color: #38afba;
    display: grid;
    .table-title {
      border-bottom: solid 1.6px #aaaaaa;
      display: grid;
      grid-template-columns: 30% 35% 35%;
      .text-title {
        padding-left: 5px;
      }
    }
  }
  .body {
    display: flex;
    width: 100%;
    .table-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      .education-status {
        width: 100%;
        display: flex;
        height: 100%;
        .education-status-title {
          border-bottom: solid 1.6px #aaaaaa;
          padding-left: 5px;
          padding-top: 5px;
          width: 30%;
          height: 100%;
        }
        .education-type-list {
          width: 70%;
          height: 100%;
          .educatio-type {
            display: flex;
            width: 100%;
            height: 100%;
            .education-type-title {
              padding-left: 5px;
              padding-top: 5px;
              display: flex;
              border-left: solid 1.6px #aaaaaa;
              border-bottom: solid 1.6px #aaaaaa;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;
export default InfoSage;
