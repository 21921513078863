import { TypesLayout } from "../../enums/enum";
import Dashboard from "../../page/admin/dashboard/dashboard";
import { IoGrid } from 'react-icons/io5';
import { BsFillPersonFill } from "react-icons/bs";
import { RiCommunityFill } from "react-icons/ri";
import UserManage from "../../page/admin/userManage/userManagement";
import CreateUser from "../../page/admin/userManage/createUser/createUser";
import EditUser from "../../page/admin/userManage/editUser/editUser";

export const managerRoute = [
    {
        name:"Dashboard",
        path:"/",
        icon:<IoGrid />,
        layout:TypesLayout.adminLayout,
        sidebar:true,
        exact:true,
        component:Dashboard
    },
    /* {
        name:"Community",
        path:"/community",
        icon:<BsFillPersonFill />,
        layout:TypesLayout.adminLayout,
        sidebar:true,
        exact:true,
        component:Community
    }, */
    /* {
        name:"Personal",
        path:"/personal",
        icon:<RiCommunityFill />,
        layout:TypesLayout.adminLayout,
        sidebar:true,
        exact:true,
        component:Personal
    }, */
    {
        name:"User",
        path:"/user",
        icon:<BsFillPersonFill />,
        layout:TypesLayout.adminLayout,
        sidebar:true,
        exact:true,
        component:UserManage
    },
    {
        name:"Create User",
        path:"/CreateUser",
        icon:<RiCommunityFill />,
        layout:TypesLayout.none,
        sidebar:false,
        exact:true,
        component:CreateUser
    },
    {
        name:"Edit User",
        path:"/EditUser",
        icon:<RiCommunityFill />,
        layout:TypesLayout.none,
        sidebar:false,
        exact:true,
        component:EditUser
    },
]