import React from "react";
import styled from "styled-components";
import { PageArray } from "../../../../../enums/enum";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export default function SectionBar({ sectionPage, setSectionPage }) {
  const [toggleState, setToggleState] = React.useState(1);

  const toggleTab = (index) => {
    setSectionPage(index);
    setToggleState(index);
  };
  return (
    <Main>
      <ButtonGroup>
        {PageArray.map((pageData, index) => (
          <ToggleButton
           /*  disabled={index === 3 || index === 4 || index === 5} */
            key={index}
            id={index}
            type="radio"
            variant={"outline-secondary"}
            name="pageData"
            checked={toggleState === pageData.id}
            onClick={() => toggleTab(pageData.id)}
          >
            {pageData.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Main>
  );
}
const Main = styled.div`  
  width: 100%;
  height: 35%;
  padding: 5px 10px 5px 10px;
  align-items: center;
  display: flex;  
  justify-content: center;
  .section-bar {
    align-items: center;
    display: flex;
    width: 70%;
    height: 100%;
    justify-content: space-between;
    .section-bar-button {
      button {
        font-size: 24px;
        border: none;
        background: transparent;
      }
    }
  }
  
  .bloc-tabs {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
  }
  .tabs {
    height: 40%;
    padding: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 50%;
    background: rgb(255, 255, 255);
    cursor: pointer;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-sizing: content-box;
    position: relative;
  }
 

  .active-tabs {
    background: #ebf4fa;
    border-bottom: 1px solid transparent;
  }

  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100%);
    height: 5px;
    background: #38afba;
  }

  button {
    border: none;
  }
  
`;
