import React from 'react'
import axios from 'axios';
import { useAlert } from 'react-alert'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { WatDatePicker } from 'thaidatepicker-react'
import { PersonalContext } from "../../../context/personal";
import { apiConfig } from '../../../config/api';

function CreatePlace({ createPlace, setCreatePlace, getBusiness }) {
    const alert = useAlert()
    const { data,setData } = React.useContext(PersonalContext);

    //state ข้อมูล input 
    const [owner, setOwner] = React.useState()
    const [business, setBusiness] = React.useState()
    const [address, setAddress] = React.useState()

    //state ข้อมูลจังหวัด อำเภอ ตำบล
    const [province, setProvince] = React.useState([])
    const [amphur, setAmphur] = React.useState([])
    const [tambol, setTambol] = React.useState([])

    //ดึงค่าจังหวัดจากหลังบ้าน
    const getProvince = async () => {
        const result = await axios(apiConfig.url + '/api/provinces');
        setProvince(result.data)
    }

    //ดึงค่าอำเภอจากหลังบ้าน
    const getAmphur = async (id_province) => {
        const result = await axios(apiConfig.url + '/api/amphurs/' + id_province);
        setAmphur(result.data)
    }

    //ดึงค่าตำบลจากหลังบ้าน
    const getTambol = async (id_amphur) => {
        const result = await axios(apiConfig.url + '/api/tambols/' + id_amphur);
        setTambol(result.data)
    }

    React.useEffect(() => {
        getProvince()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //เมื่อเลือกจังหวัดทำฟังก์ชั่นนี้
    const provinceChange = e => {
        setAmphur([])
        setTambol([])
        if (e.target.value !== '') {
            setAddress({ ...address, province: e.target.value })
            getAmphur(e.target.value)
        }
    }
    //เมื่อเลือกอำเภอทำฟังก์ชั่นนี้
    const amphurChange = e => {
        if (e.target.value !== '') {
            setAddress({ ...address, amphur: e.target.value })
            getTambol(e.target.value)
        }
    }

    //เมื่อเลือกตำบลทำฟังก์ชั่นนี้
    const tambolChange = e => {
        if (e.target.value !== '') {
            setAddress({ ...address, tambol: e.target.value })
        }
    }

    /*   ฟังก์ชั่นเซตค่า วันเกิด */
    const handleWatDatePickerChange = (christDate, buddhistDate) => {
        setBusiness({
            ...business,
            registerDate: buddhistDate
        })
    }

    const onSubmitBussiness=async () => {
        try{
            let dataRequest = {
                personal:owner,
                business:business,
                address:address,
            }

            let result = await axios.post(apiConfig.url + '/api/business/create',dataRequest)

            if (createPlace.index === null && createPlace.section === 1) {    //ถ้าเพิ่มข้อมูลอาชีพหลัก
                setData({
                    ...data,
                    first: {
                        ...data.first,
                        occupation: {
                            ...data.first.occupation,
                            main: { ...data.first.occupation.main, place: business.name, placeId: result.data.data.id }
                        }
                    }
                })
            }
            if (createPlace.index !== null && createPlace.section === 1) {     //ถ้าเพิ่มข้อมูลอาชีพเสริม
                let updateData = { ...data }
                updateData.first.occupation.additional.data[createPlace.index].place= business.name
                updateData.first.occupation.additional.data[createPlace.index].placeId= result.data.data.id
                setData(updateData)
            }
            if (createPlace.index !== null && createPlace.section === 3) {    //ถ้าเพิ่มข้อมูลอาชีพที่เคยทำ อยู่ส่วนที่ 3 ของฟอร์ม
                let updateData = { ...data }
                updateData.third[createPlace.index].place = business.name
                updateData.third[createPlace.index].placeId = result.data.data.id
                setData(updateData)
            }

            setCreatePlace({...createPlace, status: !createPlace.status})  //ปิด modal นี้
            getBusiness()
            alert.show('บันทึกข้อมูลสถานประกอบการสำเร็จ!', {
                type: 'success',
            })
        }catch(e){
            console.log(e)
        }
    }
    return (
        <>
            <Modal
                size='xl'
                show={createPlace.status}
                onHide={() => setCreatePlace({ ...createPlace, status: !createPlace.status })}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>เพิ่มข้อมูลสถานประกอบการ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <h5>ข้อมูลผู้บริหาร</h5>
                            <hr></hr>
                            <Form.Group className="mb-2"> <Form.Label>คำนำหน้า</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={(e) => setOwner({ ...owner, title: e.target.value })}>
                                    <option value=""></option>
                                    <option value="นาย">นาย</option>
                                    <option value="นาง">นาง</option>
                                    <option value="นางสาว">นางสาว</option>
                                </Form.Select></Form.Group>

                            <Form.Label>ชื่อ</Form.Label>
                            <Form.Control type="text" placeholder="ชื่อ" onChange={(e) => setOwner({ ...owner, name: e.target.value })} />
                            <Form.Label>นามสกุล</Form.Label>
                            <Form.Control type="text" placeholder="นามสกุล" onChange={(e) => setOwner({ ...owner, lastname: e.target.value })} />
                            <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            <Form.Control type="number" placeholder="เบอร์โทรศัพท์" onChange={(e) => setOwner({ ...owner, phone: e.target.value })} />
                            <Form.Label>อีเมล์</Form.Label>
                            <Form.Control type="email" placeholder="อีเมล์" onChange={(e) => setOwner({ ...owner, email: e.target.value })} />
                        </Col>
                        <Col md={6}>
                            <h5>ข้อมูลผู้สถานประกอบการ</h5>
                            <hr></hr>
                            <Form.Label>ชื่อสถานประกอบการ</Form.Label>
                            <Form.Control type="text" placeholder="ชื่อสถานประกอบการ" onChange={(e) => setBusiness({ ...business, name: e.target.value })} />
                            <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            <Form.Control type="text" placeholder="เบอร์โทรศัพท์" onChange={(e) => setBusiness({ ...business, phone: e.target.value })} />
                            <Form.Label>ประเภท</Form.Label>
                            <Form.Control type="text" placeholder="ประเภท" onChange={(e) => setBusiness({ ...business, type: e.target.value })} />
                            <Form.Label>ลักษณะ</Form.Label>
                            <Form.Control type="text" placeholder="ลักษณะ" onChange={(e) => setBusiness({ ...business, style: e.target.value })} />
                            <Form.Group className="mt-2 mb-2">
                                <Form.Label>ส่งออก/ไม่ส่งออก</Form.Label>
                                <Form.Check
                                    type='radio'
                                    label={`ส่งออก`}
                                    id={`export`}
                                    name="export"
                                    onClick={(e) => setBusiness({ ...business, export: true })}
                                />
                                <Form.Check
                                    type='radio'
                                    label={`ไม่ส่งออก`}
                                    id={`export`}
                                    name="export"
                                    onClick={(e) => setBusiness({ ...business, export: false })}
                                />
                            </Form.Group>

                            <Form.Group className="mb-2"> <Form.Label>ขนาด</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={(e) => setBusiness({ ...business, bussinessSize: e.target.value })}>
                                    <option value=""></option>
                                    <option value="เล็ก">เล็ก</option>
                                    <option value="กลาง">กลาง</option>
                                    <option value="ใหญ่">ใหญ่</option>
                                </Form.Select></Form.Group>
                            <Form.Label>จำนวนพนักงาน</Form.Label>
                            <Form.Control type="number" placeholder="จำนวนคน" onChange={(e) => setBusiness({ ...business, sizePeople: e.target.value })} />
                            <Form.Label>เว็บไซต์</Form.Label>
                            <Form.Control type="text" placeholder="เว็บไซต์" onChange={(e) => setBusiness({ ...business, website: e.target.value })} />
                            <Form.Group className="mt-2 mb-2">
                                <Form.Label>วันที่จด</Form.Label> <br></br>
                                <WatDatePicker
                                    /*      value={data.first.information.dateBirthdayTH} */
                                    inputStyle={{ height: "38px", width: "auto" }}
                                    onChange={handleWatDatePickerChange}
                                    dateFormat={'yyyy-MM-dd'}
                                    displayFormat={'DD MMMM YYYY'}
                                    clearable={true}
                                    disabled={false}
                                    readOnly={false}
                                    maxDate={"2030-1-1"}
                                    yearBoundary={99}
                                />
                            </Form.Group>

                            <Form.Label>ทุนที่จด</Form.Label>
                            <Form.Control type="number" placeholder="ทุนที่จด" onChange={(e) => setBusiness({ ...business, capital: e.target.value })} />
                            <Form.Label>สถานะการจด</Form.Label>
                            <Form.Control type="text" placeholder="สถานะการจด" onChange={(e) => setBusiness({ ...business, registerStatus: e.target.value })} />
                            <Form.Label>ลักษณะที่จด</Form.Label>
                            <Form.Control type="text" placeholder="ลักษณะที่จด" onChange={(e) => setBusiness({ ...business, registerStyle: e.target.value })} />
                        </Col>
                        <hr style={{ margin: '20px' }}></hr>
                        <Col>
                            <h5>ข้อมูลที่อยู่สถานประกอบการ</h5>
                            <Row>
                                <Col md={4}>
                                    <Form.Label>เลขที่บ้าน</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setAddress({ ...address, no: e.target.value })} />
                                </Col>
                                <Col md={4}><Form.Label>หมู่/ซอย</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setAddress({ ...address, moo: e.target.value })} /></Col>
                                <Col md={4}> <Form.Label>ถนน</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setAddress({ ...address, road: e.target.value })} /> </Col>
                                <Col md={3}> <Form.Label>จังหวัด</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={(e) => provinceChange(e)}>
                                        <option></option>
                                        {province.map(province => {
                                            return (
                                                <option key={province.value} value={province.value}>{province.text}</option>
                                            )
                                        })}
                                    </Form.Select></Col>
                                <Col md={3}>
                                    <Form.Label>อำเภอ</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={(e) => amphurChange(e)} disabled={amphur.length === 0 ? true : false}>
                                        <option></option>
                                        {amphur.map(amphur => {
                                            return (
                                                <option key={amphur.value} value={amphur.value}>{amphur.text}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>ตำบล</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={(e) => tambolChange(e)} disabled={tambol.length === 0 ? true : false}>
                                        <option></option>
                                        {tambol.map(tambol => {
                                            return (
                                                <option key={tambol.value} value={tambol.value}>{tambol.text}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>รหัสไปษณีย์</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setAddress({ ...address, zipCode: e.target.value })} />
                                </Col>
                            </Row>

                            <hr></hr>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => onSubmitBussiness()}>เพิ่มข้อมูล</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreatePlace