import React from 'react'
import { Form, Row, Col} from 'react-bootstrap'

function SectionFifth({isEdit,data, setData}) {

    console.log('SectionFifth',data);
    const addNewCommunityResource = () => {
        let updateData = { ...data }
        updateData.fifth.social.push({
            resource_name: "",
            resource_address: "",
            resource_objective: "",
            resource_supporter: "",
            org_name: "",
            title: "",
            first_name: "",
            last_name: "",
        })
        setData(updateData)
    }
    const removeCommunityResource = (index) => {
        let updateData = { ...data }
        updateData.fifth.social.splice(index, 1)
        setData(updateData)
    }

    const handleChange = (e, index) => {
        let updateData = { ...data }
        updateData.fifth.social[index][e.target.name] = e.target.value
        setData(updateData)
    }
    return (
         <Row>
            <h5 className="mb-4 text-b">ข้อมูลทรัพยากรสังคม</h5>
                {data?.fifth && data.fifth.social.map((item, index) => {
                    return (
                        <Col md={12} key={index} className="mt-3">
                            <Row>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label><label className="text-danger" style={{ cursor: 'pointer' }} onClick={() => removeCommunityResource(index)}>X</label> {index + 1}. ภายใต้การดูแล</Form.Label>
                                        <Form.Select aria-label="Default select example" value={item.resource_type} name="resource_type" onChange={(e) => handleChange(e, index)} >
                                            <option></option>
                                            <option value="ภาครัฐ">ภาครัฐ</option>
                                            <option value="ภาคประชาสังคม">ภาคประชาสังคม</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>ประเภทกลุ่ม</Form.Label>
                                        <Form.Select aria-label="Default select example" value={item.resource_group} name="resource_group" onChange={(e) => handleChange(e, index)} >
                                            <option></option>
                                            <option value="กลุ่มการผลิต/การแปรรูป">กลุ่มการผลิต/การแปรรูป</option>
                                            <option value="กลุ่มการเงิน (กองทุน/กลุ่มออมทรัพย์)">กลุ่มการเงิน (กองทุน/กลุ่มออมทรัพย์)</option>
                                            <option value="กลุ่มสวัสดิการสังคม (กลุ่มฌาปนกิจ/กองทุนสวัสดิการชุมชน ฯลฯ)">กลุ่มสวัสดิการสังคม (กลุ่มฌาปนกิจ/กองทุนสวัสดิการชุมชน ฯลฯ)</option>
                                            <option value="กลุ่มด้านสังคม (กลุ่มเยาวชน/กลุ่มผู้สูงอายุ/กลุ่มแม่บ้าน/กลุ่ม">กลุ่มด้านสังคม (กลุ่มเยาวชน/กลุ่มผู้สูงอายุ/กลุ่มแม่บ้าน/กลุ่ม</option>
                                            <option value="กลุ่มด้านสังคม (กลุ่มเยาวชน/กลุ่มผู้สูงอายุ/กลุ่มแม่บ้าน/กลุ่มสตรี/กลุ่มศาสนา ฯลฯ)">กลุ่มด้านสังคม (กลุ่มเยาวชน/กลุ่มผู้สูงอายุ/กลุ่มแม่บ้าน/กลุ่มสตรี/กลุ่มศาสนา ฯลฯ)</option>
                                            <option value="กลุ่มทรัพยากรธรรมชาติ (กลุ่มอนุรักษ์ฯ ฯลฯ)">กลุ่มทรัพยากรธรรมชาติ (กลุ่มอนุรักษ์ฯ ฯลฯ)</option>
                                            <option value="กลุ่มอื่นๆ">กลุ่มอื่นๆ</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {item.resource_group === "กลุ่มอื่นๆ"?
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>ชื่อประเภทกลุ่มอื่นๆ</Form.Label>
                                            <Form.Control type="text" value={item.resource_group_other} name="resource_group_other" onChange={(e) => handleChange(e, index)} />
                                        </Form.Group>
                                    </Col>
                                    :
                                    null
                                }
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>ชื่อ</Form.Label>
                                        <Form.Control type="text" value={item.resource_name} name="resource_name" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>หน้าที่ หรือ จุดประสงค์ของกลุ่ม</Form.Label>
                                        <Form.Control type="text" value={item.resource_objective} name="resource_objective" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>สถานที่ทำการ</Form.Label>
                                        <Form.Control type="text" value={item.resource_address} name="resource_address" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                
                                {!!data.fifth.social[index].resource_group && data.fifth.social[index].resource_group.includes('กลุ่มการเงิน') && 
                                    <>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>มีเงินหมุนเวียน (บาท)</Form.Label>
                                                <Form.Control type="number" min={0} value={item.cooperative_money} name="cooperative_money" onChange={(e) => handleChange(e, index)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>มีสมาชิก (คน)</Form.Label>
                                                <Form.Control type="number" min={0} value={item.cooperative_user} name="cooperative_user" onChange={(e) => handleChange(e, index)} />
                                            </Form.Group>
                                        </Col>
                                    </> 
                                }
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>คำนำหน้าชื่อ (หัวหน้ากลุ่ม)</Form.Label>
                                        <Form.Select aria-label="Default select example" value={item.leader_title} name="leader_title" onChange={(e) => handleChange(e, index)} >
                                            <option></option>
                                            <option value="นาย">นาย</option>
                                            <option value="นาง">นาง</option>
                                            <option value="นางสาว">นางสาว</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>ชื่อ (หัวหน้ากลุ่ม)</Form.Label>
                                        <Form.Control type="text" value={item.leader_firstname} name="leader_firstname" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>นามสกุล (หัวหน้ากลุ่ม)</Form.Label>
                                        <Form.Control type="text" value={item.leader_lastname} name="leader_lastname" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>เบอร์ติดต่อ (หัวหน้ากลุ่ม)</Form.Label>
                                        <Form.Control type="number" min={0} value={item.leader_tell} name="leader_tell" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>email (หัวหน้ากลุ่ม)</Form.Label>
                                        <Form.Control type="text" value={item.leader_email} name="leader_email" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>คำนำหน้าชื่อ (ผู้ประสานงาน)</Form.Label>
                                        <Form.Select aria-label="Default select example" value={item.contact_title} name="contact_title" onChange={(e) => handleChange(e, index)} >
                                            <option></option>
                                            <option value="นาย">นาย</option>
                                            <option value="นาง">นาง</option>
                                            <option value="นางสาว">นางสาว</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>ชื่อ (ผู้ประสานงาน)</Form.Label>
                                        <Form.Control type="text" value={item.contact_firstname} name="contact_firstname" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>นามสกุล (ผู้ประสานงาน)</Form.Label>
                                        <Form.Control type="text" value={item.contact_lastname} name="contact_lastname" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>เบอร์ติดต่อ (ผู้ประสานงาน)</Form.Label>
                                        <Form.Control type="number" min={0} value={item.contact_tell} name="contact_tell" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>email (ผู้ประสานงาน)</Form.Label>
                                        <Form.Control type="text" value={item.contact_email} name="contact_email" onChange={(e) => handleChange(e, index)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>หน่วยงานที่ให้การสนับสนุน</Form.Label>
                                        <div className="d-flex justify-content-around">
                                            <Form.Check
                                                checked={item.org_supporter === "ไม่มี"}
                                                type={'radio'}
                                                name={`org_supporter_${index}`}
                                                onChange={(e) => {
                                                    let updateData = { ...data }
                                                    updateData.fifth.social[index].org_supporter = "ไม่มี"
                                                    setData(updateData)
                                                }}
                                                label={`ไม่มี`}
                                                id={`disabled-default-radio`}
                                            />
                                            <Form.Check
                                                checked={item.org_supporter === "หน่วยงานภาครัฐ"}
                                                type={'radio'}
                                                name={`org_supporter_${index}`}
                                                onChange={(e) => {
                                                    let updateData = { ...data }
                                                    updateData.fifth.social[index].org_supporter = "หน่วยงานภาครัฐ"
                                                    setData(updateData)
                                                }}
                                                label={`ภาครัฐ`}
                                                id={`disabled-default-radio`}
                                            />
                                            <Form.Check
                                                checked={item.org_supporter === "หน่วยงานภาคเอกชน"}
                                                type={'radio'}
                                                name={`org_supporter_${index}`}
                                                onChange={(e) => {
                                                    let updateData = { ...data }
                                                    updateData.fifth.social[index].org_supporter = "หน่วยงานภาคเอกชน"
                                                    setData(updateData)
                                                }}
                                                label={`ภาคเอกชน`}
                                                id={`disabled-default-radio`}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                {item.org_supporter === 'หน่วยงานภาครัฐ' || data.fifth.social[index].org_supporter === 'หน่วยงานภาคเอกชน' &&
                                    <>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>ชื่อหน่วยงาน</Form.Label>
                                                <Form.Control type="text" value={item.org_name} name="org_name" onChange={(e) => handleChange(e, index)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>จำนวนเม็ดเงินที่ได้รับการสนับสนุน (บาท)</Form.Label>
                                                <Form.Control type="number" value={item.support_money} name="support_money" onChange={(e) => handleChange(e, index)} />
                                            </Form.Group>
                                        </Col>
                                    </>
                                    
                                }
                            </Row>
                            <hr></hr>
                        </Col>
                    )
                })}

                {!isEdit && <Col md={12}>
                    <div className="d-flex justify-content-center">
                        <div className="btn btn-success" onClick={() => addNewCommunityResource()}>
                            เพิ่มข้อมูลทรัพยากรสังคม
                        </div>
                    </div>
                </Col>}
                </Row>
    )
}

export default SectionFifth