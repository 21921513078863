import React from "react";
import EducationLavelOverview from "./educatioLavelOverview/educatioLavelOverview";
import EducationLevelDetial from "./educationLevelDetial/educationLevelDetial";
import styled from "styled-components";
import axios from "axios";
import { apiConfig } from "../../../../config/api";
import LoadingPage from "../../../loadingPage/loadingPage";
import EducationLevelTable from "./educationLevelDetial/educationLevelTable/educationLevelTable";
import { Button } from "react-bootstrap";
// import { educationOverviewMockData } from "../../../mock/dashboard/personal/education";
function EducationLavel({ address, display, auth,loadingPageCoload,token }) {
  const [educationLevelId, setEducationLevelId] = React.useState("all");
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const overviewref = React.useRef();

  async function getEducationLeveOverviewlData() {
    setData({});
    if (!address.tambol) return false;
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/get-education-overview/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`
      );
      if (res.status === 200) {
        setLoading(false);
        setData(res.data);

        console.log("educationOverview", res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  React.useEffect(() => {
    getEducationLeveOverviewlData();
  }, [address.group, address.tambol]);

  return (
    <MainContent>
      <div className="title">
        <LoadingPage
          borderRadius={"5px"}
          loading={loading}
          animatioColor={loadingPageCoload}
        />
        <span>ข้อมูลโดยประชากรจำนวน {data?.total?.total} คน</span>
        <span>
          {!!data?.total?.total
            ? "แสดงผลคิดเป็นร้อยละ " +
              ((data.total.total / data.total.population) * 100).toFixed(2) +
              "  จากข้อมูลประชากรตามทะเบียนราษฎร์"
            : "-"}{" "}
        </span>
      </div>
      <div className="overview-content">
        <LoadingPage
          borderRadius={"5px"}
          loading={loading}
          animatioColor={loadingPageCoload}
        />
        <div className="overview-content-title">
          <span>ภาพรวม</span>
          {auth && (
            <Button
              size="sm"
              onClick={() =>
                overviewref.current.getPersonalInfoAllCareerAndOverview()
              }
            >
              รายชื่อ
            </Button>
          )}
        </div>
        <EducationLavelOverview
          ref={overviewref}
          address={address}
          data={data?.overview}
        />
      </div>
      <div className="education-level-table">
        <LoadingPage
          borderRadius={"5px"}
          loading={loading}
          animatioColor={loadingPageCoload}
        />
        <div className="edu-table">
          <div className="edu-table-title">
            ข้อมูลการศึกษาที่แสดงในรูปแบบตาราง
          </div>
          <div className="edu-table-content">
            <EducationLevelTable auth={auth} token={token} address={address} data={data?.dataTable} />
          </div>
        </div>
      </div>
      <div className="education-level-detial">
        <LoadingPage
          borderRadius={"5px"}
          loading={loading}
          animatioColor={loadingPageCoload}
        />
        {/* <RenderType display={display} setDisplay={setEducationInfoDisplay} /> */}
        <EducationLevelDetial
          setEducationLevelId={setEducationLevelId}
          educationLevelId={educationLevelId}
          display={display}
          address={address}
          data={data}
          auth={auth}
        />
      </div>
    </MainContent>
  );
}

export default EducationLavel;
const MainContent = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  justify-content: center;
  padding: 0px 5px 5px 5px;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "title education-level-table"
    "education-overview-content education-level-table"
    "education-level-detial education-level-detial";
  .title {
    position: relative;
    height: 10vh;
    display: flex;
    grid-area: title;
    background-color: white;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px 10px 15px;
  }
  .overview-content {
    position: relative;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    background-color: white;
    grid-area: education-overview-content;
    padding: 10px 10px 25px 15px;
    .overview-content-title {
      display: flex;
      justify-content: space-between;
    }
  }
  .education-level-detial {
    position: relative;
    overflow: hidden;
    grid-area: education-level-detial;
  }
  .education-level-table {
    position: relative;
    grid-area: education-level-table;
    .edu-table {
      border-radius: 5px;
      background-color: #fff;
      padding: 10px 15px 10px 15px;
      .edu-table-content {
        margin-top: 10px;
      }
    }
  }
`;
