import React from 'react'
import { Pagination } from 'react-bootstrap';
import styled from 'styled-components';

function PaginationSelftInput({ page, getDashboard, pagiData }) {
    console.log('pagiData', pagiData);
    let items = [];
    for (let number = 1; number <= 2; number++) {
        if (number + page - 1 !== pagiData.last_page + 1) {
            items.push(
                <>
                    <Pagination.Item onClick={() => getDashboard(number + page - 1)} key={number} active={number + page - 1 === pagiData.current_page}>
                        {number + page - 1}
                    </Pagination.Item>
                </>


            );
        }
    }

    return (
        <Pagistyle>
            {page !== 1 &&
                <>
                    <Pagination.First
                        onClick={() => getDashboard(1)}
                    />
                    <Pagination.Prev
                        onClick={() => getDashboard(page - 1)}
                    />
                    <Pagination.Ellipsis disabled />
                    <Pagination.Item
                        onClick={() => getDashboard(page - 1)}
                    >
                        {page - 1}
                    </Pagination.Item>
                </>
            }
            {items}
            {page !== pagiData.last_page &&
                <>
                    <Pagination.Next
                        onClick={() => getDashboard(page + 1)}
                    />
                    <Pagination.Ellipsis disabled />
                    <Pagination.Last
                        onClick={() => getDashboard(pagiData.last_page)}
                    />
                </>

            }
        </Pagistyle>
    )
}

export default PaginationSelftInput
const Pagistyle = styled(Pagination)`
    margin-top: 20px;
`