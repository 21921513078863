import axios from "axios";
import React from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { apiConfig } from "../../../../config/api";
import LoadingPage from "../../../loadingPage/loadingPage";
// import { sikillMockData } from "../../../mock/dashboard/personal/skill";
import CareerSkillContent from "./careerSkill/careerSkillContent/careerSkillContent";
import CareerSkillDescription from "./careerSkill/careerSkillDescription/careerSkillDescription";
import ComputerSkillContent from "./computerSkill/computerSkillContent/computerSkillContent";
import ComputerSkillDescription from "./computerSkill/computerSkillDescription/computerSkillDescription";
import LanguageSkill from "./languageSkill/languageSkillContent/languageSkill";
import LanguageSkillDescription from "./languageSkill/languageSkillDescription/languageSkillDescroption";


export default function Skill({ address,loadingPageCoload,/* auth */ }) {
  const [data, setData] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  async function getLanguageSkill() {
    if (!address.tambol) return false;
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/get-SkillInfo/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`
      );
      if (res.status === 200) {
        setLoading(false);
        setData(res.data);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "error",
        text: error,
        icon: "error",
      });
    }
  }
  React.useEffect(() => {
    setData({})
    getLanguageSkill()
  }, [address.group, address.tambol]);
  return (
    <Main>
      
      <div className="skill">
        <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="skill-title">ข้อมูลทักษะด้านภาษา</div>
          <LanguageSkill address={address} LanguageSkillData={data?.LanguageSkillData} />
        <div className="skill-description">
          <LanguageSkillDescription LanguageSkillData={data?.LanguageSkillData} />
        </div>
      </div>
      <div className="skill">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="skill-title">ข้อมูลทักษะด้านคอมพิวเตอร์</div>
          <ComputerSkillContent address={address} ComputerSkilltData={data?.ComputerSkillData} />
        <div className="skill-description">
          <ComputerSkillDescription ComputerSkilltData={data?.ComputerSkillData} />
        </div>
      </div>
      <div className="skill">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="skill-title">ข้อมูลทักษะด้านการประกอบอาชีพ</div>
          <CareerSkillContent address={address} careerSkillData={data?.CareerSkillData} />
        <div className="skill-description">
          <CareerSkillDescription careerSkillData={data?.CareerSkillData} />
        </div>
      </div>
    </Main>
  );
}
const Main = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 100%;
  .skill {
    width: 100%;
    padding: 10px;
    display: grid;
    grid-gap: 10px;
    position: relative;
    border-radius: 5px;
    background-color: #ffff;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "skill-title skill-description"
      "skill-content skill-description";
    .skill-title {
      grid-area: skill-title;
    }
    .skill-content {
      /* background-color: #fff1f1; */
      border-right:solid 1px #ededed;
      /* border-radius: 5px; */
      width: 100%;
      grid-area: skill-content;
    }
    .skill-description {
      padding: 10px;
      grid-area: skill-description;
    }
  }
`;
