import React from "react";
import { Doughnut } from "react-chartjs-2";

function DonutBox({ chartData, pathname, section, getDataTable, cutout }) {
  const couter = {
    id: "couter",
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, width, height },
      } = chart;
      const text = chart?.data?.datasets[0].label || 0;
      const ycenter = top + height / 2;
      ctx.fillStyle = "black";
      ctx.font = "18px FCIconic";
      ctx.textAlign = "center";
      ctx.fillText("รวม", width / 2 + 20, ycenter - 15, 90, 50);
      ctx.fillText(text, width / 2 + 20, ycenter + 10, 90, 50);
      ctx.fillText("คน", width / 2 + 20, ycenter + 35, 90, 50);
    },
  };
  const donutLabelsLine = {
    id: "donutLabelsLine",
    afterDraw(chart) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;
      const data = !!chart.config._config.options.pointReferenceLetters
        .references
        ? chart.config._config.options.pointReferenceLetters.references[0]
        : [];
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
          //draw line
          const halfwidth = width / 2;
          const halfheight = height / 2;
          const xLine = x >= halfwidth ? x + 20 : x - 20;
          const yLine = y >= halfheight ? y + 20 : y - 20;
          const extraLine = x >= halfwidth ? 20 : -20;
          //line
          if (!!chart.data.labels[index]) {
            ctx.beginPath();
            ctx.moveTo(
              x >= halfwidth ? xLine - 10 : xLine + 10,
              y >= halfheight ? yLine - 10 : yLine + 10
            );
            ctx.lineTo(xLine, yLine);
            ctx.lineTo(xLine + extraLine, yLine);
            ctx.strokeStyle = "balck";
            ctx.stroke();
          }
          //text
          ctx.font = "16px FCIconic";
          //control Position
          const textPosition = x >= halfwidth ? "left" : "right";
          const plusFivePx = x >= halfwidth ? 5 : -5;
          ctx.textBaseLine = "middle";
          ctx.textAlign = textPosition;
          ctx.fillStyle = "balck";
          ctx.fillText(
            !!chart.data.labels[index] ? chart.data.labels[index] : [],
            xLine + extraLine + plusFivePx,
            yLine
          );
          ctx.fillText(
            !!data?.averageCount  ? data?.averageCount[index] > 0 && `${data.averageCount[index]}%` : [],
            x >= halfwidth ? xLine - 30 : xLine + 30,
            y >= halfheight ? yLine - 30 : yLine + 30
          );
        });
      });
    },
  };
  return (
      <Doughnut
        options={{
          onClick(click, element, chart) {
            const index = element[0].index;
            const datasetIndex = element[0].datasetIndex;
            const datasetTitle = chart.config.data.datasets[datasetIndex].label;
            const title = chart.config.data.labels[index];
            getDataTable(pathname, section, title, datasetTitle);
          },
          pointReferenceLetters: {
            display: false,
            references: [chartData],
          },
          maintainAspectRatio: false,
          responsive: true,
          cutout: cutout,
          plugins: {
            legend: { display: false },
            tooltip: { enabled: !!chartData ? true : false },
          },
          layout: {
            padding: 20,
          },
          hover: { mode: !!chartData ? "point" : null },
        }}
        plugins={[donutLabelsLine, couter]}
        data={{
          labels: !!chartData ? [...chartData?.title] : [],
          datasets: [
            {
              label: !!chartData ? chartData.sum : [],
              data: !!chartData ? [...chartData.data] : [10, 10, 10],
              backgroundColor: !!chartData
                ? [...chartData.color]
                : ["#d5eeff", "#d5eeff", "#d5eeff"],
              hoverOffset: 4,
            },
          ],
        }}
      />
  );
}

export default DonutBox;
