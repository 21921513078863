import { TypesLayout } from "../../enums/enum"
import Page404 from "../../page/default/page404"

export default [
    {
      path: "/500",
      sidebar: false,
      exact:true,
      component: Page404,
      layout: TypesLayout.none,
    },
    {
      path: "/401",
      sidebar: false,
      exact:true,
      component: Page404,
      layout: TypesLayout.none,
    },
    {   
      path:"**",
      sidebar:false,
      exact:true,
      component: Page404,
      layout: TypesLayout.none,
  },
  ] 