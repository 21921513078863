import React from 'react'
import { Container, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Selecter from '../../../component/dashboard/personal/selecter/selecterAddress'
import { ApiFetch } from '../../../config/api'
import { useAuthContextState } from '../../../context/auth/authContext/authContextState'
import { FetchInterface, Method } from '../../../enums/enum'
import PaginationSelftInput from '../../dataCollector/dashboard/pagination/pagination'
import TableUser from '../../dataCollector/dashboard/table/TableAdmin'
import TableDetial from '../../dataCollector/dashboard/table/tableDetial/tableDetial'

function Personal() {
  const [userData, setUserData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [address, setAddress] = React.useState({
    provice: null,
    amphur: null,
    tambol: null,
    group: null,
  })
  const { token } = useAuthContextState()
  async function getUser(page) {
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/get-personal-by-address/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]:{page:page}
      })
      if (res.status === 200) {
        console.log('data',res);
        setUserData(res.data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getUser(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.tambol])
  return (
    <Main>
      <Container>
        <div className="title">
          <div className='select'>
            <Selecter setAddress={setAddress} address={address} />
          </div>
        </div>
        <div className="content">
          <TableDetial 
            loading={loading}
            userData={userData?.data}
            pagidata={userData}
            page={userData.current_page}
          />
          {userData?.data?.length > 0 && 
          <PaginationSelftInput
          pagiData={userData}
          getDashboard={getUser}
          page={userData.current_page}
        />}
          
        </div>
      </Container>
    </Main>
  )
}
export default Personal
const Main = styled.div`
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .title {
    border-radius: 10px;
    background-color: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    padding: 0 15px 0 15px;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select{
      width: 50%;
    }
  }
  .content {
    width: 100%;
    height: 82vh;
    display: flex;
    padding: 10px;
    margin-top: 15px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }
`