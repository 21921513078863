import React from "react";
import { ApiFetch } from "../../../../../config/api";
import { useAuthContextState } from "../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../enums/enum";
import DonutChart from "../../../../donutChart/donutChart";
import PersonalTableDashboard from "../../../../personalTableDashboard/personalTableDashboard";

const WelfareOverview = React.forwardRef(function WelfareOverview({ data, address },ref) {
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  React.useImperativeHandle(ref, () => ({
    async getPersonalInfoAllAgeRange() {
      setModalShow(true);
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/welfare-personal-table-info/overview/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: null,
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: { datasetTitle: "-", title: "ทุกสวัสดิการ" },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    },
  }));
  return (
    <>
      <div style={{ height: "83vh" }}>
        <DonutChart
          setTableDataForFilter={setTableDataForFilter}
          address={address}
          cutout={80}
          pathname={"welfare-personal-table-info"}
          section={"overview"}
          setModalShow={setModalShow}
          setTableData={setTableData}
          chartData={!!data ? data : 0}
        />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียด เพศ ${tableData.header.datasetTitle} ประเภทที่พักอาศัย ${tableData.header.title} ใน ${address.addressName} จำนวน ${tableData?.data?.data?.length} คน`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
});
export default WelfareOverview;
