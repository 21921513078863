import React from "react";
import styled from "styled-components";
import { ApiFetch } from "../../../../../../config/api";
import { FetchInterface, Method } from "../../../../../../enums/enum";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";
// import { educationDetialMockData } from "../../../../../mock/dashboard/personal/education";

export default function EducationLevelTable({ data, address, auth, token }) {
  // const data = educationDetialMockData.dataTable
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  async function PersonalInfomation(
    educationStatus,
    educationType,
    ageRange,
    gender
  ) {
    if ((!educationStatus && !educationType && !ageRange && !gender) || !auth) {
      return false;
    }
    setModalShow(true);
    const titleData = {
      educationStatus: educationStatus,
      educationType: educationType,
      ageRange: ageRange,
      gender: gender,
    };
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/education-personal-table-info/dataTable/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });
      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({
          header: {
            datasetTitle: "-",
            title: `${educationStatus} ประเภทสถาบัน ${educationType} ช่วงอายุ ${ageRange} เพศ ${gender}`,
          },
          data: res.data,
        });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }
  return (
    <>
      <div style={{ height: "59vh", overflowY: "auto" }}>
        <EducationTable isData={!!data?.data}>
          <div className="head">
            <div className="table-title">
              {!!data?.title &&
                data.title.map((title, inx) => (
                  <span className="text-title" key={inx}>
                    {title}
                  </span>
                ))}
            </div>
          </div>
          <div className="body">
            <div className="table-content">
              {!!data?.data &&
                data.data.map((educationStatus, inx) => (
                  <EducationStatus key={inx}>
                    <div className="education-status-title">
                      {educationStatus.studentStatusTitle}
                    </div>
                    <div className="education-type-list">
                      {!!educationStatus?.educationTypeList &&
                        educationStatus.educationTypeList.map(
                          (educationType, inx) => (
                            <EducationType key={inx} educationTypeTitle={educationType.educationTypeTitle}>
                              <div className="education-type-title">
                                {educationType.educationTypeTitle}
                              </div>
                              <div className="ageRange-list">
                                {!!educationType?.ageRangeList &&
                                  educationType?.ageRangeList.map(
                                    (ageRange, inx) => (
                                      <AgeRange key={inx} ageRangeTitle={ageRange.ageRangeTitle}>
                                        <div className="ageRage-title">
                                          {ageRange.ageRangeTitle}
                                        </div>
                                        <div className="gender-list">
                                          {!!ageRange?.genderList &&
                                            ageRange.genderList.map(
                                              (gender, inx) => (
                                                <Gender
                                                  key={inx}
                                                  auth={auth}
                                                  genderTitle={
                                                    gender.genderTitle
                                                  }
                                                >
                                                  <div className="gender-title">
                                                    {gender.genderTitle}
                                                  </div>
                                                  <div
                                                    className="people-sum"
                                                    onClick={() =>
                                                      auth &&
                                                      PersonalInfomation(
                                                        educationStatus.studentStatusTitle,
                                                        educationType.educationTypeTitle,
                                                        ageRange.ageRangeTitle,
                                                        gender.genderTitle
                                                      )
                                                    }
                                                  >
                                                    <div className="count-title">
                                                      {gender.count}
                                                    </div>

                                                    <div className="average-title">
                                                      {gender.average}
                                                    </div>
                                                  </div>
                                                </Gender>
                                              )
                                            )}
                                        </div>
                                      </AgeRange>
                                    )
                                  )}
                              </div>
                            </EducationType>
                          )
                        )}
                    </div>
                  </EducationStatus>
                ))}
            </div>
          </div>
        </EducationTable>
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียด สถานะการศึกษา ${tableData.header.title}`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
}

const EducationTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-left: solid 1.6px #aaaaaa;
  border-right: solid 1.6px #aaaaaa;
  .head {
    display: grid;
    background-color: #38afba;
    top: 0;
    height: 30px;
    position: sticky;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    .table-title {
      display: grid;
      align-content: center;
      grid-template-columns: 30% 20% 20% 10% 10% 10%;
      .text-title {
        padding-left: 5px;
      }
    }
  }
  .body {
    width: 100%;
    display: flex;
    .table-content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;
const EducationStatus = styled.div`
  width: 100%;
  display: flex;
  .education-status-title {
    width: 30%;
    padding-top: 5px;
    padding-left: 5px;
    border-bottom: solid 1.6px #aaaaaa;
  }
  .education-type-list {
    width: 70%;
  }
`;
const EducationType = styled.div`
  width: 100%;
  display: flex;
  .education-type-title {
    width: 39.5%;
    display: flex;
    padding-top: 5px;
    padding-left: 5px;
    border-left: ${({ educationTypeTitle }) =>
        !!educationTypeTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .ageRange-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const AgeRange = styled.div`
  width: 100%;
  display: flex;
  .ageRage-title {
    width: 65.5%;
    padding-top: 5px;
    padding-left: 5px;
    border-left: ${({ ageRangeTitle }) =>
        !!ageRangeTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .gender-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const Gender = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
    .gender-title {
      width: 50%;
      padding-top: 5px;
      padding-left: 5px;
      border-left: ${({ genderTitle }) =>
        !!genderTitle && "solid 1.6px #aaaaaa"};
      border-bottom: solid 1.6px #aaaaaa;
    }
    .people-sum {
      width: 100%;
      display: flex;
      &:hover {
        ${({auth}) => !!auth && `cursor: pointer;background-color: #f0f0f0;`};
      }
      .count-title {
        width: 50%;
        padding-top: 5px;
        padding-left: 5px;
        border-bottom: solid 1.6px #aaaaaa;
        border-left: solid 1.6px #aaaaaa;
      }
      .average-title {
        width: 50%;
        padding-top: 5px;
        padding-left: 5px;
        border-bottom: solid 1.6px #aaaaaa;
        border-left: solid 1.6px #aaaaaa;
      }
    }
  
`;
