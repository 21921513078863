import React, { useState, createContext } from "react";

// Create Context Object
export const PersonalContext = createContext();


// Create a provider for components to consume and subscribe to changes
export const PersonalContextProvider = (props) => {
    const [data, setData] = useState({
        first: {
            information: {
                title: '',
                first_name: '',
                last_name: '',
                date_birth: '',
                religion: '',
                main_nation: '',
                gender: '',
                marital_status: '',
                father_id:'',
                father_name: '',
                father_surname:'',
                mothor_id:'',
                mother_name:'',
                mothor_surname:'',
                tell: '',
                email: '',
                

            },
            address: {
                house_number: '',
                address_no: '',
                address_mooban: '',
                address_group: '',
                tambol_id: null,
                amphur_id: null,
                province_id: null,
            },
            occupation: {
                main: {
                    job: '',
                    place: '',
                    salary: 0
                },
                additional: {
                    status: false,
                    data:[]
                }
            },
            garden:{
                have:false,
                data:[]
            },
            physicalImpairment: {
                body: {
                    status: false,
                    name: '',
                    date: '',
                    description: '',
                    hospitalName:'',
                    hospitalId: ''
                },
                other: {
                    status: false,
                    name: '',
                    date: '',
                    description: '',
                    hospitalId: ''
                }
            },
            communityRole:[],
            sage:{
                have:false,
                data:[]
            },
            saved:false
        },
        second:{
           education:[
            {
                id:0,
                level:null,
                start:null,
                end:null,
                status:null,
                schoolName:null
            }
        ],
        saved:false
        }
        ,
        third:{
            experience:[
                {
                    id:0,
                    occupation: null,
                    job_start: null,
                    job_end: null,
                    job_detail: null,
                    bussinessId: null,
                    leaveReason:null,
                }
            ],
            saved:false  
        }
         ,
        fourth:{
            train:[],
            language:[],
            skill:[],
            saved:false
        },
        fifth:{
            family:[],
            saved:false
        } ,
        sixth: {
            now:[],
            before:[],
            other:[],
            saved:false
        },
    })
    const [dataReferent,setDataReferent] = React.useState('first')
    const [parentId,setParentId] = React.useState(null)
    const [personalId, setPersonalId] = React.useState(null)

    return (
        <PersonalContext.Provider value={{ data, setData, dataReferent, setDataReferent, parentId, setParentId, personalId, setPersonalId}}>
            {props.children}
        </PersonalContext.Provider>
    )
}