import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { apiConfig } from "../../../../config/api";

function EditModal({ setModalForm, setModalShow, editDataResult,section,editState }) {
  const [editModalShow, setEditModalShow] = React.useState(true);
  async function editSave(editDataResult) {
    console.log("editDataResult",editDataResult);
    try {
      const res = await axios.post(
        apiConfig.url + `/api/community_edit`,
        {...editDataResult}
      );
        if (res.status === 200) {
          Swal.fire({
            title: "แก้ไขข้อมูลสำเร็จ",
            icon: "success",
            timer: 2000,
          });
        }
        console.log('status',res.data);
        !!setModalForm && setModalForm(false);
    !!setModalShow && setModalShow(false);
    setEditModalShow(false)
    } catch (error) {
      console.log('error',error);
    }
  }
  const postToServer = async (saveDataResult) => {
    console.log('saveDataResult',saveDataResult);
    try {
     const result = await axios.post(
       apiConfig.url + "/api/submit_form/community",
       editDataResult
     );
     if (result.status === 201) {
       await Swal.fire({
         icon: "success",
         title: "บันทึกข้อมูลสำเร็จ",
         showConfirmButton: false,
         timer: 2000,
       }).then(() => {
        setModalForm(false);
        !!setModalShow && setModalShow(false);
        setEditModalShow(false)
       })
     }
   } catch (error) {
     Swal.fire({
       icon: "error",
       title: "บันทึกข้อมูลสำเร็จไม่สำเร็จ",
       text: error.message,
       showConfirmButton: true,
     });
   }
 };
  function closeModal() {
    setModalForm(false);
    !!setModalShow && setModalShow(false);
    setEditModalShow(false)
  }
  return (

    <Modal
        show={editModalShow}
      onHide={() => closeModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {section}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => !editState ? postToServer(editDataResult) : editSave(editDataResult)}> บันทึก</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
