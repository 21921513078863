import React from 'react'
import { Form, Table, Row, Col } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";
function Language({showOnlyProps = false}) {
    const { data, setData } = React.useContext(PersonalContext);

    const SetValueLanguage = (value, index) => {
        let updatedTrain = { ...data }
        updatedTrain.fourth.language[index].value = value
        setData(updatedTrain)
    }
    const addNewLanguage = () => {
        let updatedTrain = { ...data }
        updatedTrain.fourth.language.push({
            language_name: '',
            newLanguage: true,
            value: null
        })
        setData(updatedTrain)
    }

    const setNmaeLanguage = (e, index) => {
        let updatedTrain = { ...data }
        updatedTrain.fourth.language[index].language_name = e.target.value
        setData(updatedTrain)
    }

    const deleteLanguage = (index) => {
        let updatedTrain = { ...data }
        updatedTrain.fourth.language.splice(index, 1)
        setData(updatedTrain)
    }
    const rowDataLanguage = data.fourth.language.map((d, index) => {
        return (
            <tr key={index}>
                {!d.newLanguage ?
                    <td>{!showOnlyProps &&(d.lang_level ?null: <p className="text-danger" style={{ cursor: 'pointer' }} onClick={() => deleteLanguage(index)}>X</p> )} {d.language_name}  </td>
                    :
                    <td>
                        <Row>
                            {!showOnlyProps &&(<Col md={1}>
                                <p className="text-danger" style={{ cursor: 'pointer' }} onClick={() => deleteLanguage(index)}>X</p>
                            </Col>)}
                            <Col>
                                <Form.Control disabled={showOnlyProps} className="w-50" type="text" value={d.language_name} onChange={(e) => setNmaeLanguage(e, index)} />
                            </Col>
                        </Row>
                    </td>}

                {[5, 4, 3, 2, 1].map((value) => (
                    <td key={`${value}`} >
                        <Form.Check
                        disabled={showOnlyProps}
                            checked={d.value === value || (parseInt(d.value) === value)}
                            required
                            onChange={() => SetValueLanguage(value, index)}
                            type="radio"
                            id={index}
                            name={index}
                        />
                    </td>
                ))}
            </tr>
        )
    })
    return (
        <>
            <h5 className="mb-4 mt-4">ทักษะด้านภาษา</h5>
            <small>ระดับ 5 = มากที่สุดหรือดีมาก , 4 = มากหรือดี , 3 = ปานกลางหรือพอใช้ , 2 = น้อยหรือต่ำกว่ามาตรฐาน , 1 = น้อยที่สุดหรือต้องปรับปรุงแก้ไข</small>
            <Table striped bordered hover className="mt-4">
                <thead>
                    <tr>
                        <th>ภาษา</th>
                        <th>5</th>
                        <th>4</th>
                        <th>3</th>
                        <th>2</th>
                        <th>1</th>
                    </tr>
                </thead>
                <tbody>
                    {rowDataLanguage}
                    {!showOnlyProps &&(<tr><td className="btn w-100 text-center bg-success text-white" onClick={() => addNewLanguage()}>เพิ่มข้อมูลอื่นๆ (ถ้ามี)</td></tr>)}
                </tbody>
            </Table>
        </>
    )
}

export default Language