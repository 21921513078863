import React from "react";
import { Modal, Button, Carousel } from "react-bootstrap";
import axios from "axios";
import SectionThird from "../../../CommunityForm/insertComponent/sectionThird/sectionThird";
import EditModal from "../editModal/editModal";
import { apiConfig } from "../../../../config/api";
function Popup({
  handleEdit,
  setModalForm,
  editState,
  modalShow,
  modalForm,
  editData,
  showId,
  setEditData,
  setModalShow,
  setShowId,
  setEditState,
  auth,
}) {
  const [otherResourceDataDetial, setOtherResourceDataDetial] =
    React.useState("");
  const getOtherResourceDataDetial = async () => {
    let response = await axios.get(
      apiConfig.url + "/api/community_dashboard/resource/get_one/" + showId
    );
    console.log("response", response.data);
    setOtherResourceDataDetial(response.data);
  };

  function handleClose() {
    setModalShow(false);
    setShowId(null);
    setEditState(false);
  }
  React.useEffect(() => {
    getOtherResourceDataDetial();
  }, []);
  return (
    <div>
      {!!otherResourceDataDetial &&
        (modalForm ? (
          <EditModal
            editState={editState}
            editDataResult={editData}
            setModalForm={setModalForm}
            setModalShow={setModalShow}
            section={
              <SectionThird
                isEdit={editState}
                data={editData}
                setData={setEditData}
              />
            }
          />
        ) : (
          <Modal
            onHide={() => handleClose()}
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                ข้อมูล{" "}
                {
                  otherResourceDataDetial.third.structure_resource[0].data[0]
                    .resource_name
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>
                ชื่อ:{" "}
                {
                  otherResourceDataDetial.third.structure_resource[0].data[0]
                    .resource_name
                }
              </h5>
              <h5>
                ประเภท:{" "}
                {
                  otherResourceDataDetial.third.structure_resource[0].data[0]
                    .resource_type
                }
              </h5>
              <h5>
                ปีที่ก่อตั้ง (พ.ศ.):{" "}
                {
                  otherResourceDataDetial.third.structure_resource[0].data[0]
                    .resource_year
                }
              </h5>
              <h5>
                ตำแหน่งที่ตั้ง:{" "}
                {otherResourceDataDetial.third.structure_resource[0].data[0]
                  .resource_address
                  ? otherResourceDataDetial.third.structure_resource[0].data[0]
                      .resource_address
                  : "-"}
              </h5>
              {!!otherResourceDataDetial.third.structure_resource[0].data[0]
                .resource_type && (
                <h5>
                  เป็นพื้นที่ของ:{" "}
                  {
                    otherResourceDataDetial.third.structure_resource[0].data[0]
                      .resource_type
                  }{" "}
                  {otherResourceDataDetial.third.structure_resource[0].data[0]
                    .resource_type === "บุคคล" ? (
                    <>
                      เจ้าของพื้นที่{" "}
                      {
                        otherResourceDataDetial.third.structure_resource[0]
                          .data[0].leader_title
                      }{" "}
                      {
                        otherResourceDataDetial.third.structure_resource[0]
                          .data[0].leader_firstname
                      }{" "}
                      {
                        otherResourceDataDetial.third.structure_resource[0]
                          .data[0].leader_lastname
                      }
                    </>
                  ) : null}
                </h5>
              )}
              {!!otherResourceDataDetial?.third?.structure_resource[0].data[0]
                .schoolClass.length > 0 && (
                <>
                  <h5>ลักษณะการสอน:</h5>
                  <ul>
                    {otherResourceDataDetial.third.structure_resource[0].data[0].schoolClass.map(
                      (item, inx) => {
                        return <li key={inx}>{item.name}</li>;
                      }
                    )}
                  </ul>
                </>
              )}
              {!!otherResourceDataDetial.third.structure_resource[0].data[0]
                .image.length > 0 && (
                <>
                  <Carousel>
                    {otherResourceDataDetial.third.structure_resource[0].data[0].image.map(
                      (item, inx) => {
                        return (
                          <Carousel.Item key={inx}>
                            <img
                              className="d-block w-100"
                              src={item.url}
                              alt="First slide"
                            />
                          </Carousel.Item>
                        );
                      }
                    )}
                  </Carousel>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              {auth && (
                <Button
                  onClick={() => {
                    handleEdit(otherResourceDataDetial);
                  }}
                >
                  {" "}
                  แก้ไข
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        ))}
    </div>
  );
}

export default Popup;
