import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { ApiFetch } from '../../../config/api'
import { useAuthContextState } from '../../../context/auth/authContext/authContextState'
import { FetchInterface, Method } from '../../../enums/enum'
import TableUser from './table/TableAdmin'
import TableSelft from './table/TableSelft'

function Dashboard() {
    const [userData, setUserData] = React.useState({})
    const { userInfo, token } = useAuthContextState()
    // const [paginatePageInputBySelft, setPaginatePageInputBySelft] = React.useState(1)
    const [paginatePageInputByAdmin, setPaginatePageInputByAdmin] = React.useState(1)
    const media = useMediaQuery({ minWidth: 576 })
    React.useEffect(() => {
        async function getDashboard() {
          try {
            const res = await ApiFetch({
              [FetchInterface.PATH]: `auth/checker-address-info/${userInfo.userId}`,
              [FetchInterface.METHOD]: Method.GET,
              [FetchInterface.TOKEN]: token.accessToken,
            })
            if (res.status === 200) {
              setUserData(res.data)
            }
          } catch (error) {
            console.log(error);
          }
        }
        getDashboard()
      }, [])
    return (
        <GridContainer>
            <div className='top'>
                <div style={{ marginLeft: "10px" }}>
                    <h5>
                        หมู่ที่.{!!userData.address?.moo && userData.address?.moo}
                        &nbsp;หมู่บ้าน.{!!userData.address?.mooban && userData.address?.mooban} {!media && <br />}
                        &nbsp;ตำบล.{!!userData.address?.tambol && userData.address?.tambol}{!media && <br />}
                        &nbsp;อำเภอ.{!!userData.address?.amphur && userData.address?.amphur}
                        &nbsp;จังหวัด.{!!userData.address?.province && userData.address?.province}
                    </h5>
                </div>
                <div className='avatar'>
                    <img
                        style={{ width: '50px', borderRadius: "50%" }}
                        alt='imgProfile' src={require('../../../assets/images/19199299.jpg')} />
                    <div className='textbox'>
                        <div className='name'>
                            {userInfo.name}
                        </div>
                        <spa>
                            {userInfo.role}
                        </spa>
                    </div>
                </div>
            </div>
            <div className='chart'>
                <h4>
                    รายชื่อที่ป้อนโดยคุณ
                </h4>
                <TableUser 
                    token={token} 
                    userInfo={userInfo} 
                    setPaginatePageInputByAdmin={setPaginatePageInputByAdmin}
                    paginatePageInputByAdmin={paginatePageInputByAdmin}

                />
            </div>
            {/* <div className='cardGrid'>
                <h4>
                    รายชื่อที่นำเข้าโดยเจ้าของ
                </h4>
                <TableSelft 
                    token={token} 
                    userInfo={userInfo}  
                    setPaginatePageInputBySelft={setPaginatePageInputBySelft}
                    paginatePageInputBySelft={paginatePageInputBySelft} 
                />
            </div> */}
        </GridContainer>

    )
}

export default Dashboard
const GridContainer = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    display: grid;
    grid-gap:16px;
    grid-template-columns: 32% 15% 15% 32%;
    grid-template-rows: 10% 55% 25%;
    grid-template-areas: 
     "top top  top top"
     "chart chart chart chart"
     "chart chart chart chart"
    ;
    .top{
        grid-area: top;
        padding:10px;
        border-radius:10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background-color: white;
        .avatar{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .textbox{
                    margin-left: 10px;
                }
                .name{
                    font-weight: bold;
                }
        }
    }
    .header{
        background-color: white;
        padding:10px;
        border-radius:10px;
        grid-area: header;
        display: flex;
        justify-content: space-around;
    }
    .chart{
        background-color: white;
        padding:20px;
        border-radius:10px;
        grid-area: chart;
        .TableBox{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 95%;
            z-index:1;
        }
    }
    .cardGrid{
        background-color: white;
        padding:20px;
        border-radius:10px;
        grid-area: card;    
        .TableBox{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 95%;
            z-index:1;
        }
    }
    .footer1{
         background-color: white;
         padding:10px;
         border-radius:10px;
        grid-area: footer1;
    }
    .footer2{
         background-color: white;
         padding:10px;
         border-radius:10px;
        grid-area: footer2;
    }
    @media screen and (max-width:576px) {
        margin-top: 5px;
        height: auto;
        grid-template-columns: 28% 28% 28%;
        grid-template-rows:20vh 45vh auto;
        grid-template-areas: 
        "top top  top"
        "chart chart chart"  
        "card card card"  
        ;
        .top{
            grid-area: top;
            padding:10px;
            display: flex;
            align-items: flex-start;
            flex-direction: column-reverse;
            overflow: auto;
            background-color: white;
            .avatar{       
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .textbox{
                    margin-left: 10px;
                }
                .name{
                    font-weight: bold;
                }
            }
        }
        .cardGrid{
        background-color: white;
        padding:20px;
        border-radius:10px;
        grid-area: card;   
        .TableBox{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 97%;
        }
    }
    };
`