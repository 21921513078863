import React from "react";
import { Row, } from "react-bootstrap";
import Academy from "./academy/academy";
import ReligiousPlace from "./religiousPlace/religiousPlace";
import TouristAttraction from "./touristAttraction/touristAttraction";
import CommunityImage from "./communityImage/CommunityImage";
import OtherPlace from "./otherPlace/otherPlace";
import LocalHospotal from "./localHospotal/localHospotal";
function StructureResource({
  structure,
  isEdit,
  hospital,
  item,
  setData,
  data,
  subStructureResourceHandleChange,
  main_index,
  sub_index,
}) {
  return (
    <Row key={sub_index}>
      {structure.name === "โรงพยาบาลส่งเสริมสุขภาพตำบล" ||
      item.typeresource_name == "โรงพยาบาลส่งเสริมสุขภาพตำบล" ? (
        <LocalHospotal
          isEdit={isEdit}
          hospital={hospital}
          item={item}
          setData={setData}
          data={data}
          subStructureResourceHandleChange={subStructureResourceHandleChange}
          main_index={main_index}
          sub_index={sub_index}
        />
      ) : structure.name === "ศาสนสถาน" ||
        item.typeresource_name == "ศาสนสถาน" ? (
        <ReligiousPlace
          isEdit={isEdit}
          setData={setData}
          data={data}
          subStructureResourceHandleChange={subStructureResourceHandleChange}
          main_index={main_index}
          sub_index={sub_index}
        />
      ) : structure.name === "สถานศึกษา" ||
        item.typeresource_name == "สถานศึกษา" ? (
        <Academy
          isEdit={isEdit}
          item={item}
          setData={setData}
          data={data}
          subStructureResourceHandleChange={subStructureResourceHandleChange}
          main_index={main_index}
          sub_index={sub_index}
        />
      ) : structure.name === "สถานที่ท่องเที่ยว" ||
        item.typeresource_name == "สถานที่ท่องเที่ยว" ? (
        <TouristAttraction
          item={item}
          isEdit={isEdit}
          setData={setData}
          data={data}
          subStructureResourceHandleChange={subStructureResourceHandleChange}
          main_index={main_index}
          sub_index={sub_index}
        />
      ) : (
        <OtherPlace
          item={item}
          isEdit={isEdit}
          setData={setData}
          data={data}
          subStructureResourceHandleChange={subStructureResourceHandleChange}
          main_index={main_index}
          sub_index={sub_index}
        />
      )}
      <CommunityImage
        structure={structure}
        isEdit={isEdit}
        setData={setData}
        data={data}
        subStructureResourceHandleChange={subStructureResourceHandleChange}
        main_index={main_index}
        sub_index={sub_index}
      />
    </Row>
  );
}

export default StructureResource;
