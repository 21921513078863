import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import Salary from "./salary/salary";
import CareerInfomationGender from "./careerInfomationGender/careerInfomationGender";
import Filter from "./salary/filter/filter";
import OverviewTable from "./careerInfomationoverviewTable/careerInfomationoverviewTable";
import LoadingPage from "../../../../loadingPage/loadingPage";

function CareerInfomationDetial({
  data,
  auth,
  address,
  setSalaryData,
  salaryData,
  filterData,
  loading,
  loadingPageCoload
}) {
  const salaryRef = React.useRef();
  const careerAndGenderRef = React.useRef();
  const [filterDataName, setFilterDataName] = React.useState("all");
  return (
    <MainCareer>
      <div className="career-tableDetial">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="career-tableDetial-title">
          ข้อมูลอาชีพที่แสดงในรูปแบบตาราง
        </div>
        <div className="career-tableDetial-content">
          <OverviewTable auth={auth} data={data?.dataTable} address={address} />
        </div>
      </div>
      <div className="career-gender">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="career-gender-title">
          <span>แยกตามอาชีพ - เพศ</span>
          {auth && (
            <Button
              size="sm"
              onClick={() =>
                careerAndGenderRef.current.getPersonalInfoAllCareerAndGender()
              }
            >
              รายชื่อ
            </Button>
          )}
        </div>
        <div>
          <CareerInfomationGender
            ref={careerAndGenderRef}
            data={data?.classifiedByCareerAndGender}
            address={address}
          />
        </div>
      </div>
      <div className="salary-average">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="salary-average-title">
          <div className="header">
            <span>ช่วงรายได้เฉลี่ยต่อเดือน (บาท)</span>
            <div className="filter">
              <Filter
                setFilterDataName={setFilterDataName}
                filterData={filterData}
                setSalaryData={setSalaryData}
              />
            </div>
          </div>
          {auth && (
            <Button
            size="sm"
            onClick={() =>
              salaryRef.current.getPersonalInfoAllCareerAndSalary()
            }
          >
            รายชื่อ
          </Button>
            )}
        </div>
        <Salary
          filterDataName={filterDataName}
          ref={salaryRef}
          address={address}
          salaryData={salaryData}
        />
      </div>
    </MainCareer>
  );
}

export default CareerInfomationDetial;
const MainCareer = styled.div`
  justify-content: center;
  align-content: center;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto;
  .career-tableDetial {
    position: relative;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: #ffff;
    .career-tableDetial-content {
      margin-top: 10px;
    }
  }
  .occupation {
    position: relative;
    background-color: #ffff;
    border-radius: 5px;
    height: 30vh;
    padding: 10px;
    .occupation-title {
    }
  }
  .spread-by-career {
    position: relative;
    width: 100%;
    background-color: #ffff;
    border-radius: 5px;
    padding: 5px 10px 15px 10px;
    .spread-by-career-title {
      display: flex;
      justify-content: space-between;
    }
  }
  .career-gender {
    position: relative;
    width: 100%;
    background-color: #ffff;
    border-radius: 5px;
    padding: 5px 10px 15px 10px;
    .career-gender-title {
      display: flex;
      justify-content: space-between;
    }
  }
  .salary-average {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 25px 15px;
    background-color: #ffff;
    border-radius: 5px;
    .salary-average-title {
      display: flex;
      justify-content: space-between;
      .header {
        display: flex;
        justify-content: space-between;
        width: 75%;
        .filter {
          display: flex;
          padding-left: 20px;
          width: 55%;
        }
      }
    }
  }
`;
