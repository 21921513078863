import { TypesLayout } from "../../enums/enum";
import { IoGrid } from 'react-icons/io5';
import dataCollector from "../../page/dataCollector/formSeleted/formSeleted";
import Personal from "../../page/Personal";
import Dashboard from "../../page/dataCollector/dashboard/dashboard";


export const dataCollectorRoute = [
    {
        name: "Dashboard",
        path: "/",
        icon: <IoGrid />,
        layout: TypesLayout.adminLayout,
        sidebar: true,
        exact: true,
        component: Dashboard
    },
    {
        name: "Form",
        path: "/checker",
        icon: <IoGrid />,
        layout: TypesLayout.adminLayout,
        sidebar: true,
        exact: true,
        component: dataCollector
    },
    {
        name: "Personal",
        path: "/Personal-form",
        icon: <IoGrid />,
        layout: TypesLayout.none,
        sidebar: false,
        exact: true,
        component: Personal,
    },


]