import { Button, Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import { WatDatePicker } from 'thaidatepicker-react'
import React from 'react'
import Swal from 'sweetalert2'
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import { apiConfig } from "../../../../config/api";
import { FetchInterface, Method } from '../../../../enums/enum';
import { ApiFetch } from '../../../../config/api';
import ImageUplaod from '../../../../component/formUser/imageUplaod/imageUplaod'
import { useAuthContextState } from '../../../../context/auth/authContext/authContextState'


export default function CreateEntrepreneurs() {
    const history = useHistory()
    const { token } = useAuthContextState()
    let form = new FormData()
    const [payload, setPayload] = React.useState({
        personal: {
            national_id: undefined,
            title: undefined,
            first_name: undefined,
            last_name: undefined,
            gender: "ชาย",
            date_birth: new Date().toISOString().split('T')[0],
            religion: undefined,
            main_nation: undefined,
        },
        business_address: {
            tel: undefined,
            address_no: undefined,
            address_group: undefined,
            address_mooban: undefined,
            tambol_id: undefined,
            amphur_id: undefined,
            province_id: undefined,
            address_post: undefined,
        },
        entrepreneur_level: {
            level: "ระดับเริ่มต้น",
            year: undefined,
            month: undefined,
        },
        personal_business_type: [],
        image_id: undefined
    })
    const [image, setImage] = React.useState()

    const [eventBusinessType1, setEventBusinessType1] = React.useState(false)
    const [eventBusinessType2, setEventBusinessType2] = React.useState(false)
    const [eventBusinessType3, setEventBusinessType3] = React.useState(false)
    
    const [loading, setLoading] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)
    const [province, setProvince] = React.useState([]);
    const [amphur, setAmphur] = React.useState([]);
    const [tambol, setTambol] = React.useState([]);
    const religion = [
        { text: "อิสลาม", value: "อิสลาม" },
        { text: "พุทธ", value: "พุทธ" },
        { text: "คริสต์", value: "คริสต์" },
    ];
    const national = [
        { text: "ไทย", value: "ไทย" },
        { text: "มาเลย์", value: "มาเลย์" },
    ];

    React.useEffect(() => {
        getProvince()
    }, [])

    //ดึงค่าจังหวัดจากหลังบ้าน
    const getProvince = async () => {
        const result = await axios(apiConfig.url+ "/api/provinces");
        setProvince(result.data);
    };


    //ดึงค่าอำเภอจากหลังบ้าน
    const getAmphur = async (id_province) => {
        const result = await axios(apiConfig.url + "/api/amphurs/" + id_province);
        setAmphur(result.data);
        console.log(result.data)
    };

    //ดึงค่าตำบลจากหลังบ้าน
    const getTambol = async (id_amphur) => {
        const result = await axios(apiConfig.url + "/api/tambols/" + id_amphur);
        setTambol(result.data);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        let payloadSend = payload
        setSubmitting(true)
        if (image !== null && image !== undefined) {
            let imageId = await uploadImage()
            payloadSend.image_id = imageId
            
        }

        try {
            setLoading(true)
            
            let res = await ApiFetch({
                [FetchInterface.PATH]: 'entrepreneur',
                [FetchInterface.METHOD]: Method.POST,
                [FetchInterface.DATA]: payloadSend,
                [FetchInterface.TOKEN]: token.accessToken,
            })
            if (!res) throw new Error('เกิดข้อผิดพลาดกับการเชื่อมต่อ')
            if (res.status === 200) {
                setSubmitting(false)
                Swal.fire({
                    title: "บันทึกข้อมูลสำเร็จ?",
                    icon: "success"
                  }).then((result) => {
                    setLoading(false)
                    if (result.isConfirmed) {
                        history.push("/entrepreneurs/main/" + res.data.data)
                    }
                })
            }
            
            } catch (error) {
            setLoading(false)
            setSubmitting(false)
            Swal.fire({
                icon: 'error',
                title: 'บันทึกข้อมูลไม่สำเร็จ',
                text: error.message,
                showConfirmButton: true,
            }).then(() => console.log(error))
            }
    }
    const uploadImage = async () => {
        for (const [key, value] of Object.entries(image)) {
            form.append(key, value)
            form.append("title", "temp")
        }

        let res = await ApiFetch({
            [FetchInterface.PATH]: 'upload',
            [FetchInterface.METHOD]: Method.POST,
            [FetchInterface.DATA]: form,
            [FetchInterface.TOKEN]: token.accessToken,
        })

        if (res.status === 200) {
            return res.data.data
        }
    }
    
    return (
        <div>
            <h3 className="mb-4 mt-4 text-center">แบบฟอร์มเพิ่มข้อมูลผู้ประกอบการ</h3>
            <Form className='p-3' onSubmit={(e) => handleSubmit(e)}>
                <Row>
                    <Col md={12}>
                        <div className="box-image" >
                        <div className='image-content' >
                            <ImageUplaod values={image} setValues={setImage} />
                        </div>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h5 className="mb-4 mt-4">ข้อมูลส่วนตัว</h5>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>รหัสบัตรประจำตัวประชาชน 13 หลัก</Form.Label>
                            <Form.Control
                            required={true}
                            value={payload.personal.national_id}
                            type="number"
                            min={0}
                            placeholder="รหัสบัตรประจำตัวประชาชน"
                            onChange={(e) => {
                                setPayload({ ...payload, personal: { ...payload.personal, national_id: e.target.value } })
                            }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>คำนำหน้าชื่อ</Form.Label>
                            <Form.Select
                            required
                            aria-label="Default select example"
                            value={payload.personal.title}
                            onChange={(e) => {
                                setPayload({ ...payload, personal: { ...payload.personal, title: e.target.value } })
                            }} 
                            >
                            <option value=""></option>
                            <option value="เด็กชาย">เด็กชาย</option>
                            <option value="เด็กหญิง">เด็กหญิง</option>
                            <option value="นาย">นาย</option>
                            <option value="นาง">นาง</option>
                            <option value="นางสาว">นางสาว</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>ชื่อ</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            placeholder="กรอกชื่อ"
                            value={payload.personal.first_name}
                            onChange={(e) => {
                                setPayload({ ...payload, personal: { ...payload.personal, first_name: e.target.value } })
                            }} 
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>นามสกุล</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            placeholder="กรอกนามสกุล"
                            value={payload.personal.last_name}
                            onChange={(e) => {
                                setPayload({ ...payload, personal: { ...payload.personal, last_name: e.target.value } })
                            }} 
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>วันเดือนปีเกิด</Form.Label>
                            <br></br>
                            <WatDatePicker
                            required
                            value={payload.personal.date_birth}
                            inputStyle={{ height: "38px", width: "auto" }}
                            onChange={(christDate, buddhistDate) => {
                                setPayload({ ...payload, personal: { ...payload.personal, date_birth: christDate } })
                            }}
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD MMMM YYYY"}
                            clearable={true}
                            maxDate={"2030-1-1"}
                            yearBoundary={99}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>ศาสนา</Form.Label>
                            <input
                                required
                            type="text"
                            list="religion"
                            name="religion"
                            value={payload.personal.religion}
                            className="form-control"
                            onChange={(e) => {
                                setPayload({ ...payload, personal: { ...payload.personal, religion: e.target.value } })
                            }} 
                            />
                            <datalist id="religion">
                            {religion.map((item, key) => (
                                <option key={key} value={item.value} />
                            ))}
                            </datalist>
                        </Form.Group>
                     </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>สัญชาติ</Form.Label>
                            <input
                                required
                            type="text"
                            list="main_nation"
                            name="main_nation"
                            value={payload.personal.main_nation}
                            className="form-control"
                            onChange={(e) => {
                                setPayload({ ...payload, personal: { ...payload.personal, main_nation: e.target.value } })
                            }} 
                            />
                            <datalist id="main_nation">
                            {national.map((item, key) => (
                                <option key={key} value={item.value} />
                            ))}
                            </datalist>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>เพศ</Form.Label>

                            {["radio"].map((type) => (
                            <div key={`gender-${type}`} className="mb-3">
                                <Form.Check
                                checked={
                                    payload.personal.gender === "ชาย" ? true : false
                                }
                                inline
                                label="ชาย"
                                name="gender"
                                type={type}
                                value="ชาย"
                                id={`gender-${type}-1`}
                                onChange={() => {
                                    setPayload({ ...payload, personal: { ...payload.personal, gender: "ชาย" } })
                                }} 
                                />
                                <Form.Check
                                checked={
                                    payload.personal.gender === "หญิง" ? true : false
                                }
                                inline
                                label="หญิง"
                                name="gender"
                                type={type}
                                id={`gender-${type}-2`}
                                onChange={() => {
                                    setPayload({ ...payload, personal: { ...payload.personal, gender: "หญิง" } })
                                }} 
                                />
                            </div>
                            ))}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <h5 className="mb-4 mt-4">ข้อมูลที่อยู่สถานประกอบกิจการ</h5>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>เบอร์ติดต่อ</Form.Label>
                            <Form.Control
                            maxlength="10"
                            required
                            type="text"
                            value={payload.business_address.tel}
                            onChange={(e) => {
                                setPayload({ ...payload, business_address: { ...payload.business_address, tel: e.target.value } })
                            }} 
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>บ้านเลขที่</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            value={payload.business_address.address_no}
                            onChange={(e) => {
                                setPayload({ ...payload, business_address: { ...payload.business_address, address_no: e.target.value } })
                            }} 
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>หมู่บ้าน</Form.Label>
                            <input
                                required
                             value={payload.business_address.address_mooban}
                            list="browsers"
                            name="browser"
                            id="browser"
                            className="form-control"
                            onChange={(e) => {
                                setPayload({ ...payload, business_address: { ...payload.business_address, address_mooban: e.target.value } })
                            }} 
                            ></input>
                            <datalist id="browsers">
                            {/* {community.map((item) => {
                                return <option key={item.id} value={item.community_name} />;
                            })} */}
                            </datalist>
                            
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>หมู่</Form.Label>
                            <Form.Control
                           required
                            type="number"
                            value={payload.business_address.address_group}
                            onChange={(e) => {
                                setPayload({ ...payload, business_address: { ...payload.business_address, address_group: e.target.value } })
                            }} 
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>จังหวัด</Form.Label>
                            <Form.Select
                                required
                            aria-label="Default select example"
                            value={payload.business_address.province_id}
                            onChange={(e) => {
                                getAmphur(e.target.value)
                                setPayload({ ...payload, business_address: { ...payload.business_address, province_id: e.target.value, amphur_id: undefined, tambol_id: undefined } })
                            }}
                          
                            >
                            <option></option>
                            {province.map((province) => {
                                return (
                                <option key={province.value} value={province.value}>
                                    {province.text}
                                </option>
                                );
                            })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>อำเภอ</Form.Label>
                            <Form.Select
                                required
                            aria-label="Default select example"
                            value={payload.business_address.amphur_id}
                            onChange={(e) => { 
                                getTambol(e.target.value)
                                setPayload({ ...payload, business_address: { ...payload.business_address, amphur_id: e.target.value } })
                            }}
                            // disabled={
                            //     (amphur.length === 0 && data.first.address.amphur_id == null) ||
                            //     showOnlyProps
                            //     ? true
                            //     : false
                            // }
                            >
                            <option></option>
                            {amphur.map((amphur) => {
                                return (
                                <option key={amphur.value} value={amphur.value}>
                                    {amphur.text}
                                </option>
                                );
                            })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>ตำบล</Form.Label>
                            <Form.Select
                                required
                            aria-label="Default select example"
                            value={payload.business_address.tambol_id}
                            onChange={(e) => { 
                                setPayload({ ...payload, business_address: { ...payload.business_address, tambol_id: e.target.value } })
                            }}
                            // disabled={
                            //     (tambol.length === 0 &&
                            //     !data.first.address.tambol_id == null) ||
                            //     showOnlyProps
                            //     ? true
                            //     : false
                            // }
                            >
                            <option></option>
                            {tambol.map((tambol) => {
                                return (
                                <option key={tambol.value} value={tambol.value}>
                                    {tambol.text}
                                </option>
                                );
                            })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <h5 className="mb-4 mt-4">ระดับผู้ประกอบการ</h5>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                type="radio"
                                name="level"
                                id={`level`}
                                label={`ระดับเริ่มต้น ยังไม่มีธุรกิจแต่มีไอเดียเพื่อพัฒนาธุรกิจของตัวเองและสนใจในการเป็นผู้ประกอบการ`}
                                checked={
                                    payload.entrepreneur_level.level === "ระดับเริ่มต้น" ? true : false
                                }
                                onChange={(e) => { 
                                    setPayload({ ...payload, entrepreneur_level: { ...payload.entrepreneur_level, level: "ระดับเริ่มต้น" } })
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                type="radio"
                                name="level"
                                id={`level`}
                                label={`ระดับกลาง เริ่มต้นพัฒนาธุรกิจแต่ยังไม่เปิดขายหรือให้บริการ`}
                                checked={
                                    payload.entrepreneur_level.level === "ระดับกลาง" ? true : false
                                }
                                onChange={(e) => { 
                                    setPayload({ ...payload, entrepreneur_level: { ...payload.entrepreneur_level, level: "ระดับกลาง" } })
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                type="radio"
                                name="level"
                                id={`level`}
                                label={`ระดับดำเนินการ ได้ดำเนินการให้บริการ `}
                                checked={
                                    payload.entrepreneur_level.level === "ระดับดำเนินการ" ? true : false
                                }
                                onChange={(e) => { 
                                    setPayload({ ...payload, entrepreneur_level: { ...payload.entrepreneur_level, level: "ระดับดำเนินการ", year: null, month: null } })
                                }}
                            />
                        </Form.Group>
                    </Col>
                    {payload.entrepreneur_level.level === "ระดับดำเนินการ" && <>
                        <Col md={4}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>ขายสินค้ามาเป็นเวลา </InputGroup.Text>
                                <Form.Control type="number" value={payload.entrepreneur_level.year}
                                onChange={(e) => {
                                     setPayload({...payload, entrepreneur_level: {...payload.entrepreneur_level, year: e.target.value}})
                                }} />
                                <InputGroup.Text>ปี</InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col md={4}>
                            <InputGroup className="mb-3">
                                <Form.Control type="number" value={payload.entrepreneur_level.month}
                                    onChange={(e) => {
                                     setPayload({...payload, entrepreneur_level: {...payload.entrepreneur_level, month: e.target.value}})
                                }}/>
                                <InputGroup.Text>เดือน</InputGroup.Text>
                            </InputGroup>
                        </Col>
                     </>}
                   
                </Row>

                <Row>
                    <Col md={12}>
                        <h5 className="mb-4 mt-4">ธุรกิจ (คาดว่าจะ) ดำเนินการเป็นประเภท</h5>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                type="checkbox"
                                name="typeProduct"
                                id={`typeProduct`}
                                label={`สินค้า`}
                                onChange={(e) => {
                                    setEventBusinessType1(e.target.checked)
                                    if (!e.target.checked) {
                                        const filterData = payload.personal_business_type.filter((item) => {
                                            return item.business_type_master_id !== 1;
                                        });
                                        setPayload({...payload, personal_business_type: filterData})
                                    }
                                }}
                            />
                        </Form.Group>
                        {eventBusinessType1===true && 
                            <Row>
                                {
                                    payload.personal_business_type.map((item, index) => {
                                        if (item.business_type_master_id == 1)
                                            return <Col md={12} className='mt-2' key={index}>
                                                        <Form.Control
                                                            required
                                                            name={index}
                                                            value={payload.personal_business_type[index].type_name}
                                                            style={{width: "250px"}}
                                                            type="text"
                                                            placeholder="ประเภทสินค้า"
                                                            onChange={(e) => {
                                                                let data = payload.personal_business_type    
                                                                data[parseInt(e.target.name)].type_name = e.target.value
                                                                setPayload({...payload, personal_business_type: data})
                                                            }}
                                                        />
                                                    </Col>        
                                    })
                                }
                                   
                                <Col md={12} className='mt-2 mb-2'>
                                    <Button variant="info" onClick={() => {
                                        let data = payload.personal_business_type
                                        data.push({
                                            business_type_master_id: 1,
                                            type_name: null
                                        })
                                        setPayload({...payload, personal_business_type: data})
                                    }}>เพิ่มข้อมูล</Button>
                                </Col>
                            </Row>
                        }
                       
                    </Col>
                    <Col md={12} className='mt-2'>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                type="checkbox"
                                name="typeProduct"
                                id={`typeProduct`}
                                label={`บริการ `}
                                onChange={(e) => {
                                    setEventBusinessType2(e.target.checked)
                                    if (!e.target.checked) {
                                        const filterData = payload.personal_business_type.filter((item) => {
                                            return item.business_type_master_id !== 2;
                                        });
                                        setPayload({...payload, personal_business_type: filterData})
                                    }
                                }}
                            />
                        </Form.Group>
                        {eventBusinessType2===true && 
                            <Row>
                                {
                                    payload.personal_business_type.map((item, index) => {
                                        if (item.business_type_master_id == 2)
                                            return  <Col md={12} className='mt-2' key={index}>
                                                        <Form.Control
                                                            required
                                                            name={index}
                                                            value={payload.personal_business_type[index].type_name}
                                                            style={{width: "250px"}}
                                                            type="text"
                                                             placeholder="ประเภทบริการ"
                                                            onChange={(e) => {
                                                                let data = payload.personal_business_type    
                                                                data[parseInt(e.target.name)].type_name = e.target.value
                                                                setPayload({...payload, personal_business_type: data})
                                                             }}
                                                            />
                                                    </Col>
                                    })
                                }
                                
                                <Col md={12} className='mt-2 mb-2'>
                                <Button variant="info" onClick={() => {
                                        let data = payload.personal_business_type
                                        data.push({
                                            business_type_master_id: 2,
                                            type_name: null
                                        })
                                        setPayload({...payload, personal_business_type: data})
                                    }}>เพิ่มข้อมูล</Button>
                                </Col>
                            </Row>
                        }
                        
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                type="checkbox"
                                name="typeProduct"
                                id={`typeProduct`}
                                label={`อื่นๆ `}
                                onChange={(e) => {
                                    setEventBusinessType3(e.target.checked)
                                    if (!e.target.checked) {
                                        const filterData = payload.personal_business_type.filter((item) => {
                                            return item.business_type_master_id !== 3;
                                        });
                                        setPayload({...payload, personal_business_type: filterData})
                                    }
                                }}
                            />
                        </Form.Group>
                        {eventBusinessType3===true && <>
                            <Row>
                                {
                                    payload.personal_business_type.map((item, index) => {
                                        if (item.business_type_master_id == 3)
                                            return <Col md={12} className='mt-2' key={index}>
                                                        <Form.Control
                                                            required
                                                            name={index}
                                                            value={payload.personal_business_type[index].type_name}
                                                            style={{width: "250px"}}
                                                            type="text"
                                                            placeholder="ประเภทอื่นๆ"
                                                            onChange={(e) => {
                                                                let data = payload.personal_business_type    
                                                                data[parseInt(e.target.name)].type_name = e.target.value
                                                                setPayload({...payload, personal_business_type: data})
                                                            }}
                                                        />
                                                    </Col>
                                    })
                                }
                                
                                <Col md={12} className='mt-2'>
                                <Button variant="info" onClick={() => {
                                        let data = payload.personal_business_type
                                        data.push({
                                            business_type_master_id: 3,
                                            type_name: null
                                        })
                                        setPayload({...payload, personal_business_type: data})
                                    }}>เพิ่มข้อมูล</Button>
                                </Col>
                            </Row>
                        </>
                        }
                        
                    </Col>
                </Row>

                <div className='d-flex justify-content-center'>
                    <Button disabled={submitting} className='w-50' variant="primary" type="submit">
                        {submitting===true? <><Spinner animation="border" role="status" variant="light">
                        </Spinner><span>กำลังบันทึกข้อมูล</span></>: <span>บันทึกข้อมูล</span>} 
                    </Button>
                </div>
            </Form>

        </div>
    )
}