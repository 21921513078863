import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container, Form, Alert, Row, Col } from 'react-bootstrap'

function ThirdForm({ acceptConcentForm }) {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    const [user, setUset] = useState({})
    const [accept, setAccept] = useState()
    const [warning, setWarning] = useState(false)
    const styles = {
        width: isTabletOrMobile ? '100%' : '850px',
        height: isTabletOrMobile ? '100%' : '600px',
    }

    const handleSubmit = () => {
        if (accept !== undefined && user.accepter_title !== undefined && user.accepter_firstname !== undefined && user.accepter_lastname !== undefined) {
            setWarning(false)
            acceptConcentForm(accept, user)
        } else {
            setWarning(true)
        }
    }

    const userHandleChange = (e) => {
        let updateData = { ...user }
        updateData[e.target.name] = e.target.value
        setUset(updateData)
    }

    return (
        <Container>
            <div style={{ width: styles.width, margin: '0 auto' }}>
                <div className="text-center" >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Emblem_of_Prince_of_Songkla_University_line_version.png" alt="" width="50" />
                    <p className="mt-2">แบบชี้แจงข้อมูลสำหรับอาสาสมัครโครงการวิจัย <br /> (ที่ใช้แบบสอบถามตอบด้วยตนเอง)</p>
                </div>
                <div className="mt-4">
                    <p>&nbsp; &nbsp; &nbsp; เนื่องด้วย ข้าพเจ้า  ดร.ศริยา บิลแสละ กำลังดำเนินการวิจัยเรื่อง การวิจัยและพัฒนาระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานี โดยมีวัตถุประสงค์ของการวิจัย </p>
                    <p>&nbsp; &nbsp; &nbsp; 1) เพื่อศึกษากระบวนการพัฒนาระบบนิเวศและสร้างความเป็นผู้ประกอบการท้องถิ่นบนฐานทรัพยากรในพื้นที่และเกิดกิจกรรมทางเศรษฐกิจที่ช่วยยกระดับคุณภาพชีวิตประชากรกลุ่มฐานราก</p>
                    <p>&nbsp; &nbsp; &nbsp; 2) เพื่อออกแบบและพัฒนาระบบฐานข้อมูลที่สามารถใช้เป็นข้อมูลประกอบการตัดสินใจและกำหนดการพัฒนาเศรษฐกิจฐานรากในพื้นที่จังหวัดปัตตานีได้ (Data Driven Ecosystem for InnovaTive Entrepreneur: DD-Ecotive) </p>
                    <p>&nbsp; &nbsp; &nbsp; 3) เพื่อออกแบบระบบการวางแผนทรัพยากรในชุมชน (ERP for Community) เพื่อสร้างระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานี</p>
                    <p>&nbsp; &nbsp; &nbsp; 4) เพื่อจัดทำข้อเสนอแนะเชิงนโยบายแนวทางการพัฒนาระบบนิเวศฯ เพื่อพัฒนาผู้ประกอบการนวัตกรรมในพื้นที่ยากจน</p>
                </div>
                <div className="mt-2">
                    <p>&nbsp; &nbsp; &nbsp; เหตุผลที่ผู้เข้าร่วมวิจัยได้รับเชิญเข้าร่วมโครงการหรือเข้าเป็นกลุ่มตัวอย่าง เนื่องจากเป็นบุคคลในชุมชนเป้าหมายของงานวิจัยในครั้งนี้ ซึ่งประกอบด้วยชุมชนบ้านสุไหงปาแน ม. 1 หรือ บ้านจือโระ หมู่ 6 ตำบลบานา อ.เมือง หรือ หมู่ 1และ หมู่ 2 ตำบลตะบิ้ง อ.สายบุรี จังหวัดปัตตานี สำหรับประโยชน์ที่คาดว่าจะได้รับจากการวิจัยนี้คือ </p>
                    <p>&nbsp; &nbsp; &nbsp; 1. ผู้ประกอบการในพื้นที่ตำบลบานา อ.เมือง และตำบลตะบิ้ง อ.สายบุรี จังหวัดปัตตานีมีกระบวนการคิดแบบนวัตกรรมเพื่อสร้างสรรค์สินค้าหรือบริการแบบใหม่ที่ตอบโจทย์ความต้องการตลาด</p>
                    <p>&nbsp; &nbsp; &nbsp; 2. ระบบนิเวศสร้างสรรค์ (Innovative Eco system) ที่ได้พัฒนาขึ้นสามารถบ่มเพาะธุรกิจ SMEs Start Up เยาวชน นักศึกษาและบุคคลทั่วไปที่ต้องการเป็นผู้ประกอบการเพื่อให้ก้าวทันกับสังคมที่ใช้ดิจิทัลในการขับเคลื่อนทุกด้านในจังหวัดปัตตานี</p>
                    <p>&nbsp; &nbsp; &nbsp; จึงเรียนมาเพื่อขอความอนุเคราะห์ในการตอบแบบสอบถาม  เพื่อประโยชน์ทางการศึกษาดังกล่าว  โดยขอให้ท่านตอบตามความเป็นจริง  เพราะคำตอบของท่านมีความสำคัญต่อการวิเคราะห์ข้อมูลเป็นอย่างยิ่ง   ข้าพเจ้าขอรับรองว่าจะเก็บรักษาข้อมูลในการตอบแบบสอบถามของท่านไว้เป็นความลับ  และผลการวิจัยจะนำเสนอในลักษณะภาพรวม  ไม่ระบุชื่อ/ ข้อมูลส่วนตัวของท่าน  จึงไม่เกิดผลกระทบต่อการปฏิบัติงานของท่านแต่ประการใด </p>
                    <p>&nbsp; &nbsp; &nbsp;  ข้าพเจ้าหวังเป็นอย่างยิ่งว่าจะได้รับความร่วมมือจากท่านเป็นอย่างดี และขอขอบพระคุณ เป็นอย่างสูง
                        มา ณ โอกาสนี้  หากท่านมีข้อสงสัยเกี่ยวกับงานวิจัย โปรดติดต่อได้ที่ ดร.ศริยา บิลแสละ คณะวิทยาการสื่อสาร  มหาวิทยาลัยสงขลานครินทร์  E-mail: sariya.b@psu.ac.th เบอร์โทรศัพท์มือถือ 083-5199065
                    </p>
                    <p><b>&nbsp; &nbsp; &nbsp;หากท่านมีปัญหาสงสัยเกี่ยวกับสิทธิของท่านขณะเข้าร่วมการวิจัยนี้  ต้องการทราบข้อมูลเพิ่มเติม  โปรดสอบถามได้ที่ <u> งานส่งเสริมการวิจัย นวัตกรรม และบริการวิชาการ กองบริหารวิชาการและวิจัยวิทยาเขตปัตตานี มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตปัตตานี  94000 หรือทางโทรศัพท์หมายเลข 064-6952922 </u></b></p>
                </div>
                <div className="mt-4 d-flex flex-column align-items-end">
                    <p>ขอขอบคุณอย่างสูง</p>
                    <p className="mt-4">(ดร.ศริยา บิลแสละ) <br></br> หัวหน้าโครงการวิจัย</p>
                </div>
                <div className="mt-4 text-center">
                    <Row>
                        <Col md={12}>
                            <p>เอกสารขอความยินยอมในการให้ข้อมูล</p>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>คำนำหน้าชื่อ</Form.Label>
                                        <Form.Select aria-label="Default select example" name="accepter_title" onChange={(e) => userHandleChange(e)}>
                                            <option></option>
                                            <option value="นาย">นาย</option>
                                            <option value="นาง">นาง</option>
                                            <option value="นางสาว">นางสาว</option>
                                        </Form.Select>
                                    </Form.Group>
                                    </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>ชื่อ</Form.Label>
                                        <Form.Control type="text" name="accepter_firstname" onChange={(e) => userHandleChange(e)}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>นามสกุล</Form.Label>
                                        <Form.Control type="text" name="accepter_lastname" onChange={(e) => userHandleChange(e)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="d-flex flex-column align-items-center mb-2">
                            <div className="d-flex">
                                <Form.Check
                                    onChange={() => {
                                        setAccept(true)
                                    }}
                                    name="accept"
                                    className="mx-2"
                                    type={"radio"}
                                    id={`default-radio`}
                                    label={`ยอมรับ`}
                                />
                                <Form.Check
                                    onChange={() => {
                                        setAccept(false)
                                    }}
                                    name="accept"
                                    type={"radio"}
                                    id={`default-radio`}
                                    label={`ไม่ยอมรับ`}
                                /></div>
                        </Col>
                        <Col md={12}>
                            <p>
                                <button type="button" className="btn btn-success" onClick={() => handleSubmit()}>ยืนยัน</button>
                            </p>
                        </Col>
                    </Row>
                </div>
                {warning ?
                    <div className="mt-2 text-center">
                        <Alert variant={"warning"}>
                            โปรดตรวจสอบความถูกต้องก่อนยืนยัน
                        </Alert>
                    </div>
                    : null
                }

            </div>
        </Container>
    )
}

export default ThirdForm