import React from "react";
import { Col, Form, Row } from 'react-bootstrap';

function UnityOther({ setData, data, index, item }) {
  return (
    <Form.Group className="mb-3 w-50" controlId="exampleForm.ControlInput1">
      <Form.Label>{item.detail}</Form.Label>
      <Form.Control
        type="text"
        name="resource_address"
        value={item.how_to}
        onChange={(e) => {
          let updateData = { ...data };
          updateData.third.weast_resource[index].how_to = e.target.value;
          setData(updateData);
        }}
      />
    </Form.Group>
  );
}

export default UnityOther;
