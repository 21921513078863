import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
// import { CommunityContext } from "../../../../context/community";
import axios from "axios";
import _ from "lodash";

import StructureResource from "./structureResource";
import WeastResource from "./weastResource";
import { apiConfig } from "../../../../config/api";

function SectionThird({ isEdit,data, setData }) {
  // const { data, setData } = useContext(CommunityContext);
  console.log("SectionThirdContextData", data);
  const [hospital, setHospital] = useState([]);
  //ดึงข้อมูลทรัพยากรโครงสร้าง
  const getStructureResource = async () => {
    let response = await axios.get(`${apiConfig.url}/api/structure_resource`);
    let updateData = { ...data };
    updateData.third.structure_resource = response.data.structureResource;
    updateData.third.weast_resource = response.data.unityTypeWeast;
    updateData.third.utilities_resource = response.data.unityTypeUtilities;
    setData(updateData);
  };

  //เพิ่มข้อมูลรายข้อมูลทรัพยากรโครงสร้างพื้นฐาน checkend
  const structureResourceHandleChange = (e, name, index) => {
    let updateData = { ...data };
    updateData.third[name][index][e.target.name] = e.target.checked
      ? e.target.checked
      : e.target.value;

    if (updateData.third[name][index].data === undefined) {
      updateData.third[name][index].data = [];
    }
    /* if (updateData.third[name][index][e.target.name] !== "") {
            updateData.third[name][index].data = [];
            for (let i = 0; i < parseInt(updateData.third[name][index][e.target.name]); i++) {
                updateData.third[name][index].data.push({
                    resource_name: "",
                    resource_year: "",
                    resource_address: "",
                    image: [],
                })
            }
        } else {
            
        } */
    setData(updateData);
  };

  const addNewStructureResource = (index) => {
    let updateData = { ...data };
    updateData.third.structure_resource[index].data.push({
      resource_name: "",
      resource_year: "",
      resource_address: "",
      image: [],
      schoolClass: [],
    });
    setData(updateData);
  };

  //เพิ่มข้อมูลรายข้อมูลทรัพยากรโครงสร้าง
  const subStructureResourceHandleChange = (e, main_index, subIndex) => {
    let updateData = { ...data };
    updateData.third.structure_resource[main_index].data[subIndex][
      e.target.name
    ] = e.target.value;
    setData(updateData);
    if (e.target.name === "hospital_name") searchHospital(e);
  };

  const searchHospital = _.debounce(async (e) => {
    try {
      let response = await axios.get(`${apiConfig.url}/api/hospital?name=${e.target.value}`);
      setHospital(response.data);
    } catch (err) {
      console.log(err);
    }
  }, 700);

  const addNewUtilities = () => {
    let updateData = { ...data };
    updateData.third.utilities_resource.push({
      unity_name: "",
      type: "utilities",
      isNew: true,
    });
    setData(updateData);
  };
  useEffect(() => {
    if (!!data.third?.structure_resource && data.third.structure_resource.length === 0) {
      getStructureResource();
    }
  }, []);
  return (
    <Row>
      {!isEdit && <h5 className="mb-4">ข้อมูลทรัพยากรโครงสร้าง</h5>}
      <Col md={12}>
        {!!data.third?.structure_resource && data.third.structure_resource.map((structure, main_index) => {
          return (
            <React.Fragment key={main_index}>
              {!isEdit && (
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="">
                      <Form.Check
                        checked={
                          data.third.structure_resource[main_index].checked ===
                          true
                        }
                        name="checked"
                        onChange={(e) =>
                          structureResourceHandleChange(
                            e,
                            "structure_resource",
                            main_index
                          )
                        }
                        type={"checkbox"}
                        id={`default-checkbox`}
                        label={structure.name}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {!!structure.data &&
                structure.data.map((item, sub_index) => {
                  return (
                    <StructureResource
                      structure={structure}
                      isEdit={isEdit}
                      hospital={hospital}
                      item={item}
                      setData={setData}
                      data={data}
                      subStructureResourceHandleChange={
                        subStructureResourceHandleChange
                      }
                      main_index={main_index}
                      sub_index={sub_index}
                    />
                  );
                })}
              {structure.checked === true && (
                <Row>
                  <Col md={1} className="p-2">
                    <Button
                      variant="success"
                      onClick={() => addNewStructureResource(main_index)}
                    >
                      เพิ่มข้อมูล
                    </Button>
                  </Col>
                </Row>
              )}
              <hr />
            </React.Fragment>
          );
        })}
      </Col>
      {!!data.third?.weast_resource && (
        <>
          <h5 className="mb-4 mt-3">การจัดการขยะในชุมชน</h5>
          {data.third.weast_resource.map((item, index) => {
            return (
              <WeastResource
              key={index}
              item={item}
                index={index}
                data={data}
                setData={setData}
                structureResourceHandleChange={structureResourceHandleChange}
              />
            );
          })}
        </>
      )}
      {!!data.third?.utilities_resource && (
        <>
          <h5 className="mb-4 mt-3">ระบบสาธารณูปโภค</h5>
          {data.third.utilities_resource.map((item, index) => {
            return (
              <Col md={12} key={index}>
                {item.isNew ? (
                  <Form.Group className="mb-3 d-flex" controlId="">
                    <Form.Check
                      type={"checkbox"}
                      checked={
                        item.checked === true
                      }
                      name="checked"
                      onChange={(e) =>
                        structureResourceHandleChange(
                          e,
                          "utilities_resource",
                          index
                        )
                      }
                      id={`default-checkbox`}
                    />
                    <div className="px-2">
                      <Form.Control
                        type="text"
                        value={item.unity_name}
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.third.utilities_resource[
                            index
                          ].unity_name = e.target.value;
                          setData(updateData);
                        }}
                      />
                    </div>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="">
                    <Form.Check
                      type={"checkbox"}
                      checked={
                        item.checked === true
                      }
                      name="checked"
                      onChange={(e) =>
                        structureResourceHandleChange(
                          e,
                          "utilities_resource",
                          index
                        )
                      }
                      id={`default-checkbox`}
                      label={`${item.unity_name}`}
                    />
                  </Form.Group>
                )}
              </Col>
            );
          })}
          <Col md={12}>
            <Button variant="success" onClick={() => addNewUtilities()}>
              เพิ่มข้อมูลระบบสาธารณูปโภค
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
}

export default SectionThird;
