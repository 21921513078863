import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SubSidebar from "./subSidebar/subSidebar";
import { AiOutlineRight } from "react-icons/ai";
import { useTransition, animated } from "react-spring";

function Sidebaritem({
  routePath,
  routeName,
  pathname,
  setSidebarActive,
  sidebarActive,
  setsidebarstate,
  icon,
  subSidebar,
  subsidebarState,
  setSubsidebarState
}) {
  const history = useHistory();

  function handleNavigate(pathname, name,subSidebar) {
    if (!!pathname && !subSidebar) {
      localStorage.setItem('pathname',JSON.stringify(name))
      history.push(pathname);
      setsidebarstate(true);
      setSidebarActive(name);
    } else {
      setSubsidebarState(!subsidebarState);
    }
  }
  const transition = useTransition(subsidebarState && !!subSidebar, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 60 },
    leave: {
      height: 0,

    },
  });
  return (
    <SidebarBox sidebarActive={routeName === sidebarActive} subSidebarState={subsidebarState}>
      <button
        className="SidebarBox"
        id="toggle"
        key={routePath}
        onClick={() => handleNavigate(pathname, routeName,subSidebar)}
      >
        {icon}
        <div className="text">
          {routeName}
          <div className="underline" />
        </div>
        {!!subSidebar && (
          <div className="end-icon" >
            <AiOutlineRight className="icon" />
          </div>
        )}
      </button>
      <div style={{marginTop:"5px"}}>
      {transition(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <ul style={{display:"flex",flexDirection:"column"}}>
              {!!subSidebar && subSidebar.map((data, inx) => (
                  <SubSidebar
                    key={inx}
                    handleNavigate={handleNavigate}
                    parentName={routeName}
                    name={data.name}
                    path={data.path}
                  />
                
              ))}
              </ul>           
            </animated.div>
          )
      )}
      </div>
    </SidebarBox>
  );
}
export default Sidebaritem;

const SidebarBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .SidebarBox {
    border: none;
    font-size: 18px;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.2s;
    background-color: ${(prop) => (prop.sidebarActive ? "#38AFBA" : "#FFFF")};
    .text {
      margin-left: 10px;
      position: relative;
      .underline {
        width: 0%;
        border-radius: 10px;
      }
    }
    .end-icon{
      width: 30%;
      display: flex;
      justify-content: flex-end;
      .icon{
        font-size: 14px;
        transition:all .2s;
        transform: ${(prop) => (!prop.subSidebarState ? "rotate(90deg)" : "rotate(0deg)")};
      }
    }
    color: ${(prop) => (prop.sidebarActive ? "#FFFF" : "black")};
    &:hover {
      @media screen and (min-width: 576px) {
        color: ${(prop) => (prop.sidebarActive ? "#FFFF" : "black")};
        .text {
          position: relative;
          .underline {
            transition: all 0.3s;
            position: absolute;
            height: 3px;
            border-radius: 20px;
            width: ${(prop) => (prop.sidebarActive ? "0%" : "100%")};
            background-color: black;
          }
        }
      }
    }
    @media screen and (min-width: 720px) and (max-width: 920px) {
      height: 55px;
      justify-content: center;
      font-size: 32px;
      .text {
        display: none;
        position: relative;
        .underline {
          width: 0%;
        }
      }
    }
  }
`;
