import React from "react";
import Index from "../../../component/dashboard";
function Dashboard() {
  return (
    <Index />
  );
}

export default Dashboard;

