import React from "react";
import styled from "styled-components";

export default function DescriptionSkill({ data,textDesscription }) {
  return (
    <Main>
      <div className="text-infomation">
        {!!data?.descriptionData &&
          data.descriptionData.map((descriptionData, inx) => (
            <div className="box" key={inx}>
              <div className="text">
                <Dot color={'black'} />
                {descriptionData.title}
              </div>
              <ul style={{marginLeft:"20px"}}>
                {descriptionData.detail.map((detial, inx) => (
                  <li key={inx}>
                    <span>
                      ประชากรจำนวน {detial.peopleCount} คน {" "}
                      {textDesscription} {descriptionData.title} {" "}
                      ได้ในระดับ {detial.range}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </Main>
  );
}

const Main = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  .text-infomation {
    padding: 10px 0 0 10px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    .box {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 5px;
      padding: 2px;
      .text-cont {
        color: #0e185f;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffff;
        width: 25%;
      }
    }
  }
`;
const Dot = styled.div`
  width: 15px;
  height: 8px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: ${({ color }) => color};
`;
