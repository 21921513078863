import React from "react";
// import { CommunityContext } from "../../../context/community";
import { Form, Button, Row, Col, Table } from "react-bootstrap";

function SectionSecond({ data, setData, isEdit }) {
  // const { data, setData } = React.useContext(CommunityContext)
  console.log("SectionSecond", data);
  const addNewRiver = () => {
    let updateData = { ...data };
    updateData.second.river.push({
      resource_type: "",
      resource_name: "",
      resource_width: 0.0,
      resource_length: 0.0,
      depth: 0.0,
    });
    setData(updateData);
  };
  const deleteRiver = (index) => {
    let updateData = { ...data };
    updateData.second.river.splice(index, 1);
    setData(updateData);
  };

  const addNewTree = () => {
    let updateData = { ...data };
    updateData.second.tree.push({
      resource_name: "",
      type: null,
    });
    setData(updateData);
  };
  const deleteTree = (index) => {
    let updateData = { ...data };
    updateData.second.tree.splice(index, 1);
    setData(updateData);
  };

  const handleChange = (e, name, index) => {
    let updateData = { ...data };
    updateData.second[name][index][e.target.name] = e.target.value;
    setData(updateData);
  };

  return (
    <Row>
      {!!data.second?.river && (
        <>
          <h5 className="mb-4">ข้อมูลแม่น้ำ</h5>
          {data.second.river.map((item, index) => {
            return (
              <Col md={12} key={index}>
                <Row className="mb-3">
                  <Col md={12} className="mb-3">
                    <label
                      className="text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteRiver(index)}
                    >
                      X
                    </label>{" "}
                    {index + 1}. ชนิดแม่น้ำ
                  </Col>
                  <Col md={2}>
                    <Form.Check
                      checked={item.resource_type === "แม่น้ำสายหลัก"}
                      name={`resource_type _${index}`}
                      onChange={(e) => {
                        let updateData = { ...data };
                        updateData.second.river[index].resource_type =
                          e.target.value;
                        setData(updateData);
                      }}
                      type={"radio"}
                      id={`default-${"checkbox"}`}
                      value={"แม่น้ำสายหลัก"}
                      label={"แม่น้ำสายหลัก"}
                    />
                  </Col>
                  <Col md={2}>
                    <Form.Check
                      checked={item.resource_type === "แม่น้ำสายรอง"}
                      name={`resource_type _${index}`}
                      onChange={(e) => {
                        let updateData = { ...data };
                        updateData.second.river[index].resource_type =
                          e.target.value;
                        setData(updateData);
                      }}
                      type={"radio"}
                      id={`default-${"checkbox"}`}
                      value={"แม่น้ำสายรอง"}
                      label={"แม่น้ำสายรอง"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3 mt-2" controlId="">
                      <Form.Label>ชื่อแม่น้ำ</Form.Label>
                      <Form.Control
                        type="text"
                        name="resource_name"
                        value={item.resource_name}
                        onChange={(e) => handleChange(e, "river", index)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3 mt-2" controlId="">
                      <Form.Label>ความยาว (เมตร)</Form.Label>
                      <Form.Control
                        type="number"
                        name="resource_length"
                        value={item.resource_length}
                        onChange={(e) => handleChange(e, "river", index)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3 mt-2" controlId="">
                      <Form.Label>ความกว้าง (เมตร)</Form.Label>
                      <Form.Control
                        type="number"
                        name="resource_width"
                        value={item.resource_width}
                        onChange={(e) => handleChange(e, "river", index)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3 mt-2" controlId="">
                      <Form.Label>ความลึก (เมตร)</Form.Label>
                      <Form.Control
                        type="number"
                        name="resource_depth"
                        value={item.resource_depth}
                        onChange={(e) => handleChange(e, "river", index)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
              </Col>
            );
          })}
          <Row>
            <Col md={3}>
              <Button variant="success" onClick={() => addNewRiver()}>
                เพิ่มข้อมูลแม่น้ำในหมู่บ้าน
              </Button>
            </Col>
          </Row>
        </>
      )}
      {!!data.second?.tree && (
        <>
          <h5 className="mb-4 mt-4">ข้อมูลพันธุ์ไม้</h5>
          <Col md={12}>
            <Table responsive>
              <thead>
                <tr>
                  <th width="40%">ชื่อพันธุ์ไม้</th>
                  <th width="30%">
                    พันธุ์ไม้ที่ทำรายได้ให้ชุมชน (พันธุ์ไม้เศรษฐกิจ)
                  </th>
                  <th width="30%">พันธุ์ไม้ที่ต้องการอนุรักษ์</th>
                </tr>
              </thead>
              <tbody>
                {data.second.tree.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput1"
                        >
                          <Row>
                            <Col md={1}>
                              <label
                                className="text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteTree(index)}
                              >
                                X
                              </label>
                            </Col>
                            <Col md={11}>
                              <Form.Control
                                type="text"
                                name="resource_name"
                                value={item.resource_name}
                                onChange={(e) => handleChange(e, "tree", index)}
                                placeholder="ชื่อพันธุ์ไม้"
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </td>
                      <td className="text-center">
                        <Form.Check
                          checked={item.resource_type === "พันธุ์ไม้เศรษฐกิจ"}
                          name={`resource_tree_type _${index}`}
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.second.tree[index].resource_type =
                              e.target.value;
                            setData(updateData);
                          }}
                          type={"radio"}
                          id={`default-${"checkbox"}`}
                          value={"พันธุ์ไม้เศรษฐกิจ"}
                        />
                      </td>
                      <td className="text-center">
                        <Form.Check
                          checked={item.resource_type === "พันธุ์ไม้อนุรักษ์"}
                          name={`resource_tree_type _${index}`}
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.second.tree[index].resource_type =
                              e.target.value;
                            setData(updateData);
                          }}
                          type={"radio"}
                          id={`default-${"checkbox"}`}
                          value={"พันธุ์ไม้อนุรักษ์"}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
          <Col md={12} className="text-center">
        <Button variant="success" onClick={() => addNewTree()}>
          เพิ่มข้อมูลพันธุ์ไม้
        </Button>
      </Col>
        </>
      )}
    </Row>
  );
}

export default SectionSecond;
