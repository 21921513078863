import React from 'react'
import axios from 'axios';
import { useAlert } from 'react-alert'
import { Modal, Button, Form, } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";
import { apiConfig } from '../../../config/api';

function CreateOccupation({ createOccupation, setCreateOccupation, getOccupation}) {
    const alert = useAlert()
    const { data, setData } = React.useContext(PersonalContext);
    
    const [occupation, setOccupation] = React.useState()
    const [typeId, setTypeId] = React.useState()
    const [typeOccupation, setTypeOccupation] = React.useState([])
    
    //ดึงค่าประเภทมาจาก db
    const getTypeOccupation= async()=>{
        let response = await axios.get(apiConfig.url +'occupation_type');
        setTypeOccupation(response.data)
    }
    React.useEffect(() => {
        getTypeOccupation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //เมื่อคลิกบันทึกข้อมูลจะทำฟังก์ชันนี้
    const postOccupation=async()=>{
        let request = {
            occupation_name:occupation,
            jobtype_id:typeId
        }

        let response = await axios.post(apiConfig.url + 'occupation/create', request)
        if (createOccupation.index === null && createOccupation.section ===1){    //ถ้าเพิ่มข้อมูลอาชีพหลัก
            setData({
                ...data,
                first: {
                    ...data.first,
                    occupation: {
                        ...data.first.occupation,
                        main: { ...data.first.occupation.main, job: occupation, jobId: response.data.data.id }
                    }
                }
            })
        }
        if (createOccupation.index !== null && createOccupation.section === 1) {     //ถ้าเพิ่มข้อมูลอาชีพเสริม
            let updateData = {...data}
            updateData.first.occupation.additional.data[createOccupation.index].job = occupation
            updateData.first.occupation.additional.data[createOccupation.index].jobId = response.data.data.id
            setData(updateData)
        }
        if (createOccupation.index !== null && createOccupation.section === 3) {    //ถ้าเพิ่มข้อมูลอาชีพที่เคยทำ อยู่ส่วนที่ 3 ของฟอร์ม
            let updateData = { ...data }
            updateData.third[createOccupation.index].occupation = occupation
            updateData.third[createOccupation.index].occupationId = response.data.data.id
            setData(updateData)
        }
        
        setCreateOccupation({ ...createOccupation, status: !createOccupation.status })  //ปิด modal
        getOccupation()
        alert.show('บันทึกข้อมูลอาชีพสำเร็จ!', {
            type: 'success',
        }) 
    }
  return (
      <>
          <Modal
              show={createOccupation.status}
              onHide={() => setCreateOccupation({...createOccupation,status:!createOccupation.status})}
              backdrop="static"
              keyboard={false}
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>เพิ่มข้อมูลอาชีพ</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form.Group className="mb-3" >
                      <Form.Label>อาชีพ</Form.Label>
                      <Form.Control type="text" 
                           onChange={(e)=> setOccupation(e.target.value)}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                      <Form.Label>ประเภทอาชีพ</Form.Label>
                      <Form.Select aria-label="Default select example" 
                      onChange={(e)=>setTypeId(e.target.value)}>
                          <option></option>
                          {typeOccupation.map((data)=>{
                              return(
                                  <option key={data.id} value={data.id}>{data.jobtype_name}</option>
                              )
                          })}
                         
                        
                      </Form.Select>
                  </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={() => postOccupation()}>เพิ่มข้อมูล</Button>
              </Modal.Footer>
          </Modal>
      </>
  )
}

export default CreateOccupation