import React from "react";
import { Col, Form } from "react-bootstrap";

function LocalHospotal({
  data,
  setData,
  subStructureResourceHandleChange,
  main_index,
  sub_index,
  hospital,
  item,
  isEdit
}) {
  return (
    <Col md={4}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>
          {!isEdit && <label
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => {
              let updateData = { ...data };
              updateData.third.structure_resource[main_index].data.splice(
                sub_index,
                1
              );
              setData(updateData);
            }}
          >
            X
          </label>}{" "}
          {sub_index + 1}. ชื่อสถานที่
        </Form.Label>
        <input
          type="text"
          list="hospital"
          className="form-control"
          name="hospital_name"
          value={item.resource_name}
          onChange={(e) =>
            subStructureResourceHandleChange(e, main_index, sub_index)
          }
        />
        <datalist id="hospital">
          {hospital.map((item, key) => (
            <option key={key} value={item.hospital_name} />
          ))}
        </datalist>
      </Form.Group>
    </Col>
  );
}

export default LocalHospotal;
