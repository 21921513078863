import React from 'react';
import { Form } from 'react-bootstrap';

export default function RadioInput({ titleKey, subKey, valKey, score, subItem, checked, handleChange }) {

    React.useEffect(() => { 
    }, [checked])

    return <>
            {checked==true? <td>
            <Form.Check
                name={`question-${titleKey}-${subKey}-${valKey}`}
                type="radio"
                id={`question-${titleKey}-${subKey}-${valKey}-${score}`}
                defaultChecked={true}
                onChange={() => handleChange({ titleKey, subKey, valKey, score, questionId: subItem.split(':')[0] })}
            />
        </td>
            :
            <td>
            <Form.Check
                name={`question-${titleKey}-${subKey}-${valKey}`}
                type="radio"
                id={`question-${titleKey}-${subKey}-${valKey}-${score}`}
                onChange={() => handleChange({ titleKey, subKey, valKey, score, questionId: subItem.split(':')[0] })}
            />
            </td>
        }
    </>
};