import React from "react";
import Swal from "sweetalert2";
import { FetchInterface, Method } from "../../../enums/enum";
import { ApiFetch } from "../../../config/api";
import { useAuthContextState } from "../../../context/auth/authContext/authContextState";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

export default function Actions({data,getUserListByAdmin}) {
    const { token } = useAuthContextState()
    const history = useHistory();
    async function deleteUser(id) {
        try {
          const res = await ApiFetch({
            [FetchInterface.PATH]: `auth/delete-user-by-admin/${id}`,
            [FetchInterface.METHOD]: Method.DELETE,
            [FetchInterface.TOKEN]: token.accessToken,
          });
          if (res.status === 200) {
              console.log(res.data);
            Swal.fire({
              title: "ลบข้อมูลสำเร็จ",
              icon: "success",
            }).then(() => {
              getUserListByAdmin();
            });
          }
        } catch (error) {
            Swal.fire({
                title: "ลบข้อมูลไม่สำเร็จ",
                text: error,
                icon: "error",
            })
          console.log(error);
        }
      }
  return (
            <ActionItems>
              {/* {data.user_type === 'dataCollector' && (
                <button
                onClick={() =>
                  history.push({
                    pathname: `/ShowDetailDataCollector/${data.id}`,
                  })
                }
              >
                รายละเอียด
              </button>
              )} */}
              <button
                onClick={() =>
                  history.push({
                    pathname: `/EditUser`,
                    state: { state: "edit", id: data.id },
                  })
                }
              >
                แก้ไข
              </button>
              <button onClick={() => deleteUser(data.id)}>ลบ</button>
            </ActionItems>
        
  );
}

const ActionItems = styled.div`
  padding: 5px;
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  button {
    /* font-weight: bold; */
    overflow: hidden;
    font-size: 14px;
    width:max-content;
    text-align: left;
    background-color: transparent;
    margin-bottom: 5px;
    border: none;
    color: #393939;
    border-radius: 10px;
      background-color: #e6e6e6;
    &:hover {
      overflow: hidden;
      width:max-content;
    }
  }
`;
