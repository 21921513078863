import React from 'react'
import styled from 'styled-components'
import Index from '../../../component/dashboard'
import PersonalDashboard from '../../../component/dashboard/personal/personalDashboard'
import { useAuthContextState } from '../../../context/auth/authContext/authContextState'


function Dashboard() {
    return (
        <div style={{width:"100%",height:"100%",padding:"0 5px 5px 5px",overflowX:"auto"}}>
            <PersonalDashboard />
        </div>
    )
}

export default Dashboard