import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";
import { ApiFetch } from "../../../../../../config/api";
import { FetchInterface, Method } from "../../../../../../enums/enum";
import { useAuthContextState } from "../../../../../../context/auth/authContext/authContextState";

const EducationAgeRage = React.forwardRef(function EducationAgeRage(
  { educationLeveDetialData,address,edulevel },
  ref
) {
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  async function getDataTable(section,edulevel, title, datasetTitle) {
    if (!token.accessToken) return false;
    setTableData({ header: title, data: [] });
    const titleData = {
      title: title,
      edulevel:edulevel,
      datasetTitle: !!datasetTitle ? datasetTitle : "",
    };
    setModalShow(true);
    setTableData({ header: titleData, data: [] });
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/education-personal-table-info/${section}/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });
      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({ header: titleData, data: res.data });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }
  React.useImperativeHandle(ref, () => ({
    async getPersonalInfoAllageRage() {
      setModalShow(true);
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/education-personal-table-info/ageRange/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: {edulevel:'all'},
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: { datasetTitle: "ชายและหญิง", title: "ทุกช่วงอายุ" },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    },
  }));
  const dataBar = {
    labels: [
      "สถาบันในพื้นที่",
      "สถาบันนอกพื้นที่ (ในประเทศ)",
      "สถาบันนอกพื้นที่ (ต่างประเทศ)",
    ],
    datasets: [
      {
        label: "ชาย",
        data: [
          !!educationLeveDetialData ? educationLeveDetialData.men?.local : 0,
          !!educationLeveDetialData
            ? educationLeveDetialData.men?.inCountry
            : 0,
          !!educationLeveDetialData ? educationLeveDetialData.men?.foreign : 0,
        ],
        backgroundColor: ["#37E2D5"],
      },
      {
        label: "หญิง",
        data: [
          !!educationLeveDetialData ? educationLeveDetialData.girl?.local : 0,
          !!educationLeveDetialData
            ? educationLeveDetialData.girl?.inCountry
            : 0,
          !!educationLeveDetialData ? educationLeveDetialData.girl?.foreign : 0,
        ],

        backgroundColor: ["#115f9a"],
      },
      {
        label: "ไม่ระบุเพศ",
        data: [
          !!educationLeveDetialData
            ? educationLeveDetialData.noGender?.local
            : 0,
          !!educationLeveDetialData
            ? educationLeveDetialData.noGender?.inCountry
            : 0,
          !!educationLeveDetialData
            ? educationLeveDetialData.noGender?.foreign
            : 0,
        ],

        backgroundColor: ["#20c997"],
      },
    ],
  };
  const options = {
    onClick(click, element, chart) {
      const index = element[0].index;
      const datasetIndex = element[0].datasetIndex;
      const datasetTitle = chart.config.data.datasets[datasetIndex].label;
      const title = chart.config.data.labels[index];
      getDataTable("ageRange",edulevel, title, datasetTitle);
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        align:"start",
      },
      datalabels: {
        color: "#3e3e3e",
        anchor: "end",
        align: "top",
        font: "bold",
      },
    },
  };

  return (
    <>
      <div style={{ height: "40vh" }}>
        <Bar plugins={[ChartDataLabels]} options={options} data={dataBar} />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายชื่อบุคคล เพศ  ${tableData.header.datasetTitle} สำเร็จการศึกษา ${tableData.header.title} ใน ${address.addressName} มีจำนวน ${tableData?.data?.data?.length} คน`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
});

export default EducationAgeRage;
