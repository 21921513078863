import React from "react";
import styled from "styled-components";

export default function SideNavicationItem({
  setDashboardOfPageRender,
  dashboardOfPageRender,
  name,
  icon,
  show,
}) {
  return (
    <SideNavicationItems
      show={show}
      active={dashboardOfPageRender.name === name}
      onClick={() => setDashboardOfPageRender({ name: name })}
      className="sidebar-item"
    >
      <div className="icon">{icon}</div>
      <div className="sidebar-text">{name}</div>
    </SideNavicationItems>
  );
}
const SideNavicationItems = styled.button`
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  background-color: ${({ active }) => (active ? "#38afba" : "#FFFF")};
  border-radius: 5px;
  transition: all 0.2s;
  margin-bottom: 10px;
  height: 45px;
  width: 80%;
  &:hover {
    border: 2px solid #000000;
    cursor: pointer;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: ${({ show }) => (show ? "100%" : "30%")};
    background-color: transparent;
    color: ${({ active }) => (active ? "#FFFF" : "#2f6b70")};
    font-size: 30px;
  }
  .sidebar-text {
    padding-left: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    color: ${({ active }) => (active ? "#FFFF" : "#2f6b70")};
    display: ${({ show }) => show && "none"};
  }
`;
