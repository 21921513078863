import React from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";

function Other({ welfareType, org, setCreateOrg, showOnlyProps = false}) {
    const { data, setData } = React.useContext(PersonalContext);
    const org_filter = org.filter((e) => e.org_type === 'หน่วยงานภาคเอกชน')
    //เมื่อใส่ข้อมูลใน input
    const handleChange = (index, e) => {
        let updateData = { ...data }
        updateData.sixth.other[index] = {
            ...updateData.sixth.other[index],
            [e.target.name]: e.target.value
        }
        setData(updateData)
    }

    //เมื่อกดเพิ่มแถวข้อมูล
    const addNewRow = () => {
        let updateData = { ...data }
        updateData.sixth.other.push({
            welfare_name: null,
            welfare_start: null,
            welfare_end: null,
            welfare_type: null,
            org: null,
            welfare_amount: null
        })
        setData(updateData)
    }

    return (
        <Row>
            <Col md={12}><h5 className="my-3">เงินหรือทุนที่เคยได้รับการสนับสนุนจากหน่วยงานอื่นๆ</h5></Col>
            <Col md={12}>
                        {data.sixth.other.map((item, index) => {
                            return (
                              <Row key={index}>
                                <Col md={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>
                                      {!showOnlyProps &&
                                        !data.sixth.other[index].id && (
                                          <label
                                            className="text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              let updateData = { ...data };
                                              updateData.sixth.other.splice(
                                                index,
                                                1
                                              );
                                              setData(updateData);
                                            }}
                                          >
                                            X
                                          </label>
                                        )}
                                      {index + 1}. สวัสดิการ
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="welfare_name"
                                      disabled={showOnlyProps}
                                      value={
                                        data.sixth.other[index].welfare_name
                                      }
                                      onChange={(e) => handleChange(index, e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>ปีที่ได้รับ (พ.ศ.)</Form.Label>
                                    <Form.Control
                                      type="number"
                                      min={0}
                                      name="welfare_start"
                                      disabled={showOnlyProps}
                                      value={
                                        data.sixth.other[index].welfare_start
                                      }
                                      onChange={(e) => handleChange(index, e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>ปีที่สิ้นสุด (พ.ศ.)</Form.Label>
                                    <Form.Control
                                      disabled={showOnlyProps}
                                      type="number"
                                      min={0}
                                      name="welfare_end"
                                      value={
                                        data.sixth.other[index].welfare_end
                                      }
                                      onChange={(e) => handleChange(index, e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>ประเภท</Form.Label>
                                    <Form.Select
                                      disabled={showOnlyProps}
                                      name="welfare_type"
                                      value={
                                        data.sixth.other[index].welfare_type
                                      }
                                      onChange={(e) => handleChange(index, e)}
                                    >
                                      <option value=""></option>
                                      {welfareType.map((e) => {
                                        return (
                                          <option key={e.id} value={e.id}>
                                            {e.welfare_type}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>ชื่อหน่วยงาน</Form.Label>
                                    <input
                                      disabled={showOnlyProps}
                                      type="text"
                                      list="org_other"
                                      className="form-control"
                                      name="org"
                                      value={data.sixth.other[index].org}
                                      onChange={(e) => handleChange(index, e)}
                                    />
                                    <datalist id="org_other">
                                      {org_filter.map((item) => (
                                        <option
                                          key={item.id}
                                          value={item.org_name}
                                        />
                                      ))}
                                    </datalist>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>จำนวน (บาท)</Form.Label>
                                    <Form.Control
                                      disabled={showOnlyProps}
                                      type="number"
                                      min={0}
                                      name="welfare_amount"
                                      value={
                                        data.sixth.other[index].welfare_amount
                                      }
                                      onChange={(e) => handleChange(index, e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <hr></hr>
                              </Row>
                            );
                        })}
            </Col>
            <Col md={12} >
                <div className="text-center">
                    {!showOnlyProps &&(<Button variant="primary" className="w-50" onClick={() => addNewRow()}>
                        เพิ่มแถวข้อมูล
                    </Button>)}
                </div>

            </Col>
        </Row>
    )
}

export default Other