import React from 'react'
import { useHistory } from 'react-router-dom'

import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { ApiFetch } from "../../../../config/api";
import { apiConfig } from "../../../../config/api";
import { FetchInterface, Method } from "../../../../enums/enum";
import { useAuthContextState } from "../../../../context/auth/authContext/authContextState";
import Swal from 'sweetalert2'



export default function MainEntrepreneurs() {
    const history = useHistory()

    const { token } = useAuthContextState();
    const params = useParams();

    const [loading, setLoading] = React.useState(false)
    const [personal, setPersonal] = React.useState()
    const [roundSubmit, setRoundSubmit] = React.useState([])
    const [courses, setCourses] = React.useState([])

    const [show, setShow] = React.useState(false);

    const handleModal = () => setShow(!show);

    async function getData(id) {
        try {
            setLoading(true)
            const res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/main/${id}`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.TOKEN]: token.accessToken,
            });
            if (res.status === 200) {
                setPersonal(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    async function getRoundSubmit(id) {
        try {
            setLoading(true)
            const res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/round-submit/${id}`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.TOKEN]: token.accessToken,
            });
            if (res.status === 200) {
                setRoundSubmit(res.data)
                console.log(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    async function getCourse() {
        try {
            setLoading(true)
            const res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/courses`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.TOKEN]: token.accessToken,
            });
            if (res.status === 200) {
                setCourses(res.data.data)
                console.log(res.data.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    function checkRoundSubmit(round) {
        let data = roundSubmit
        let find = data.findIndex((val) => val.round === round)
        if (find >= 0) return true
        else return false
    }

    function handleRoundButtonClick(round, isSaved) {
        if (isSaved) {
            Swal.fire({
                title: "ต้องการอัพเดตข้อมูล?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ต้องการ",
                cancelButtonText: "ไม่ต้องการ"
              }).then((result) => {
                setLoading(false)
                if (result.isConfirmed) {
                    history.push(`/entrepreneurs/question/edit/${params.personalId}/${round}`)
                }
            })
        } else {
            history.push(`/entrepreneurs/question/${params.personalId}/${round}`)
        }
    }

    React.useEffect(() => {
        getData(params.personalId)
        getRoundSubmit(params.personalId)
        getCourse()
    }, [])

    return (
        <div>
            {personal != null &&
                <div className="information p-5">
                    <div className="box-info bg-light w-100 p-5">
                        {personal.file_path !== null &&
                            <Row>
                                <Col md={12}>
                                    <div className='d-flex justify-content-center'>
                                        <img
                                            alt={personal.first_name}
                                            width={"200"}
                                            src={`${apiConfig.url}/${personal.file_path}`}
                                        />
                                    </div>

                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>รหัสบัตรประจำตัวประชาชน 13 หลัก</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        value={personal.national_id}
                                        type="number"
                                        min={0}
                                        placeholder="รหัสบัตรประจำตัวประชาชน"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>คำนำหน้าชื่อ</Form.Label>
                                    <Form.Select
                                        disabled={true}
                                        value={personal.title}
                                        aria-label="Default select example"
                                    >
                                        <option value=""></option>
                                        <option value="เด็กชาย">เด็กชาย</option>
                                        <option value="เด็กชาย">เด็กหญิง</option>
                                        <option value="นาย">นาย</option>
                                        <option value="นาง">นาง</option>
                                        <option value="นางสาว">นางสาว</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>ชื่อ</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        value={personal.first_name}
                                        type="text"
                                        placeholder=""

                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>นามสกุล</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        disabled={true}
                                        value={personal.last_name} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>ระดับผู้ประกอบการ</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        value={personal.level === "ระดับดำเนินการ" ? `${personal.level} ขายสินค้ามาเป็นระยะเวลา ${personal.year} ปี ${personal.month}` : personal.level}
                                        type="string"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* <Row>
                    <Col md={12}>
                        <h5 className="mb-4 mt-4">ข้อมูลที่อยู่สถานประกอบกิจการ</h5>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>เบอร์ติดต่อ</Form.Label>
                                <Form.Control
                                    disabled
                            maxlength="10"
                            required
                            type="text"
                            value={personal.tel}
                            
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>บ้านเลขที่</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            value={personal.address_no}
                            disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>หมู่บ้าน</Form.Label>
                            <input
                                required
                             value={personal.address_mooban}
                            list="browsers"
                            name="browser"
                            id="browser"
                            className="form-control"
                            disabled
                            ></input>
                            <datalist id="browsers">
                            </datalist>
                            
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>หมู่</Form.Label>
                            <Form.Control
                           required
                            type="number"
                            value={personal.address_group}
                            disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>จังหวัด</Form.Label>
                            <Form.Control
                                    disabled
                            required
                            type="text"
                            value={personal.province_name}
                            
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>อำเภอ</Form.Label>
                            <Form.Control
                                    disabled
                            required
                            type="text"
                            value={personal.amphur_name}
                            
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>ตำบล</Form.Label>
                            <Form.Control
                                    disabled
                            required
                            type="text"
                            value={personal.tambol_name}
                            
                            />
                        </Form.Group>
                        </Col>
                        <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>รหัสไปรษณีย์</Form.Label>
                            <Form.Control
                                    disabled
                            required
                            type="text"
                            value={personal.zip_code}
                            
                            />
                        </Form.Group>
                    </Col>
                    </Row> */}
                    </div>
                </div>}


            <div className="save-information d-flex justify-content-center mb-4">
                <Button variant="warning w-50 p-3" onClick={() => history.push(`/entrepreneurs/entrepreneurs-detail/${params.personalId}`)}>ดูข้อมูลผู้ประกอบการเพิ่มเติม</Button>
            </div>

            <div className="save-information d-flex justify-content-center mb-4">
                <Button variant="warning w-50 p-3" onClick={() => history.push(`/entrepreneurs/edit/${params.personalId}`)}>แก้ไขข้อมูลผู้ประกอบการ</Button>
            </div>

            <div className="save-information d-flex justify-content-center mb-4">
                <Button variant="warning w-50 p-3" onClick={() => handleModal()}>บันทึกข้อมูลการอบรม</Button>
            </div>

            <div className="save-revenue-expenses d-flex justify-content-center mb-4">
                <Button variant="warning w-50 p-3" onClick={() => history.push(`/entrepreneurs/accounting/${params.personalId}`)}>บันทึกข้อมูลรายรับ-รายจ่ายประจำเดือน</Button>
            </div>

            <div className="save-img-product d-flex justify-content-center mb-4">
                <Button variant="warning w-50 p-3" onClick={() => history.push(`/entrepreneurs/product-image/${params.personalId}`)}>ข้อมูลรูปภาพสินค้า</Button>
            </div>

            <Modal show={show} onHide={handleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>เลือกครั้งที่การบันทึก</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {courses.length>0 && courses.map(course => {
                        return <div key={course.id} className="save-information d-flex justify-content-center mb-4">
                                    <Button variant={`${checkRoundSubmit(course.id) === true ? 'secondary' : 'warning'} w-100 p-3`} onClick={() => handleRoundButtonClick(course.id, checkRoundSubmit(course.id))}>บันทึกข้อมูลการอบรมหลักสูตร { course.course_name}</Button>
                                </div>
                    })}
                </Modal.Body>
            </Modal>
        </div>
    )
}