import React from "react";
import { Button, Col, Row } from "react-bootstrap";

function Utilities({ resourceItem, handleEdit, handleAdd, auth }) {
  return (
    <>
      <Row>
        <Col md={10}>
          <h4>
            <b>{resourceItem.title}</b>
          </h4>
        </Col>
        <Col>
          {resourceItem.third.utilities_resource.some(
            (data) => data.checked === true
          ) &&
            auth && (
              <Button onClick={() => handleEdit(resourceItem)}>แก้ไข</Button>
            )}
        </Col>
      </Row>
      <Row>
        {resourceItem.third.utilities_resource.some(
          (data) => data.checked === true
        )
          ? resourceItem.third.utilities_resource.map((item, inx) => {
              return (
                item.checked && (
                  <span key={inx}>
                    <p>
                      {inx + 1}.{item.unity_name}{" "}
                      {item.checked && (
                        <small>
                          <img
                            src="https://www.klangbattery.com/wp-content/uploads/2019/05/604a0cadf94914c7ee6c6e552e9b4487-curved-check-mark-circle-icon-by-vexels.png"
                            alt=""
                            width="30"
                          />
                        </small>
                      )}
                    </p>
                  </span>
                )
              );
            })
          : auth && (
              <Button onClick={() => handleAdd(resourceItem)}>
                เพิ่มข้อมูล
              </Button>
            )}
      </Row>
    </>
  );
}

export default Utilities;
