import React from "react";
import { ApiFetch } from "../../../../../config/api";
import { useAuthContextState } from "../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../enums/enum";
import BarChart from "../../../../barChart/barChart";
import PersonalTableDashboard from "../../../../personalTableDashboard/personalTableDashboard";
const GeneralAge = React.forwardRef(
  ({ data, address, totalPopulation }, ref) => {
    const { token } = useAuthContextState();
    const [modalShow, setModalShow] = React.useState(false);
    const [tableData, setTableData] = React.useState({
      header: "",
      data: [],
    });
    const [tableDataForFilter, setTableDataForFilter] = React.useState({});
    React.useImperativeHandle(ref, () => ({
      async getPersonalInfoAllAge() {
        setModalShow(true);
        try {
          const res = await ApiFetch({
            [FetchInterface.PATH]: `auth/personal-table-info/GeneralAge/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
            [FetchInterface.METHOD]: Method.GET,
            [FetchInterface.TOKEN]: token.accessToken,
            [FetchInterface.PARAMS]: null,
          });
          if (res.status === 200) {
            console.log("res", res.data);
            setTableDataForFilter(res.data);
            setTableData({
              header: { datasetTitle: "ชายและหญิง", title: "ทุกช่วงอายุ" },
              data: res.data,
            });
          }
          // setLoading(false)
        } catch (error) {
          // setLoading(false)
          console.log(error);
        }
      },
    }));
    console.log(data);
    return (
      <>
        <div style={{ height: "40vh" }}>
          <BarChart
            setTableDataForFilter={setTableDataForFilter}
            address={address}
            section={"GeneralAge"}
            pathname={"personal-table-info"}
            setModalShow={setModalShow}
            setTableData={setTableData}
            data={data}
          />
        </div>
        <PersonalTableDashboard
          title={`ข้อมูลรายชื่อบุคคล เพศ ${tableData.header.datasetTitle} อยู่ช่วงอายุ ${tableData.header.title} ใน ${address.addressName} มีจำนวน ${tableData.data?.data?.length} คน`}
          tableData={tableData}
          modalShow={modalShow}
          setModalShow={setModalShow}
          setTableData={setTableData}
          tableDataForFilter={tableDataForFilter}
        />
      </>
    );
  }
);

export default GeneralAge;
