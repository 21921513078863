import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container, Form, Alert, Row, Col } from 'react-bootstrap'

function PersonalConsent({handleConsentSubmit}) {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    const [checkType, setCheckType] = React.useState(null)
    const [user, setUset] = React.useState({})
    const [accept, setAccept] = React.useState()
    const warning = false
    const styles = {
        width: isTabletOrMobile ? '100%' : '850px',
        height: isTabletOrMobile ? '100%' : '600px',
    }

     const handleSubmit = () => {
         handleConsentSubmit(accept, user)
     }

    const userHandleChange = (e) => {
        let updateData = { ...user }
        updateData[e.target.name] = e.target.value
        setUset(updateData)
    }

    const one = (
        <div style={{ width: styles.width, margin: '0 auto' }}>
            <div className="text-center" >
                <img src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Emblem_of_Prince_of_Songkla_University_line_version.png" alt="" width="50" />
                <p className="mt-2"><b>เอกสารชี้แจงพร้อมคำยินยอมพร้อมใจ สำหรับเด็กอายุ 7-12 ปี</b></p>
            </div>
            <div className="mt-4">
                <p>&nbsp; &nbsp; &nbsp; โครงการวิจัย เรื่อง การวิจัยและพัฒนาระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานีเป็นโครงการที่ได้รับการวางแผนมาแล้วอย่างละเอียดรอบคอบเพื่อการจัดทำฐานข้อมูลที่จะสามารถใช้เพื่อการวิเคราะห์ชุมชนซึ่งสามารถช่วยเหลือในเรื่องการประกอบอาชีพโดยอ้างอิงจากต้นทุนรวมทั้งส่งเสริมศักยภาพของคนในชุมชนได้ในอนาคต </p>
                <p>&nbsp; &nbsp; &nbsp; <b>โปรดอ่านข้อมูลนี้อย่างละเอียดหรือขอให้ผู้อื่นอ่านข้อมูลนี้ให้ (ด.ญ./ด.ช.) ฟัง และขอให้ (ด.ญ./ด.ช.) ใช้เวลาทบทวนข้อมูลเหล่านี้กับคุณพ่อคุณแม่  ถ้ามีข้อสงสัยเกี่ยวกับโครงการนี้ (ด.ญ./ด.ช.) สามารถโทรติดต่อ
                    ที่ ดร.ศริยา บิลแสละ ได้ที่เบอร์ 083-5199065 หรือ 064-6952922</b></p>
                <p>&nbsp; &nbsp; &nbsp; วัตถุประสงค์ของการวิจัย  </p>
            </div>
            <div className="mt-2">
                <p>&nbsp; &nbsp; &nbsp; 1)	เพื่อศึกษากระบวนการพัฒนาระบบนิเวศและสร้างความเป็นผู้ประกอบการท้องถิ่นบนฐานทรัพยากรในพื้นที่และเกิดกิจกรรมทางเศรษฐกิจที่ช่วยยกระดับคุณภาพชีวิตประชากรกลุ่มฐานราก</p>
                <p>&nbsp; &nbsp; &nbsp; 2)	เพื่อออกแบบและพัฒนาระบบฐานข้อมูลที่สามารถใช้เป็นข้อมูลประกอบการตัดสินใจและกำหนดการพัฒนาเศรษฐกิจฐานรากในพื้นที่จังหวัดปัตตานีได้ (Data Driven Ecosystem for InnovaTive Entrepreneur: DD-Ecotive)  </p>
                <p>&nbsp; &nbsp; &nbsp; 3)	เพื่อออกแบบระบบการวางแผนทรัพยากรในชุมชน (ERP for Community) เพื่อสร้างระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานี</p>
                <p>&nbsp; &nbsp; &nbsp; 4)	เพื่อจัดทำข้อเสนอแนะเชิงนโยบายแนวทางการพัฒนาระบบนิเวศฯ เพื่อพัฒนาผู้ประกอบการนวัตกรรมในพื้นที่ยากจน</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ทำไม(ด.ญ./ด.ช.) จึงถูกขอให้เข้าร่วมโครงการ?</b>&nbsp; &nbsp; &nbsp; เนื่องจากเด็ก ๆ ถือเป็นกำลังสำคัญในชุมชน การเก็บข้อมูลที่รอบด้านมีความสำคัญเป็นอย่างยิ่งต่อการพัฒนาชุมชน ในอนาคต ดังนั้น จึงมีความจำเป็นต้องเก็บข้อมูลส่วนตัว เช่น ข้อมูลเกี่ยวกับอายุ เพศ ระดับการศึกษาของเด็กที่อาศัยในชุมชน การเก็บข้อมูลและบันทึกลงในฐานข้อมูลจะทำให้ผู้นำชุมชนสามารถมองภาพของชุมชนได้ชัดเจนตามข้อมูลที่ถูกต้องมากขึ้น นอกจากนี้ หน่วยงานภาครัฐยังสามารถประยุกต์ใช้ฐานข้อมูลชุมชนเพื่อการส่งเสริมชุมชนในด้านต่าง ๆ ไม่ว่าจะเป็น การศึกษา สวัสดิการจากภาครัฐ โดยเฉพาะส่งเสริมในเรื่องการประกอบอาชีพและทักษะที่จะเป็นแก่กลุ่มเยาวชนได้</p>

            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp;โครงการวิจัยนี้จะมีเด็กจากหมู่ 1และ หมู่ 6 ตำบลบานา อ.เมือง และ หมู่ 1 และหมู่ 2 ตำบลตะบิ้ง อ.สายบุรี  ร้อยละ 10 ของประชากรแต่ละพื้นที่ ซึ่งจะมีจำนวนดังนี้</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 1 ตำบลบานา อ.เมือง ประชากร 1612 จำนวนเด็กที่เข้าร่วม 161 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 6 ตำบลบานา อ.เมือง ประชากร 1609 จำนวนเด็กที่เข้าร่วม 161 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 1 ตำบลตะบิ้ง อ.สายบุรี ประชากร 1217 จำนวนเด็กที่เข้าร่วม 121 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 2 ตำบลตะบิ้ง อ.สายบุรี ประชากร 860 จำนวนเด็กที่เข้าร่วม 86 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; รวมจำนวนเด็กที่เข้าร่วมทั้งหมดประมาณ 529 คน  </p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ถ้า (ด.ญ./ด.ช.) ตกลงที่จะเข้าร่วมโครงการวิจัยจะเกิดอะไรขึ้นบ้าง?</b> <br></br>ข้อมูลของเด็ก ๆ จะถูกจัดเก็บไว้ในระบบ โดยผู้ปกครองจะเป็นคนให้ข้อมูลแก่ทีมวิจัย</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ข้าพเจ้าจะต้องทำอะไรบ้างในระหว่างการศึกษาวิจัย?</b> <br></br>เด็ก ๆ ไม่ได้มีหน้าที่รับผิดชอบใด ๆ ในการเข้าร่วมโครงการ ข้อมูลของเด็ก ๆ จะถูกสอบถามจากผู้ปกครองโดยตรง</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ข้าพเจ้าอาจได้รับความเสี่ยงหรือความไม่สบายต่างๆ ได้แก่  </b> (เช่น อาการข้างเคียงของยา ความเจ็บปวด
                    ความอึดอัดไม่สบายใจ เป็นต้น)
                    <br></br>เด็ก ๆ ไม่มีความเสี่ยงทางด้านสุขภาพ กาย และสุขภาพจิตในการเข้าร่วมโครงการนี้</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>การเข้าร่วมการวิจัยจะมีประโยชน์กับข้าพเจ้าหรือไม่ อย่างไร?</b> <br></br>การบันทึกข้อมูลในฐานข้อมูลจะมีประโยชน์ต่อการส่งเสริมอาชีพ ทักษะและสวัสดิการอื่น ๆ ใน อนาคตของเด็ก ๆ</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ข้าพเจ้าจำเป็นต้องเข้าร่วมในโครงการวิจัยนี้หรือไม่</b> <br></br>“คุณมีสิทธิปฏิเสธเข้าร่วมการวิจัยหากคุณไม่ต้องการ ไม่มีใครสามารถบังคับคุณให้เข้าร่วมการวิจัยได้ คุณมีสิทธิในการถามหากสงสัยหรือไม่เข้าใจ หากคุณตกลงยินยอมเข้าร่วมวิจัย นักวิจัยจะต้องขอให้คุณลงลายมือชื่อในใบชี้แจงข้อมูลและแสดงความสมัครใจเข้าร่วมโครงการวิจัยครั้งนี้ ในกรณีที่คุณรู้สึกไม่สบายใจเกี่ยวกับการวิจัยครั้งนี้ จะมีการขอให้บิดามารดาหรือผู้ปกครอง หรือผู้แทนโดยชอบธรรมของคุณลงลายมือชื่อในแบบฟอร์มเพื่ออนุญาตด้วย ทั้งนี้ ขอยืนยันว่า คุณสามารถหยุดการเข้าร่วมวิจัยได้ทุกเมื่อโดยไม่จำเป็นต้องให้เหตุผล”</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; ผู้วิจัยได้อธิบายข้อมูลและขั้นตอนต่างๆ ในกระบวนการวิจัยให้ฟังจนเข้าใจดีแล้ว และข้าพเจ้าทราบว่า
                    ไม่ว่าข้าพเจ้าจะเข้าร่วมหรือไม่เข้าร่วมการวิจัยก็ตาม ย่อมไม่ส่งผลกระทบต่อการดูแลรักษาพยาบาลของข้าพเจ้า และข้าพเจ้าทราบว่าจะยินดีเข้าร่วมโครงการหรือไม่ก็ได้ โดยไม่มีผลกระทบต่อการดูแลรักษาพยาบาล
                    ของข้าพเจ้า
                </p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; ข้าพเจ้าเข้าใจโครงการนี้ และโดยความเห็นชอบของผู้ปกครองของข้าพเจ้า จึงได้ตกลงเข้าร่วม
                    โครงการวิจัยนี้
                </p>
            </div>
            <div className="mt-4 d-flex flex-column align-items-end">
                <p>ขอขอบคุณอย่างสูง</p>
                <p className="mt-4">(ดร.ศริยา บิลแสละ) <br></br> หัวหน้าโครงการวิจัย</p>
            </div>
            <div className="mt-4 text-center">
                <Row>
                    <Col md={12}>
                        <p>เอกสารขอความยินยอมในการให้ข้อมูล</p>
                    </Col>
                    <Col md={12}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>คำนำหน้าชื่อ (ผู้แทนโดยชอบธรรม)</Form.Label>
                                    <Form.Select aria-label="Default select example" name="accepter_title" onChange={(e) => userHandleChange(e)}>
                                        <option></option>
                                        <option value="นาย">นาย</option>
                                        <option value="นาง">นาง</option>
                                        <option value="นางสาว">นางสาว</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>ชื่อ (ผู้แทนโดยชอบธรรม)</Form.Label>
                                    <Form.Control type="text" name="accepter_firstname" onChange={(e) => userHandleChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>นามสกุล (ผู้แทนโดยชอบธรรม)</Form.Label>
                                    <Form.Control type="text" name="accepter_lastname" onChange={(e) => userHandleChange(e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className="d-flex flex-column align-items-center mb-2">
                        <div className="d-flex">
                            <Form.Check
                                onChange={() => {
                                    setAccept(true)
                                }}
                                name="accept"
                                className="mx-2"
                                type={"radio"}
                                id={`default-radio`}
                                label={`ยอมรับ`}
                            />
                            <Form.Check
                                onChange={() => {
                                    setAccept(false)
                                }}
                                name="accept"
                                type={"radio"}
                                id={`default-radio`}
                                label={`ไม่ยอมรับ`}
                            /></div>
                    </Col>
                    <Col md={12}>
                            <p>
                                <button type="button" className="btn btn-success" onClick={() => handleSubmit()}>ยืนยัน</button>
                            </p>
                        </Col>
                </Row>
            </div>
            {warning ?
                <div className="mt-2 text-center">
                    <Alert variant={"warning"}>
                        โปรดตรวจสอบความถูกต้องก่อนยืนยัน
                    </Alert>
                </div>
                : null
            }

        </div>
    )

    const second = (
        <div style={{ width: styles.width, margin: '0 auto' }}>
            <div className="text-center" >
                <img src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Emblem_of_Prince_of_Songkla_University_line_version.png" alt="" width="50" />
                <p className="mt-2"><b>เอกสารชี้แจงพร้อมคำยินยอมพร้อมใจ สำหรับเด็กอายุ 13 - 18 ปี</b></p>
            </div>
            <div className="mt-4">
                <p>&nbsp; &nbsp; &nbsp; โครงการวิจัย เรื่อง:  การวิจัยและพัฒนาระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานี</p>
                <p>&nbsp; &nbsp; &nbsp; <b>โปรดอ่านข้อมูลนี้อย่างละเอียด หรือขอให้ผู้อื่นอ่านข้อมูลนี้ให้คุณฟัง ขอให้ใช้เวลาในการพิจารณาเกี่ยวกับข้อมูลเหล่านี้ ถ้าคุณไม่เข้าใจหรือมีข้อสงสัย ขอให้สอบถามผู้วิจัยหรือผู้แทนจนกว่าจะเข้าใจดี คุณอาจจะขอเอกสารนี้กลับไปอ่านที่บ้านเพื่อปรึกษา หารือกับญาติพี่น้อง เพื่อนสนิท แพทย์ประจำตัวของคุณ หรือแพทย์ท่านอื่น เพื่อช่วยในการตัดสินใจเข้าร่วมการวิจัย</b></p>
                <p>&nbsp; &nbsp; &nbsp; <b>การวิจัยคืออะไร?
                    การวิจัยเป็นโครงการที่ได้รับการวางแผนมาแล้วอย่างละเอียดรอบคอบเพื่อการจัดทำฐานข้อมูลที่จะสามารถใช้เพื่อการวิเคราะห์ชุมชนซึ่งสามารถช่วยเหลือในเรื่องการประกอบอาชีพโดยอ้างอิงจากต้นทุนรวมทั้งส่งเสริมศักยภาพของคนในชุมชนได้ในอนาคต
                </b></p>
                <p>&nbsp; &nbsp; &nbsp; <b>ทำไมจึงต้องทำโครงการวิจัยนี้?</b> <br></br>โครงการวิจัยนี้มีวัตถุประสงค์ของการวิจัยคือ </p>
            </div>
            <div className="mt-2">
                <p>&nbsp; &nbsp; &nbsp; 1)	เพื่อศึกษากระบวนการพัฒนาระบบนิเวศและสร้างความเป็นผู้ประกอบการท้องถิ่นบนฐานทรัพยากรในพื้นที่และเกิดกิจกรรมทางเศรษฐกิจที่ช่วยยกระดับคุณภาพชีวิตประชากรกลุ่มฐานราก</p>
                <p>&nbsp; &nbsp; &nbsp; 2)	เพื่อออกแบบและพัฒนาระบบฐานข้อมูลที่สามารถใช้เป็นข้อมูลประกอบการตัดสินใจและกำหนดการพัฒนาเศรษฐกิจฐานรากในพื้นที่จังหวัดปัตตานีได้ (Data Driven Ecosystem for InnovaTive Entrepreneur: DD-Ecotive) </p>
                <p>&nbsp; &nbsp; &nbsp; 3)	เพื่อออกแบบระบบการวางแผนทรัพยากรในชุมชน (ERP for Community) เพื่อสร้างระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานี</p>
                <p>&nbsp; &nbsp; &nbsp; 4)	เพื่อจัดทำข้อเสนอแนะเชิงนโยบายแนวทางการพัฒนาระบบนิเวศฯ เพื่อพัฒนาผู้ประกอบการนวัตกรรมในพื้นที่ยากจน</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ทำไมคุณจึงถูกขอให้เข้าร่วมโครงการ?</b> <br></br>เนื่องจากเยาวชนถือเป็นกำลังสำคัญในชุมชน การเก็บข้อมูลที่รอบด้านมีความสำคัญเป็นอย่างยิ่งต่อการพัฒนาชุมชน ในอนาคต ดังนั้น จึงมีความจำเป็นต้องเก็บข้อมูลส่วนตัว เช่น ข้อมูลเกี่ยวกับอายุ เพศ ระดับการศึกษาของเยาวชนที่อาศัยในชุมชน การเก็บข้อมูลและบันทึกลงในฐานข้อมูลจะทำให้ผู้นำชุมชนสามารถมองภาพของชุมชนได้ชัดเจนตามข้อมูลที่ถูกต้องมากขึ้น นอกจากนี้ หน่วยงานภาครัฐยังสามารถประยุกต์ใช้ฐานข้อมูลชุมชนเพื่อการส่งเสริมชุมชนในด้านต่าง ๆ ไม่ว่าจะเป็น การศึกษา สวัสดิการจากภาครัฐ โดยเฉพาะส่งเสริมในเรื่องการประกอบอาชีพและทักษะที่จะเป็นแก่กลุ่มเยาวชนได้</p>

            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>จะมีเด็กคนอื่นๆ เข้าร่วมโครงการด้วยหรือไม่?</b> <br></br>ใช่ โครงการวิจัยนี้จะมีเยาวชนอายุตั้งแต่ ...13......ปี จนถึง...18....ปี จากหมู่ 1และ หมู่ 6 ตำบลบานา อ.เมือง และ หมู่ 1 และหมู่ 2 ตำบลตะบิ้ง อ.สายบุรี  ร้อยละ 10 ของประชากรแต่ละพื้นที่ ซึ่งจะมีจำนวนดังนี้</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 1 ตำบลบานา อ.เมือง ประชากร 1612 จำนวนเด็กที่เข้าร่วม 161 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 6 ตำบลบานา อ.เมือง ประชากร 1609 จำนวนเด็กที่เข้าร่วม 161 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 1 ตำบลตะบิ้ง อ.สายบุรี ประชากร 1217 จำนวนเด็กที่เข้าร่วม 121 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; หมู่ 2 ตำบลตะบิ้ง อ.สายบุรี ประชากร 860 จำนวนเด็กที่เข้าร่วม 86 คน</p>
                <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; รวมจำนวนเด็กที่เข้าร่วมทั้งหมดประมาณ 529 คน  </p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ถ้าคุณตกลงที่จะเข้าร่วมโครงการวิจัยจะเกิดอะไรขึ้นบ้าง?</b> <br></br>ข้อมูลของเยาวชนจะถูกจัดเก็บไว้ในระบบ </p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>คุณจะต้องทำอะไรบ้างในระหว่างการศึกษาวิจัย?</b> <br></br>เยาวชนไม่ได้มีหน้าที่รับผิดชอบใด ๆ ในการเข้าร่วมโครงการนอกจากตอบแบบสอบถาม</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>คุณอาจได้รับความเสี่ยงหรือความไม่สบายต่างๆ ได้แก่ </b> (เช่น อาการข้างเคียงของยา ความเจ็บปวด ความอึดอัดความไม่สบายใจ เป็นต้น) <br></br>ไม่มี</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>จะทำอย่างไรถ้ามีบางสิ่งบางอย่างผิดปกติเกิดขึ้นในระหว่างการศึกษาวิจัย?</b> <br></br>ไม่มีความเสี่ยงทางด้านสุขภาพ กาย และสุขภาพจิตในการเข้าร่วมโครงการนี้</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>การเข้าร่วมการวิจัยจะมีประโยชน์กับคุณหรือคนอื่นๆหรือไม่?</b> <br></br>การบันทึกข้อมูลในฐานข้อมูลจะมีประโยชน์ต่อการส่งเสริมอาชีพ ทักษะและสวัสดิการอื่น ๆ ใน อนาคตของเด็ก ๆ</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>เกิดอะไรขึ้นถ้าคุณต้องการที่จะยุติการเข้าร่วมโครงการ?</b> <br></br>คุณสามารถยุติการเข้าร่วมโครงการในระหว่างการวิจัยได้ทุกเมื่อ จะไม่มีใครโกรธคุณหากคุณ บิดา มารดา ผู้ปกครองหรือผู้แทนโดยชอบธรรมของคุณ หรือผู้วิจัยคิดว่าสมควรที่สุดที่จะยุติการเข้าร่วมโครงการ และจะไม่มีผลกระทบใด ๆ ต่อตัวคุณและครอบครัว </p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>คุณจำเป็นต้องเข้าร่วมในโครงการวิจัยนี้หรือไม่</b> <br></br><b>“คุณมีสิทธิปฏิเสธเข้าร่วมการวิจัยหากคุณไม่ต้องการ ไม่มีใครสามารถบังคับคุณให้เข้าร่วมการวิจัยได้ คุณมีสิทธิในการถามหากสงสัยหรือไม่เข้าใจ หากคุณตกลงยินยอมเข้าร่วมวิจัย นักวิจัยจะต้องขอให้คุณลงลายมือชื่อในใบชี้แจงข้อมูลและแสดงความสมัครใจเข้าร่วมโครงการวิจัยครั้งนี้ ในกรณีที่คุณรู้สึกไม่สบายใจเกี่ยวกับการวิจัยครั้งนี้ จะมีการขอให้บิดามารดาหรือผู้ปกครอง หรือผู้แทนโดยชอบธรรมของคุณลงลายมือชื่อในแบบฟอร์มเพื่ออนุญาตด้วย ทั้งนี้ ขอยืนยันว่า คุณสามารถหยุดการเข้าร่วมวิจัยได้ทุกเมื่อโดยไม่จำเป็นต้องให้เหตุผล”</b></p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; <b>ข้อมูลของคุณในการวิจัยนี้จะถูกเก็บรักษาไว้เป็นความลับหรือไม่?</b> <br></br>ใช่ จะอนุญาตให้เฉพาะผู้กำกับดูแลการวิจัย สถาบันหรือองค์กรของรัฐที่มีหน้าที่ตรวจสอบ  รวมถึงคณะกรรมการจริยธรรมการวิจัยวิทยาเขตปัตตานี เป็นต้น ดูข้อมูลวิจัยของข้าพเจ้าได้ และนักวิจัยและผู้ได้รับอนุญาตสัญญาที่จะเก็บรักษาข้อมูลเหล่านี้ไว้เป็นความลับ</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; ผู้วิจัยได้อธิบายข้อมูลและขั้นตอนต่างๆ ในกระบวนการวิจัยให้ฟังจนเข้าใจได้ดี และข้าพเจ้าทราบว่า ไม่ว่าข้าพเจ้าจะเข้าร่วมหรือไม่เข้าร่วมการวิจัยก็ตาม ย่อมไม่ส่งผลกระทบต่อการดูแลรักษาพยาบาลของข้าพเจ้า และข้าพเจ้าทราบว่าจะยินดีเข้าร่วมโครงการหรือไม่ก็ได้ โดยไม่มีผลกระทบต่อการดูแลรักษาพยาบาลของข้าพเจ้า</p>
            </div>
            <div className="mb-2">
                <p>&nbsp; &nbsp; &nbsp; ข้าพเจ้าเข้าใจโครงการนี้ และโดยความเห็นชอบของผู้ปกครองของข้าพเจ้า จึงได้ตกลงเข้าร่วมโครงการวิจัยนี้</p>
            </div>
            <div className="mt-4 d-flex flex-column align-items-end">
                <p>ขอขอบคุณอย่างสูง</p>
                <p className="mt-4">(ดร.ศริยา บิลแสละ) <br></br> หัวหน้าโครงการวิจัย</p>
            </div>
            <div className="mt-4 text-center">
                <Row>
                    <Col md={12}>
                        <p>เอกสารขอความยินยอมในการให้ข้อมูล</p>
                    </Col>
                    <Col md={12}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>คำนำหน้าชื่อ (ผู้แทนโดยชอบธรรม)</Form.Label>
                                    <Form.Select aria-label="Default select example" name="accepter_title" onChange={(e) => userHandleChange(e)}>
                                        <option></option>
                                        <option value="นาย">นาย</option>
                                        <option value="นาง">นาง</option>
                                        <option value="นางสาว">นางสาว</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>ชื่อ (ผู้แทนโดยชอบธรรม)</Form.Label>
                                    <Form.Control type="text" name="accepter_firstname" onChange={(e) => userHandleChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>นามสกุล (ผู้แทนโดยชอบธรรม)</Form.Label>
                                    <Form.Control type="text" name="accepter_lastname" onChange={(e) => userHandleChange(e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className="d-flex flex-column align-items-center mb-2">
                        <div className="d-flex">
                            <Form.Check
                                onChange={() => {
                                    setAccept(true)
                                }}
                                name="accept"
                                className="mx-2"
                                type={"radio"}
                                id={`default-radio`}
                                label={`ยอมรับ`}
                            />
                            <Form.Check
                                onChange={() => {
                                    setAccept(false)
                                }}
                                name="accept"
                                type={"radio"}
                                id={`default-radio`}
                                label={`ไม่ยอมรับ`}
                            /></div>
                    </Col>
                    <Col md={12}>
                            <p>
                                <button type="button" className="btn btn-success" onClick={() => handleSubmit()}>ยืนยัน</button>
                            </p>
                        </Col>
                </Row>
            </div>
            {warning ?
                <div className="mt-2 text-center">
                    <Alert variant={"warning"}>
                        โปรดตรวจสอบความถูกต้องก่อนยืนยัน
                    </Alert>
                </div>
                : null
            }

        </div>
    )

    const third = (
        <div style={{ width: styles.width, margin: '0 auto' }}>
            <div className="text-center" >
                <img src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Emblem_of_Prince_of_Songkla_University_line_version.png" alt="" width="50" />
                <p className="mt-2">แบบชี้แจงข้อมูลสำหรับอาสาสมัครโครงการวิจัย <br /> (ที่ใช้แบบสอบถามตอบด้วยตนเอง)</p>
            </div>
            <div className="mt-4">
                <p>&nbsp; &nbsp; &nbsp; เนื่องด้วย ข้าพเจ้า  ดร.ศริยา บิลแสละ กำลังดำเนินการวิจัยเรื่อง การวิจัยและพัฒนาระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานี โดยมีวัตถุประสงค์ของการวิจัย </p>
                <p>&nbsp; &nbsp; &nbsp; 1) เพื่อศึกษากระบวนการพัฒนาระบบนิเวศและสร้างความเป็นผู้ประกอบการท้องถิ่นบนฐานทรัพยากรในพื้นที่และเกิดกิจกรรมทางเศรษฐกิจที่ช่วยยกระดับคุณภาพชีวิตประชากรกลุ่มฐานราก</p>
                <p>&nbsp; &nbsp; &nbsp; 2) เพื่อออกแบบและพัฒนาระบบฐานข้อมูลที่สามารถใช้เป็นข้อมูลประกอบการตัดสินใจและกำหนดการพัฒนาเศรษฐกิจฐานรากในพื้นที่จังหวัดปัตตานีได้ (Data Driven Ecosystem for InnovaTive Entrepreneur: DD-Ecotive) </p>
                <p>&nbsp; &nbsp; &nbsp; 3) เพื่อออกแบบระบบการวางแผนทรัพยากรในชุมชน (ERP for Community) เพื่อสร้างระบบนิเวศสร้างสรรค์เพื่อการเป็นผู้ประกอบการรายย่อยสู่โมเดลพึ่งตนเองจังหวัดปัตตานี</p>
                <p>&nbsp; &nbsp; &nbsp; 4) เพื่อจัดทำข้อเสนอแนะเชิงนโยบายแนวทางการพัฒนาระบบนิเวศฯ เพื่อพัฒนาผู้ประกอบการนวัตกรรมในพื้นที่ยากจน</p>
            </div>
            <div className="mt-2">
                <p>&nbsp; &nbsp; &nbsp; เหตุผลที่ผู้เข้าร่วมวิจัยได้รับเชิญเข้าร่วมโครงการหรือเข้าเป็นกลุ่มตัวอย่าง เนื่องจากเป็นบุคคลในชุมชนเป้าหมายของงานวิจัยในครั้งนี้ ซึ่งประกอบด้วยชุมชนบ้านสุไหงปาแน ม. 1 หรือ บ้านจือโระ หมู่ 6 ตำบลบานา อ.เมือง หรือ หมู่ 1และ หมู่ 2 ตำบลตะบิ้ง อ.สายบุรี จังหวัดปัตตานี สำหรับประโยชน์ที่คาดว่าจะได้รับจากการวิจัยนี้คือ </p>
                <p>&nbsp; &nbsp; &nbsp; 1. ผู้ประกอบการในพื้นที่ตำบลบานา อ.เมือง และตำบลตะบิ้ง อ.สายบุรี จังหวัดปัตตานีมีกระบวนการคิดแบบนวัตกรรมเพื่อสร้างสรรค์สินค้าหรือบริการแบบใหม่ที่ตอบโจทย์ความต้องการตลาด</p>
                <p>&nbsp; &nbsp; &nbsp; 2. ระบบนิเวศสร้างสรรค์ (Innovative Eco system) ที่ได้พัฒนาขึ้นสามารถบ่มเพาะธุรกิจ SMEs Start Up เยาวชน นักศึกษาและบุคคลทั่วไปที่ต้องการเป็นผู้ประกอบการเพื่อให้ก้าวทันกับสังคมที่ใช้ดิจิทัลในการขับเคลื่อนทุกด้านในจังหวัดปัตตานี</p>
                <p>&nbsp; &nbsp; &nbsp; จึงเรียนมาเพื่อขอความอนุเคราะห์ในการตอบแบบสอบถาม  เพื่อประโยชน์ทางการศึกษาดังกล่าว  โดยขอให้ท่านตอบตามความเป็นจริง  เพราะคำตอบของท่านมีความสำคัญต่อการวิเคราะห์ข้อมูลเป็นอย่างยิ่ง   ข้าพเจ้าขอรับรองว่าจะเก็บรักษาข้อมูลในการตอบแบบสอบถามของท่านไว้เป็นความลับ  และผลการวิจัยจะนำเสนอในลักษณะภาพรวม  ไม่ระบุชื่อ/ ข้อมูลส่วนตัวของท่าน  จึงไม่เกิดผลกระทบต่อการปฏิบัติงานของท่านแต่ประการใด </p>
                <p>&nbsp; &nbsp; &nbsp;  ข้าพเจ้าหวังเป็นอย่างยิ่งว่าจะได้รับความร่วมมือจากท่านเป็นอย่างดี และขอขอบพระคุณ เป็นอย่างสูง
                    มา ณ โอกาสนี้  หากท่านมีข้อสงสัยเกี่ยวกับงานวิจัย โปรดติดต่อได้ที่ ดร.ศริยา บิลแสละ คณะวิทยาการสื่อสาร  มหาวิทยาลัยสงขลานครินทร์  E-mail: sariya.b@psu.ac.th เบอร์โทรศัพท์มือถือ 083-5199065
                </p>
                <p><b>&nbsp; &nbsp; &nbsp;หากท่านมีปัญหาสงสัยเกี่ยวกับสิทธิของท่านขณะเข้าร่วมการวิจัยนี้  ต้องการทราบข้อมูลเพิ่มเติม  โปรดสอบถามได้ที่ <u> งานส่งเสริมการวิจัย นวัตกรรม และบริการวิชาการ กองบริหารวิชาการและวิจัยวิทยาเขตปัตตานี มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตปัตตานี  94000 หรือทางโทรศัพท์หมายเลข 064-6952922 </u></b></p>
            </div>
            <div className="mt-4 d-flex flex-column align-items-end">
                <p>ขอขอบคุณอย่างสูง</p>
                <p className="mt-4">(ดร.ศริยา บิลแสละ) <br></br> หัวหน้าโครงการวิจัย</p>
            </div>
            <div className="mt-4 text-center">
                <Row>
                    <Col md={12}>
                        <p>เอกสารขอความยินยอมในการให้ข้อมูล</p>
                    </Col>
                    <Col md={12} className="d-flex flex-column align-items-center mb-2">
                        <div className="d-flex">
                            <Form.Check
                                onChange={() => {
                                    setAccept(true)
                                }}
                                name="accept"
                                className="mx-2"
                                type={"radio"}
                                id={`default-radio`}
                                label={`ยอมรับ`}
                            />
                            <Form.Check
                                onChange={() => {
                                    setAccept(false)
                                }}
                                name="accept"
                                type={"radio"}
                                id={`default-radio`}
                                label={`ไม่ยอมรับ`}
                            /></div>
                    </Col>
                     <Col md={12}>
                        <p>
                            <button type="button" className="btn btn-success" onClick={() => handleSubmit()}>ยืนยัน</button>
                        </p>
                    </Col>
                </Row>
            </div>
            {warning ?
                <div className="mt-2 text-center">
                    <Alert variant={"warning"}>
                        โปรดตรวจสอบความถูกต้องก่อนยืนยัน
                    </Alert>
                </div>
                : null
            }

        </div>
    )

    const check = (
        <>
            <div className="text-center">
                <p>ใบขอความยินยอมในการให้ข้อมูล</p>
                <div className="mt-4 d-flex justify-content-center">
                    <Form.Check
                        onChange={() => {
                            setCheckType(0)
                        }}
                        className="mx-2"
                        type="radio"
                        label={`เด็กอายุ 7-12 ปี`}
                        name="check"
                    />
                    <Form.Check
                        onChange={() => {
                            setCheckType(1)
                        }}
                        className="mx-2"
                        type="radio"
                        label={`เด็กอายุ 13 - 18 ปี`}
                        name="check"
                    />
                    <Form.Check
                        onChange={() => {
                            setCheckType(2)
                        }}
                        className="mx-2"
                        type="radio"
                        label={`อายุมากว่า 18 ปี ขึ้นไป`}
                        name="check"
                    />
                </div>
            </div>
        </>

    )

    return (
        <Container>
            {check}
            {checkType === 0 ?
                <>{one}</> : null
            }
            {checkType === 1 ?
                <>{second}</> : null
            }
            {checkType === 2 ?
                <>{third}</> : null
            }
        </Container>
    )
}

export default PersonalConsent