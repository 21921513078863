import React from "react";
import styled from "styled-components";
import { ApiFetch } from "../../config/api";
import { useAuthContextState } from "../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../enums/enum";
import Description from "./description/description";
import DonutBox from "./donutBox/Donut";

function DonutChart({
  chartData,
  cutout,
  pathname,
  section,
  setModalShow,
  setTableData,
  address,
  setTableDataForFilter,
}) {
  const { token } = useAuthContextState();
  async function getDataTable(pathname, section, title, datasetTitle) {
    if (!token.accessToken) return false;
    setTableData({ header: title, data: [] });
    const titleData = {
      title: title,
      datasetTitle: !!datasetTitle ? datasetTitle : "",
    };
    setModalShow(true);
    setTableData({ header: titleData, data: [] });
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/${pathname}/${section}/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });

      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({ header: titleData, data: res.data });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }
  return (
    <ChartBox>
      <div className="donut-box">
      <DonutBox
        cutout={cutout}
        chartData={chartData}
        pathname={pathname}
        section={section}
        getDataTable={getDataTable}
      />
      </div>
      <label className="mt-3" htmlFor="">
        {" "}
        *คิดเป็นร้อยละจาก จำนวนรวม {!!chartData && chartData.population} คน
      </label>
      <Description
        chartData={chartData}
        pathname={pathname}
        section={section}
        getDataTable={getDataTable}
      />
    </ChartBox>
  );
}

export default DonutChart;
const ChartBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  .donut-box {
    padding: 5px;
    height: 60%;
    width: 95%;
  }
  .Description {
    padding: 10px 10px 20px 10px;
    margin: 15px 0 15px 0;
    height: 50%;
    width: 90%;
    overflow-y: auto;
    .box {
      width: 100%;
      display: flex;
      align-items: center;
      overflow-y: auto;
    }
    .dot {
      width: 20px;
      height: 10px;
      border-radius: 10px;
    }
    .text {
      width: 90%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-left: 5px;
      padding: 2px;
      .text-title {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        width: 60%;
      }
      .text-cont {
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: cyan;
        width: 35%;
      }
    }
  }
  @media screen and (max-width: 576px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: solid 0.5px gray;
    height: 80%;
    width: 90%;
    .box {
      width: 100%;
      display: flex;
      align-items: center;
      height: 90%;
      overflow-x: auto;
    }
  }
`;
