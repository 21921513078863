import React from "react";
import { Modal, Button } from "react-bootstrap";
import SectionFifth from "../../../CommunityForm/insertComponent/sectionFifth";
import EditModal from "../editModal/editModal";

function Popup({
  setModalForm,
  editState,
  modalShow,
  infoGroupItem,
  setModalShow,
  handleEdit,
  editData,
  setEditData,
  modalForm,
  auth,
}) {
  console.log("infoGroupPopupData", infoGroupItem);
  function handleClose() {
    setModalShow(false);
  }
  return (
    <div>
      {modalForm ? (
        <EditModal
          editDataResult={editData}
          setModalForm={setModalForm}
          setModalShow={setModalShow}
          editState={editState}
          section={
            <SectionFifth
              isEdit={editState}
              data={editData}
              setData={setEditData}
            />
          }
        />
      ) : (
        <Modal
          onHide={() => handleClose()}
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              ข้อมูลกลุ่ม {infoGroupItem.fifth.social[0].resource_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              อยู่ภายใต้การดูแล:{" "}
              <b>{infoGroupItem.fifth.social[0].resource_type}</b>
            </h5>
            <h5>
              ประเภทกลุ่ม: <b>{infoGroupItem.fifth.social[0].resource_group}</b>
            </h5>
            <h5>
              ชื่อกลุ่ม: <b>{infoGroupItem.fifth.social[0].resource_name}</b>
            </h5>
            <h5>
              จุดประสงค์ของกลุ่ม:{" "}
              <b>{infoGroupItem.fifth.social[0].resource_objective}</b>
            </h5>
            <h5>
              สถานที่ทำการกลุ่ม:{" "}
              <b>{infoGroupItem.fifth.social[0].resource_address}</b>
            </h5>
            <h5>
              การได้รับสนับสนุนงบประมาณ:{" "}
              <b>
                {infoGroupItem.fifth.social[0].org_supporter}{" "}
                {!!infoGroupItem.fifth.social[0].support_money && (
                  <>
                    จำนวน {infoGroupItem.fifth.social[0].support_money ?
                    infoGroupItem.fifth.social[0].support_money : 0} บาท
                  </>
                )}
                
              </b>
            </h5>
            {!!(infoGroupItem.fifth.social[0].org_supporter != "ไม่มี") && (
              <h5>
                องค์กรที่สนับสนุน:{" "}
                <b>{infoGroupItem.fifth.social[0].org_name}</b>
              </h5>
            )}
            {infoGroupItem.fifth.social[0].resource_group ===
            "กลุ่มการเงิน (กองทุน/กลุ่มออมทรัพย์)" ? (
              <>
                {" "}
                <h5>
                  เงินทุนหมุนเวียน:{" "}
                  <b>
                    จำนวน{" "}
                    {infoGroupItem.fifth.social[0].cooperative_money
                      ? infoGroupItem.fifth.social[0].cooperative_money
                      : 0}{" "}
                    บาท
                  </b>
                </h5>
                <h5>
                  จำนวนสมาชิก:{" "}
                  <b>
                    จำนวน{" "}
                    {infoGroupItem.fifth.social[0].cooperative_user
                      ? infoGroupItem.fifth.social[0].cooperative_user
                      : 0}{" "}
                    บาท
                  </b>
                </h5>
              </>
            ) : null}
            <hr />
            <h5>ผู้ประสานงานกลุ่ม</h5>
            <h5>
              {infoGroupItem.fifth.social[0].leader_title}{" "}
              {infoGroupItem.fifth.social[0].leader_name}{" "}
              {infoGroupItem.fifth.social[0].leader_lastname} | เบอร์ติดต่อ:{" "}
              {infoGroupItem.fifth.social[0].leader_phone} | Email:{" "}
              {infoGroupItem.fifth.social[0].leader_email}
            </h5>
            <hr />
            <h5>ผู้ประสานงานองค์กรณ์</h5>
            <h5>
              {infoGroupItem.fifth.social[0].contact_title}{" "}
              {infoGroupItem.fifth.social[0].contact_name}{" "}
              {infoGroupItem.fifth.social[0].contact_lastname} | เบอร์ติดต่อ:{" "}
              {infoGroupItem.fifth.social[0].contact_phone} | Email:{" "}
              {infoGroupItem.fifth.social[0].contact_email}
            </h5>
          </Modal.Body>
          <Modal.Footer>
            {auth && (
              <Button onClick={() => handleEdit(infoGroupItem)}>แก้ไข</Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Popup;
