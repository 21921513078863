import React from "react";
import Swal from "sweetalert2";
import styled from "styled-components";
import { BsList } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import SideBar from "../../component/sideBar/sideBar";
import { isTokenExpired } from "../../utils/isTokenExpired";
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "../../context/auth/authContext/authContextState";
import Loading from "../../component/loadingAnimation/loading";

function AdminLayout({ routes, sidebar }) {
  const [subsidebarState, setSubsidebarState] = React.useState(false);
  const [sidebarState, setSidebarState] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [sidebarActive, setSidebarActive] = React.useState();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const history = useHistory();
  React.useEffect(() => {
    const pathname = localStorage.getItem("pathname");
    if (!!pathname) {
      setSidebarActive(JSON.parse(pathname));
    } else {
      setSidebarActive("Dashboard");
    }
    if (isTokenExpired(token)) {
      Swal.fire({
        title: "หมดเวลาเข้าสู่ระบบ?",
        text: "กรุณาทำการเข้าสู่ระบบอีกครั้ง!",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      })
        _signOut();
        localStorage.removeItem('pathname')
        history.push("/");
    }
    function handler(e) {
      if (e.path[0].id !== "toggle" && e.path[0].id !== "togglesvg") {
        setSidebarState(true);
      }
    }
    document.addEventListener("click", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Main sidebarstate={sidebarState}>
      <StateSidebarButton
        id="toggle"
        onClick={() => {
          setSidebarState(!sidebarState);
        }}
      >
        <BsList fontSize={"25px"} id="togglesvg" />
      </StateSidebarButton>
      <SideBar
        setSubsidebarState={setSubsidebarState}
        subsidebarState={subsidebarState}
        sidebar={sidebar}
        setsidebarstate={setSidebarState}
        sidebarState={sidebarState}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
        loading={loading}
        setLoading={setLoading}
      />
      <FilterBlurContent sidebarstate={sidebarState} />
      <div className="content">
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              height: "50px",
              zIndex: "11",
            }}
          >
            <Loading color={"#3499ff"} />
          </div>
        )}
        <Switch>
          {routes.map((data) =>
            !!data.subSidebar ? (
              data.subSidebar.map((subRoute) => (
                <Route
                  key={subRoute.path}
                  exact={subRoute.exact}
                  path={subRoute.path}
                  component={subRoute.component}
                />
              ))
            ) : (
                <Route
                key={data.path}
                exact={data.exact}
                path={data.path}
                component={data.component}
              />
               
            )
          )}
        </Switch>
      </div>
    </Main>
  );
}
const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: all 0.2s;
  backdrop-filter: blur(10px);
  background-color: #ebf4fa;
  .content {
    overflow-x: auto;
    width: 87vw;
    height: 100vh;
    @media screen and (max-width: 576px) {
      width: 100vw;
      overflow-y: auto;
      margin: 60px 0px 0px 0px;
      padding: 0px 0px 10px 0px;
    }
  }
`;
const StateSidebarButton = styled.button`
  border: none;
  background-color: transparent;
  height: 50px;
  width: 50px;
  padding: 0 15px 0 15px;
  position: absolute;
  border-radius: 0 0 15px 0px;
  z-index: 4;
`;
const FilterBlurContent = styled.div`
  ${(prop) => !prop.sidebarstate && "backdrop-filter:blur(5px);"};
  ${(prop) => prop.sidebarstate && "visibility: hidden;"};
  transition: all 0.2s;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: ${(prop) =>
    !prop.sidebarstate ? "#fffbfb8e" : "transparent"};
`;
export default AdminLayout;
