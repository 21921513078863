import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { BiLogOut } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Sidebaritem from "./sidebarItem/sidebaritem";
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "../../context/auth/authContext/authContextState";
import { apiConfig, ApiFetch } from "../../config/api";
import { FetchInterface, Method } from "../../enums/enum";

function SideBar(props) {
  const { _signOut } = useAuthContextDispatch();
  const { token, userInfo } = useAuthContextState();
  
  const history = useHistory();
  async function logout() {
    try {
      props.setsidebarstate(false);
      props.setLoading(true);
      setTimeout(() => {
        _signOut();
        props.setsidebarstate(true);
        props.setLoading(false);
        history.push("/");
        localStorage.removeItem('pathname')
      }, 3000);
      const res = await ApiFetch({
        [FetchInterface.PATH]: "auth/logout",
        [FetchInterface.METHOD]: Method.DELETE,
        [FetchInterface.TOKEN]: token.accessToken,
      });
      if (res.status === 200) {
      }
    } catch (error) {
      props.setsidebarstate(true);
      // props.setLoading(false);
      console.log(error);
    }
  }
  
  return (
    <Main sidebarstate={props.sidebarState}>
      <Containner image={!userInfo.image}>
        <div className="imageBox">
          <div className="imageBoxItem">
            {!!userInfo.image ? (
              <img
                alt={userInfo.name}
                width={"100%"}
                src={`${apiConfig.url}/image/${userInfo.image}`}
              />
            ) : (
              <img
              alt={userInfo.name}
              width={"100%"}
              src={require('../../assets/images/user-image.png')}
            />
            )}
          </div>
          <div className="user-info-title">
            <span style={{marginRight:"5px"}}>{userInfo.name}</span>
            <span style={{marginLeft:"5px"}}>{userInfo.lastname}</span>
          </div>       
        </div>
        <Row style={{ height: "65%" }}>
          <div className="contentBox">
            {props.sidebar.map((routesData, inx) => (
              <div key={inx} style={{ width: "100%", marginBottom: "10px" }}>
                <Sidebaritem
                  setSubsidebarState={props.setSubsidebarState}
                  subsidebarState={props.subsidebarState}
                  pathname={routesData.path}
                  routeName={routesData.name}
                  icon={routesData.icon}
                  key={routesData.path}
                  sidebarActive={props.sidebarActive}
                  setSidebarActive={(e) => props.setSidebarActive(e)}
                  routePath={routesData.path}
                  setsidebarstate={(e) => {
                    props.setsidebarstate(e);
                  }}
                  sidebarState={props.sidebarState}
                  subSidebar={routesData.subSidebar}
                />
              </div>
            ))}
          </div>
        </Row>
        <div className="logoutSection">
          <LogOutButton id="toggle" onClick={() => logout()}>
            <BiLogOut id="togglesvg" />
            <span style={{ marginLeft: "10px" }} id="toggle">
              Logout
            </span>
          </LogOutButton>
        </div>
      </Containner>
    </Main>
  );
}
export default SideBar;

const Main = styled.div`
  box-sizing: border-box;
  width: 13vw;
  height: 100vh;
  z-index: 5;
  background-color: white;
  @media screen and (max-width: 576px) {
    position: absolute;
    width: 60vw;
    transition: all 0.2s;
    background-color: white;
    ${(prop) => (prop.sidebarstate ? "left: -60vw;" : "left: 0px;")}
    ${(prop) =>
      !prop.sidebarstate &&
      "box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
  }
  @media (min-width: 720px) and (max-width: 920px) {
    width: 10vw;
    transition: all 0.2s;
    background-color: white;
    ${(prop) =>
      !prop.sidebarstate &&
      "box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
  }
`;
const LogOutButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
`;
const Containner = styled.div`
  height: 100vh;
  padding: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .imageBox {
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .imageBoxItem {
      border-radius: 3px;
      ${(props)=> props.image && "padding: 10px"}
      ${(props)=> !props.image && "border: solid 2px #38afba"};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 40%;
      overflow: hidden;
      background-color: #38afba;
      object-fit: contain;
      
      .imageLogo {
        width: 100%;
        height: auto;
        object-fit: cover;
    
      }
      margin-bottom: 10px;
    }
    .user-info-title {
      display: flex;
      justify-content: space-around;
      span{
        font-weight: bold;
      }
    }
    @media (min-width: 720px) and (max-width: 920px) {
      height: 10%;
      .imageBoxItem {
        height: 20%;
      }
    }
  }
  .contentBox {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .logoutSection {
    display: flex;
    justify-content: center;
    border: solid 1px #bfbfbf;
    border-radius: 5px;
  }
`;
