import React from 'react'
import axios from "axios"
import ModalSchool from '../../school/createSchool'
import { PersonalContext } from "../../../context/personal";
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { apiConfig } from '../../../config/api';
function SectionSecond({showOnlyProps=false, personalIdProps}) {
    const { data, setData } = React.useContext(PersonalContext);
    const { personalId, setPersonalId } = React.useContext(PersonalContext);
    const [level, setLevel] = React.useState([])
    const [schools, setSchools] = React.useState([])
    const [createSchool, setCreateSchool] = React.useState({
        status: false,
        index: null
    })
    //state ข้อมูลที่อยู่ดึงมาจาก db เก็บที่นี้
    const [province, setProvince] = React.useState([])


    //ดึงค่าจังหวัดจากหลังบ้าน
    const getProvince = async () => {
        const result = await axios(apiConfig.url + '/api/provinces');
        setProvince(result.data)
    }


    //ดึงค่าสถานศึกษา
    const getSchools = async () => {
        try {
            let result = await axios.get(apiConfig.url + '/api/localshchool')
            setSchools(result.data)
        } catch (err) {
            console.log(err)
        }
    }

    //ดึงค่าระดับการศึกษา
    const getEduLevel = async () => {
        try {
            let result = await axios.get(apiConfig.url + '/api/edulevel')
            setLevel(result.data)
        } catch (err) {
            console.log(err)
        }
    }

    //ลบข้อมูลแถวที่เลือก
    const deleteRow = (index) => {
        const filterData = data.second.education.filter((e) => e.ids !== index);
        setData({...data,second:{...data.second,education:filterData}})
    }

    const handleChange = (e, index) => {
        let updateData = { ...data }
        updateData.second.education[index] = {
            ...updateData.second.education[index],
            [e.target.name]: e.target.value
        }
        setData(updateData)
    }

    //ดึงข้อมูลที่บันทึกก่อนหน้านี้ (ถ้ามี)
    const getInfo = async (e) => {
        try {
            const result = await axios.get(
              apiConfig.url + "/api/personal/get_education/" + personalId
            );
            let updateData = { ...data }
            updateData.second.saved = false
            updateData.second.education = result.data.map(item=>{
                return{...item,isSaved:true}
            })
            setData(updateData)
        } catch (err) {
            console.log(err)
        }
    }

    React.useEffect(() => {
        getEduLevel()
        getSchools()
        getProvince()
        getInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
      if (personalIdProps) {
        setPersonalId(personalIdProps);
        getInfo();
      }
    }, []);

    //component แถว
    const rowData = data.second.education.map((row, index) => {
        return (
          <React.Fragment key={row.ids}>
            <Row>
              {!showOnlyProps && (
                <Col md={1} className="pt-4">
                  <Button
                    variant="danger"
                    onClick={() => deleteRow(row.ids)}
                    disabled={!!data.second.education[index].isSaved}
                  >
                    X
                  </Button>
                </Col>
              )}

              <Col md={5}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{index + 1}. ระดับการศึกษา</Form.Label>
                  <Form.Select
                    disabled={showOnlyProps}
                    aria-label="Default select example"
                    name="level"
                    value={
                      data.second.education[index].level
                        ? data.second.education[index].level
                        : data.second.education[index].edulevel_id
                    }
                    onChange={(e) => handleChange(e, index)}
                  >
                    <option></option>
                    {level.map((l) => {
                      return (
                        <option key={l.id} value={l.id}>
                          {l.edulevel_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label> ปีที่เริ่มการศึกษา (พ.ศ.)</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    type="number"
                    name="start"
                    min={0}
                    value={
                      data.second.education[index].start
                        ? data.second.education[index].start
                        : data.second.education[index].education_start
                    }
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ปีที่สำเร็จการศึกษา (พ.ศ.)</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    type="number"
                    name="end"
                    min={0}
                    value={
                      data.second.education[index].end
                        ? data.second.education[index].end
                        : data.second.education[index].education_end
                    }
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={1}></Col>
              <Col md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>สถานะการศึกษา</Form.Label>
                  <Form.Select
                    disabled={showOnlyProps}
                    aria-label="status"
                    name="status"
                    value={
                      data.second.education[index].status
                        ? data.second.education[index].status
                        : data.second.education[index].education_status
                    }
                    onChange={(e) => handleChange(e, index)}
                  >
                    <option></option>
                    <option value="กำลังศึกษา">กำลังศึกษา</option>
                    <option value="สำเร็จการศึกษา">สำเร็จการศึกษา</option>
                    <option value="ไม่สำเร็จการศึกษา">ไม่สำเร็จการศึกษา</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {/* ถ้าข้อมูลที่จะเพิ่มใหม่ */}
              {!data.second.education[index].id && (
                <>
                  <Col md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>สถาบันการศึกษา</Form.Label>
                      <Row>
                        <Col md={6}>
                          <Form.Check
                            checked={
                              data.second.education[index].schoolForeign === "0"
                            }
                            value={"0"}
                            onChange={(e) => {
                              let updateData = { ...data };
                              updateData.second.education[index].schoolForeign =
                                e.target.value;
                              setData(updateData);
                            }}
                            name={`schoolForeign${index}`}
                            type={"radio"}
                            label={`สถาบันในพื้นที่อาศัย`}
                            id={`school-${index}`}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            checked={
                              data.second.education[index].schoolForeign === "1"
                            }
                            value={"1"}
                            onChange={(e) => {
                              let updateData = { ...data };
                              updateData.second.education[index].schoolForeign =
                                e.target.value;
                              setData(updateData);
                            }}
                            name={`schoolForeign${index}`}
                            type={"radio"}
                            label={`สถาบันนอกพื้นที่อาศัย`}
                            id={`school-${index}`}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col md={4}></Col>
                  {data.second.education[index].schoolForeign ||
                  data.second.education[index].is_local == null ? (
                    data.second.education[index].schoolForeign === "0" ||
                    data.second.education[index].is_local ? (
                      <>
                        <Col md={1}></Col>
                        <Col md={11}>
                          <h5>ข้อมูลสถานศึกษาในพื้นที่</h5>
                          <Row>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>ชื่อสถานศึกษา</Form.Label>
                                <input
                                  className="form-control"
                                  type="text"
                                  list="school_name"
                                  name="school_name"
                                  value={
                                    data.second.education[index].school_name
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                />
                                <datalist id="school_name">
                                  {schools.map((item, key) => (
                                    <option
                                      key={key}
                                      value={item.edulocal_name}
                                    />
                                  ))}
                                </datalist>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>ประเภทสถานศึกษา</Form.Label>
                                <Form.Select
                                  aria-label="status"
                                  name="school_type"
                                  value={
                                    data.second.education[index].school_type
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                >
                                  <option></option>
                                  <option value="โรงเรียนเอกชน">
                                    โรงเรียนเอกชน
                                  </option>
                                  <option value="โรงเรียนรัฐ">
                                    โรงเรียนรัฐ
                                  </option>
                                  <option value="โรงเรียนปอเนาะ">
                                    โรงเรียนปอเนาะ
                                  </option>
                                  <option value="มหาวิทยาลัย">
                                    มหาวิทยาลัย
                                  </option>
                                  <option value="สถาบันอาชีวะ">
                                    สถาบันอาชีวะ
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={1}></Col>
                        <Col md={11}>
                          <h5>ข้อมูลสถานศึกษานอกพื้นที่</h5>
                          <Row>
                            <Col md={3}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>ชื่อสถานศึกษา</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="school_name"
                                  value={
                                    data.second.education[index].school_name
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>ประเภทสถานศึกษา</Form.Label>
                                <Form.Select
                                  aria-label="status"
                                  name="school_type"
                                  value={
                                    data.second.education[index].school_type
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                >
                                  <option></option>
                                  <option value="โรงเรียนเอกชน">
                                    โรงเรียนเอกชน
                                  </option>
                                  <option value="โรงเรียนรัฐ">
                                    โรงเรียนรัฐ
                                  </option>
                                  <option value="โรงเรียนปอเนาะ">
                                    โรงเรียนปอเนาะ
                                  </option>
                                  <option value="มหาวิทยาลัย">
                                    มหาวิทยาลัย
                                  </option>
                                  <option value="สถาบันอาชีวะ">
                                    สถาบันอาชีวะ
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>ตั้งอยู่ในพื้นที่</Form.Label>
                                <div className="d-flex justify-content-around">
                                  <Form.Check
                                    checked={
                                      data.second.education[index].inCountry ===
                                        "1" ||
                                      (data.second.education[index]
                                        .school_country == null &&
                                        typeof data.second.education[index]
                                          .school_country !== "undefined")
                                    }
                                    disabled={
                                      !!data.second.education[index].isSaved
                                    }
                                    value={"1"}
                                    onChange={(e) => {
                                      let updateData = { ...data };
                                      updateData.second.education[
                                        index
                                      ].inCountry = e.target.value;
                                      setData(updateData);
                                    }}
                                    type={"radio"}
                                    name={`inCountry${index}`}
                                    label={`ในประเทศ`}
                                  />
                                  <Form.Check
                                    checked={
                                      data.second.education[index].inCountry ===
                                        "0" ||
                                      data.second.education[index]
                                        .school_country != null
                                    }
                                    disabled={
                                      !!data.second.education[index].isSaved
                                    }
                                    value={"0"}
                                    onChange={(e) => {
                                      let updateData = { ...data };
                                      updateData.second.education[
                                        index
                                      ].inCountry = e.target.value;
                                      setData(updateData);
                                    }}
                                    type={"radio"}
                                    name={`inCountry${index}`}
                                    label={`นอกประเทศ`}
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                            {data.second.education[index].inCountry === "1" ||
                            (data.second.education[index].school_country ==
                              null &&
                              typeof data.second.education[index]
                                .school_country !== "undefined") ? (
                              <Col md={3}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>จังหวัด</Form.Label>
                                  <Form.Select
                                    aria-label="status"
                                    name="province"
                                    disabled={
                                      !!data.second.education[index].isSaved
                                    }
                                    value={
                                      data.second.education[index].province
                                        ? data.second.education[index].province
                                        : data.second.education[index]
                                            .school_province_id
                                    }
                                    onChange={(e) => handleChange(e, index)}
                                  >
                                    <option></option>
                                    {province.map((item, index) => {
                                      return (
                                        <option key={index} value={item.value}>
                                          {item.text}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            ) : null}
                            {data.second.education[index].inCountry === "0" ||
                            data.second.education[index].school_country !=
                              null ? (
                              <Col md={3}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>ประเทศ</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="country"
                                    disabled={
                                      !!data.second.education[index].isSaved
                                    }
                                    value={
                                      data.second.education[index].country
                                        ? data.second.education[index].country
                                        : data.second.education[index]
                                            .school_country
                                    }
                                    onChange={(e) => handleChange(e, index)}
                                  />
                                </Form.Group>
                              </Col>
                            ) : null}
                          </Row>
                        </Col>
                      </>
                    )
                  ) : null}
                </>
              )}

              {/*   ข้อมูลที่กำลังแก้ไข */}
              {!!data.second.education[index].id && (
                <>
                  <Col md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>สถาบันการศึกษา</Form.Label>
                      <Row>
                        <Col md={6}>
                          <Form.Check
                            disabled={showOnlyProps}
                            checked={
                              data.second.education[index].is_local == true
                            }
                            onChange={(e) => {
                              let updateData = { ...data };
                              updateData.second.education[
                                index
                              ].is_local = true;
                              setData(updateData);
                            }}
                            name={`schoolForeign${index}`}
                            type={"radio"}
                            label={`สถาบันในพื้นที่อาศัย`}
                            id={`school-${index}`}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            disabled={showOnlyProps}
                            checked={
                              data.second.education[index].is_local === null
                            }
                            value={"1"}
                            onChange={(e) => {
                              let updateData = { ...data };
                              updateData.second.education[index].is_local =
                                null;
                              setData(updateData);
                            }}
                            name={`schoolForeign${index}`}
                            type={"radio"}
                            label={`สถาบันนอกพื้นที่อาศัย`}
                            id={`school-${index}`}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col md={4}></Col>
                  {data.second.education[index].is_local ? (
                    <>
                      <Col md={1}></Col>
                      <Col md={11}>
                        <h5>ข้อมูลสถานศึกษาในพื้นที่</h5>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>ชื่อสถานศึกษา</Form.Label>
                              <input
                                disabled={showOnlyProps}
                                className="form-control"
                                type="text"
                                list="school_name"
                                name="school_name"
                                value={data.second.education[index].school_name}
                                onChange={(e) => handleChange(e, index)}
                              />
                              <datalist id="school_name">
                                {schools.map((item, key) => (
                                  <option
                                    key={key}
                                    value={item.edulocal_name}
                                  />
                                ))}
                              </datalist>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>ประเภทสถานศึกษา</Form.Label>
                              <Form.Select
                                disabled={showOnlyProps}
                                aria-label="status"
                                name="school_type"
                                value={data.second.education[index].school_type}
                                onChange={(e) => handleChange(e, index)}
                              >
                                <option></option>
                                <option value="โรงเรียนเอกชน">
                                  โรงเรียนเอกชน
                                </option>
                                <option value="โรงเรียนรัฐ">โรงเรียนรัฐ</option>
                                <option value="โรงเรียนปอเนาะ">
                                  โรงเรียนปอเนาะ
                                </option>
                                <option value="มหาวิทยาลัย">มหาวิทยาลัย</option>
                                <option value="สถาบันอาชีวะ">
                                  สถาบันอาชีวะ
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={1}></Col>
                      <Col md={11}>
                        <h5>ข้อมูลสถานศึกษานอกพื้นที่</h5>
                        <Row>
                          <Col md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>ชื่อสถานศึกษา</Form.Label>
                              <Form.Control
                                disabled={showOnlyProps}
                                type="text"
                                name="school_name"
                                value={data.second.education[index].school_name}
                                onChange={(e) => handleChange(e, index)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>ประเภทสถานศึกษา</Form.Label>
                              <Form.Select
                                disabled={showOnlyProps}
                                aria-label="status"
                                name="school_type"
                                value={data.second.education[index].school_type}
                                onChange={(e) => handleChange(e, index)}
                              >
                                <option></option>
                                <option value="โรงเรียนเอกชน">
                                  โรงเรียนเอกชน
                                </option>
                                <option value="โรงเรียนรัฐ">โรงเรียนรัฐ</option>
                                <option value="โรงเรียนปอเนาะ">
                                  โรงเรียนปอเนาะ
                                </option>
                                <option value="มหาวิทยาลัย">มหาวิทยาลัย</option>
                                <option value="สถาบันอาชีวะ">
                                  สถาบันอาชีวะ
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          {/*  <Col md={3}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>ตั้งอยู่ในพื้นที่</Form.Label>
                                <div className="d-flex justify-content-around">
                                  <Form.Check
                                    checked={
                                      data.second.education[index].inCountry ===
                                        "1" ||
                                      (data.second.education[index]
                                        .school_country == null &&
                                        typeof data.second.education[index]
                                          .school_country !== "undefined")
                                    }
                                   
                                    value={"1"}
                                    onChange={(e) => {
                                      let updateData = { ...data };
                                      updateData.second.education[
                                        index
                                      ].inCountry = e.target.value;
                                      setData(updateData);
                                    }}
                                    type={"radio"}
                                    name={`inCountry${index}`}
                                    label={`ในประเทศ`}
                                  />
                                  <Form.Check
                                    checked={
                                      data.second.education[index].inCountry ===
                                        "0" ||
                                      data.second.education[index]
                                        .school_country != null
                                    }
                                   
                                    value={"0"}
                                    onChange={(e) => {
                                      let updateData = { ...data };
                                      updateData.second.education[
                                        index
                                      ].inCountry = e.target.value;
                                      setData(updateData);
                                    }}
                                    type={"radio"}
                                    name={`inCountry${index}`}
                                    label={`นอกประเทศ`}
                                  />
                                </div>
                              </Form.Group>
                            </Col> */}
                          {data.second.education[index].inCountry === "1" ||
                          (data.second.education[index].school_country ==
                            null &&
                            typeof data.second.education[index]
                              .school_country !== "undefined") ? (
                            <Col md={3}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>จังหวัด</Form.Label>
                                <Form.Select
                                  disabled={showOnlyProps}
                                  aria-label="status"
                                  name="province"
                                  value={
                                    data.second.education[index].province
                                      ? data.second.education[index].province
                                      : data.second.education[index]
                                          .school_province_id
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                >
                                  <option></option>
                                  {province.map((item, index) => {
                                    return (
                                      <option key={index} value={item.value}>
                                        {item.text}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          ) : null}
                          {data.second.education[index].inCountry === "0" ||
                          data.second.education[index].school_country !=
                            null ? (
                            <Col md={3}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>ประเทศ</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="country"
                                  disabled={
                                    !!data.second.education[index].isSaved
                                  }
                                  value={
                                    data.second.education[index].country
                                      ? data.second.education[index].country
                                      : data.second.education[index]
                                          .school_country
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
            <hr></hr>
          </React.Fragment>
        );
    })

    const addRow = () => {
        let updateData = { ...data }
        let lengthData = updateData.second.education.length
        updateData.second.education.push({
            ids: lengthData + 1,
            level: null,
            start: null,
            end: null,
            status: null,
            schoolName: null
        })
        setData(updateData)
    }
    return (
        <Container>
            {createSchool.status ? <ModalSchool createSchool={createSchool} setCreateSchool={setCreateSchool} getSchools={getSchools} /> : null}
            <h5 className="mb-4 mt-4">ข้อมูลการศึกษา</h5>
            {rowData}
          { !showOnlyProps && ( <div className="btn btn-primary w-100"
                style={{ marginTop: '30px', marginBottom: '30px', width: '200px' }}
                onClick={addRow}>
                เพิ่มแถวข้อมูล
            </div>)}
            {/* <Row>
                <Col md={6}>
                  <div className="d-flex justify-content-start">
                      <div className="btn btn-info"
                      style={{ marginTop: '30px', marginBottom: '30px', width: '200px' }}
                          onClick={() => setPage(page - 1)}>
                          กลับ
                      </div>
                  </div>
              </Col>
                <Col md={12}>
                    <div className="d-flex justify-content-end">
                        <div className="btn btn-info"
                            style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
                            onClick={() => setPage(page + 1)}>
                            ถัดไป
                        </div>
                    </div>
                </Col>

            </Row> */}
        </Container>
    )
}

export default SectionSecond