import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

export default function Filter({ setSalaryData, filterData ,setFilterDataName}) {
  const handleChange = (e) => {
    const { value } = e.target;
    setFilterDataName(value)
    const filtered = filterData.find((item) => {
      return item.name === value;
    }).salary;
    setSalaryData(filtered);
  };
  return (
    <Main>
      <Form.Group className="item">
        <Form.Label>กลุ่มคน</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Default select example"
          name={"group"}
          //   value={!!address.group ? address.group : ""}
          onChange={(e) => handleChange(e)}
        >
          {!!filterData &&
            filterData.map((filterData, inx) => {
              return (
                <option key={inx} value={filterData.name}>
                  {filterData.name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  .item {
    display: flex;
    width: 100%;
    label {
      margin-right: 5px;
      padding: 0px;
      display: flex;
      justify-content: center;
      width: 30%;
    }
  }
`;
