import React from "react";
import { Button } from "react-bootstrap";
import { ApiFetch } from "../../../../../config/api";
import { useAuthContextState } from "../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../enums/enum";
import BarChart from "../../../../barChart/barChart";
import PersonalTableDashboard from "../../../../personalTableDashboard/personalTableDashboard";

const Accommodation = React.forwardRef(({ data, address },ref) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState()
  const [tableData, setTableData] = React.useState({
    header: {
      title: "",
      gender: "",
    },
    data: {
      title: [],
      data: [],
    },
  });
  React.useImperativeHandle(ref, () => ({
    async getPersonalInfoAllAccommodation() {
      setModalShow(true)
      try {
        const res = await ApiFetch({        
          [FetchInterface.PATH]: `auth/personal-table-info/accommodation/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: null,
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: {
              datasetTitle: "ชายและหญิง",
              title: "ทุกประเภทที่พักอาศัย",
            },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }

    }
  }));

  console.log(tableData);
  return (
    <>
      <div
        style={{
          position: "absolute",
        }}
      ></div>
      <div style={{ height: "40vh" }}>
        <BarChart
          setTableDataForFilter={setTableDataForFilter}
          address={address}
          section={"accommodation"}
          pathname={"personal-table-info"}
          setModalShow={setModalShow}
          setTableData={setTableData}
          data={data}
        />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายชื่อบุคคล เพศ ${tableData.header.datasetTitle} ประเภทที่พักอาศัย ${tableData.header.title} ใน ${address.addressName} มีจำนวน ${tableData?.data?.data?.length} คน`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
});
export default Accommodation;
