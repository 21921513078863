import React from "react";
import { ApiFetch } from "../../../../../../config/api";
import { useAuthContextState } from "../../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../../enums/enum";
import BarChart from "../../../../../barChart/barChart";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";
const FarmingArea = React.forwardRef(function FarmingArea(
  { data, address },
  ref
) {
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  React.useImperativeHandle(ref, () => ({
    async getPersonalInfoAllCareerAndFarmingArea() {
      setModalShow(true);
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/career-personal-table-info/farmingArea/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: null,
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: { datasetTitle: "-", title: "ทุกสถานะพื้นที่ทำกิน" },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    },
  }));

  return (
    <>
      <div style={{ height: "40vh" }}>
        <BarChart
          data={data}
          setTableDataForFilter={setTableDataForFilter}
          address={address}
          pathname={"career-personal-table-info"}
          section={"farmingArea"}
          setModalShow={setModalShow}
          setTableData={setTableData}
        />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียด สถานะพื้นที่ทำกิน ${tableData.header.title} ใน ${address.addressName} จำนวน ${tableData?.data?.data?.length} คน`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
});
export default FarmingArea;
