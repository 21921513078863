import React from "react";
import styled from "styled-components";
import { ApiFetch } from "../../../../config/api";
import { useAuthContextState } from "../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../enums/enum";
import { useParams } from "react-router-dom";
import TableUser from "../../../dataCollector/dashboard/table/TableAdmin";
function DataCollector({ id }) {
  const { token } = useAuthContextState();
  const params = useParams();
  const [userData, setUserData] = React.useState({
    name: "",
    lastname: "",
  });
  const [paginatePageInputByAdmin, setPaginatePageInputByAdmin] =
    React.useState(1);
  async function getDataCollectorInfo(id) {
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/get-user-by-id/${id}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
      });
      if (res.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getDataCollectorInfo(!!params.id ? params.id : id);
  }, []);
  return (
    <GridContainer>
      <div className="title">
        <h5>
          รายชื่อที่ถูกป้อนข้อมูลโดย : {userData.name} {userData.lastname}
        </h5>
      </div>
        <TableUser
          token={token}
          userInfo={{ userId: id }}
          setPaginatePageInputByAdmin={setPaginatePageInputByAdmin}
          paginatePageInputByAdmin={paginatePageInputByAdmin}
        />
      {/* <div className='cardGrid'>
                <h4>
                    รายชื่อที่นำเข้าโดยเจ้าของ
                </h4>
                <TableSelft 
                    token={token} 
                    userInfo={userInfo}  
                    setPaginatePageInputBySelft={setPaginatePageInputBySelft}
                    paginatePageInputBySelft={paginatePageInputBySelft} 
                />
            </div> */}
    </GridContainer>
  );
}
export default DataCollector;
const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title{
    padding-left: 20px;
    padding-right: 20px;
  }
    .TableBox {
      width: 100%;
      padding: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
    }
`
