import React from "react";
import { Col, Form } from "react-bootstrap";

function ReligiousPlace({
  setData,
  data,
  subStructureResourceHandleChange,
  main_index,
  sub_index,
  isEdit
}) {
  return (
    <>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>
            {!isEdit && <label
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                let updateData = { ...data };
                console.log(
                  "updateData",
                  data.third.structure_resource[main_index].data
                );
                updateData.third.structure_resource[main_index].data.splice(
                  sub_index,
                  1
                );
                setData(updateData);
              }}
            >
              X
            </label>}{" "}
            {sub_index + 1}. ประเภทสถานที่
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="resource_type"
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .resource_type
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          >
            <option></option>
            <option value="มัสยิด">มัสยิด</option>
            <option value="วัด">วัด</option>
            <option value="โบสถ์">โบสถ์</option>
            <option value="เจดีย์">เจดีย์</option>
            <option value="สถูป">สถูป</option>
            <option value="กุโบร์">กุโบร์</option>
            <option value="วิหาร">วิหาร</option>
            <option value="ซากศาสนสถานโบราณ">ซากศาสนสถานโบราณ</option>
          </Form.Select>
          {/*  <Form.Control type="text" name="resource_name"
                value={data.third.structure_resource[main_index].data[sub_index].resource_name}
                onChange={(e) => subStructureResourceHandleChange(e, main_index, sub_index)} /> */}
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label> ชื่อสถานที่</Form.Label>
          <Form.Control
            type="text"
            name="resource_name"
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .resource_name
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>รายละเอียดสถานที่ (ถ้ามี)</Form.Label>
          <Form.Control
            type="text"
            name="detail"
            value={
              data.third.structure_resource[main_index].data[sub_index].detail
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ปีที่ก่อตั้งขึ้น (พ.ศ.)</Form.Label>
          <Form.Control
            type="number"
            name="resource_year"
            min="1000"
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .resource_year
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ตำแหน่งที่ตั้ง</Form.Label>
          <Form.Control
            type="text"
            name="resource_address"
            placeholder="รายละเอียดคร่าว ๆ"
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .resource_address
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ละติจูด</Form.Label>
          <Form.Control
            type="number"
            name="latitude"
            placeholder=""
            value={
              data.third.structure_resource[main_index].data[sub_index].latitude
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ลองจิจูด</Form.Label>
          <Form.Control
            type="number"
            name="longitude"
            placeholder=""
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .longitude
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
    </>
  );
}

export default ReligiousPlace;
