import React from "react";
import { useTransition, animated } from "react-spring";
import { RiCommunityLine, RiAccountBoxLine } from "react-icons/ri";
import { MdOutlineFeed } from "react-icons/md";
import styled from "styled-components";
import CommunityDashboard from "./community/communityDashboard";
import PersonalDashboard from "./personal/personalDashboard";
import EntrepreneurDashboard from "./entrepreneurs/EntrepreneurDashboard";
import SideNavication from "./navication/sideNavication/sideNavication";
const navicationItems = [
  {
    name: "personal",
    icon: <RiAccountBoxLine />,
  },
  {
    name: "community",
    icon: <RiCommunityLine />,
  },
  {
    name: "entrepreneurs",
    icon: <MdOutlineFeed />,
  },
];
function Index() {
  const [dashboardOfPageRender, setDashboardOfPageRender] = React.useState(navicationItems[0]);
  const [show, setShow] = React.useState(true);

  const displayPersonal = useTransition(dashboardOfPageRender.name === "personal", {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });
  const displayCommunity = useTransition(dashboardOfPageRender.name === "community", {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });
  const entrepreneurs = useTransition(dashboardOfPageRender.name === "entrepreneurs", {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });
  return (
    <Main>
      <SideNavication
        setDashboardOfPageRender={setDashboardOfPageRender}
        dashboardOfPageRender={dashboardOfPageRender}
        show={show}
        setShow={setShow}
        navicationItems={navicationItems}
      />
      {displayPersonal(
        (styles, item) =>
          item && (
            <AnimateRender style={styles}>
              <PersonalDashboard />
            </AnimateRender>
          )
      )}
      {displayCommunity(
        (styles, item) =>
          item && (
            <AnimateRender style={styles}>
              <CommunityDashboard />
            </AnimateRender>
          )
      )},
       {entrepreneurs(
        (styles, item) =>
          item && (
            <AnimateRender style={styles}>
              <EntrepreneurDashboard />
            </AnimateRender>
          )
      )}
    </Main>
  );
}

export default Index;
const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #ebf4fa;
`;
const AnimateRender = styled(animated.div)`
  width: 100%;
  height: 100%;
  margin-left: 4vw;
`;
