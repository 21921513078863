import { TypesLayout } from "../../enums/enum";
import EntrepreneursDetail from "../../page/admin/entrepreneurs/entrepreneurs-detail/entrepreneursDetail";
import Community from "../../page/Community";
import Home from "../../page/Home";
import Dashboard from "../../page/index/dashboard/dashboard";
import Login from "../../page/login/login";
import Personal from "../../page/Personal";

export const indexRoute = [
    {
        name: "home",
        path: "/",
        layout: TypesLayout.indexLayout,
        navbar: false,
        exact: true,
        component: Home
    },
    {
        name: "personal",
        path: "/personal-form",
        layout: TypesLayout.none,
        navbar: false,
        exact: true,
        component: Personal
    },
    {
        name: "community",
        path: "/community-form",
        layout: TypesLayout.none,
        navbar: false,
        exact: true,
        component: Community
    },
    {
        name: "Dashboard",
        path: "/dashboard",
        layout: TypesLayout.none,
        navbar: false,
        exact: true,
        component: Dashboard
    },
    {
        name: "Entrepreneurs",
        path: "/entrepreneurs/entrepreneurs-detail/:personalId",
        layout: TypesLayout.indexLayout,
        sidebar: false,
        exact: true,
        component: EntrepreneursDetail
    },
    {
        name: "login",
        path: "/login",
        layout: TypesLayout.indexLayout,
        navbar: false,
        exact: true,
        component: Login
    },
    {
        path: "**",
        layout: TypesLayout.indexLayout,
        navbar: false,
        exact: true,
        redirectTo: "/",
    },
]