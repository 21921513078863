import React from "react";
import Chart from "chart.js/auto";
import styled from "styled-components";
import { CategoryScale } from "chart.js";
import Selecter from "../personal/selecter/selecterAddress";
import EducationLavel from "./educationLavel/educationLavel";
import AddressInfomation from "./addressInfomation/addressInfomation";
import CareerInfomation from "./careerInfomation/careerInfomation";
import Welfare from "./welfare/welfare";
import SectionBar from "./selecter/sectionBar/sectionBar";
import GeneralInfo from "./generalInfo/generalInfo";
import Skill from "./skill/skill";
import { useAuthContextState } from "../../../context/auth/authContext/authContextState";

function PersonalDashboard() {
  const [loader, setLoader] = React.useState(false);
  const [sectionPage, setSectionPage] = React.useState(1);
  const { auth,token } = useAuthContextState()
  const [address, setAddress] = React.useState({
    provice: 30,
    amphur: 402,
    tambol: 3061,
    group: null,
    addressName: " ตำบล.บานา อำเภอ.เมืองปัตตานี จังหวัดปัตตานี",
  });
  function section() {
    switch (sectionPage) {
      case 1:
        return <GeneralInfo loadingPageCoload={"#38afba"} auth={auth} address={address} loader={loader} />;
      case 2:
        return  <EducationLavel loadingPageCoload={"#38afba"} token={token} auth={auth} address={address} loader={loader} />;
      case 3:
        return  <CareerInfomation loadingPageCoload={"#38afba"} auth={auth} address={address} loader={loader} />;
      case 4:
        return  <Skill loadingPageCoload={"#38afba"} auth={auth} address={address} loader={loader} />;
      case 5:
        return  <Welfare loadingPageCoload={"#38afba"} auth={auth} address={address} loader={loader} />;
      default:
        <GeneralInfo />;
    }
  }

  Chart.register(CategoryScale);
  return (
    <Main>
      <div className="selecter">
        <Selecter
          address={address}
          setAddress={setAddress}
          setLoader={setLoader}
        />
        <hr style={{width:"98%",marginLeft:"5px"}} />
        <SectionBar sectionPage={sectionPage} setSectionPage={setSectionPage} />
      </div>
      <div className="address-infimation">
        <AddressInfomation loader={loader} address={address} />
      </div>
      <div className="content-box">
        {section()}
      </div>
    </Main>
  );
}
export default PersonalDashboard;
const Main = styled.div`
  margin-top: 10px;
  width: 100%;
  align-content: center;
  justify-content: center;
  display: grid;
  grid-gap: 10px;
  padding-bottom: 20px;
  background-color: transparent;
  grid-template-columns: 23% 12.5% 12.5% 25% 23%;
  grid-template-rows: minmax(25vh) auto;
  grid-template-areas:
    "selecter selecter address-infimation address-infimation address-infimation"
    "content-box content-box content-box content-box content-box";
  .selecter {
    grid-area: selecter;
    background-color: white;
    border-radius: 5px;
  }
  .address-infimation {
    padding: 5px;
    grid-area: address-infimation;
    background-color: white;
    border-radius: 5px;
  }
  .content-box {
    width: 100%;
    height: auto;
    grid-area: content-box;
    border-radius: 5px;
    display: grid;
    grid-gap: 10px;
  }
`;
//----------------mobile--------------
