import React from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import AppBar from '../../component/AppBar'

function IndexLayout({ routes,auth }) {

  return (
    <IndexLayoutContainner>
      {!auth && <AppBar />}
      <Switch>
        {routes.map((data) => (
          <Route key={data.path} exact={data.exact} path={data.path} component={data.component} />
        ))}
      </Switch>
    </IndexLayoutContainner>
  )
}
export default IndexLayout
const IndexLayoutContainner = styled.div`
  width:100vw;
  height:100vh;
  display: grid;
  grid-template-rows: 10vh 90vh;
  overflow-x: hidden;
`