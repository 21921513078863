import React from "react";
import styled from "styled-components";
import SelectCommunity from "./selectCommunity/selectCommunity";
import axios from "axios";
import SelectTap from "./selectTap/selectTap";
import InfoCommunity from "./infoCommunity/infoCommunity";
import InfoNaturalResource from "./infoNaturalResource/infoNaturalResource";
import InfoOtherResource from "./infoOtherResource/infoOtherResource";
import InfoOccupation from "./infoOccupation/infoOccupation";
import InfoSage from "./infoSage/infoSage";
import InfoUnityResource from "./infoUnityResource/infoUnityResource";
import InfoGroup from "./infoGroup/infoGroup";
import InfoSupport from "./infoSupport/infoSupport";
import { useAuthContextState } from "../../../context/auth/authContext/authContextState";
import { apiConfig } from "../../../config/api";
function CommunityDashboard() {
  const { auth } = useAuthContextState();
  const [community, setCommunity] = React.useState([]);
  const [communityId, setCommunityId] = React.useState();
  const [target, setTarget] = React.useState(0);

  const getCommunity = async () => {
    let response = await axios.get(apiConfig.url + "/api/community_dashboard");
    setCommunity(response.data);
  };
  const selectTarget = async (e) => {
    setCommunityId(parseInt(e.target.value));
    /* try {
      const res = await axios.get(`${apiConfig.url}/api/community_dashboard/community/${parseInt(e.target.value)}`)
      if (res.status === 200) {
        setCommunityId(parseInt(e.target.value));
      }
    } catch (error) {
      console.log('error',error);
    } */
  };

  const changeTab = async (target) => {
    setTarget(target);
  };

  const ShowInfo = (target) => {
    switch (target) {
      case 0:
        return <InfoCommunity auth={auth} communityId={communityId} />;
      case 1:
        return <InfoNaturalResource auth={auth} communityId={communityId} />;
      case 2:
        return <InfoOtherResource auth={auth} communityId={communityId} />;
      case 3:
        return <InfoUnityResource auth={auth} communityId={communityId} />;
      case 4:
        return <InfoOccupation communityId={communityId} />;
      case 5:
        return <InfoSage communityId={communityId} />;
      case 6:
        return <InfoGroup auth={auth} communityId={communityId} />;
      case 7:
        return <InfoSupport auth={auth} communityId={communityId} />;
    }
  };
  React.useEffect(() => {
    getCommunity();
  }, []);

  return (
    <Main>
      <div>
        <h5 style={{ marginTop: "20px" }}>เลือกข้อมูลชุมชน</h5>
        <div style={{ width: "25%" }}>
          <SelectCommunity community={community} selectTarget={selectTarget} />
        </div>
      </div>
      <>
        <div
          style={{ marginTop: "15px", paddingLeft: "5px", paddingRight: "5px" }}
        >
          <SelectTap
            disable={!communityId}
            target={target}
            changeTab={changeTab}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflowY: "hidden",
            height: "100%",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <div
            style={{
              overflowY: "auto",
              width: "100%",
              backgroundColor: "white",
              padding: "20px",
              minHeight: "60vh",
            }}
          >
            {ShowInfo(target)}
          </div>
        </div>
      </>
    </Main>
  );
}

export default CommunityDashboard;
const Main = styled.div`
  height: 97.5vh;
  overflow-y: hidden;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
