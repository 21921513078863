import React from 'react'
import styled, { keyframes } from 'styled-components'

function LoadingAnimation({color}) {
    return (
        <div style={{ display: "flex",width:"200px",alignItems:'center'}}>
            <LoadingText color={color}>
                Loading
            </LoadingText>
            <div style={{ display: "flex", position: "relative",width:"100px",marginLeft:"5px",height:"15px"}}>
                <LoadingAnimate1 color={color} />
                <LoadingAnimate2 color={color} />
                <LoadingAnimate3 color={color} />
            </div>
        </div>
    )
}
export default LoadingAnimation
const animateLoadingAnimate1 = keyframes`
  0%,100%{
    top: 0px;
  }
  50%{
    top: 7px;
  }
`
const animateLoadingAnimate2 = keyframes`
  1%,100%{
    top: 0px;
  }
  50%{
    top: 7px;
  }
`
const animateLoadingAnimate3 = keyframes`
  2%,100%{
    top: 0px;
  }
  50%{
    top: 7px;
  }
`
const LoadingAnimate1 = styled.div`
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: ${(props) => !!props.color ? props.color : "black"};
  border-radius: 50%;
  animation: ${animateLoadingAnimate1} 1s infinite; 
  animation-delay: .1s;
`
const LoadingAnimate2 = styled.div`
position: absolute;
  left: 10%;
  width: 7px;
  height: 7px;
  background-color: ${(props) => !!props.color ? props.color : "black"};
  border-radius: 50%;
  animation: ${animateLoadingAnimate2} 1s infinite;
  animation-delay: .2s;
  
`
const LoadingAnimate3 = styled.div`
position: absolute;
left: 20%;
  width: 7px;
  height: 7px;
  background-color: ${(props) => !!props.color ? props.color : "black"};
  border-radius: 50%;
  animation: ${animateLoadingAnimate3} 1s infinite;
  animation-delay: .3s;
`
const LoadingText = styled.div`
    color:${(props) => !!props.color ? props.color : "black"};
`