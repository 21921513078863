import { Row, Col } from 'react-bootstrap';

export default function InfoEntrepreneurs() {
    return (
        <div>
            <Row>
                <Col md={4}>
                    <div className='p-5'>
                        {/* <img src={require("src/assets/images/1.jpg")}  width="100%" /> */}
                    </div>
                </Col>
                <Col md={8}>
                    <div className='p-5'>
                        <h5>Name: xxxxxxxx xxxxxxxx</h5>
                        <h5>Address of the bussness: xxxxxxxxxx</h5>
                        <h5>Tel: xxxxxxxxx</h5>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <div className='p-5'>
                        <h5>Business</h5>
                    </div>
                </Col>
                <Col md={8}>
                    <div className='p-5'>
                        <h5>Business Growth</h5>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <div className='p-5'>
                        <h5>Ecotive Journey</h5>
                    </div>
                </Col>
                <Col md={8}>
                    <div className='p-5'>
                        <h5>Ecotive Skills Matrix</h5>
                    </div>
                </Col>
            </Row>
        </div>
    )
}