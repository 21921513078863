import React from "react";
import { Form } from "react-bootstrap";
function selectCommunity({ community, selectTarget }) {
  return (
    <Form.Select
      aria-label="Default select example"
      className="w-100"
      onChange={(e) => selectTarget(e)}
    >
      <option>เลือกชุมชน</option>
      {community.map((item) => {
        return (
          <option value={item.id} key={item.id}>
            {item.community_name} ต.{item.tambol_name} อ.{item.amphur_name} จ.
            {item.province_name}
          </option>
        );
      })}
    </Form.Select>
  );
}

export default selectCommunity;
