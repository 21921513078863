import React from "react";
import axios from "axios";
import { apiConfig } from "../../../config/api";
import { Form, Button, Row, Col } from "react-bootstrap";
// import { CommunityContext } from "../../../context/community";

function SectionFourth({ data, setData }) {
  // const { data, setData } = React.useContext(CommunityContext)
  const [typeProject, setTypeProject] = React.useState([]);

  const getTypeProject = async () => {
    const result = await axios.get(`${apiConfig.url}/api/typeproject`);
    setTypeProject(result.data);
  };

  const addNewProject = () => {
    let updateData = { ...data };
    updateData.fourth.training.push({
      project_name: "",
      project_objective: "",
      project_begin: "",
      project_end: "",
      project_money: 0,
      project_participart: 0,
      project_achievement: "",
      project_supporter: "",
      org_name: "",
      title: "",
      first_name: "",
      last_name: "",
      tel_no: "",
      email: "",
    });
    setData(updateData);
  };
  const removeProject = (index) => {
    let updateData = { ...data };
    updateData.fourth.training.splice(index, 1);
    setData(updateData);
  };

  const handleChange = (e, index) => {
    let updateData = { ...data };
    updateData.fourth.training[index][e.target.name] = e.target.value;
    setData(updateData);
  };

  React.useEffect(() => {
    getTypeProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row>
      <h5 className="mb-4">ข้อมูลการพัฒนาทรัพยากรในด้านอบรมและส่งเริมอาชีพ</h5>
      {data.fourth.training.map((item, index) => {
        return (
          <Row key={index}>
            <Col md={4}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  <label
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => removeProject(index)}
                  >
                    X
                  </label>{" "}
                  {index + 1}. ชื่อโครงการ
                </Form.Label>
                <Form.Control
                  type="text"
                  value={data.fourth.training[index].project_name}
                  name="project_name"
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>วัตถุประสงค์โครงการ</Form.Label>
                <Form.Control
                  type="text"
                  value={data.fourth.training[index].objective}
                  name="objective"
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ประเภทโครงการ</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="typeproject"
                  value={data.fourth.training[index].typeproject}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option></option>
                  {typeProject.map((d, index) => {
                    return (
                      <option key={index} value={d.typeproject_name}>
                        {d.typeproject_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            {data.fourth.training[index].typeproject === "ประเภทอื่น ๆ" ? (
              <Col md={2}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ชื่อประเภทโครงการอื่น ๆ</Form.Label>
                  <Form.Control
                    type="text"
                    min={0}
                    name="typeproject_other"
                    value={data.fourth.training[index].typeproject_other}
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
            ) : null}
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ปีที่เริ่มโครงการ (พ.ศ.)</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  value={data.fourth.training[index].project_begin}
                  name="project_begin"
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ปีที่สิ้นสุดโครงการ (พ.ศ.)</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  value={data.fourth.training[index].project_end}
                  name="project_end"
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>งบประมาณโครงการ (บาท)</Form.Label>
                <Form.Control
                  type="text"
                  value={data.fourth.training[index].project_money}
                  name="project_money"
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>จำนวนผู้เข้าร่วม (คน)</Form.Label>
                <Form.Control
                  type="text"
                  value={data.fourth.training[index].project_participart}
                  name="project_participart"
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ผลสัมฤทธิ์</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={data.fourth.training[index].project_achievement}
                  name="project_achievement"
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>

            <Row>
              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>หน่วยงานที่สนับสนุน</Form.Label>
                  <div className="d-flex justify-content-around">
                    <Form.Check
                      type={"radio"}
                      name={`project_supporter_${index}`}
                      checked={
                        data.fourth.training[index].project_supporter ===
                        "ไม่ได้รับการสนับสนุน"
                      }
                      onChange={(e) => {
                        let updateData = { ...data };
                        updateData.fourth.training[index].project_supporter =
                          "ไม่ได้รับการสนับสนุน";
                        setData(updateData);
                      }}
                      label={`ไม่ได้รับการสนับสนุน`}
                      id={`disabled-default-`}
                    />
                    <Form.Check
                      type={"radio"}
                      name={`project_supporter_${index}`}
                      checked={
                        data.fourth.training[index].project_supporter ===
                        "หน่วยงานภาครัฐ"
                      }
                      onChange={(e) => {
                        let updateData = { ...data };
                        updateData.fourth.training[index].project_supporter =
                          "หน่วยงานภาครัฐ";
                        setData(updateData);
                      }}
                      label={`ภาครัฐ`}
                      id={`disabled-default-`}
                    />
                    <Form.Check
                      type={"radio"}
                      checked={
                        data.fourth.training[index].project_supporter ===
                        "หน่วยงานภาคเอกชน"
                      }
                      name={`project_supporter_${index}`}
                      onChange={(e) => {
                        let updateData = { ...data };
                        updateData.fourth.training[index].project_supporter =
                          "หน่วยงานภาคเอกชน";
                        setData(updateData);
                      }}
                      label={`ภาคเอกชน`}
                      id={`disabled-default-`}
                    />
                  </div>
                </Form.Group>
              </Col>
              {data.fourth.training[index].project_supporter ===
              "หน่วยงานภาครัฐ" ? (
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ชื่อหน่วยงานรัฐ</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.fourth.training[index].org_name}
                      name="org_name"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
              ) : null}
              {data.fourth.training[index].project_supporter ===
              "หน่วยงานภาคเอกชน" ? (
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ชื่อหน่วยงานเอกชน</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.fourth.training[index].org_name}
                      name="org_name"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
              ) : null}
            </Row>
            {data.fourth.training[index].project_supporter ===
            "หน่วยงานภาคเอกชน" ? (
              <Row>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>คำนำหน้า (ผู้ประสานงานองค์กร)</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={data.fourth.training[index].title}
                      name="title"
                      onChange={(e) => handleChange(e, index)}
                    >
                      <option></option>
                      <option value="นาย">นาย</option>
                      <option value="นาง">นาง</option>
                      <option value="นางสาว">นางสาว</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ชื่อ (ผู้ประสานงานองค์กร)</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.fourth.training[index].first_name}
                      name="first_name"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>นามสกุล (ผู้ประสานงานองค์กร)</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.fourth.training[index].last_name}
                      name="last_name"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>เบอร์ติดต่อ (ผู้ประสานงานองค์กร)</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.fourth.training[index].tel_no}
                      name="tel_no"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>email (ผู้ประสานงานองค์กร)</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.fourth.training[index].email}
                      name="email"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : null}

            <hr></hr>
          </Row>
        );
      })}
      <Col md={12} className="text-center">
        <Button variant="success" onClick={() => addNewProject()}>
          เพิ่มข้อมูลการจัดโครงการ
        </Button>
      </Col>
    </Row>
  );
}

export default SectionFourth;
