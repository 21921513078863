import React from "react";
import axios from "axios";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { apiConfig } from "../../../../config/api";

function Selecter({ address, setAddress }) {
  const [province, setProvince] = React.useState([]);
  const [amphur, setAmphur] = React.useState([]);
  const [tambol, setTambol] = React.useState([]);
  const [group, setGroup] = React.useState([]);

  const provinceChange = (e) => {
    setAmphur([]);
    setTambol([]);
    setGroup([]);
    if (!!e.target.value) {
      setAddress({
        provice: parseInt(e.target.value),
        amphur: null,
        tambol: null,
        group: null,
      });
      getAmphur(e.target.value);
    }
  };
  const amphurChange = (e) => {
    setTambol([]);
    setGroup([]);
    if (!!e.target.value) {
      setAddress({
        provice: address.provice,
        amphur: parseInt(e.target.value),
        tambol: null,
        group: null,
      });
      getTambol(e.target.value);
    }
  };
  const tambolChange = (e) => {
    let tambolName = tambol.find((name) => {
      return String(name.value) === e.target.value;
    }).text;
    let provinceName = province.find((name) => {
      return name.value === address.provice;
    }).text;
    let amphure = amphur.find((name) => {
      return name.value === address.amphur;
    }).text;
    setGroup([]);
    if (!!e.target.value) {
      setAddress({
        provice: address.provice,
        amphur: address.amphur,
        tambol: e.target.value,
        group: null,
        addressName: `ตำบล.${tambolName} อำเภอ.${amphure} จังหวัด${provinceName}`,
      });
      getGroupt(e.target.value);
    }
  };
  const groupChange = (e) => {
    console.log('tambol',tambol);
    let villageName = group.find((name) => {
      return String(name.group) === String(e.target.value);
    }).mooban;
    let tambolName = tambol.find((name) => {
      return String(name.value) === String(address.tambol);
    }).text;
    let provinceName = province.find((name) => {
      return String(name.value) === String(address.provice);
    }).text;
    let amphure = amphur.find((name) => {
      return String(name.value) === String(address.amphur);
    }).text;

    if (!!e.target.value) {
      setAddress({
        ...address,
        group: e.target.value,
        addressName: `หมู่.${e.target.value} หมู่บ้าน.${villageName} ตำบล.${tambolName} อำเภอ.${amphure} จังหวัด${provinceName}`,
      });
    }
  };
  //ดึงค่าจังหวัดจากหลังบ้าน
  const getProvince = async () => {
    const result = await axios(apiConfig.url + "/api/provinces");
    if (result.status === 200) {
      setProvince(result.data);
    }
  };

  //ดึงค่าอำเภอจากหลังบ้าน
  const getAmphur = async (id_province) => {
    const result = await axios(apiConfig.url + "/api/amphurs/" + id_province);
    if (result.status === 200) {
      setAmphur(result.data);
    }
  };

  //ดึงค่าตำบลจากหลังบ้าน
  const getTambol = async (id_amphur) => {
    const result = await axios(apiConfig.url + "/api/tambols/" + id_amphur);
    if (result.status === 200) {
      setTambol(result.data);
    }
  };
  //ดึงค่าหมู่จากหลังบ้าน
  const getGroupt = async (group_id) => {
    const result = await axios(apiConfig.url + "/api/get-group-village/" + group_id);
    if (result.status === 200) {
      setGroup(result.data);
    }
  };
  React.useEffect(() => {
    getProvince(address.provice);
    if (!!address.tambol && !!address.amphur) {
      getTambol(address.amphur);
      getAmphur(address.provice);
      getGroupt(address.tambol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Main>
      <Form.Group className="item">
        <Form.Label>จังหวัด</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Default select example"
          value={!!address.provice ? address.provice : ""}
          onChange={(e) => provinceChange(e)}
        >
          <option></option>
          {province.map((province, inx) => {
            return (
              <option key={inx} value={province.value}>
                {province.text}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="item">
        <Form.Label>อำเภอ</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Default select example"
          value={!!address.amphur ? address.amphur : ""}
          onChange={(e) => amphurChange(e)}
          disabled={!address.provice}
        >
          <option></option>
          {amphur.map((amphur, inx) => {
            return (
              <option key={inx} value={amphur.value}>
                {amphur.text}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="item">
        <Form.Label>ตำบล</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Default select example"
          value={!!address.tambol ? address.tambol : ""}
          onChange={(e) => tambolChange(e)}
          disabled={!address.amphur}
        >
          <option></option>
          {tambol.map((tambol, inx) => {
            return (
              <option key={inx} value={tambol.value}>
                {tambol.text}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="item">
        <Form.Label>หมู่-หมู่บ้าน</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Default select example"
          name={address.group}
          value={!!address.group ? address.group : ""}
          onChange={(e) => groupChange(e)}
          disabled={!address.tambol}
        >
          <option></option>
          {group.map((group, inx) => {
            return (
              <option key={inx} value={group.group}>
                {group.group} - {group.mooban}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    </Main>
  );
}

export default Selecter;
const Main = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 5px 5px 5px 5px;
  .item {
    width: 22%;
    label {
      width: 90%;
    }
  }
`;
