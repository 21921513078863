import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import ResponibilityOverview from "./reponsibilityOverview/ResponibilityOverview";

function Responsibility({ data, address,auth }) {
  const overviewRef = React.useRef();
  {
    /* <ChartLayout
      average={!!data?.average ? data.average.toFixed(2) : 0}
      children={<ResponibilityOverview ref={overviewRef} data={data} address={address} />}
      overviewRef={overviewRef}
      title={"ข้อมูลบทบาทหน้าที่การมีส่วนร่วมในชุมชน"}
    /> */
  }
  return (
    <Main>
      <div className="title">
        <div>
          <span>ข้อมูลภาพรวมกลุ่มบุคคลที่มีหน้าที่ในชุมชน</span>
          <br />
          <span>{address.addressName}</span>
          <br />
          {!!data?.population ? (
            <span>มีจำนวน {!!data?.population ? data.population : 0} คน</span>
          ) : (
            "-"
          )}
          <br />
          {!!data?.average ? (
            <span>
              {" "}
              คิดเป็นร้อยละ {!!data?.average ? data.average.toFixed(2) : 0} 
              จากข้อมูลประชากรตามทะเบียนราษฎร์
            </span>
          ) : (
            "-"
          )}
        </div>
        {auth && (
          <Button
          onClick={() => overviewRef.current.getPersonalInfoOverview()}
          size="sm"
          style={{ height: "35px" }}
        >
          รายชื่อประชากร
        </Button>
        )}
        
      </div>
      <ResponibilityOverview ref={overviewRef} data={data} address={address} />
    </Main>
  );
}

export default Responsibility;
const Main = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: solid 1px #e6e6e6;
  }
`;
