export const  section = Object.freeze({
    0:"first",
    1:"second",
    2:"third",
    3:"fourth",
    4:"fifth",
    5:"sixth",
})
export const  Role = Object.freeze({
    ADMIN:"admin",
    MANAGER:"manager",
    DATACOLLECTOR:"dataCollector",
})
export const  TypesLayout = Object.freeze({
    none:"none",
    indexLayout:"indexLayout",
    adminLayout:"adminLayout",
})
export const  Method = Object.freeze({
    DELETE:"delete",
    POST:"post",
    GET:"get",
    PUT:"put",
    PATCH:"patch",
})
export const  FetchInterface = Object.freeze({
    PATH:"path",
    DATA:"data",
    METHOD:"method",
    PARAMS:"params",
    TOKEN:"token",
})
export const  PageNameEnum = Object.freeze({
    1:"ข้อมูลทั่วไป",
    2:"ข้อมูลการศึกษา",
    3:"ข้อมูลอาชีพ",
    4:"ข้อมูลทักษะ",
    5:"ข้อมูลสวัสดิการ",
})

export const  ThaiMonth = Object.freeze({
    1:"มกราคม",
    2:"กุมภาพันธ์",
    3:"มีนาคม",
    4:"เมษายน",
    5: "พฤษภาคม",
    6: "มิถุนายน",
    7: "กรกฎาคม",
    8: "สิงหาคม",
    9: "กันยายน",
    10: "ตุลาคม",
    11: "พฤศจิกายน",
    12:"ธันวาคม",
})
export const  PageArray = [
    {
        id:1,
        name:PageNameEnum[1],
    },{
        id:2,
        name:PageNameEnum[2],
    }
    ,{
        id:3,
        name:PageNameEnum[3],
    }
    ,{
        id:4,
        name:PageNameEnum[4],
    }
    ,{
        id:5,
        name:PageNameEnum[5],
    }
]