import { Role } from "../../enums/enum"
import { indexRoute } from "../routeData"
import { adminRoute } from "../routeData/admin"
import { dataCollectorRoute } from "../routeData/dataCollector"
import defult from "../routeData/defult"
import { managerRoute } from "../routeData/manager"

const routes = {
    index:indexRoute,
    admin:adminRoute,
    manager:managerRoute,
    dataCollector:dataCollectorRoute,
}
export const getRoutes =(permissions)=>{
    if (permissions === 'index') {
        return [...routes["index"]]
    } else if(
        permissions === Role.ADMIN ||
        permissions === Role.MANAGER||
        permissions === Role.DATACOLLECTOR

    ) {
        return [...routes[permissions],...defult]
        
    }else{
        return [...routes['index']]
    }
}
export const getBarMenu = (permissions) => {
    if (!!permissions) {
        const routesPermissions = getRoutes(permissions)
        return routesPermissions.filter((routes) =>  !!routes.sidebar)
    } else {
        const routesPermissions = getRoutes(permissions)
        return routesPermissions.filter((routes) =>  !!routes.navbar)
    }
}