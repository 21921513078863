import axios from "axios";
import { FetchInterface, Method } from "../enums/enum";

export const apiConfig  = {
    // url:'http://localhost:8000',
    url:'https://api.ecotives.info',
}
export async function ApiFetch  (fetchData) { 
    if (!!fetchData[FetchInterface.TOKEN]) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${fetchData[FetchInterface.TOKEN]}`;
        axios.defaults.responseType = 'application/json';
    }   
    const res = await axios[fetchData[FetchInterface.METHOD]](`${apiConfig.url}/api/${[fetchData[FetchInterface.PATH]]}`,
    fetchData[FetchInterface.METHOD] === Method.POST || fetchData[FetchInterface.METHOD] === Method.PATCH ? fetchData[FetchInterface.DATA] : !!fetchData[FetchInterface.PARAMS] && {params:fetchData[FetchInterface.PARAMS] })
    return res
}
export async function ApiFetchFile  (fetchData) { 
    if (!!fetchData[FetchInterface.TOKEN]) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${fetchData[FetchInterface.TOKEN]}`;
        axios.defaults.responseType = 'blob';
    }   
    const res = await axios[fetchData[FetchInterface.METHOD]](`${apiConfig.url}/api/${[fetchData[FetchInterface.PATH]]}`,
    fetchData[FetchInterface.METHOD] === Method.POST || fetchData[FetchInterface.METHOD] === Method.PATCH ? fetchData[FetchInterface.DATA] : !!fetchData[FetchInterface.PARAMS] && {params:fetchData[FetchInterface.PARAMS] })
    return res
}