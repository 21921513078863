import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import LineChart from "../../../../lineChart/lineChart";
import LoadingPage from "../../../../loadingPage/loadingPage";
import DataTable from "./dataTable/dataTable";
import InLocation from "./inLocation/inLocation";

export default function WelfareDetial({ data, address,auth,loadingPageCoload,loading }) {
  const inLocationRef = React.useRef();
  const eachofYearRef = React.useRef();
  return (
    <Main>
      <div className="data-table">
      <LoadingPage loading={loading} animatioColor={loadingPageCoload} />
        <div className="data-table-title">
          <span>ข้อมูลสวัสดิการที่แสดงในรูปแบบตาราง</span>
        </div>
        <div className="data-table-content">
          <DataTable auth={auth} address={address} data={data.dataTable} />
        </div>
      </div>
      <div className="in-location">
      <LoadingPage loading={loading} animatioColor={loadingPageCoload} />
        <div className="in-location-title">
          <span>สวัสดิการที่ประชากรในพื้นได้รับ</span>
          {auth && <Button
            onClick={() => inLocationRef.current.getPersonalInfoAllInLocation()}
            size="sm"
          >
            รายชื่อประชากร
          </Button>}
        </div>
        <InLocation
          ref={inLocationRef}
          address={address}
          data={data.inLocation}
        />
      </div>
      <div className="year-for-line-chart">
      <LoadingPage loading={loading} animatioColor={loadingPageCoload} />
        <div className="year-for-line-chart-title">
          <span>ปีที่ได้รับการสนับสนุน</span>
          {auth && <Button
            onClick={() => eachofYearRef.current.getPersonalInfoAllInEachofYear()}
            size="sm"
          >
            รายชื่อประชากร
          </Button>}
        </div>
        <div className="year-for-line-chart-content">
        <LoadingPage loading={loading} animatioColor={loadingPageCoload} />
          <LineChart ref={eachofYearRef} data={data.eachOfYear} />
        </div>
      </div>
    </Main>
  );
}

const Main = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 10px;
  .in-location {
    position: relative;
    padding: 10px;
    width: 100%;
    background-color: #ffff;
    border-radius: 5px;
    .in-location-title {
      display: flex;
      justify-content: space-between;
    }
  }
  .year-for-line-chart {
    position: relative;
    padding: 10px;
    width: 100%;
    background-color: #ffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .year-for-line-chart-title {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .year-for-line-chart-content {
      position: relative;
      padding: 10px 10px 15px 10px;
      width: 95%;
      height: 90%;
    }
  }
  .data-table {
    position: relative;
    padding: 10px;
    width: 100%;
    background-color: #ffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    .data-table-title {
      width: 100%;
      margin-bottom: 5px;
    }
    .data-table-content {
      width: 100%;
    }
  }
`;
