import React from "react";
import { ApiFetch } from "../../../../../../config/api";
import { useAuthContextState } from "../../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../../enums/enum";
import DonutChart from "../../../../../donutChart/donutChart";
// import ModalDashBoardTable from "../../../../../modal/ModalDashBoardTable";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";

const ResponibilityOverview = React.forwardRef(({ data, address }, ref) => {
  const { token } = useAuthContextState();
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const [tableData, setTableData] = React.useState({
    header: {
      title: "",
      gender: "",
    },
    data: {
      title: [],
      data: [],
    },
  });
  React.useImperativeHandle(ref, () => ({
    async getPersonalInfoOverview() {
      setModalShow(true);
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/personal-table-info/responibility/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: null,
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: {
              datasetTitle: "-",
              title: "ทั้งหมด",
            },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    },
  }));
  return (
    <>
      <div style={{ height: "86vh" }}>
        <DonutChart
          section={"responibility"}
          pathname={"personal-table-info"}
          setModalShow={setModalShow}
          setTableData={setTableData}
          cutout={65}
          address={address}
          chartData={data}
          setTableDataForFilter={setTableDataForFilter}
        />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายชื่อบุคคลที่มีหน้าที่ ${
          tableData.header.title
        } จำนวน ${tableData?.data?.data?.length} คน คิดเป็นร้อยละ ${
          !!tableData?.data?.data?.length
            ? (
                (tableData?.data?.data?.length / data?.populationAll) *
                100
              ).toFixed(2)
            : 0
        } 
              จากข้อมูลประชากรตามทะเบียนราษฎร์ ใน ${address.addressName}`}
        setTableDataForFilter={setTableDataForFilter}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
});
export default ResponibilityOverview;
