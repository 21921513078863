import React from 'react'
import { Container } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";
import LanguageComponent from '../languageComponent/language'
import SkillComponent from '../skillComponent/skill'
import TrainComponent from '../trainComponent/train'
import axios from 'axios';
import { apiConfig } from '../../../config/api';

function SectionFourth({ showOnlyProps = false, personalIdProps }) {
  const { data, setData } = React.useContext(PersonalContext);
  const { personalId, setPersonalId } = React.useContext(PersonalContext);
  const [trainCommunity, setTrainCommunity] = React.useState([]);
  const [typeProject, setTypeProject] = React.useState([]);

  const getTypeProject = async () => {
    const result = await axios.get(`${apiConfig.url}typ/api/eproject`);
    setTypeProject(result.data);
  };

  const getSkillType = async () => {
    const result = await axios.get(apiConfig.url + "/api/skilltype");
    let updateData = { ...data };
    updateData.fourth.skill = result.data;
    setData(updateData);
  };

  const getLanguage = async () => {
    const result = await axios.get(apiConfig.url + "/api/language");
    let updateData = { ...data };
    updateData.fourth.language = result.data;
    setData(updateData);
  };

  const getDataFromContext = () => {
    console.log(data.fourth);
    if (data.fourth.language.length === 0) getLanguage();
    if (
      data.fourth.skill[0].data.length === 0 ||
      data.fourth.skill[1].data.length === 0
    )
      getSkillType();
  };

  const getTrainById = async () => {
    const result = await axios.get(
      apiConfig.url + "/api/train/" + data.first.address.tambol_id
    );
    setTrainCommunity(result.data);
  };
  const getInfo = async () => {
    let result = await axios.get(apiConfig.url + "/api/personal/get_skill/" + personalId);
    let updateData = { ...data };
    updateData.fourth.language = result.data.language;
    updateData.fourth.skill = result.data.skill;
    updateData.fourth.train = result.data.train;
    setData(updateData);
    console.log(result.data.skill[0]);
    if (
      result.data.language.length == 0 &&
      result.data.skill[0].data.length == 0 &&
      result.data.skill[1].data.length == 0 &&
      result.data.train.length == 0
    ) {
      getDataFromContext();
    }
  };
  React.useEffect(async () => {
    await getInfo();
    await getTypeProject();
    await getTrainById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (personalIdProps) {
      setPersonalId(personalIdProps);
      getInfo();
    }
  }, []);

  return (
    <Container>
      <TrainComponent
        showOnlyProps={showOnlyProps}
        typeProject={typeProject}
        trainCommunity={trainCommunity}
      />
      <LanguageComponent showOnlyProps={showOnlyProps} />
      <SkillComponent showOnlyProps={showOnlyProps} />
      {/* <Row>
                <Col md={6}>
                    <div className="d-flex justify-content-start">
                        <div className="btn btn-info"
                            style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
                            onClick={() => {
                                addToContext()
                                setPage(page - 1)
                                }}>
                            กลับ
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="d-flex justify-content-end">
                        <div className="btn btn-info"
                            style={{ marginTop: '30px', marginBottom: '30px', width: '100%'  }}
                            onClick={() => {
                                addToContext()
                                setPage(page + 1)
                            }}>
                            ถัดไป
                        </div>
                    </div>
                </Col>
            </Row> */}
    </Container>
  );
}

export default SectionFourth