import axios from "axios";
import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { apiConfig } from "../../../../config/api";
import LoadingPage from "../../../loadingPage/loadingPage";
// import { careerMockData } from "../../../mock/dashboard/personal/career";
import CareerInfomationDetial from "./careerInfomationDetial/careerInfomationDetial";
import CareerInfomationOverview from "./careerInfomationOverview/careerInfomationOverview";
import CareerInfomationAgerage from "./careerInfomationDetial/careerInfomationAgerage/careerInfomationAgerage";

function CareerInfomation({ address,auth,loadingPageCoload }) {
  const [data, setData] = React.useState({});
  const careerAndAgerangeRef = React.useRef();
  const [caeerId, setCaeerId] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [salaryData, setSalaryData] = React.useState();
  async function getResponsibility() {
    if (!address.tambol) return false;
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/get-career-section/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`
      );
      if (res.status === 200) {
        console.log("CareerInfomation", res.data);
        setData(res.data);
        setSalaryData(res.data.overview.filterData[0].salary);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "error",
        text: error,
        icon: "error",
      });
    }
  }
  React.useEffect(() => {
    setData({});
    getResponsibility();
  }, [address.group, address.tambol]);
  return (
    <MainContent>
      <div className="overview-content">
        <CareerInfomationOverview loadingPageCoload={loadingPageCoload} loading={loading} auth={auth} address={address} data={data.overview} />
      </div>
      <div className="career-infomation-detial">
        <CareerInfomationDetial
        loadingPageCoload={loadingPageCoload}
          loading={loading}
          auth={auth}
          address={address}
          setCaeerId={setCaeerId}
          caeerId={caeerId}
          data={data.detial}
          salaryData={salaryData}
          filterData={data?.overview?.filterData}
          setSalaryData={setSalaryData}
        />
      </div>
      <div className="career-ageRange-detial">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="career-age-title">
          <span>แยกตามอาชีพ - ช่วงอายุ</span>
          {auth && (
            <Button
              size="sm"
              onClick={() =>
                careerAndAgerangeRef.current.getPersonalInfoAllCareerAndAgeRange()
              }
            >
              รายชื่อ
            </Button>
          )}
        </div>
        <div className="career-age-content">
          <CareerInfomationAgerage
            ref={careerAndAgerangeRef}
            address={address}
            data={data?.detial?.classifiedByCareerAndAgeRange}
          />
        </div>
      </div>
    </MainContent>
  );
}

export default CareerInfomation;
const MainContent = styled.div`
  width: 100%;
  height: auto;
  align-content: center;
  justify-content: center;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 49.5% 49.5%;
  position: relative;
  /* grid-template-rows: auto auto; */
  grid-template-areas:
    "careeroverview careerdetial"
    "careerageRange careerageRange";
  .overview-content {
    grid-area: careeroverview;
    width: 100%;
    height: 100%;
  }
  .career-infomation-detial {
    grid-area: careerdetial;
    width: 100%;
    height: 100%;
  }
  .career-ageRange-detial {
    position: relative;
    grid-area: careerageRange;
    width: 100%;
    background-color: #ffff;
    border-radius: 5px;
    padding: 5px 10px 15px 10px;
    .career-age-title {
      display: flex;
      justify-content: space-between;
    }
    .career-age-content {
      
    }
  }
`;
