import React from 'react'
import styled,{ keyframes } from 'styled-components'

export default function LoadingSpin() {
  return (
    <Spin />
  )
}
const animateLoadingAnimate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  `
const Spin = styled.div`
   width: 18px;
   height: 18px;
   border-radius: 50%;
   border-top: 3px solid #e8e8e8;
   animation: ${animateLoadingAnimate} 2s linear infinite;;
`