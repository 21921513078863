import React from "react";
import { useAuthContextState } from "../../../../../../context/auth/authContext/authContextState";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";
import BarChartSkill from "../../barChartSkill/barChartSkill";
function CareerSkill({ careerSkillData, address }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  return (
    <>
      <div style={{ height: "50vh", overflowY: "auto" }}>
        <BarChartSkill
          data={careerSkillData}
          skillType={"ทักษะทางด้านการประกอบอาชีพ"}
          section={"skillDetial"}
          pathname={"skill-personal-table-info"}
          setModalShow={setModalShow}
          setTableData={setTableData}
          address={address}
          setTableDataForFilter={setTableDataForFilter}
        />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียดทักษะ ${tableData.header.title} ในระดับ ${tableData.header.datasetTitle} ใน ${address.addressName} จำนวน ${tableData?.data?.data?.length} คน`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
}

export default CareerSkill;
