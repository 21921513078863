import React from 'react'
import { Container, Table, Form,  Col, Row } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";
function Skill({showOnlyProps = false}) {
    const { data, setData } = React.useContext(PersonalContext);

    const addNewSkill = (index) => {
        let updatedTrain = { ...data }
        updatedTrain.fourth.skill[index].data.push({
            skill_name: '',
            newSkill: true
        })
        setData(updatedTrain)
        /* let updatedSkill = [...skill]
        updatedSkill[index].data.push({
            skill_name: '',
            newSkill:true
        })
        setSkill(updatedSkill) */
    }

    const SetValueSkill = (value, index, subIndex) => {
        let updatedTrain = { ...data }
        updatedTrain.fourth.skill[index].data[subIndex].value = value
        setData(updatedTrain)
        /* let updatedSkill = [...skill]
        updatedSkill[index].data[subIndex].value = value
        setSkill(updatedSkill) */
    }

    const setNameSkill = (e,index,subIndex)=>{
        let updatedTrain = { ...data }
        updatedTrain.fourth.skill[index].data[subIndex].skill_name = e.target.value
        setData(updatedTrain)
        /* let updatedSkill = [...skill]
        updatedSkill[index].data[subIndex].skill_name = e.target.value
        setSkill(updatedSkill) */
    }

    const deleteSkill=(index,subIndex)=>{
        let updatedTrain = { ...data }
        updatedTrain.fourth.skill[index].data.splice(subIndex, 1)
        setData(updatedTrain)
       /*  let updatedSkill = [...skill]
        updatedSkill[index].data = updatedSkill[index].data.filter((e,index)=>{
            if(index!==subIndex) return e
        })
        setSkill(updatedSkill) */
    }
    const rowDataSkill = data.fourth.skill.map((skill, index) => {         //แถวข้อมูลทักษะ
        return (
            <React.Fragment key={index}>
                <Table striped bordered hover className="" style={{ marginBottom: '0px', }}>
                    <thead>
                        <tr>
                            <th>{skill.key}</th>                {/* หัวแถว */}
                        </tr>
                    </thead>
                </Table>
                <Table striped bordered hover className="" style={{ marginBottom: '0px', }}>        {/* ข้อมูลทักษะต่างๆ */}
                    <tbody>
                        {
                            skill.data.map((p, subIndex) => {
                                return (
                                    <tr key={subIndex}>         {/* ชื่อทักษะต่างๆ */}
                                        {!p.newSkill ? <th style={{ fontWeight: 'normal', width: "50%" }}>
                                            {!showOnlyProps && (!p.skill_level && <div className="text-danger" style={{ cursor: 'pointer' }} onClick={() => deleteSkill(index, subIndex)}>X</div>) } {p.skill_name}</th>
                                            :
                                            <th style={{ fontWeight: 'normal', width: "50%" }}>
                                                <Row>
                                                    {!showOnlyProps &&(<Col md={1}>
                                                        <div className="text-danger" style={{ cursor: 'pointer' }} onClick={() => deleteSkill(index, subIndex)}>X</div>
                                                    </Col>)}
                                                    <Col>
                                                        <Form.Control className="w-50" type="text" placeholder="" value={p.skill_name} onChange={(e) => setNameSkill(e,index,subIndex)}/>
                                                    </Col>
                                                </Row></th>
                                        }

                                        {[5, 4, 3, 2, 1].map((value) => (
                                            <th style={{ width: "10%" }} key={`${value}`} >         {/* คะแนนทักษะต่างๆ */}
                                                <Form.Check
                                                disabled={showOnlyProps}
                                                    checked={p.value === value || parseInt(p.value) == value }
                                                    required
                                                    type="radio"
                                                    onChange={() => SetValueSkill(value, index, subIndex)}
                                                    id={subIndex}
                                                    label={value}
                                                    name={`${skill.key}-${subIndex}`}
                                                />
                                            </th>
                                        ))}
                                    </tr>
                                )
                            })
                        }
                        {!showOnlyProps &&(<tr>
                            <td className="btn w-100 text-center bg-success text-white" onClick={() => addNewSkill(index)}>เพิ่มข้อมูลอื่นๆ (ถ้ามี)</td>
                        </tr>)}

                    </tbody>
                </Table>
            </React.Fragment>
        )
    })
    return (
        <Container>
            <h5 className="mb-4 mt-4">ทักษะด้านอื่น ๆ</h5>
            <small>ระดับ 5 = มากที่สุดหรือดีมาก , 4 = มากหรือดี , 3 = ปานกลางหรือพอใช้ , 2 = น้อยหรือต่ำกว่ามาตรฐาน , 1 = น้อยที่สุดหรือต้องปรับปรุงแก้ไข</small>
            <Table striped bordered hover className="mt-4" style={{ marginBottom: '0px', }}>
                <thead>
                    <tr>
                        <th style={{ width: "50%" }}>ทักษะ</th>
                        <th style={{ width: "10%" }}>5</th>
                        <th style={{ width: "10%" }}>4</th>
                        <th style={{ width: "10%" }}>3</th>
                        <th style={{ width: "10%" }}>2</th>
                        <th style={{ width: "10%" }}>1</th>
                    </tr>
                </thead>
            </Table>
            {rowDataSkill}
        </Container>
    )
}

export default Skill