import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import Religion from "./religion/religion";
import GeneralAge from "./generalAge/generalAge";
import { apiConfig } from "../../../../config/api";
import Accommodation from "./accommodation/accommodation";
import LoadingPage from "../../../loadingPage/loadingPage";
import Responsibility from "./Responsibility/Responsibility";
// import { generalInfoMockData } from "../../../mock/dashboard/personal/generalInfo";

export default function GeneralInfo({ address,auth,loadingPageCoload }) {
  const ageRef = React.useRef();
  const religionRef = React.useRef();
  const accommodationRef = React.useRef();
  const [data, setData] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  async function getGeneralSection() {
    if (!address.tambol) return false;
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/get-general-section/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`
      );
      if (res.status === 200) {
        console.log("generalInfo", res.data);
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "error",
        text: error,
        icon: "error",
      });
    }
  }
  React.useEffect(() => {
    setData({});
    getGeneralSection();
  }, [address.group, address.tambol]);
  const genStringReligion = () => {
    if (data?.religion) {
      let a = data?.religion;
      return data?.religion?.title?.map((data, index) => {
        return (
          `${data}` + ` ${a.data[0].data[index] + a.data[1].data[index]} คน`
        );
      });
    }
  };
  return (
    <Main>
      <div className="general-overview">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="general-overview-content">
          <Responsibility auth={auth} address={address} data={data?.responsibility} />
        </div>
      </div>
      <div className="general-detial">
        <div className="general-age">
        <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
          <div className="general-age-title">
            <span>
              ช่วงอายุเฉลี่ยของบุคคลใน {address.addressName} <br /> มีทั้งหมด{" "}
              {data?.generalAge?.total} คน แบ่งเป็น ชาย{" "}
              {data?.generalAge?.totalMan} คน, 
              หญิง{" "}{data?.generalAge?.totalWoman} คน,
              {!!data?.generalAge?.totalNodata && 
                <>
                ไม่พบข้อมูลเพศ{" "}{data.generalAge.totalNodata} คน,
                </>
              }
              
            </span>
            {auth && (
              <Button
                size="sm"
                onClick={() => ageRef.current.getPersonalInfoAllAge()}
              >
                รายชื่อประชากร
              </Button>
            )}
          </div>
          <GeneralAge
            address={address}
            data={data?.generalAge}
            ref={ageRef}
            totalPopulation={data.responsibility?.populationAll}
          />
        </div>
        <div className="general-religion">
        <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
          <div className="general-religion-title">
            <span>
              {/* {!!data.religion && (
                <>
                  จากกลุ่มตัวอย่าง{" "}
                  {data.religion.total} {""}คน<br/>
                </>
                )} */}
              ศาสนาทั้งหมด {data?.religion?.title.length} ศาสนา ใน{" "}
              {address.addressName}
              <br /> แบ่งเป็น{" "}
              {!!data.religion && genStringReligion().join(", ")}
            </span>
            {auth && (
              <Button
                size="sm"
                onClick={() => religionRef.current.getPersonalInfoAllReligion()}
              >
                รายชื่อประชากร
              </Button>
            )}
          </div>
          <Religion
            auth={auth}
            ref={religionRef}
            address={address}
            data={data?.religion}
            totalPopulation={data.responsibility?.populationAll}
          />
        </div>
        <div className="general-accommodation">
        <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
          <div className="general-accommodation-title">
            <span>ประเภทที่พักอาศัย ใน {address.addressName}</span>
            {auth && (
              <Button
                size="sm"
                onClick={() =>
                  accommodationRef.current.getPersonalInfoAllAccommodation()
                }
              >
                รายชื่อประชากร
              </Button>
            )}
          </div>
          <Accommodation
            ref={accommodationRef}
            address={address}
            data={data?.accommodation}
          />
        </div>
      </div>
    </Main>
  );
}
const Main = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 10px;
  justify-content: center;
  grid-template-columns: 49.5% 49.5%;
  .general-overview {
    background-color: #ffff;
    border-radius: 5px;
    width: 100%;
    height: min-content;
    display: flex;
    justify-content: center;
    position: relative;
    .general-overview-content {
      width: 95%;
      height: 90%;
      overflow: hidden;
    }
  }
  .general-detial {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    align-content: center;
    justify-content: center;
    .general-age {
      position: relative;
      background-color: #ffff;
      border-radius: 5px;
      width: 100%;
      padding: 10px 10px 15px 10px;
      .general-age-title {
        display: flex;
        justify-content: space-between;
      }
    }
    .general-religion {
      position: relative;
      background-color: #ffff;
      border-radius: 5px;
      width: 100%;
      padding: 10px 10px 15px 10px;
      .general-religion-title {
        display: flex;
        justify-content: space-between;
      }
    }
    .general-accommodation {
      position: relative;
      background-color: #ffff;
      border-radius: 5px;
      width: 100%;
      padding: 10px 10px 15px 10px;
      .general-accommodation-title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;
