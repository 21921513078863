import React from 'react'
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FiUpload } from 'react-icons/fi';

function ImageUplaod({ setValues, values }) {
    const [review, setReview] = React.useState('')
    const inputFileRef = React.useRef();
    function upload(event) {
        const reader = new FileReader();
        const image = event.target.files[0]
        if (!image) {
            return false
        } else {
            if (image.type.substr(0, 5) !== "image") {
                setValues({ ...values, image: null })
                return false
            }
            setValues({ ...values, image: event.target.files[0] })
            reader.onloadend = () => {
                setReview(reader.result)
            }
            reader.readAsDataURL(image)
        }
    }
    return (
        <ImageContainer >
            <div className='imageBox'>
                {!!review ?
                    <img
                        className='imgRe'
                        width={"100%"}
                        src={review}
                    /> :
                    <img
                        className='imgReNone'
                        width={"100%"}
                        src={require('./../../../assets/images/user.png')}
                    />
                }
            </div>
            <input
                className='inputFile'
                ref={inputFileRef}
                id="contained-button-file"
                onChange={(e) => upload(e)}
                type={'file'}
                accept="image/*"
            />
            <Button className='buttonUpload' variant="outline-primary" onClick={() => inputFileRef.current.click()} size='md' >
                เลือกรูป
                <FiUpload />
            </Button>
        </ImageContainer>


    )
}

export default ImageUplaod
const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height:100%;
    width:100%;
    border-radius: 10px;
    background-color:white;
    .inputFile{
        display: none;
        position: absolute;
    }
    .buttonUpload{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 110px;
        margin-top: 20px;
    }
    .imageBox{
        transition: all .3s;
        width: 180px;
        height: 180px;
        border-radius:50%;
        overflow: hidden;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        .imgRe{
            object-fit: contain;
        }
        .imgReNone{
            padding:25px
        }
    }
    
`