import axios from "axios";
import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { apiConfig } from "../../../../config/api";
import SectionSecond from "../../../CommunityForm/insertComponent/sectionSecond";
import EditModal from "../editModal/editModal";

function InfoNaturalResource({ communityId, auth }) {
  const [infoNaturalResourceData, setInfoNaturalResourceData] =
    React.useState("");
  console.log("infoNaturalResourceData", infoNaturalResourceData);
  const [editState, setEditState] = React.useState(false);
  const [modalForm, setModalForm] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  function handleEdit(infoNaturalResourceData) {
    setEditData({
      parentId: communityId,
      ...infoNaturalResourceData
    });
    setModalForm(true);
    setEditState(true);
    console.log("handleEditinfoNaturalResourceData", infoNaturalResourceData);
  }
  function handleAdd(section) {
    setEditData({
      parentId: communityId,
      second: {
        [`${section}`]: [],
      },
    });
    setModalForm(true);
    setEditState(false);
    console.log("EditinfoNaturalResourceData", infoNaturalResourceData);
  }
  async function getDataApi(communityId) {
    if (!communityId) {
      setInfoNaturalResourceData("");
      return false;
    }
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/community_dashboard/natural_resource/${communityId}`
      );
      if (res.status === 200) {
        setInfoNaturalResourceData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getDataApi(communityId);
  }, [communityId]);
  return modalForm ? (
    <>
      <EditModal
        editDataResult={editData}
        editState={editState}
        setModalForm={setModalForm}
        section={
          <SectionSecond
            data={editData}
            setData={setEditData}
            isEdit={editState}
          />
        }
      />
    </>
  ) : (
    !!infoNaturalResourceData && (
      <>
        {!!infoNaturalResourceData?.data?.tree && (
          <>
            <Row>
              <Row>
                <Col>
                  <h4>
                    <b>ข้อมูลพันธ์ไม้ในชุมชน</b>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Table responsive="md">
                  <thead>
                    <tr>
                      <th>ชื่อพันธ์ไม้</th>
                      <th>ประเภท</th>
                    </tr>
                  </thead>
                  <tbody>
                    {infoNaturalResourceData.data.tree.map((item, inx) => {
                      return (
                        <tr key={inx}>
                          <td>{item.second.tree[0].resource_name}</td>
                          <td>{item.second.tree[0].resource_type}</td>
                          <td style={{ textAlign: "end" }}>
                            {auth && (
                              <Button
                                size="sm"
                                onClick={() => handleEdit(item)}
                              >
                                แก้ไข
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {auth && (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => handleAdd("tree")}
                  >
                    เพิ่ม
                  </Button>
                )}
              </Row>
            </Row>
          </>
        )}
        <br />
        {!!infoNaturalResourceData?.data?.river && (
          <>
            <Row>
              <Row>
                <h4>
                  <b>ข้อมูลแม่น้ำในชุมชน</b>
                </h4>
              </Row>
              <br />
              <Row>
                <Table responsive="md">
                  <thead>
                    <tr>
                      <th>ชื่อแม่น้ำ</th>
                      <th>ประเภท</th>
                      <th>ความกว้าง(เมตร)</th>
                      <th>ความยาว(เมตร)</th>
                      <th>ความลึก(เมตร)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {infoNaturalResourceData.data.river.map((item,inx) => {
                      return (
                        <tr key={inx}>
                          <td>{item.second.river[0].resource_name}</td>
                          <td>{item.second.river[0].resource_type}</td>
                          <td>{item.second.river[0].resource_width}</td>
                          <td>{item.second.river[0].resource_length}</td>
                          <td>{item.second.river[0].resource_depth}</td>
                          <td style={{ textAlign: "end" }}>
                            {auth && (
                              <Button
                                size="sm"
                                onClick={() => handleEdit(item, "river")}
                              >
                                แก้ไข
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {auth && (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => handleAdd("river")}
                  >
                    เพิ่ม
                  </Button>
                )}
              </Row>
            </Row>
          </>
        )}
      </>
    )
  );
}

export default InfoNaturalResource;
