import React from 'react'
import {  Form, Row, Col } from 'react-bootstrap'
import DummyWelfare from '../../../dummy/api_welfare.json'
import { PersonalContext } from "../../../context/personal";

function Now({ org, welfareType, showOnlyProps=false }) {
  const { data, setData } = React.useContext(PersonalContext);
  /*   ฟังก์ชั่นเซตค่า วันเกิด */
  /* const birthDate = (christDate, buddhistDate) => {
        setData({
            ...data,
            first: { ...data.first, information: { ...data.first.information, dateBirthdayTH: buddhistDate, dateBirthdayGB: christDate } }
        })
    } */

  //เมื่อกรอกข้อมูลสวัสดิการที่ได้รับจากภาครัฐในปัจจุบัน
  const handleNowWelfareChange = (index, e) => {
    let updateData = { ...data };
    updateData.sixth.now[index] = {
      ...updateData.sixth.now[index],
      [e.target.name]: e.target.value,
    };
    setData(updateData);
  };
  /*   ฟังก์ชั่นเซตค่าวันที่ */
  /* const setDateStartNowWelfareChange = (index, date) => {
        let updateData = { ...data }
        updateData.sixth.now[index] = {
            ...updateData.sixth.now[index],
            dateStart: date
        }
        setData(updateData)
    } */
  //เมื่อ checked ที่ สวัสดิการที่ได้รับจากภาครัฐในปัจจุบัน
  const AddNowWelfare = (value) => {
    let check = data.sixth.now.find((e) => e.ids === value.id);
    let updateData = { ...data };
    if (check) {
      updateData.sixth.now = updateData.sixth.now.filter((e) => {
        return e.ids !== value.id;
      });
    } else {
      data.sixth.now.push({
        ids: value.id,
        welfare_name: value.name,
        welfare_type: value.type,
        org: value.org,
        welfare_start: "",
        canInputName: value.canInputName,
      });
    }
    setData(updateData);
  };

  //แสดง checkbox
  const WalfareShow = DummyWelfare.map((e, index) => {
    return (
      <React.Fragment key={`${e.id}`}>
        <Col md={2}>
          <div className="mb-3">
            <Form.Check
            disabled={showOnlyProps}
              checked={
                data.sixth.now.find((item) => e.id === item.ids) ? true : false
              }
              type="checkbox"
              label={e.name}
              onChange={() => AddNowWelfare(e)}
            />
          </div>
        </Col>
      </React.Fragment>
    );
  });

  //แสดง box เมื่อ checkbox ที่เลือก
  const welfateNowBoxCheck = data.sixth.now.map((value, index) => {
    return (
      <React.Fragment key={value.id}>
        <hr></hr>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>สวัสดิการ</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="text"
                name="welfare_name"
                value={
                  data.sixth.now[index].welfare_name.includes("อื่น")
                    ? ""
                    : data.sixth.now[index].welfare_name
                }
                onChange={(e) => handleNowWelfareChange(index, e)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>หน่วยงาน </Form.Label>
              <Row>
                <>
                  <Col md={12}>
                    <input
                      disabled={showOnlyProps}
                      type="text"
                      list="org_now"
                      className="form-control"
                      name="org"
                      value={data.sixth.now[index].org}
                      onChange={(e) => handleNowWelfareChange(index, e)}
                    />
                    <datalist id="org_now">
                      {org.map((item) => (
                        <option key={item.id} value={item.org_name} />
                      ))}
                    </datalist>
                  </Col>
                </>
              </Row>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>ประเภทสวัสดิการ</Form.Label>
              <Form.Select
                disabled={showOnlyProps}
                name="welfare_type"
                value={data.sixth.now[index].welfare_type}
                onChange={(e) => handleNowWelfareChange(index, e)}
              >
                <option value=""></option>
                {welfareType.map((e) => {
                  return (
                    <option key={e.id} value={e.id}>
                      {e.welfare_type}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>ปีเริ่มได้รับ (พ.ศ.)</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="number"
                value={data.sixth.now[index].welfare_start}
                min={0}
                name="welfare_start"
                onChange={(e) => handleNowWelfareChange(index, e)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>จำนวนเงิน</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="number"
                value={data.sixth.now[index].welfare_amount}
                min={0}
                name="welfare_amount"
                onChange={(e) => handleNowWelfareChange(index, e)}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  });
  return (
    <>
      <Row>
        <Col md={12}>
          <h5 className="my-3">สวัสดิการที่ได้รับจากภาครัฐในปัจจุบัน</h5>
        </Col>
        {WalfareShow}
      </Row>
      <Row>{welfateNowBoxCheck}</Row>
    </>
  );
}

export default Now