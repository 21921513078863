import { Row, Col, Button } from 'react-bootstrap';
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { BsPencilSquare, BsEye } from "react-icons/bs";
import { FetchInterface, Method } from "../../../../enums/enum";
import { ApiFetch, apiConfig } from "../../../../config/api";
import { useAuthContextState } from '../../../../context/auth/authContext/authContextState'
import React from 'react';



export default function EntrepreneursList() {
    const history = useHistory();
    const { token } = useAuthContextState()
    const [entrepreneursList, setEntrepreneursList] = React.useState([])

    async function getData(search='') {
        try {
            // setLoading(true)
          const res = await ApiFetch({
            [FetchInterface.PATH]: `entrepreneur?search=${search}`,
            [FetchInterface.METHOD]: Method.GET,
            [FetchInterface.TOKEN]: token.accessToken,
          });
            if (res.status === 200) {
                console.log(res.data)
                setEntrepreneursList(res.data)
            }
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
          console.log(error);
        }
    }

    React.useEffect(() => { 
        getData()
    }, [])


    return (
        <div>
            <Row className="mb-4 m-2">
                <Col md={12}>
                    <Row>
                    <Col md={6}>
                            <div>
                                <h2>จัดการข้อมูลผู้ประกอบการ</h2>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div >
                                <PositionButton>
                                        <Button variant="primary"
                                            onClick={() => { history.push("/entrepreneurs/search"); }}>
                                            เพิ่มข้อมูลผู้ประกอบการ
                                        </Button>
                                </PositionButton>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="content">
                        <TableUserData bordered responsive>
                        <thead style={{ backgroundColor: "#38AFBA", color: "white" }}>
                            <tr>
                            <th>#</th>
                            <th>หมายเลข ปชช</th>
                            <th>รูป</th>
                            <th>ข้อมูลผู้ประกอบการ</th>
                            <th>จัดการ</th>
                            </tr>
                        </thead>
                            <tbody>
                                {entrepreneursList.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{`${item.national_id}`}</td>
                                        <td>{item.file_path && <img src={`${apiConfig.url}/${item.file_path}`} alt="Girl in a jacket" width="200"/>}</td>
                                            <td>{`${item.title}${item.first_name} ${item.last_name}`} <br></br> เพศ {item.gender} <br></br> ศาสนา {item.religion} <br></br> ระดับของผู้ประกอบการ {item.level}</td>
                                        <td style={{ textAlign: "center", width: "10%" }} >
                                            <Button
                                                    variant="warning"
                                                    onClick={()=>history.push(`/entrepreneurs/main/${item.id}`)}
                                            >
                                             <BsPencilSquare />
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    variant="info"
                                                    onClick={()=>history.push(`/entrepreneurs/entrepreneurs-detail/${item.id}`)}
                                            >
                                             <BsEye />
                                            </Button>
                                        </td>
                                     </tr>
                                    )
                                })}
                           
                        </tbody>
                        </TableUserData>
                        {/* {userData?.data?.length > 0 &&
                        <PaginationSelftInput
                            pagiData={userData}
                            getDashboard={getUser}
                            page={userData.current_page}
                        />} */}
            
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const PositionButton = styled.div`
    display: flex;
    justify-content: flex-end;
`

const TableUserData = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  transition: all .2s ;
tr th,
tr td {
  border-right: 1px solid #38AFBA;
  border-bottom: 1px solid #38AFBA;
  padding: 5px;
}

tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th {
  background: #38AFBA;
  text-align: left;
  border-top: solid 1px #38AFBA;
}

/* top-left border-radius */
tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
`