import React from 'react'
import { Button, Modal, Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { apiConfig } from "../../../../config/api";
import { useParams } from "react-router-dom";
import { FetchInterface, Method } from '../../../../enums/enum';
import { ApiFetch } from '../../../../config/api';
import { useAuthContextState } from '../../../../context/auth/authContext/authContextState'
import { BsFillTrash2Fill } from "react-icons/bs";
import Swal from 'sweetalert2'

export default function ProductImageEntrepreneurs() {
    const { token } = useAuthContextState()
    const params = useParams();
    const [openModal, setOpenModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [files, setFiles] = React.useState([])

    const [dataImages, setDataImages] = React.useState([])

    async function getData(personalId) {
        try {
            // setLoading(true)
          const res = await ApiFetch({
            [FetchInterface.PATH]: `entrepreneur/product-image/${personalId}`,
            [FetchInterface.METHOD]: Method.GET,
            [FetchInterface.TOKEN]: token.accessToken,
          });
            if (res.status === 200) {
                console.log(res.data)
                setDataImages(res.data)
            }
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
          console.log(error);
        }
    }

    const deleteFile = (i) => {
        let data = Array.from(files)
        data.splice(i, 1)
        setFiles(data)
    }
    
    const renderFileList = () => (<ol>
        {[...files].map((f, i) => (
            <li className='mb-2' key={i}><img src={URL.createObjectURL(f)} width={100} /><Button variant="danger" onClick={()=>deleteFile(i)}>ลบ</Button></li>
        ))}
    </ol>)

    const packFiles = (files)=> {
        const data = new FormData();

        [...files].forEach((file, i) => {
            data.append('images[]', file);
        })
        data.append('title', "temp")
        return data
    }

    const handleUploadClick = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (files.length) {
            const data = packFiles(files)
            let res = await ApiFetch({
                [FetchInterface.PATH]: 'upload-multi',
                [FetchInterface.METHOD]: Method.POST,
                [FetchInterface.DATA]: data,
                [FetchInterface.TOKEN]: token.accessToken,
            })
    
            if (res.status === 200) {
                let payload = res.data.data.map(value => {
                    return {
                        personal_id: params.personalId,
                        image_id: value
                    }
                })
                let res2 = await ApiFetch({
                    [FetchInterface.PATH]: 'entrepreneur/product-image',
                    [FetchInterface.METHOD]: Method.POST,
                    [FetchInterface.DATA]: payload,
                    [FetchInterface.TOKEN]: token.accessToken,
                })
                if (res2.status === 200) {
                    Swal.fire({
                        title: "บันทึกข้อมูลสำเร็จ?",
                        icon: "success"
                    })
                    setFiles([])
                    setOpenModal(false)
                    setLoading(false)
                    getData(params.personalId)
                } else {
                    setLoading(false)
                    throw new Error('เกิดข้อผิดพลาดกับการเชื่อมต่อ')
                }
            } else {
                setLoading(false)
                throw new Error('เกิดข้อผิดพลาดกับการเชื่อมต่อ')
            }
        }
        setLoading(false)
    }

    async function handleDeleteImageById(id) {
        let result = await Swal.fire({
            icon: 'warning',
            showDenyButton: true,
            title: `ต้องการลบข้อมูลรูปภาพนี้?`,
            showConfirmButton: true,
            confirmButtonText: "ต้องการลบ",
            denyButtonText: `ไม่ต้องการ`
        })
        if (result.isConfirmed) {
            try {
                // setLoading(true)
              const res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/product-image/${id}`,
                [FetchInterface.METHOD]: Method.DELETE,
                [FetchInterface.TOKEN]: token.accessToken,
              });
                if (res.status === 200) {
                    Swal.fire({
                        title: "ลบข้อมูลมูลสำเร็จ?",
                        icon: "success"
                    })
                    getData(params.personalId)
                }
                // setLoading(false)
            } catch (error) {
                // setLoading(false)
              console.log(error);
            }
        } 
    }

    React.useEffect(() => { 
        getData(params.personalId)
    }, [])

    return (
        <Main>
        <Container>
          <div className="title">
            <div className='select'>
              จัดการรูปสินค้า
            </div>
            <div>
              <Button variant="primary" onClick={() => setOpenModal(true)}>เพิ่มข้อมูล</Button>
            </div>
            <Modal
              show={openModal}
              onHide={() => setOpenModal(false)}
            >
            <Form onSubmit={(e) => handleUploadClick(e)}>
              <Modal.Header closeButton>
                <Modal.Title>เพิ่มรูปภาพสินค้า (เพิ่มได้สูงสุด ครั้งละ 5 ภาพ)</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              
                <Row className="justify-content-md-center p-3">
                  <Col md='12'>
                  <DropContainer>
                    <DropTitle>Drop files here</DropTitle>
                    or
                    <FileInputButton type="file" id="images" accept="image/*" multiple  onChange={(e)=> setFiles(e.target.files)}  required/>
                  </DropContainer>
                  </Col>
                </Row>
                {renderFileList()}
                
              </Modal.Body>
              <Modal.Footer>
                     <Button disabled={loading} type='submit' variant="primary" >{ loading==false? "เพิ่มข้อมูล":"กำลังอัพโหลด..."}</Button>
            </Modal.Footer>
            </Form>
            </Modal>
          </div>
          <div className="content">
            <TableUserData bordered responsive>
              <thead style={{ backgroundColor: "#38AFBA", color: "white" }}>
                <tr>
                  <th>#</th>
                  <th>รูป</th>
                  <th>จัดการ</th>
                </tr>
              </thead>
              <tbody>
                {!!dataImages &&
                  dataImages.map((data, inx) => (
                      <tr key={inx}>
                          <td>{inx+1}</td>
                      <td><img src={`${apiConfig.url}/${data.file_path}`} alt="Girl in a jacket" width="200"/></td>
                      <td style={{ textAlign: "center", width: "10%" }} >
                        <Button
                                  variant="danger"
                                  onClick={()=>handleDeleteImageById(data.id)}
                        >
                          <BsFillTrash2Fill />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </TableUserData>
            {/* {userData?.data?.length > 0 &&
              <PaginationSelftInput
                pagiData={userData}
                getDashboard={getUser}
                page={userData.current_page}
              />} */}
  
          </div>
        </Container>
      </Main>
    )
}

const Main = styled.div`
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .title {
    border-radius: 10px;
    background-color: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    padding: 0 15px 0 15px;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select{
      width: 50%;
    }
  }
  .content {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px;
    margin-top: 15px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }
`
const TableUserData = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  transition: all .2s ;
tr th,
tr td {
  border-right: 1px solid #38AFBA;
  border-bottom: 1px solid #38AFBA;
  padding: 5px;
}

tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th {
  background: #38AFBA;
  text-align: left;
  border-top: solid 1px #38AFBA;
}

/* top-left border-radius */
tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
`

const DropContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;

  &:hover {
    background: #eee;
    border-color: #111;
  }

  &:hover .drop-title {
    color: #222;
  }
`;

const DropTitle = styled.div`
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
`;


const FileInputButton = styled.input.attrs({ type: 'file' })`
  &::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;

    &:hover {
      background: #0d45a5;
    }
  }
`;