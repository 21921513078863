import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { WatDatePicker } from "thaidatepicker-react";
import axios from "axios";
import Search from "../../search/search";
import { apiConfig } from "../../../config/api";

function SectionOne({ data, setData }) {
  console.log("SectionOne", data);
  const [filterData, setFilterData] = React.useState([]);

  //ดึงค่าจังหวัดจากหลังบ้าน
  const getProvince = async () => {
    const result = await axios(apiConfig.url + "/api/provinces");
    let updateData = { ...data };
    updateData.first.community.province = result.data;
    setData(updateData);
  };

  //ดึงค่าอำเภอจากหลังบ้าน
  const getAmphur = async (e) => {
    handleChange(e, "community");
    const result = await axios(
      apiConfig.url + "/api/amphurs/" + e.target.value
    );
    let updateData = { ...data };
    updateData.first.community.amphur = result.data;
    setData(updateData);
  };
  console.log("data", data);
  //ดึงค่าตำบลจากหลังบ้าน
  const getTambol = async (e) => {
    handleChange(e, "community");
    const result = await axios(
      apiConfig.url + "/api/tambols/" + e.target.value
    );
    let updateData = { ...data };
    updateData.first.community.tambol = result.data;
    setData(updateData);
  };
  //ดึงค่าหมู่จากหลังบ้าน
  const getGroupVillage = async (e) => {
    handleChange(e, "community");
    const result = await axios(
      apiConfig.url + "/api/get-group-village/" + e.target.value
    );
    let updateData = { ...data };
    updateData.first.community.group = result.data;
    setData(updateData);
    setFilterData(result.data);
  };

  /*   ฟังก์ชั่นวันที่*/
  const handleDateChange = (christDate, buddhistDate, name) => {
    let updateData = { ...data };
    updateData.first.leader[name] = christDate;
    setData(updateData);
  };

  const handleChange = (e, title) => {
    const updateData = { ...data };
    updateData.first[title][e.target.name] = e.target.value;
    setData(updateData);
  };

  //จะทำเมื่อเริ่มเปิดหน้านี้
  React.useEffect(() => {
    getProvince();
    if (data.first.community.province_id) {
      setData({
        ...data,
        first: { ...data.first, ...data.first, saved: true },
      });
      getAmphur({ target: { value: data.first.community.province_id } });
      if (data.first.community.amphur_id) {
        getTambol({ target: { value: data.first.community.amphur_id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <h5 className="mb-4">ข้อมูลหมู่บ้าน</h5>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="">
          <Form.Label>จังหวัด</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="province_id"
            value={data.first.community.province_id}
            onChange={(e) => getAmphur(e)}
          >
            <option></option>
            {!!data.first?.community.province &&
              data.first.community.province.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="">
          <Form.Label>อำเภอ</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="amphur_id"
            /* disabled={
              data.first.community.amphur.length > 0 ||
              data.first.community.amphur_id !== null
                ? null
                : true
            } */
            value={data.first.community.amphur_id}
            onChange={(e) => getTambol(e)}
          >
            <option></option>
            {!!data.first?.community.amphur &&
              data.first.community.amphur.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="">
          <Form.Label>ตำบล</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="tambol_id"
            value={data.first.community.tambol_id}
            /* disabled={
              data.first.community.tambol.length > 0 ||
              data.first.community.tambol_id !== null
                ? null
                : true
            } */
            onChange={(e) => getGroupVillage(e)}
          >
            <option></option>
            {data.first?.community.tambol &&
              data.first.community.tambol.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={2}>
        <Search filterData={filterData} data={data} setData={setData} />
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="">
          <Form.Label>ชื่อหมู่บ้าน</Form.Label>
          <Form.Control
            type="text"
            name="community_name"
            /* disabled={
              !data.first.community.community_moo ||
                !data.first.community.group.every(
                  (item) => item.group_id === data.first.community.community_moo
                )
            } */
            value={data.first.community.community_name}
            onChange={(e) => handleChange(e, "community")}
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group className="mb-3" controlId="">
          <Form.Label>จำนวนประชากรในหมู่บ้าน (คน)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            name="population"
            value={data.first.community.population}
            onChange={(e) => handleChange(e, "community")}
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group className="mb-3" controlId="">
          <Form.Label>จำนวนหลังคาเรือน (เรือน)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            name="house"
            value={data.first.community.house}
            onChange={(e) => handleChange(e, "community")}
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group className="mb-3" controlId="">
          <Form.Label>พื้นที่หมู่บ้าน (ไร่)</Form.Label>
          <Form.Control
            type="number"
            min={0}
            name="community_area"
            value={data.first.community.community_area}
            onChange={(e) => handleChange(e, "community")}
          />
        </Form.Group>
      </Col>
      {!!data.first?.leader && (
        <>
          <h5 className="mt-4 mb-4">ข้อมูลผู้ใหญ่บ้าน</h5>
          <Col md={2}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>คำนำหน้าชื่อ</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="title"
                value={data.first.leader.title}
                onChange={(e) => handleChange(e, "leader")}
              >
                <option value=""></option>
                <option value="นาย">นาย</option>
                <option value="นาง">นาง</option>
                <option value="นางสาว">นางสาว</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>ชื่อ ผู้ใหญ่บ้าน</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={data.first.leader.first_name}
                onChange={(e) => handleChange(e, "leader")}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>นามสกุล ผู้ใหญ่บ้าน</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={data.first.leader.last_name}
                onChange={(e) => handleChange(e, "leader")}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>เบอร์ติดต่อ ผู้ใหญ่บ้าน</Form.Label>
              <Form.Control
                type="text"
                name="tel_no"
                value={data.first.leader.tel_no}
                onChange={(e) => handleChange(e, "leader")}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>วันที่เริ่มรับตำแหน่ง</Form.Label>
              <br />
              <WatDatePicker
                value={data.first.leader.leader_start}
                inputStyle={{ height: "38px", width: "auto" }}
                onChange={(christDate, buddhistDate) =>
                  handleDateChange(christDate, buddhistDate, "leader_start")
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD MMMM YYYY"}
                clearable={true}
                disabled={false}
                readOnly={false}
                maxDate={"2030-1-1"}
                yearBoundary={99}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>วันที่หมดวาระ</Form.Label>
              <br></br>
              <WatDatePicker
                value={data.first.leader.leader_end}
                inputStyle={{ height: "38px", width: "auto" }}
                onChange={(christDate, buddhistDate) =>
                  handleDateChange(christDate, buddhistDate, "leader_end")
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD MMMM YYYY"}
                clearable={true}
                disabled={false}
                readOnly={false}
                maxDate={"2030-1-1"}
                yearBoundary={99}
              />
            </Form.Group>
          </Col>
        </>
      )}
    </Row>
  );
}

export default SectionOne;
