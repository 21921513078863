import React from "react";
import styled from "styled-components";

function EducationLocation({ educationSchool }) {
  console.log(educationSchool?.school);
  return (
    <ul>
      <li>
        <BoxLocationCount>
          สถาบันในพื้นที่ <b>{educationSchool?.school?.local}</b> คน
          {/* <div style={{ paddingLeft: "25px" }}>
            {!!educationLeveDetialData?.educationLocation && (
              <lu>
                <li>
                  ชาย {educationLeveDetialData?.educationLocation.local.men} คน
                </li>
                <li>
                  หญิง {educationLeveDetialData?.educationLocation.local.girl}{" "}
                  คน
                </li>
                <li>
                  รวม {educationLeveDetialData?.educationLocation.local.sum} คน
                </li>
              </lu>
            )}
          </div> */}
        </BoxLocationCount>
      </li>
      <li>
        <div style={{ fontSize: "18px" }}>
          สถาบันนอกพื้นที่
          <ul>
            <li>
              <BoxLocationCount>
                ในประเทศ <b>{educationSchool?.school?.inCountry}</b> คน
                {/*  <div style={{ paddingLeft: "25px" }}>
                  {!!educationLeveDetialData?.educationLocation && (
                    <lu>
                      <li>
                        ชาย{" "}
                        {
                          educationLeveDetialData?.educationLocation.inCountry
                            .men
                        }{" "}
                        คน
                      </li>
                      <li>
                        หญิง
                        {
                          educationLeveDetialData?.educationLocation.inCountry
                            .girl
                        }{" "}
                        คน
                      </li>
                      <li>
                        รวม{" "}
                        {
                          educationLeveDetialData?.educationLocation.inCountry
                            .sum
                        }{" "}
                        คน
                      </li>
                    </lu>
                  )}
                </div> */}
              </BoxLocationCount>
            </li>
            <li>
              <BoxLocationCount>
                ต่างประเทศ <b>{educationSchool?.school?.foreign}</b> คน
                {/*  <div style={{ paddingLeft: "25px" }}>
                  {!!educationLeveDetialData?.educationLocation && (
                    <lu>
                      <li>
                        ชาย{" "}
                        {
                          educationLeveDetialData?.educationLocation.outCountry
                            .men
                        }{" "}
                        คน
                      </li>
                      <li>
                        หญิง
                        {
                          educationLeveDetialData?.educationLocation.outCountry
                            .girl
                        }{" "}
                        คน
                      </li>
                      <li>
                        รวม{" "}
                        {
                          educationLeveDetialData?.educationLocation.outCountry
                            .sum
                        }{" "}
                        คน
                      </li>
                    </lu>
                  )}
                </div> */}
              </BoxLocationCount>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  );
}

export default EducationLocation;
const BoxLocationCount = styled.div`
  padding: 2px;
  width: 100%;
  font-size: 18px;
  margin-bottom: 5px;
`;
