import axios from "axios";
import React from "react";
import { Row, Col, Table, Container, Button } from "react-bootstrap";
import { apiConfig } from "../../../../config/api";
import ModalPopup from "./popup";

function InfoGroup({ communityId,auth }) {
  const [data, setData] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalForm, setModalForm] = React.useState(false);
  const [editState, setEditState] = React.useState(false);
  const [editData, setEditData] = React.useState("");
  const [infoGroupItem, setInfoGroupItem] = React.useState("");
  console.log("InfoGroupData", data);

  function handleAdd() {
    console.log("infoGroupPopupHandleAdd");
    setEditState(false);
    setModalForm(true);
    setModalShow(true);
    setEditData({
      parentId:communityId,
      fifth: {
        social: [],
      },
    });
  }
  function handleEdit(detail) {
    console.log("infoGroupPopupHandleEdit", detail);
    setEditData({
      parentId:communityId,
      ...detail
      
    });
    setModalForm(true);
    setEditState(true);
  }
  async function getDataApi(communityId) {
    if (!communityId) {
      setData("")
      return false;
    }
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/community_dashboard/group/${communityId}`
      );
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getDataApi(communityId);
  }, [communityId]);
  return (
    <Container>
      {!!modalShow && (
        <ModalPopup
        auth={auth}
          modalForm={modalForm}
          setModalForm={setModalForm}
          editData={editData}
          setEditData={setEditData}
          editState={editState}
          setEditState={setEditState}
          modalShow={modalShow}
          setModalShow={setModalShow}
          infoGroupItem={infoGroupItem}
          handleEdit={handleEdit}
        />
      )}
      <div>
        {!!data?.socialData && (
          <Row>
            <Col>
              <Table responsive="md">
                <thead>
                  <tr>
                    <th>ประเภทกลุ่ม</th>
                    <th>ชื่อกลุ่ม</th>
                  </tr>
                </thead>
                <tbody>
                  {data.socialData.map((item,inx) => {
                    return (
                      <tr key={inx}>
                        <td>{item.fifth.social[0].resource_group}</td>
                        <td> {item.fifth.social[0].resource_name}</td>
                        <td style={{textAlign:"end"}}>
                          <Button
                            variant="info"
                            onClick={() => {
                              setModalShow(true);
                              setInfoGroupItem(item);
                            }}
                          >
                            ดูข้อมูลเพิ่มเติม
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
            {auth && <Button
              variant="success"
              onClick={() => handleAdd()}
            >
              เพิ่มข้อมูล
            </Button>}
            
          </Row>
        )}
      </div>
    </Container>
  );
}

export default InfoGroup;
