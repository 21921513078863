import axios from "axios";
import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

function EducationLavelFilter({
  educationLevelFilterData,
  setEducationLevelId,
  educationLevelId,
}) {
  return (
    <Main>
      <Form.Group className="item">
        <Form.Label>เลือกระดับการศึกษา</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Default select example"
          value={educationLevelId}
          onChange={(e) => setEducationLevelId(e.target.value)}
        >
          <option></option>
          {!!educationLevelFilterData?.filterData &&
            educationLevelFilterData.filterData.map((data, inx) => {
              return (
                <option key={inx} value={data.id}>
                  {data.name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>
    </Main>
  );
}

export default EducationLavelFilter;
const Main = styled.div`
  width: 45%;
  height: 100%;
  margin-top:5px;
  .item {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    label {
      width: 70%;
      margin-right: 10px;
    }
  }
`;
