import React from 'react'
import { ApiFetch } from '../../../../config/api'
import { FetchInterface, Method } from '../../../../enums/enum'
import PaginationSelftInput from '../pagination/pagination'
import TableDetial from './tableDetial/tableDetial'

function TableSelft({ token, userInfo, setPaginatePageInputBySelft, paginatePageInputBySelft }) {
    const [userData, setUserData] = React.useState()
    const [loading, setLoading] = React.useState(true)

    async function getDashboard(page) {
        console.log('isPage',page);
        setLoading(true)
        try {
            const res = await ApiFetch({
                [FetchInterface.PATH]: `auth/checker-dashboard-input-by-selft/${userInfo.userId}`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.TOKEN]: token.accessToken,
                [FetchInterface.PARAMS]: { page: page }
            })
            if (res.status === 200) {
                setPaginatePageInputBySelft(Number(page))
                setLoading(false)
                setUserData(res.data.selftInput)
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    
    React.useEffect(() => {
        getDashboard(paginatePageInputBySelft);
    }, [])
    return (
        <>
            {!!userData && userData.data.length > 0 && (
                <div className='TableBox'>
                    <TableDetial
                        loading={loading}
                        userData={userData.data}
                        pagidata={userData}
                        page={paginatePageInputBySelft}
                    />
                    <PaginationSelftInput
                        pagiData={userData}
                        getDashboard={getDashboard}
                        page={paginatePageInputBySelft}
                    />
                </div>
            )}
            {!!userData && userData.data.length <= 0 && !loading && (
                <h1>
                    ไม่พบข้อมูล
                </h1>
            )}
        </>
    )

}

export default TableSelft