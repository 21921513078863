import React from 'react'
import { Button, Row, Container, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { Link } from "react-router-dom";
import styled from 'styled-components';

function DataCollector() {
    const isdesktop = useMediaQuery({ minWidth: 992 })
    const styles = {
        button: {
            height: "60px",
            borderRadius: "8px",
            backgroundColor: "#38AFBA"
        },
    }
    return (
        <IndexLayoutContainner>
            <Content >
                <Col md={7}  >
                    <Container >
                        <Row className="justify-content-md-center">
                            <Col md={8}>
                                <Contain >
                                    <h2>
                                        แบบฟอร์มสำหรับข้อมูลบุคคล
                                    </h2>
                                    <Row xs={12} md={12} lg={12} className={isdesktop ? "mt-5" : 'mt-3'}>
                                        <p className="text-center"><Link to='/personal-form'><Button variant="white" className={isdesktop ? "w-45" : 'w-100'} style={styles.button}>
                                            <span className="d-flex justify-content-center text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-clipboard2-data mx-2" viewBox="0 0 16 16">
                                                    <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                                                    <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                                                    <path d="M10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1Z" />
                                                </svg> แบบฟอร์มข้อมูลบุคคล</span></Button></Link></p>

                                    </Row>
                                    {/* <Row xs={12} md={12} lg={12} className={isdesktop ? "mt-2" : 'mt-3'}>
                                        <p className="text-center"><Link to='/community-form'><Button variant="white" className={isdesktop ? "w-45" : 'w-100'} style={styles.button}>
                                            <span className="d-flex justify-content-center text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-clipboard2-data mx-2" viewBox="0 0 16 16">
                                                    <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                                                    <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                                                    <path d="M10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1Z" />
                                                </svg> แบบฟอร์มข้อมูลชุมชน</span></Button></Link></p>
                                    </Row> */}
                                </Contain>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Content>
        </IndexLayoutContainner>
    )
}

export default DataCollector
const IndexLayoutContainner = styled.div`
  width:100%;
  height:100%;
  padding: 15px;
`
const Contain = styled(Container)`
    height:90vh;
    padding:20px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:flex-start;
    overflow: hidden;
`
const Content = styled.div`
    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 15px;
`