import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import LoadingPage from "../../../../loadingPage/loadingPage";
// import { careerMockData } from "../../../../mock/dashboard/personal/career";
import FarmingArea from "./farmingArea/farmingArea";
import Occupation from "./occupation/occupation";
import Overview from "./overview/overview";
// import Filter from "../careerInfomationDetial/salary/filter/filter";
// import Salary from "../careerInfomationDetial/salary/salary";
function CareerInfomationOverview({
  data,
  auth,
  // setSalaryData,
  // salaryData,
  address,
  loading,
  loadingPageCoload
}) {
  const overviewRef = React.useRef();
  const occupationRef = React.useRef();
  const farmingAreaRef = React.useRef();
  // const salaryRef = React.useRef();
  console.log('data.average?.average.toFixed(2)',!!data?.average && data);
  return (
    <Main>
      <div className="title">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <span>ข้อมูลโดยประชากรจำนวน {!!data?.average?.total ? data.average.total : '-'} คน</span>
        <br />
        <span>
          {!!data?.average
            ? "แสดงผลคิดเป็นร้อยละ " +
               data.average.average.toFixed(2)+
              " จากข้อมูลประชากรตามทะเบียนราษฎร์"
            : ""}{"-"}
        </span>
      </div>
      <div className="overview">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="overview-title">
          <span>ภาพรวม</span>
          {auth && (
            <Button
              size="sm"
              onClick={() =>
                overviewRef.current.getPersonalInfoAllCareerAndOverview()
              }
            >
              รายชื่อ
            </Button>
          )}
        </div>
        <Overview address={address} ref={overviewRef} data={data?.overview} />
      </div>
      <div className="occupation">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="occupation-title">
          <span>กราฟแสดงสัดส่วนการมีอาชีพเสริม (มี/ไม่มี)</span>
          {auth && (
            <Button
              size="sm"
              onClick={() =>
                occupationRef.current.getPersonalInfoAllCareerAndOccupation()
              }
            >
              รายชื่อ
            </Button>
          )}
        </div>
        <Occupation
          address={address}
          ref={occupationRef}
          data={data?.occupation}
        />
      </div>
      <div className="farming-area">
      <LoadingPage borderRadius={"5px"} loading={loading} animatioColor={loadingPageCoload} />
        <div className="farming-area-title">
          <span>
            กราฟแสดงสัดส่วนการมีพื้นที่ทำกินของประชากรในหมู่บ้าน (มี/ไม่มี)
          </span>
          {auth && (
            <Button
              size="sm"
              onClick={() =>
                farmingAreaRef.current.getPersonalInfoAllCareerAndFarmingArea()
              }
            >
              รายชื่อ
            </Button>
          )}
        </div>
        <FarmingArea
          ref={farmingAreaRef}
          address={address}
          data={data?.farmingArea}
        />
      </div>
    </Main>
  );
}
export default CareerInfomationOverview;
const Main = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  grid-gap: 10px;
  grid-template-columns: 100%;
  .overview {
    padding: 10px 10px 25px 15px;
    background-color: #ffff;
    border-radius: 5px;
    position: relative;
    .overview-title {
      display: flex;
      justify-content: space-between;
    }
  }
  .occupation {
    position: relative;
    padding: 10px 10px 25px 15px;
    background-color: #ffff;
    border-radius: 5px;
    .occupation-title {
      display: flex;
      justify-content: space-between;
    }
  }
  .farming-area {
    position: relative;
    padding: 10px 10px 25px 15px;
    background-color: #ffff;
    border-radius: 5px;
    .farming-area-title {
      display: flex;
      justify-content: space-between;
    }
  }
  /* .salary-average {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 25px 15px;
    background-color: #ffff;
    border-radius: 5px;
    .salary-average-title {
      display: flex;
      justify-content: space-between;
      .header {
        display: flex;
        justify-content: space-between;
        width: 75%;
        .filter {
          display: flex;
          padding-left: 20px;
          width: 55%;
        }
      }
    }
  } */
  .title {
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px 25px 15px;
    height: 10vh;
    background-color: #ffff;
    border-radius: 5px;
  }
`;
