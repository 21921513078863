import React from "react";
import styled from "styled-components";

function EducationLevelStatus({ educationLeveDetialData }) {
  return (
    <ul>
      <li>
        <BoxLocationCount>
          กำลังศึกษา{" "}
          <div style={{ paddingLeft: "25px" }}>
            {" "}
            {!!educationLeveDetialData?.education_status ? (
              <lu>
                <li>
                  ชาย{" "}{educationLeveDetialData?.education_status.Studying.men}{" "}
                  คน
                </li>
                <li>
                  หญิง{" "}
                   {educationLeveDetialData?.education_status.Studying.girl}{" "}คน
                </li>
                <li>
                  รวม{" "}{educationLeveDetialData?.education_status.Studying.sum}{" "}
                  คน
                </li>
              </lu>
            ) : (
              ""
            )}
          </div>
        </BoxLocationCount>
      </li>
      <li>
        <BoxLocationCount>
          สำเร็จการศึกษา{" "}
          {!!educationLeveDetialData?.education_status ? (
            <div style={{ paddingLeft: "25px" }}>
              {" "}
              {!!educationLeveDetialData?.education_status ? (
                <lu>
                  <li>
                    ชาย{" "}{educationLeveDetialData?.education_status.finish.men}{" "}
                    คน
                  </li>
                  <li>
                    หญิง{" "}
                    {educationLeveDetialData?.education_status.finish.girl}{" "}คน
                  </li>
                  <li>
                    รวม {educationLeveDetialData?.education_status.finish.sum}{" "}
                    คน
                  </li>
                </lu>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </BoxLocationCount>
      </li>
      <li>
        <BoxLocationCount>
          ไม่สำเร็จการศึกษา{" "}
          {!!educationLeveDetialData?.education_status ? (
            <div style={{ paddingLeft: "25px" }}>
              {" "}
              {!!educationLeveDetialData?.education_status ? (
                <lu>
                  <li>
                    ชาย{" "}{educationLeveDetialData?.education_status.unfinish.men}{" "}
                    คน
                  </li>
                  <li>
                    หญิง{" "}
                    {educationLeveDetialData?.education_status.unfinish.girl}{" "}คน
                  </li>
                  <li>
                    รวม{" "}{educationLeveDetialData?.education_status.unfinish.sum}{" "}
                    คน
                  </li>
                </lu>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </BoxLocationCount>
      </li>
    </ul>
  );
}
export default EducationLevelStatus;
const BoxLocationCount = styled.div`
  padding: 2px;
  font-size: 15px;
  width: 100%;
  .LocationCount {
    width: auto;
    height: 25%;
    overflow-y: auto;
    height: 25px;
    padding: 0px 2px 0 2px;
    font-size: 15px;
    color: darkblue;
    background-color: white;
    border-radius: 5px;
  }
`;
