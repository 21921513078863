import React from "react";
import axios from "axios";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { apiConfig } from "../../../../../config/api";
import EducationLavelFilter from "./educationLevelFilter/filter";
import EducationAgeRage from "./educationAgeRage/educationAgeRage";
import EducationLocation from "./educationLocation/educationLocation";
import EducationLevelStatus from "./educationLevelStatus/educationLevelStatus";
import EducationLevaelhistoryData from "./educationLevaelhistoryData/educationLevaelhistoryData";
// import EducationLevelTable from "./educationLevelTable/educationLevelTable";
// import { educationDetialMockData } from "../../../../mock/dashboard/personal/education";
/* import { animated, useTransition } from "react-spring";
import EducationTable from "./educationTable/educationTable"; */

function EducationLevelDetial({
  address,
  setEducationLevelId,
  educationLevelId,
  data,
  auth,
}) {
  const ageRageRef = React.useRef();
  const [educationLeveDetialData, setEducationLeveDetialData] =
    React.useState(null);
  const [educationSchool, setEducationSchool] = React.useState(null);
  async function getEducationLevelDetialData() {
    setEducationLeveDetialData(null);
    if (!address.tambol) return false;
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/get-education-detial/${address.provice}/${address.amphur}/${address.tambol}/${address.group}/${educationLevelId}`
      );
      const res2 = await axios.get(
        `${apiConfig.url}/api/get-education-school/${address.provice}/${address.amphur}/${address.tambol}/${address.group}/${educationLevelId}`
      );
      if (res.status === 200) {
        console.log("getEducationLevelDetialData", res.data);
        setEducationLeveDetialData(res.data);
      }
      if (res2.status === 200) {
        console.log("getEducationLevelDetialData", res2.data);
        setEducationSchool(res2.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getEducationLevelDetialData();
  }, [address.group, address.tambol, educationLevelId]);
  return (
    <MainContent>
      <div className="ContentDetialBox">
        <div className="select">
          <EducationLavelFilter
            address={address}
            educationLevelFilterData={data}
            educationLevelId={educationLevelId}
            setEducationLevelId={setEducationLevelId}
          />
        </div>
        <div className="ageRage">
          <div className="ageRagetitle">
            <span>
              จำนวนบุคคลที่ได้สำเร็จการศึกษาในระดับต่างๆ
              โดยแยกตามสถานที่ตั้งของสถาบันการศึกษา
            </span>
            {auth && (
              <Button
                onClick={() => ageRageRef.current.getPersonalInfoAllageRage()}
                size="sm"
              >
                รายชื่อ
              </Button>
            )}
          </div>
          <EducationAgeRage
            address={address}
            edulevel={educationLevelId}
            ref={ageRageRef}
            educationLeveDetialData={
              !!educationLeveDetialData?.agerange
                ? educationLeveDetialData.agerange
                : 0
            }
          />
        </div>
        {/* <div className="eduStatus">
          <div className="eduStatusTitle">สถานะทางการศึกษา</div>
          <EducationLevelStatus
            educationLeveDetialData={educationLeveDetialData}
          />
        </div> */}
        <div className="location">
          <div className="eduLocalTitle">
            <h5>
              จำนวนบุคคลในพื้นที่ {address.addressName}{" "}
              ที่ได้สำเร็จการศึกษาในระดับต่างๆ
              โดยแยกตามสถานที่ตั้งของสถาบันการศึกษา
            </h5>
          </div>
          <EducationLocation
            educationSchool={!!educationSchool && educationSchool}
          />
        </div>
        <div className="historicalData">
          <div className="historicalDataTitle">ข้อมูล 20 ปี ย้อนหลัง</div>
          <div className="historicalDataContent">
            <EducationLevaelhistoryData
              educationLeveDetialData={
                !!educationLeveDetialData?.educationHistory
                  ? educationLeveDetialData.educationHistory
                  : 0
              }
            />
          </div>
        </div>
      </div>
    </MainContent>
  );
}

export default EducationLevelDetial;
const MainContent = styled.div`
  display: grid;
  grid-gap: 10px;
  .ContentDetialBox {
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    grid-template-columns: 30.5% 17.5% 17.5% 30.5%;
    grid-template-areas:
      "select select select select"
      "ageRage ageRage ageRage location"
      "historicalData historicalData historicalData eduStatus";
    .select {
      grid-area: select;
      padding: 0px 0px 0px 10px;
    }
    .ageRage {
      position: relative;
      grid-area: ageRage;
      background-color: #eff8ff;
      border-radius: 5px;
      padding: 10px;
      .ageRagetitle {
        display: flex;
        justify-content: space-between;
      }
    }
    .historicalData {
      position: relative;
      padding: 10px;
      grid-area: historicalData;
      background-color: #eff8ff;
      border-radius: 5px;
      .historicalDataTitle {
        position: absolute;
      }
      .historicalDataContent {
        margin-top: 25px;
        height: 90%;
      }
    }
    .eduStatus {
      grid-area: eduStatus;
      background-color: #eff8ff;
      border-radius: 5px;
      padding: 10px;
      overflow-y: auto;
    }
    .location {
      grid-area: location;
      background-color: #eff8ff;
      border-radius: 5px;
      padding: 10px;
      height: 100%;
      overflow-y: auto;
    }
  }
`;
