import axios from 'axios';
import React from 'react'
import { Button, Form } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { ApiFetchFile } from '../../../../../config/api';
import { useAuthContextState } from '../../../../../context/auth/authContext/authContextState';
import { FetchInterface, Method } from '../../../../../enums/enum';
import LoadingSpin from '../../../../loadingAnimation/loadingSpin';

export default function ExcelFileDownload({address}) {
    const [excelFile,setExcelFile ] = React.useState({
      value:'',
      name:''
    })
    const [loading,setLoading] = React.useState(false)
    const { token } = useAuthContextState()

    function handlechangFilename(e) {
      setExcelFile({name:e.target.options[e.target.selectedIndex].text,value:e.target.value});
    }
    async function download() {
        setLoading(true)
        if (!address.tambol) return false;
        if(!excelFile.value) return false
        try {
            const res = await ApiFetchFile({
                [FetchInterface.PATH]: `auth/export/${excelFile.value}/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.TOKEN]: token.accessToken
              })
              if (res.status === 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${excelFile.name}.xlsx`);
                document.body.appendChild(link);
                link.click();
              }
              axios.defaults.responseType = 'application/json';
              setLoading(false)
        } catch (error) {
          axios.defaults.responseType = 'application/json';
            setLoading(false)
          console.log(error);
        }
      }

  return (
    <div className="files-download">
          <Form.Select size="sm" onChange={(e) => handlechangFilename(e)}>
          <option  hidden value="ดาวน์โหลดไฟล์" >
            ดาวน์โหลดไฟล์
            </option>
            <option name="ข้อมูลทั่วไป.xlsx" value={"general"} >ข้อมูลทั่วไป</option>
            <option name="ข้อมูลการติดต่อ.xlsx" value={"contact"}>ข้อมูลการติดต่อ</option>
            <option name="ข้อมูลอาชีพ.xlsx" value={"career"}>ข้อมูลอาชีพ</option>
            <option name="ข้อมูลพื้นที่ทำกิน.xlsx" value={"farming-area"}>ข้อมูลพื้นที่ทำกิน</option>
            <option name="ข้อมูลการบกพร่อง.xlsx" value={"disabled"}>ข้อมูลการบกพร่อง</option>
            <option name="ข้อมูลบทบาทหน้าที่ในชุมชน.xlsx" value={"role"}>ข้อมูลบทบาทหน้าที่ในชุมชน</option>
            <option name="ข้อมูลปราชญ์.xlsx" value={"sage"}>ข้อมูลปราชญ์</option>
            <option name="ข้อมูลการศึกษา.xlsx" value={"education"}>ข้อมูลการศึกษา</option>
            <option name="ข้อมูลประสบการณ์การทำงาน.xlsx" value={"work-experince"}>ข้อมูลประสบการณ์การทำงาน</option>
            <option name="ข้อมูลทักษะทั่วไป.xlsx" value={"skill"}>ข้อมูลทักษะทั่วไป</option>
            <option name="ข้อมูลทักษะด้านภาษา.xlsx" value={"language-skill"}>ข้อมูลทักษะด้านภาษา</option>
            <option name="ข้อมูลการฝึกอบรม.xlsx" value={"train"}>ข้อมูลการฝึกอบรม</option>
            <option name="ข้อมูลการได้รับสวัสดิการ.xlsx" value={"welfare"}>ข้อมูลการได้รับสวัสดิการ</option>
          </Form.Select>
          <Button size="sm" disabled={!address.tambol || loading || !excelFile.value} onClick={()=> download()}>
            {!!loading ? (
                <LoadingSpin />
            ):(
                 <AiOutlineDownload size={"20px"} /> 
            )}
          </Button>
        </div>
  )
}
