import React from "react";
import styled from "styled-components";
import { ApiFetch } from "../../../../../../config/api";
import { useAuthContextState } from "../../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../../enums/enum";
// import { careerMockData } from "../../../../../mock/dashboard/personal/career";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";

function OverviewTable({ data, address,auth }) {
  // const data = careerMockData.detial.dataTable
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  async function PersonalInfomation(education, ageRange, gender, career) {
    if (!education || !ageRange || !gender || !career) {
      return false;
    }
    setModalShow(true);
    const titleData = {
      education: education,
      ageRange: ageRange,
      gender: gender,
      career: career,
    };
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/career-personal-table-info/dataTable/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });
      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({
          header: {
            datasetTitle: "-",
            title: `${career} เพศ ${gender} ช่วงอายุ ${ageRange} ระดับการศึกษา ${education}`,
          },
          data: res.data,
        });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }
  return (
    <>
      <div style={{ height: "53vh", overflowY: "auto" }}>
        <CareerTable isData={!!data?.data}>
          <div className="table-head">
            <div className="table-title">
              {!!data?.title &&
                data.title.map((title, inx) => (
                  <span className="text-title" key={inx}>
                    {title}
                  </span>
                ))}
            </div>
          </div>
          <div className="table-body">
            <div className="table-content">
              {!!data?.data &&
                data.data.map((career, inx) => (
                  <Career key={inx}>
                    <div className="career-title">{career.careerTitle}</div>
                    <div className="gender-list">
                      {!!career?.genderList &&
                        career.genderList.map((gender, inx) => (
                          <Gender key={inx} genderTitle={gender.genderTitle}>
                            <div className="gender-title">
                              {gender.genderTitle}
                            </div>
                            <div className="ageRange-list">
                              {!!gender?.ageRangeList &&
                                gender.ageRangeList.map((ageRange, inx) => (
                                  <AgeRange key={inx} ageRangeTitle={ageRange.ageRangeTitle}>
                                    <div className="ageRange-title">
                                      {ageRange.ageRangeTitle}
                                    </div>
                                    <div className="education-list">
                                      {!!ageRange?.educationList &&
                                        ageRange.educationList.map(
                                          (education, inx) => (
                                            <EducationLevel auth={auth} key={inx} educationTitle={education.educationTitle}>
                                              <div className="education-title">
                                                {education.educationTitle}
                                              </div>
                                              <div
                                                className="count"
                                                onClick={() => auth && 
                                                  PersonalInfomation(
                                                    education.educationTitle,
                                                    ageRange.ageRangeTitle,
                                                    gender.genderTitle,
                                                    career.careerTitle
                                                  )
                                                }
                                              >
                                                <div className="count-title">
                                                  {education.count}
                                                </div>
                                                <div className="average-title">
                                                  {education.average}
                                                </div>
                                              </div>
                                            </EducationLevel>
                                          )
                                        )}
                                    </div>
                                  </AgeRange>
                                ))}
                            </div>
                          </Gender>
                        ))}
                    </div>
                  </Career>
                ))}
            </div>
          </div>
        </CareerTable>
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียด อาชีพ ${tableData.header.title}`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
}
export default OverviewTable;
const CareerTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-left: solid 1.6px #aaaaaa;
  border-right: solid 1.6px #aaaaaa;
  .table-head {
    top: 0;
    height: 30px;
    position: sticky;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #38afba;
    display: grid;
    .table-title {
      align-content: center;
      border-bottom: solid 1.6px #aaaaaa;

      display: grid;
      grid-template-columns: 25% 10% 16% 29% 10% 10%;
      .text-title {
        padding-left: 5px;
      }
    }
  }
  .table-body {
    display: flex;
    width: 100%;
    .table-content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;
const Career = styled.div`
  width: 100%;
  display: flex;
  .career-title {
    border-bottom: solid 1.6px #aaaaaa;
    padding-left: 5px;
    padding-top: 5px;
    width: 25%;
  }
  .gender-list {
    width: 75%;
  }
`;
const Gender = styled.div`
  display: flex;
  width: 100%;
  .gender-title {
    padding-left: 5px;
    padding-top: 5px;
    display: flex;
    border-left: ${({genderTitle}) => !!genderTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
    width: 13%;
  }
  .ageRange-list {
    display: flex;
    flex-direction: column;
    width: 87%;
  }
`;
const AgeRange = styled.div`
  width: 100%;
  display: flex;
  .ageRange-title {
    padding-left: 5px;
    padding-top: 5px;
    width: 25%;
    border-left: ${({ageRangeTitle}) => !!ageRangeTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .education-list {
    display: flex;
    flex-direction: column;
    width: 75%;
  }
`;
const EducationLevel = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  .education-title {
    padding-left: 5px;
    padding-top: 5px;
    width: 60%;
    border-left: ${({educationTitle}) => !!educationTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .count {
    display: flex;
    width: 40%;
    &:hover {
      ${({auth}) => !!auth && `cursor: pointer;background-color: #f0f0f0;`}
    }
    .count-title {
      padding-left: 5px;
      padding-top: 5px;
      width: 45%;
      border-left: solid 1.6px #aaaaaa;
      border-bottom: solid 1.6px #aaaaaa;
    }
    .average-title {
      padding-left: 5px;
      padding-top: 5px;
      width: 55%;
      border-left: solid 1.6px #aaaaaa;
      border-bottom: solid 1.6px #aaaaaa;
    }
  }
`;
