import React from "react";
import { ApiFetch } from "../../../../../config/api";
import { useAuthContextState } from "../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../enums/enum";
import BarChart from "../../../../barChart/barChart";
import PersonalTableDashboard from "../../../../personalTableDashboard/personalTableDashboard";

const EducatioLavelOverview = React.forwardRef(function EducatioLavelOverview(
  { data, address },
  ref
) {
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  React.useImperativeHandle(ref, () => ({
    async getPersonalInfoAllCareerAndOverview() {
      setModalShow(true);
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/education-personal-table-info/overview/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: null,
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: { datasetTitle: "ชายและหญิง", title: "ทุกระดับการศึกษา" },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    },
  }));
  return (
    <>
      <div style={{ height: "46vh" }}>
        <BarChart
          data={data}
          setTableDataForFilter={setTableDataForFilter}
          address={address}
          section={"overview"}
          pathname={"education-personal-table-info"}
          setModalShow={setModalShow}
          setTableData={setTableData}
        />
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียดระดับการศึกษา ${tableData.header.title}`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
});

export default EducatioLavelOverview;
