export const signIn = (dispatch) => 
    async (payload) => {
        const token = {
            accessToken:payload.token.accessToken || "",
            expiresIn:payload.token.expiresIn || "",
        }
        const userInfo = payload.userInfo;
        localStorage.setItem("token",JSON.stringify(token))
        localStorage.setItem("userInfo",JSON.stringify(userInfo))
        dispatch({
            type:"LOGIN",
            payload:{ token, userInfo }
        })
    }

export const signOut =(dispatch)=> async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')

    dispatch({
        type:"LOGOUT",
        payload:{
            token: {
                accesstoken: "",
                refreshToken:"",
                expiresIn:"",
              },
              userInfo: {
                userId: "",
                image: "",
                name: "",
                lastname: "",
                role: "", 
                address:""           
              },
        }
    })
}