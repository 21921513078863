import React from "react";
import { Col, Form } from "react-bootstrap";

function Academy({
  setData,
  data,
  subStructureResourceHandleChange,
  main_index,
  sub_index,
  item,
  isEdit
}) {
  console.log("Academy",data);
  return (
    <>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>
            {!isEdit && <label
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                let updateData = { ...data };
                updateData.third.structure_resource[main_index].data.splice(
                  sub_index,
                  1
                );
                setData(updateData);
              }}
            >
              X
            </label>}{" "}
            {sub_index + 1}. ชื่อสถานที่
          </Form.Label>
          <Form.Control
            type="text"
            name="resource_name"
            value={
              item.resource_name
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ประเภทสถานศึกษา</Form.Label>

          <Form.Select
            aria-label="Default select example"
            value={
              item.resource_type
            }
            name="resource_type"
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          >
            <option></option>
            <option value="สถาบันปอเนาะ">สถาบันปอเนาะ</option>
            <option value="ศูนย์สอนอัลกุรอานกีรออัตตี">
              ศูนย์สอนอัลกุรอานกีรออัตตี
            </option>
            <option value="ศูนย์การศึกษานอกโรงเรียน">
              ศูนย์การศึกษานอกโรงเรียน
            </option>
            <option value="ศูนย์การส่งเสริมทักษะอาชีพ">
              ศูนย์การส่งเสริมทักษะอาชีพ
            </option>
            <option value="โรงเรียนเอกชนสอนศาสนาควบคู่สามัญ">
              โรงเรียนเอกชนสอนศาสนาควบคู่สามัญ
            </option>
            <option value="โรงเรียนเอกชนสามัญ">โรงเรียนเอกชนสามัญ</option>
            <option value="โรงเรียนรัฐ">โรงเรียนรัฐ</option>
            <option value="วิทยาลัย">วิทยาลัย</option>
            <option value="มหาวิทยาลัย">มหาวิทยาลัย</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ลักษณะการสอน</Form.Label>
          <div className="d-flex justify-content-around">
            {[
              "อิบตีดาอียะห์",
              "มูตาวัซซีเตาะห์",
              "ซานาวียะห์",
              "อนุบาล",
              "ประถม",
              "มัธยมตอนต้น",
              "มัธยมตอนปลาย",
              "ปวส.",
              "ปวช",
              "อุดมศึกษาหรือเทียบเท่า",
            ].map((name, index) => (
              <div key={index} className="mb-3">
                <Form.Check
                  onChange={() => {
                    let updateData = { ...data };
                    let find = updateData.third.structure_resource[
                      main_index
                    ].data[sub_index].schoolClass.find(
                      (item) => item.name === name
                    );
                    console.log(find);
                    if (find) {
                      updateData.third.structure_resource[main_index].data[
                        sub_index
                      ].schoolClass = updateData.third.structure_resource[
                        main_index
                      ].data[sub_index].schoolClass.filter(
                        (item) => item.name !== name
                      );
                    } else {
                      updateData.third.structure_resource[main_index].data[
                        sub_index
                      ].schoolClass.push({ name: name });
                    }
                    setData(updateData);
                  }}
                  checked={item.schoolClass.some((school) =>school.name === name)}
                  type={"checkbox"}
                  id={`default-${name}`}
                  label={`${name}`}
                />
              </div>
            ))}
          </div>
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>รายละเอียดสถานที่ (ถ้ามี)</Form.Label>
          <Form.Control
            type="text"
            name="detail"
            value={
              data.third.structure_resource[main_index].data[sub_index].detail
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ปีที่ก่อตั้งขึ้น (พ.ศ.)</Form.Label>
          <Form.Control
            type="number"
            name="resource_year"
            min="1000"
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .resource_year
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ตำแหน่งที่ตั้ง</Form.Label>
          <Form.Control
            type="text"
            name="resource_address"
            placeholder="รายละเอียดคร่าว ๆ"
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .resource_address
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ละติจูด</Form.Label>
          <Form.Control
            type="number"
            name="latitude"
            placeholder=""
            value={
              data.third.structure_resource[main_index].data[sub_index].latitude
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ลองจิจูด</Form.Label>
          <Form.Control
            type="number"
            name="longitude"
            placeholder=""
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .longitude
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
    </>
  );
}

export default Academy;
