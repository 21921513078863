import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import LoadingPage from "../../../loadingPage/loadingPage";
import PersonalDetial from "./pesonalDetial/pesonalDetial";
import Swal from "sweetalert2";
import { PersonalContext } from "../../../../context/personal";
import { useAuthContextState } from "../../../../context/auth/authContext/authContextState";
import axios from "axios";
import { apiConfig } from "../../../../config/api";
export default function ModalDashBoardTable({
  modalShowPersonalDetial,
  setModalShowPersonalDetial,
  personalId,
  isEdit = false,
}) {
  function MyVerticallyCenteredModal(props) {
    const { data, setData, setPersonalId } = React.useContext(PersonalContext);
    const [loading, setLoading] = React.useState(true);
    const [textSubmit, setTextSubmit] = React.useState("บันทึกข้อมูล");
    const [radioValue, setRadioValue] = React.useState("1");
    const { userInfo } = useAuthContextState();

    const onSubmitForm = async (e) => {
      let values = {};
      setTextSubmit("กำลังบันทึกข้อมูล...");
      try {
        if (radioValue == "1") {
          values = { personalId: personalId, first: data.first };
        }
        if (radioValue == "2") {
          values = {
            parentId: personalId,
            second: data.second,
            address: data.first.address,
          };
        }
        if (radioValue == "3") {
          values = { parentId: personalId, third: data.third };
        }
        if (radioValue == "4") {
          values = { parentId: personalId, fourth: data.fourth };
        }
        if (radioValue == "5") {
          values = { parentId: personalId, fifth: data.fifth };
        }
        if (radioValue == "6") {
          values = { parentId: personalId, sixth: data.sixth };
        }
        values.dataCollector = userInfo.userId;
        console.log(values);
        let response = await axios.post(`${apiConfig.url}/api/submit_form/personal`, values);
        console.log(response);
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
        });
        setTextSubmit("บันทึกข้อมูล");
      } catch (e) {
        setTextSubmit("บันทึกข้อมูล");
        Swal.fire({
          icon: "error",
          title: "บันทึกข้อมูลสำเร็จไม่สำเร็จ",
          text: e.message,
          showConfirmButton: true,
        });
        setPersonalId(personalId);
      }
    };

    const closeModal = () => {
      setData({
        first: {
          information: {
            title: "",
            first_name: "",
            last_name: "",
            date_birth: "",
            religion: "",
            main_nation: "",
            gender: "",
            marital_status: "",
            father_id: "",
            father_name: "",
            father_surname: "",
            mothor_id: "",
            mother_name: "",
            mothor_surname: "",
            tell: "",
            email: "",
          },
          address: {
            house_number: "",
            address_no: "",
            address_mooban: "",
            address_group: "",
            tambol_id: null,
            amphur_id: null,
            province_id: null,
          },
          occupation: {
            main: {
              job: "",
              place: "",
              salary: 0,
            },
            additional: {
              status: false,
              data: [],
            },
          },
          garden: {
            have: false,
            data: [],
          },
          physicalImpairment: {
            body: {
              status: false,
              name: "",
              date: "",
              description: "",
              hospitalName: "",
              hospitalId: "",
            },
            other: {
              status: false,
              name: "",
              date: "",
              description: "",
              hospitalId: "",
            },
          },
          communityRole: [],
          sage: {
            have: false,
            data: [],
          },
          saved: false,
        },
        second: {
          education: [
            {
              id: 0,
              level: null,
              start: null,
              end: null,
              status: null,
              schoolName: null,
            },
          ],
          saved: false,
        },
        third: {
          experience: [
            {
              id: 0,
              occupation: null,
              job_start: null,
              job_end: null,
              job_detail: null,
              bussinessId: null,
              leaveReason: null,
            },
          ],
          saved: false,
        },
        fourth: {
          train: [],
          language: [],
          skill: [],
          saved: false,
        },
        fifth: {
          family: [],
          saved: false,
        },
        sixth: {
          now: [],
          before: [],
          other: [],
          saved: false,
        },
      });
      props.onHide();
    };

    return (
      <Modal
        {...props}
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            ข้อมูลส่วนบุคคล
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <LoadingPage animatioColor={"darkblue"} loading={loading} />

          <PersonalDetial
            isEdit={isEdit}
            setLoading={setLoading}
            personalData={personalId}
            radioValue={radioValue}
            setRadioValue={setRadioValue}
          />
        </Modal.Body>
        <Modal.Footer>
          {!!isEdit && (
            <Button
              variant="success"
              onClick={onSubmitForm}
              type="submit"
              disabled={textSubmit !== "บันทึกข้อมูล" ? true : false}
            >
              {textSubmit}
            </Button>
          )}

          <Button variant="danger" onClick={closeModal}>
            ปิดหน้าต่าง
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <MyVerticallyCenteredModal
      show={modalShowPersonalDetial}
      onHide={() => setModalShowPersonalDetial(false)}
    />
  );
}
