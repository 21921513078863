import React from 'react'
import axios from 'axios';
import { useAlert } from 'react-alert'
import { PersonalContext } from "../../context/personal";
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { apiConfig } from '../../config/api';

function CreateSchool({ createSchool, setCreateSchool, getSchools}) {
    const alert = useAlert()
    const { data, setData } = React.useContext(PersonalContext);
    const [school, setSchool] = React.useState({})

    //state ข้อมูลประเภทสถานศึกษา
    const [ eduType, setEduType ] = React.useState([])

    //state ข้อมูลจังหวัด อำเภอ ตำบล
    const [province, setProvince] = React.useState([])
    const [amphur, setAmphur] = React.useState([])
    const [tambol, setTambol] = React.useState([])

    //ดึงค่าจังหวัดจากหลังบ้าน
    const getProvince = async () => {
        const result = await axios(apiConfig.url + '/api/provinces');
        setProvince(result.data)
    }

    //ดึงค่าอำเภอจากหลังบ้าน
    const getAmphur = async (id_province) => {
        const result = await axios(apiConfig.url + '/api/amphurs/' + id_province);
        setAmphur(result.data)
    }

    //ดึงค่าตำบลจากหลังบ้าน
    const getTambol = async (id_amphur) => {
        const result = await axios(apiConfig.url + '/api/tambols/' + id_amphur);
        setTambol(result.data)
    }

    const getEduType = async ()=>{
        const result = await axios(apiConfig.url + '/api/edutype');
        setEduType(result.data)
    }
    React.useEffect(() => {
        getProvince()
        getEduType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const provinceChange = e => {
        setAmphur([])
        setTambol([])
        if (e.target.value !== '') {
            handleChange(e)
            getAmphur(e.target.value)
        }
    }

    const amphurChange = e => {
        if (e.target.value !== '') {
            handleChange(e)
            getTambol(e.target.value)
        }
    }

    const tambolChange = e => {
        if (e.target.value !== '') {
            handleChange(e)
        }
    }

    const handleChange= (e) => {
        let updateData = { ...school}
        updateData={...updateData,[e.target.name]:e.target.value}
        setSchool(updateData)
    }

    const submitData = async ()=>{
        try{
           /*  let result = await axios.post(api + 'edulocation/create', school) */
            let updateData = {...data}
            updateData.second[createSchool.index].schoolName = school.school_name
            setData(updateData)
            setCreateSchool({ ...createSchool, status: !createSchool.status })
            getSchools()
            alert.show('บันทึกข้อมูลสถานศึกษาสำเร็จ!', {
                type: 'success',
            })
        }catch(e){
            console.log(e)
        }
    }

  return (
      <>
          <Modal
              show={createSchool.status}
              onHide={() => setCreateSchool({ ...createSchool, status: !createSchool.status })}
              backdrop="static"
              keyboard={false}
              centered
              size={'lg'}
          >
              <Modal.Header closeButton>
                  <Modal.Title>เพิ่มข้อมูลสถานศึกษา</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form.Group className="mb-3" >
                      <Form.Label>ชื่อสถานศึกษา</Form.Label>
                      <Form.Control type="text" name="school_name" onChange={(e) => handleChange(e)}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                      <Form.Label>รหัสสถานศึกษา</Form.Label>
                      <Form.Control type="text" name="key" onChange={(e) => handleChange(e)} />
                  </Form.Group>
                  <Row>
                      <p>ที่อยู่</p>
                      <hr></hr>
                      <Col md={4}>
                          <Form.Group className="mb-3" >
                              <Form.Label>บ้านเลขที่</Form.Label>
                              <Form.Control type="text" rows={3} name="address_no" onChange={(e) => handleChange(e)} />
                          </Form.Group>
                      </Col>
                      <Col md={4}>
                          <Form.Group className="mb-3" >
                              <Form.Label>หมู่/ซอย</Form.Label>
                              <Form.Control type="text" rows={3} name="address_group" onChange={(e) => handleChange(e)} />
                          </Form.Group>
                      </Col>
                      <Col md={4}>
                          <Form.Group className="mb-3" >
                              <Form.Label>ถนน</Form.Label>
                              <Form.Control type="text" rows={3} name="address_road" onChange={(e) => handleChange(e)} />
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col md={4}> <Form.Label>จังหวัด</Form.Label>
                          <Form.Select aria-label="Default select example" name="province" onChange={(e) => provinceChange(e)}>
                              <option></option>
                              {province.map(province => {
                                  return (
                                      <option key={province.value} value={province.value}>{province.text}</option>
                                  )
                              })}
                          </Form.Select></Col>
                      <Col md={4}>
                          <Form.Label>อำเภอ</Form.Label>
                          <Form.Select aria-label="Default select example" name="amphur" onChange={(e) => amphurChange(e)} disabled={amphur.length === 0 ? true : false}>
                              <option></option>
                              {amphur.map(amphur => {
                                  return (
                                      <option key={amphur.value} value={amphur.value}>{amphur.text}</option>
                                  )
                              })}
                          </Form.Select>
                      </Col>
                      <Col md={4}>
                          <Form.Label>ตำบล</Form.Label>
                          <Form.Select aria-label="Default select example" name="tambol" onChange={(e) => tambolChange(e)} disabled={tambol.length === 0 ? true : false}>
                              <option></option>
                              {tambol.map(tambol => {
                                  return (
                                      <option key={tambol.value} value={tambol.value}>{tambol.text}</option>
                                  )
                              })}
                          </Form.Select>
                      </Col>
                      
                  </Row>
                  <hr></hr>
                  <Form.Group className="mb-3 mt-3" >
                      <Form.Label>เบอร์โทรศัพท์</Form.Label>
                      <Form.Control type="number" name="tell" onChange={(e) => handleChange(e)}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                      <Form.Label>เว็บไซต์</Form.Label>
                      <Form.Control type="text" name="website" onChange={(e) => handleChange(e)}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                      <Form.Label>ประเภทการศึกษา</Form.Label>
                      <Form.Select aria-label="Default select example" name="edutype_id" onChange={(e) => handleChange(e)} >
                          <option></option>
                          {eduType.map((e,index) =>{
                              return(
                                  <option key={index} value={e.id}>{e.edutype_name}</option>
                              )
                          })}
                      </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                      <Form.Label>ระบบสถานศึกษา</Form.Label>
                      <Form.Check
                          type={'radio'}
                          label={`ในระบบ`}
                          value="ในระบบ"
                          name="system"
                          id={`system`}
                          onChange={(e) => handleChange(e)}
                      />
                      <Form.Check
                          type={'radio'}
                          label={`นอกระบบ`}
                          value="นอกระบบ"
                          name="system"
                          id={`system`}
                          onChange={(e) => handleChange(e)}
                      />
                  </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={() => submitData()}>เพิ่มข้อมูล</Button>
              </Modal.Footer>
          </Modal>
      </>
  )
}

export default CreateSchool