import React from 'react'
import axios from 'axios';
import WelfareNow from '../welfare/Now'
import ModalOrg from '../org/createOrg'
import WelfareOther from '../welfare/Other'
import { Container } from 'react-bootstrap'
import WelfareBefore from '../welfare/Before'
import { PersonalContext } from "../../../context/personal";
import { apiConfig } from '../../../config/api';

function SectionSixth({ showOnlyProps = false, personalIdProps }) {
  const { data, setData } = React.useContext(PersonalContext);
  const { personalId, setPersonalId } = React.useContext(PersonalContext);
  const [welfareType, setWelfareType] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const [createOrg, setCreateOrg] = React.useState({
    status: false,
    section: null,
    index: null,
  });
  //ดึงข้อมูลประเภทสวัสดิก่าร
  const getWelfareType = async () => {
    let response = await axios.get(apiConfig.url + "/api/welfaretype");
    setWelfareType(response.data);
  };

  //ดึงข้อมูลหน่วยงาน
  const getOrg = async () => {
    let response = await axios.get(apiConfig.url + "/api/welfareorg");
    setOrg(response.data);
  };

  const getInfo = async () => {
    let result = await axios.get(apiConfig.url + "/api/personal/get_welfare/" + personalId);
    if (
      result.data.now.length > 0 ||
      result.data.before.length > 0 ||
      result.data.other.length > 0
    ) {
      let updateData = { ...data };
      if (result.data.now.length > 0) updateData.sixth.now = result.data.now;
      if (result.data.before.length > 0)
        updateData.sixth.before = result.data.before;
      if (result.data.other.length > 0)
        updateData.sixth.other = result.data.other;
      setData(updateData);
    }
  };

  React.useEffect(() => {
    getWelfareType();
    getOrg();
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (personalIdProps) {
      setPersonalId(personalIdProps);
      getInfo();
    }
  }, []);

  return (
    <Container>
      {/* Modal สร้างข้อมูลองค์กรณ์ */}
      <ModalOrg
        createOrg={createOrg}
        setCreateOrg={setCreateOrg}
        getOrg={getOrg}
        org={org}
        welfareType={welfareType}
      />
      {/* Model check box */}
      <WelfareNow
        showOnlyProps={showOnlyProps}
        createOrg={createOrg}
        setCreateOrg={setCreateOrg}
        org={org}
        welfareType={welfareType}
      />
      {/* Table สวัสดิการที่เคยได้รับจากภาครัฐ */}
      <WelfareBefore
        showOnlyProps={showOnlyProps}
        welfareType={welfareType}
        org={org}
        setCreateOrg={setCreateOrg}
      />
      {/* Table เงินหรือทุนที่เคยได้รับการสนับสนุนจากหน่วยงานอื่นๆ */}
      <WelfareOther
        showOnlyProps={showOnlyProps}
        welfareType={welfareType}
        org={org}
        setCreateOrg={setCreateOrg}
      />
      {/* <Row>
        <Col md={6}>
          <div className="d-flex justify-content-start">
            <div className="btn btn-info"
              style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
              onClick={() => setPage(page - 1)}>
              กลับ
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end">
            <div className="btn btn-success"
              style={{ marginTop: '30px', marginBottom: '30px', width: '100%'  }}
              onClick={() => onSubmitForm()}
            >
              บันทึกข้อมูล
            </div>
          </div>
        </Col>

      </Row> */}
    </Container>
  );
}

export default SectionSixth