import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SideNavicationItem from "./sideNavicationItem/sideNavicationItem";

export default function SideNavication({
  navicationItems,
  show,
  setShow,
  setDashboardOfPageRender,
  dashboardOfPageRender,
}) {
  const { push } = useHistory();
  const handleShow = () => setShow(!show);
  return (
    <SideBar show={{ show }}>
      <div className="togleButton">
        <button variant="primary" onClick={handleShow}>
          <AiFillCaretRight />
        </button>
      </div>
      <button className="home-icon" onClick={() => push("/")}>
        <img
          src={require("../../../../eco.png")}
          alt="home"
          style={{ width: "100%" }}
        />
      </button>
      <div className="sidebar-item-box">
        {navicationItems.map((item, idx) => (
          <SideNavicationItem
            key={idx}
            setDashboardOfPageRender={setDashboardOfPageRender}
            dashboardOfPageRender={dashboardOfPageRender}
            icon={item.icon}
            name={item.name}
            show={show}
          />
        ))}
      </div>
    </SideBar>
  );
}
const SideBar = styled.div`
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  height: 100vh;
  width: ${({ show }) => (show.show ? "4vw" : "10vw")};
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  .togleButton {
    display: flex;
    width: 100%;
    height: 5%;
    button {
      width: 100%;
      border: none;
      background-color: transparent;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        background-color: #d7d7d73e;
      }
    }
  }
  .home-icon {
    display: flex;
    align-items: center;
    padding: 5px;
    border: none;
    background-color: #38afba;
    width: 100%;
    height: 10%;
    &:hover {
      cursor: pointer;
    }
  }
  .sidebar-item-box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 20px;
  }
`;
