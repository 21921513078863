import React, { useState, useContext } from "react";
import { Spinner, Form, Row, Col, Container, Button } from "react-bootstrap";
import SectionOne from "../component/CommunityForm/insertComponent/sectionOne";
import SectionSecond from "../component/CommunityForm/insertComponent/sectionSecond";
import SectionFourth from "../component/CommunityForm/insertComponent/sectionFourth";
import SectionFifth from "../component/CommunityForm/insertComponent/sectionFifth";
import SectionSixth from "../component/CommunityForm/insertComponent/sectionSixth";
import ConsentForm from "../component/consentForm/ThirdForm";
import { CommunityContext } from "../context/community";
import Stepper from "../component/stepper/Stepper";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { section } from "../enums/enum";
import SectionThird from "../component/CommunityForm/insertComponent/sectionThird/sectionThird";
import { apiConfig } from "../config/api";

function Community() {
  const [page, setPage] = useState(0);
  const { dataReferent, setDataReferent, parentId, setParentId } =
    useContext(CommunityContext);
  const [textSubmit, setTextSubmit] = useState("บันทึกข้อมูล");
  const [data, setData] = useState({
    first: {
      saved: false,
      community: {
        community_name: "",
        community_area: 0,
        community_history: "",
        area: 0,
        house: 0,
        population: 0,
        tambol_id: null,
        amphur_id: null,
        province_id: null,
        tambol: [],
        amphur: [],
        province: [],
        group: [],
      },
      leader: {
        title: "",
        first_name: "",
        last_name: "",
        tel_no: "",
        leader_start: "",
        leader_end: "",
      },
    },
    second: {
      saved: false,
      river: [],
      tree: [],
    },
    third: {
      saved: false,
      structure_resource: [],
      weast_resource: [],
      utilities_resource: [],
    },
    fourth: {
      training: [],
      saved: false,
    },
    fifth: {
      social: [],
      saved: false,
    },
    sixth: {
      financial: [],
      saved: false,
    },
  });
  const title = [
    "ข้อมูลทั่วไปเกี่ยวกับชุมชน",
    "ข้อมูลทรัพยากรธรรมชาติ",
    "ข้อมูลทรัพยากรโครงสร้าง",
    "การพัฒนาทรัพยากรในด้านอบรมและส่งเสริมอาชีพ",
    "ข้อมูลทรัพยากรสังคม",
    "ข้อมูลทรัพยากรการเงิน",
  ];
  const [noneData, setNoneData] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false,
  });
  const history = useHistory();

  const changPageFromStepper = (e, index) => {
    if (!(e === "ข้อมูลทั่วไป")) {
      setPage(e);
    }
  };

  const acceptConcentForm = (e, user) => {
    let updateData = { ...data };
    let today = new Date();
    updateData.consentForm = {
      accepter_title: user.accepter_title,
      accepter_firstname: user.accepter_firstname,
      accepter_lastname: user.accepter_lastname,
      accept: e,
      date:
        today.getFullYear() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getDate(),
      time:
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
    };
    setData(updateData);
  };

  const PageShow = (index,data,setData) => {
    setDataReferent(section[index]);
    switch (index) {
      case 0:
        return <SectionOne data={data} setData={setData} />;
      case 1:
        return <SectionSecond data={data} setData={setData} />;
      case 2:
        return <SectionThird data={data} setData={setData} />;
      case 3:
        return <SectionFourth data={data} setData={setData} />;
      case 4:
        return <SectionFifth data={data} setData={setData} />;
      case 5:
        return <SectionSixth data={data} setData={setData} />;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let values = {};
    if (dataReferent === section[0]) {
      values = {
        [`${dataReferent}`]: data[`${dataReferent}`],
        consentForm: data["consentForm"],
      };
    } else {
      values = {
        parentId: parentId,
        [`${dataReferent}`]: data[`${dataReferent}`],
      };
    }
    delete values[`${dataReferent}`]["saved"];
    try {
      setTextSubmit("กำลังบันทึกข้อมูล");
      console.log(values);
      let result = await axios.post(apiConfig.url + "/api/submit_form/community", values);
      console.log("response", result);
      if (!result) throw new Error("เกิดข้อผิดพลาดกับการเชื่อมต่อ");
      Swal.fire({
        icon: "success",
        title: "บันทึกข้อมูลสำเร็จ",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (page === title.length - 1) {
          /* setData({
            first: {
              saved: false,
              community: {
                community_name: "",
                community_area: 0,
                community_history: "",
                area: 0,
                house: 0,
                population: 0,
                tambol_id: null,
                amphur_id: null,
                province_id: null,
                tambol: [],
                amphur: [],
                province: [],
              },
              leader: {
                title: "",
                first_name: "",
                last_name: "",
                tel_no: "",
                leader_start: "",
                leader_end: "",
              },
            },
            second: {
              saved: false,
              river: [],
              tree: [],
            },
            third: {
              saved: false,
              structure_resource: [],
              weast_resource: [],
              utilities_resource: [],
            },
            fourth: {
              training: [],
              saved: false,
            },
            fifth: {
              social: [],
              saved: false,
            },
            sixth: {
              financial: [],
              saved: false,
            },
          }); */
          history.push("/");
        }
        if (page === 0 && result.data.id) {
          setPage(1);
          setParentId(result.data.id);
        }
        setTextSubmit("บันทึกข้อมูล");
        setData({
          ...data,
          [`${dataReferent}`]: { ...data[`${dataReferent}`], saved: true },
        });
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "บันทึกข้อมูลสำเร็จไม่สำเร็จ",
        text: error.message,
        showConfirmButton: true,
      }).then(() => console.log(error));
      setTextSubmit("บันทึกข้อมูล");
    }
  };

  return (
    <Form className="mt-4" onSubmit={(e) => handleSubmit(e)}>
      {data.consentForm ? (
        <>
          {!!page && <Stepper page={page} setPage={setPage} list={title} />}
          <Container style={{ minHeight: "80vh", maxHeight: "auto" }}>
            <div
              style={{
                minHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {PageShow(page, data, setData)}
              <div>
                <Row>
                  {page > 0 && (
                    <Col md={12}>
                      <div className="d-flex justify-content-start">
                        <Form.Check
                          onChange={() =>
                            setNoneData({
                              ...noneData,
                              [`${dataReferent}`]: !noneData[`${dataReferent}`],
                            })
                          }
                          type="checkbox"
                          label="ไม่มีข้อมูลส่วนนี้"
                          disabled={data[`${dataReferent}`].saved}
                          checked={noneData[`${dataReferent}`]}
                          value={noneData[`${dataReferent}`]}
                          name={dataReferent}
                          id={dataReferent}
                        />
                      </div>
                    </Col>
                  )}
                  <Col md={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="success"
                        disabled={
                          data[`${dataReferent}`].saved ||
                          noneData[`${dataReferent}`]
                        }
                        style={{
                          marginTop: "30px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                        type="submit"
                      >
                        {textSubmit}
                      </Button>
                    </div>
                  </Col>
                  {page > 0 && (
                    <>
                      <Col md={6} xs={6}>
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="info"
                            disabled={page === 0}
                            style={{
                              marginTop: "10px",
                              marginBottom: "20px",
                              width: "100%",
                            }}
                            onClick={() => setPage(page - 1)}
                          >
                            ย้อนกลับ
                          </Button>
                        </div>
                      </Col>
                      <Col md={6} xs={6}>
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="info"
                            disabled={
                              title.length - 1 === page ||
                              (!data[`${dataReferent}`].saved &&
                                !noneData[`${dataReferent}`])
                            }
                            style={{
                              marginTop: "10px",
                              marginBottom: "20px",
                              width: "100%",
                            }}
                            onClick={() => setPage(page + 1)}
                          >
                            ถัดไป
                          </Button>
                        </div>
                      </Col>
                    </>
                  )}
                  {page === 0 && data[`${dataReferent}`].saved && (
                    <Col md={12} xs={6}>
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="info"
                          disabled={
                            title.length - 1 === page ||
                            (!data[`${dataReferent}`].saved &&
                              !noneData[`${dataReferent}`])
                          }
                          style={{
                            marginTop: "10px",
                            marginBottom: "20px",
                            width: "100%",
                          }}
                          onClick={() => setPage(page + 1)}
                        >
                          ถัดไป
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </Container>
        </>
      ) : (
        <ConsentForm acceptConcentForm={acceptConcentForm} />
      )}
    </Form>
  );
}

export default Community;
