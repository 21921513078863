import React from 'react'
import Entrepreneurs from '../../../page/admin/entrepreneurs/entrepreneurs'
import styled from 'styled-components'

function EntrepreneurDashboard() {
  return (
    <Main >
      <Entrepreneurs />
    </Main>
  )
}

export default EntrepreneurDashboard
const Main = styled.div`
 margin-top: 10px;
  width: 100%;
`