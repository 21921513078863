import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import DonutBox from '../../../donutChart/donutBox/Donut';
ChartJS.register(ArcElement, Tooltip, Legend);
function Chart({ occupation }) {
  console.log('occupation',occupation);

  return (
      <DonutBox
        cutout={90}
        chartData={occupation}
        pathname={null}
        section={null}
        getDataTable={null}
      />
  );
}

export default Chart