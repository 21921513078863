import React from "react";
import axios from "axios";
import { Form, Button, Row, Col } from "react-bootstrap";
import { apiConfig } from "../../../config/api";
function SectionSixth({isEdit, data, setData }) {
  console.log("SectionSixth",data);
  const [province, setProvince] = React.useState([]);

  //ดึงค่าจังหวัดจากหลังบ้าน
  const getProvince = async () => {
    const result = await axios(apiConfig.url + "/api/provinces");
    setProvince(result.data);
  };

  const addNewBudgetResource = () => {
    let updateData = { ...data };
    updateData.sixth.financial.push({
      resource_year: "",
      org_name: "",
      resource_address: "",
      org_type: "",
      org_province: "",
      org_coutry: "",
      resource_money: [],
      resource_money_term: "",
      title: "",
      first_name: "",
      lastname_name: "",
      tel_no: "",
      email: "",
    });
    setData(updateData);
  };

  const removeBudgetResource = (index) => {
    let updateData = { ...data };
    updateData.sixth.financial.splice(index, 1);
    setData(updateData);
  };

  const handleChange = (e, index) => {
    let updateData = { ...data };
    updateData.sixth.financial[index][e.target.name] = e.target.value;
    setData(updateData);
  };

  //จะทำเมื่อเริ่มเปิดหน้านี้
  React.useEffect(() => {
    getProvince();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <h5 className="mb-4">ข้อมูลทรัพยากรการเงิน</h5>
      {!!data.sixth && data.sixth.financial.map((item, index) => {
        return (
          <Row key={index} className="mt-3">
            <Row>
              <p>
                {!isEdit && <label
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeBudgetResource(index)}
                >
                  X
                </label>}{" "}
                {index + 1}.
              </p>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>ชื่อองค์กร, ชื่อกลุ่ม</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.org_name}
                    name="org_name"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <p className="mb-2">ที่ตั้งองค์กรณ</p>
              <Col md={4}>
                <div
                  className="mb-3 d-flex justify-content-around"
                  controlId=""
                >
                  <Form.Check
                    checked={item.org_address === "องค์กรภายในประเทศ"}
                    type={"radio"}
                    name={`resource_name-${index}`}
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.sixth.financial[index].org_address =
                        "องค์กรภายในประเทศ";
                      setData(updateData);
                    }}
                    id={`default-checkbox`}
                    label={`องค์กรภายในประเทศ`}
                  />
                  <Form.Check
                    checked={item.org_address === "องค์กรต่างประเทศ"}
                    type={"radio"}
                    name={`resource_name-${index}`}
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.sixth.financial[index].org_address =
                        "องค์กรต่างประเทศ";
                      setData(updateData);
                    }}
                    id={`default-checkbox`}
                    label={`องค์กรต่างประเทศ`}
                  />
                </div>
              </Col>
              {item.org_address === "องค์กรภายในประเทศ" && (
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>จังหวัด</Form.Label>
                    <Form.Select
                      value={item.org_province}
                      name="org_province"
                      onChange={(e) => handleChange(e, index)}
                    >
                      <option></option>
                      {province.map((item) => {
                        return (
                          <option key={item.value} id={item.value} value={item.value}>
                            {item.text}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              {item.org_address === "องค์กรต่างประเทศ" && (
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>ประเทศ</Form.Label>
                    <Form.Control
                      type="text"
                      value={item.org_country}
                      name="org_country"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <p className="mb-2">ประเภทองค์กรณ์</p>
              <Col md={12}>
                <div className="mb-3 " controlId="">
                  <Form.Check
                    checked={item.org_type === "หน่วยงานภาครัฐ"}
                    type={"radio"}
                    name={`org_type-${index}`}
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.sixth.financial[index].org_type =
                        "หน่วยงานภาครัฐ";
                      setData(updateData);
                    }}
                    id={`default-checkbox`}
                    label={`หน่วยงานภาครัฐ`}
                  />
                  <Form.Check
                    checked={item.org_type === "หน่วยงานเอกชน"}
                    type={"radio"}
                    name={`org_type-${index}`}
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.sixth.financial[index].org_type =
                        "หน่วยงานเอกชน";
                      setData(updateData);
                    }}
                    id={`default-radio`}
                    label={`หน่วยงานเอกชน`}
                  />
                  <Form.Check
                    checked={item.org_type === "กลุ่มบุคคล"}
                    type={"radio"}
                    name={`org_type-${index}`}
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.sixth.financial[index].org_type = "กลุ่มบุคคล";
                      setData(updateData);
                    }}
                    id={`default-radio`}
                    label={`กลุ่มบุคคล`}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <p className="mb-2 mt-3">ข้อมูลการได้รับงบประมาณ</p>
              {!!item.resource_money && item.resource_money.map((item, subIndex) => {
                return (
                  <Row key={subIndex}>
                    <Col md={2}>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>
                          {!isEdit && <label
                            className="text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              let updateData = { ...data };
                              updateData.sixth.financial[
                                subIndex
                              ].resource_money.splice(subIndex, 1);
                              setData(updateData);
                            }}
                          >
                            X
                          </label>}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={`ปีที่ ${subIndex + 1}`}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>
                          จำนวนเงินทุนที่ได้รับการสนับสนุน (บาท)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          value={item.money}
                          name="money"
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.sixth.financial[index].resource_money[
                              subIndex
                            ].money = e.target.value;
                            setData(updateData);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>ปีที่ได้รับ (พ.ศ.)</Form.Label>
                        <Form.Control
                          type="number"
                          min="2000"
                          value={item.term}
                          name="term"
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.sixth.financial[index].resource_money[
                              subIndex
                            ].term = e.target.value;
                            setData(updateData);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                );
              })}
               <Col md={12}>
                <Button
                  variant="success"
                  onClick={(e) => {
                    let updateData = { ...data };
                    updateData.sixth.financial[index].resource_money.push({
                      money: "",
                      term: "",
                    });
                    setData(updateData);
                  }}
                >
                  เพิ่มข้อมูลการได้รับงบประมาณ
                </Button>
              </Col>
            </Row>
            <Row>
              <p className="mb-2 mt-4">ข้อมูลผู้ประสานงานหลักจากองค์กร</p>
              <Col md={2}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>คำนำหน้า</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={item.leader_title}
                    name="leader_title"
                    onChange={(e) => handleChange(e, index)}
                  >
                    <option></option>
                    <option value="นาย">นาย</option>
                    <option value="นาง">นาง</option>
                    <option value="นางสาว">นางสาว</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>ชื่อ</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.leader_firstname}
                    name="leader_firstname"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>นามสกุล</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.leader_lastname}
                    name="leader_lastname"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>เบอร์ติดต่อ</Form.Label>
                  <Form.Control
                    type="number"
                    mix="0"
                    value={item.leader_tell}
                    name="leader_tell"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>อีเมล์</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.leader_email}
                    name="leader_email"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <p className="mb-2 mt-4">ข้อมูลผู้ประสานงานในชุมชน</p>
              <Col md={2}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>คำนำหน้า</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={item.contact_title}
                    name="contact_title"
                    onChange={(e) => handleChange(e, index)}
                  >
                    <option></option>
                    <option value="นาย">นาย</option>
                    <option value="นาง">นาง</option>
                    <option value="นางสาว">นางสาว</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>ชื่อ</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.contact_firstname}
                    name="contact_firstname"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>นามสกุล</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.contact_lastname}
                    name="contact_lastname"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>เบอร์ติดต่อ</Form.Label>
                  <Form.Control
                    type="number"
                    mix="0"
                    value={item.contact_tell}
                    name="contact_tell"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>อีเมล์</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.contact_email}
                    name="contact_email"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
          </Row>
        );
      })}
      {!isEdit && <Col md={12}>
        <div className="d-flex justify-content-center">
          <div
            className="btn btn-success"
            onClick={() => addNewBudgetResource()}
          >
            เพิ่มข้อมูลทรัพยากรการเงิน
          </div>
        </div>
      </Col>}
    </Row>
  );
}

export default SectionSixth;
