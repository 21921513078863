import axios from "axios";
import React from "react";
import { Button } from "react-bootstrap";
import { apiConfig } from "../../../../config/api";
import SectionOne from "../../../CommunityForm/insertComponent/sectionOne";
import EditModal from "../editModal/editModal";
function InfoCommunity({ communityId, auth }) {
  const [data, setData] = React.useState("");
  console.log("infoCommunity", data);
  const [modalForm, setModalForm] = React.useState(false);
  const [editState, setEditState] = React.useState(false);
  const [editData, setEditData] = React.useState("");
  function handleEdit(communityData) {
    setEditData({
      communityId: communityId,
      ...communityData,
    });
    setModalForm(true);
    setEditState(true);
  }
  async function getDataApi(communityId) {
    if (!communityId) {
      setData("")
      return false;
    }
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/community_dashboard/community/${communityId}`
      );
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getDataApi(communityId);
  }, [communityId]);
  return modalForm ? (
    <EditModal
      editDataResult={editData}
      setModalForm={setModalForm}
      editState={editState}
      section={
        <SectionOne isEdit={editState} data={editData} setData={setEditData} />
      }
    />
  ) : (
    !!data && (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>
            <b>ข้อมูลชุมชน</b>
          </h4>
          <div>
            {auth && <Button onClick={() => handleEdit(data)}>แก้ไข</Button>}
          </div>
        </div>
        <br />
        <h4>
          หมู่บ้าน {data.first.community.community_name} &nbsp;&nbsp; หมู่.
          {data.first.community.community_moo} &nbsp;&nbsp; ตำบล.
          {data.first.community.tambol_name} &nbsp;&nbsp; อำเภอ.
          {data.first.community.amphur_name} &nbsp;&nbsp; จังหวัด.
          {data.first.community.province_name}
        </h4>
        <br />
        <h4>
          จำนวนประชากรทั้งหมด <u>{data.first.community.population}</u> คน <br />
          <br />
          จำนวนครัวเรือน <u>{data.first.community.house}</u> หลังคาเรือน
        </h4>
        <br />
        <h4>
          จำนวนพื้นที่ของชุมชน <u>{data.first.community.community_area}</u> ไร่
        </h4>
        <br />
        <br />
        <h4>
          <b>ผู้ใหญ่บ้าน</b>
        </h4>
        <br />
        <h4>
          {data.first.leader.title}&nbsp;
          {data.first.leader.first_name}
          &nbsp;&nbsp;{data.first.leader.last_name}&nbsp;&nbsp; ติดต่อ:{" "}
          {data.first.leader.tel_no}
        </h4>
      </>
    )
  );
}

export default InfoCommunity;
