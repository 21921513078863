import React from "react";
import Swal from 'sweetalert2'

import { Form, Button, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { FetchInterface, Method } from '../../../../enums/enum';
import { ApiFetch } from '../../../../config/api';
import { useHistory } from 'react-router-dom';

export default function SearchEntrepreneurs() {
    React.useEffect(() => { }, []);

    const history = useHistory();
    const [loading, setLoading] = React.useState(false)
    const [validIdCard, setValidIdCard] = React.useState(false)
    const [idCard, setIdCard] = React.useState('')


    const handleChange = e => {
        setIdCard(e.target.value);
    };
    
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setValidIdCard(false)
            setLoading(true)
            let res = await ApiFetch({
                [FetchInterface.PATH]: 'entrepreneur/check',
                [FetchInterface.METHOD]: Method.POST,
                [FetchInterface.DATA]: {search: idCard},
            })
            if (!res) throw new Error('เกิดข้อผิดพลาดกับการเชื่อมต่อ')
            if (res.status === 200) {
                const { data } = res
                console.log(data)
                if (data === null || Object.keys(data).length === 0){
                    Swal.fire({
                        title: "ไม่เจอเลขประจำตัวประชาชนนี้ในระบบ?",
                        text: "ต้องการสร้างข้อมูลใหม่!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "ใช่!",
                        cancelButtonText: "ยกเลิก",
                    }).then((result) => {
                        setLoading(false)
                        if (result.isConfirmed) {
                            history.push("/entrepreneurs/create")
                        }
                    })    
                } else if(data.is_business_address === 0 || data.is_entrepreneur_level === 0) {
                    Swal.fire({
                        title: "ข้อมูลผู้ประกอบการไม่ครบถ้วน?",
                        text: `ต้องการอัพเดตข้อมูล ${data.title}${data.first_name} ${data.last_name} \nหมายเลขประจำตัว 13 หลัก ${data.national_id}!`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "ใช่!",
                        cancelButtonText: "ยกเลิก",
                    }).then((result) => {
                        setLoading(false)
                        if (result.isConfirmed) {
                            history.push("/entrepreneurs/edit/" + data.personal_id)
                        }
                    })  
                } else {
                    Swal.fire({
                        title: `มีข้อมูลผู้ประกอบการ ${data.title}${data.first_name} ${data.last_name} อยู่แล้ว?`,
                        text: "ต้องการไปหน้าจัดการของผู้ประกอบการไหม!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "ใช่!",
                        cancelButtonText: "ยกเลิก",
                    }).then((result) => {
                        setLoading(false)
                        if (result.isConfirmed) {
                            history.push("/entrepreneurs/main/" + data.personal_id)
                        }
                    })  
                }
            } 
            } catch (error) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'เข้าสู่ระบบสำเร็จไม่สำเร็จ',
                text: error.message,
                showConfirmButton: true,
            }).then(() => console.log(error))
            }

    };

    // const FormSearch = () => (
       
    // )
    return (
        <div>
             <div>
            <FormPosition>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" >
                        <Form.Label>ค้นหาด้วยชื่อ หรือ หมายเลขประจำตัว 13 หลัก</Form.Label>
                        <Form.Control disabled={loading} type="text" required value={idCard} onChange={(e) => handleChange(e)} />
                        {validIdCard &&  <label className="text-danger fs-6">ข้อมูลไม่ถูกต้องกรุณาตรวจสอบอีกครั้ง</label>}
                       
                        </Form.Group>

                        {loading ?
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" role="status" variant="success">
                                    <span className="visually-hidden">กำลังค้นหาข้อมูล</span>
                                </Spinner>
                            </div>
                            : 
                            <Button className="w-100" variant="primary" type="submit">
                                ค้นหา
                            </Button>
                        }
                    
                        
                    
                </Form>
            </FormPosition>
        </div>
        </div>
    )  
}

const FormPosition = styled.div`
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
`
