import React from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { Table, Form, Button, Spinner } from 'react-bootstrap';
import { ApiFetch } from '../../../../config/api';
import { FetchInterface, Method } from '../../../../enums/enum';
import { useAuthContextState } from '../../../../context/auth/authContext/authContextState'
import Swal from 'sweetalert2'


export default function QuestionEntrepreneurs() {
    const history = useHistory()
    const { token } = useAuthContextState();
    const params = useParams();

    const [questions, setQuestion] = React.useState([])
    const [questionsTotal, setQuestionTotal] = React.useState(0)
    const [submitting, setSubmitting] = React.useState(false)

    const [result, setResult] = React.useState([])
    const [validate, setValidate] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    async function getData() {
        try {
            // setLoading(true)
          const res = await ApiFetch({
            [FetchInterface.PATH]: `entrepreneur/questions`,
            [FetchInterface.METHOD]: Method.GET,
            [FetchInterface.TOKEN]: token.accessToken,
          });
            if (res.status === 200) {
                let data = res.data
                let total = 0
                for (let item of data) {
                    for (let q of item.question) {
                        q.question_name = q.question_name.split(',')
                        total += q.question_name.length
                    }
                }
                setQuestion(data)
                setQuestionTotal(total)
                console.log(data)
            }
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
          console.log(error);
        }
    }

    async function handleSubmit(e){
        e.preventDefault();
        setValidate(false)
        setSubmitting(true)
        if (result.length !== questionsTotal) {
            setValidate(true)
            setSubmitting(false)
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน?",
                icon: "error"
            })
        } else {
            let payload = {
                round: {round: params.round, personal_id:params.personalId, course_id: params.round},
                question: result.map((value) => { return { question_master_id: value.questionId, score: value.score, personal_id: params.personalId } })
            }
            let res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/question-result`,
                [FetchInterface.METHOD]: Method.POST,
                [FetchInterface.DATA]: payload,
                [FetchInterface.TOKEN]: token.accessToken,
            })
            setSubmitting(false)
            if (!res) throw new Error('เกิดข้อผิดพลาดกับการเชื่อมต่อ')
            if (res.status === 200) {
                Swal.fire({
                    title: "บันทึกข้อมูลสำเร็จ?",
                    icon: "success"
                  }).then((result) => {
                    setLoading(false)
                    if (result.isConfirmed) {
                        history.push("/entrepreneurs/main/" + params.personalId)
                    }
                })
            }
        }
    }

    function handleChange({ titleKey, subKey, valKey, score, questionId }) { 
        let data = result
        let value = data.findIndex(val => val.titleKey === titleKey && val.subKey === subKey && val.valKey === valKey);
        if (value>=0) data[value].score = score
        else data.push({ titleKey, subKey, valKey, score, questionId })
        setResult(data)
   
    }
    
    React.useEffect(() => {
        getData()
   
    }, [])

    
    return (
        <div>
                <div className="header d-flex justify-content-center mt-3">
                <h3>แบบประเมินหลังการเข้าร่วม workshop เพื่อการติดตามผลจากการเข้าร่วมโครงการ <br /> <span className='d-flex justify-content-center'>ครั้งที่ {params.round}</span></h3>
                
            </div>
            
            <div className="question mt-5">
                {/* {showQuestion} */}
                <Form onSubmit={(e)=> handleSubmit(e)}>
                {questions.length>0 && questions.map((val, titleKey) => {
                    return <div className='mt-5 p-3' key={val.id}>
                                
                                    <h5>{val.title_name}</h5>
                                <Table bordered className='bg-white'>
                                <thead >
                                    <tr>
                                    <th>{val.have_sub === 0 ? 'ข้อ' : "OSCAR"}</th>
                                    <th>ทักษะและความรู้</th>
                                    {val.have_sub === 1 && <th>ไม่สามารถประเมินผลได้</th>}
                                    <th>น้อยที่สุด</th>
                                    <th>น้อย</th>
                                    <th>พอมี</th>
                                    <th>มี</th>
                                    <th>ชำนาญ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {val.question.length > 0 && val.question.map((item, subKey) => {
                                    if (item.sub_title !== null) {
                                        return [
                                            <tr key={subKey}>
                                                <td colSpan={8}><b>{item.sub_title}</b></td>
                                            </tr>,     
                                            item.question_name.map((subItem, valKey) => {
                                            return <tr key={valKey} >
                                                    <td>{item.symbol}-{valKey + 1}</td>
                                                        <td>{subItem.split(':')[1]}</td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 0, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 1, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 2, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 3, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 4, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 5, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                    </tr>
                                        })]
                                    } else {
                                        return item.question_name.map((subItem, valKey) => {
                                            return <tr key={valKey} >
                                                <td>{valKey + 1}</td>
                                                    <td>{subItem.split(':')[1]}</td>
                                                    <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 1, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 2, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 3, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 4, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                            <td> <Form.Check // prettier-ignore
                                                                name={`question-${titleKey}-${subKey}-${valKey}`}
                                                                type="radio"
                                                                id={`question-${titleKey}-${subKey}-${valKey}`}
                                                                onChange={() => handleChange({titleKey, subKey, valKey, score: 5, questionId: subItem.split(':')[0]})}
                                                                    />
                                                            </td>
                                                </tr>
                                        }) 
                                    }
                                    
                                    })}
                                </tbody>
                            </Table>
                            
                            </div>
                    })}
                    
                            <Button disabled={submitting} variant="primary" type="submit" className='w-100 mb-3'>
                                    {submitting===true? <><Spinner animation="border" role="status" variant="light">
                                </Spinner><span>กำลังบันทึกข้อมูล</span></>: <span>บันทึกข้อมูล</span>} 
                            </Button>
                    </Form>
            </div>
                           
        </div>
    )
}