import axios from "axios";
import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { apiConfig } from "../../../../config/api";
import ModalPopup from "./popup";
function InfoSupport({communityId,auth }) {
  const [data, setData] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalForm, setModalForm] = React.useState(false);
  const [editState, setEditState] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [dataSelect, setDataSelect] = React.useState();
  console.log("InfoSupportData", data);
  function handleAdd() {
    console.log("infoGroupPopupHandleAdd");
    setEditState(false);
    setModalForm(true);
    setModalShow(true);
    setEditData({
      parentId:communityId,
      sixth: {
        financial: [],
      },
    });
  }
  function handleEdit(detail) {
    console.log("infoGroupPopupHandleEdit", detail);
    setEditData({
      parentId:communityId,
      ...detail,
    });
    setModalForm(true);
    setEditState(true);
  }
  async function getDataApi(communityId) {
    if (!communityId) {
      setData("");
      return false;
    }
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/community_dashboard/support/${communityId}`
      );
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getDataApi(communityId);
  }, [communityId]);
  return (
    <>
      {!!modalShow && (
        <ModalPopup
        auth={auth}
          modalForm={modalForm}
          setModalForm={setModalForm}
          editData={editData}
          setEditData={setEditData}
          editState={editState}
          setEditState={setEditState}
          modalShow={modalShow}
          setModalShow={setModalShow}
          dataSelect={dataSelect}
          handleEdit={handleEdit}
        />
      )}
      {!!data?.financialData && (
        <Row>
          <Col>
            <Table responsive="md">
              <thead>
                <tr>
                  <th>หน่วยงานที่สนับสนุน</th>
                </tr>
              </thead>
              <tbody>
                {data.financialData.map((item,inx) => {
                  return (
                    <tr key={inx}>
                      <td>{item.sixth.financial[0].org_name}</td>
                      <td style={{textAlign:"end"}}>
                        <Button
                          variant="info"
                          onClick={() => {
                            setModalShow(true);
                            setDataSelect(item);
                          }}
                        >
                          ดูข้อมูลเพิ่มเติม
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
          {auth && <Button onClick={() => handleAdd()} variant="success">
            เพิ่มข้อมูล
          </Button>}
        </Row>
      )}
    </>
  );
}

export default InfoSupport;
