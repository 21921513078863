import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FetchInterface, Method } from "../../../../../enums/enum";
import { useAuthContextState } from "../../../../../context/auth/authContext/authContextState";
import { ApiFetch } from "../../../../../config/api";

export default function BarChartSkill({
  data,
  pathname,
  section,
  setModalShow,
  setTableData,
  address,
  setTableDataForFilter,
  setDisableFilter,
  filterDataName,
  skillType
}) {
  console.log("skillData",data?.data);
  const { token } = useAuthContextState();
  async function getDataTable(pathname, section, title, datasetTitle) {
    if (!token.accessToken) return false;
    if (!!setDisableFilter) {
      setDisableFilter(true);
    }
    setTableData({ header: title, data: [] });
    const titleData = {
      title: title,
      skillType:!!skillType ? skillType : "",
      filterDataName: !!filterDataName ? filterDataName : "",
      datasetTitle: !!datasetTitle ? datasetTitle : "",
    };
    setModalShow(true);
    setTableData({ header: titleData, data: [] });
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/${pathname}/${section}/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });
      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({ header: titleData, data: res.data });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }
  return (
    <div style={{minHeight:"80vh"}}>
    <Bar
      options={{
        onClick(click, element, chart) {
            const index = element[0].index;
            const datasetIndex = element[0].datasetIndex;
            const datasetTitle = chart.config.data.datasets[datasetIndex].label;
            const title = chart.config.data.labels[index];
            getDataTable(pathname,section, title, datasetTitle);
          },
        maintainAspectRatio: false,
        layout: {
          padding: 10,
        },
        scales: {
          x: {
            stacked: true,
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
          },
        },
        indexAxis: "y",
        plugins: {
          subtitle: {
            align: "end",
            position: "top",
            display: true,
            text: "Custom Chart Subtitle",
          },

          legend: {
            display: true,
            align: "center",
            position: "top",
          },
          datalabels: {
            color: "#ffffff",
            anchor: "center",
            align: "center",
            font: {
              weight: "bold",
            },
          },
        },
      }}
      plugins={[ChartDataLabels]}
      data={{
        labels: !!data?.title ? [...data.title] : [],
        datasets: !!data?.data ? [...data.data] : [],
      }}
    />
    </div>
  );
}
