import { TypesLayout } from "../../enums/enum";
import Dashboard from "../../page/admin/dashboard/dashboard";
import { MdPeople } from "react-icons/md";
import { BsFillPersonFill, BsListUl, BsPersonLinesFill } from "react-icons/bs";
import { RiCommunityFill } from "react-icons/ri";
import { AiOutlineBarChart } from "react-icons/ai";
import UserManage from "../../page/admin/userManage/userManagement";
import CreateUser from "../../page/admin/userManage/createUser/createUser";
import EditUser from "../../page/admin/userManage/editUser/editUser";
import DataCollector from "../../page/admin/dataCollector/dataCollectorInfo/dataCollectorInfo"
import PersonalDashboard from "../../component/dashboard/personal/personalDashboard";
import CommunityDashboard from "../../component/dashboard/community/communityDashboard";
import DataCollectorList from "../../page/admin/dataCollector";
import { AiFillSnippets } from "react-icons/ai";
import Personal from "../../page/admin/personal/personal";
import Entrepreneurs from "../../page/admin/entrepreneurs/entrepreneurs";
import SearchEntrepreneurs from "../../page/admin/entrepreneurs/search/searchEntrepreneurs.jsx";
import CreateEntrepreneurs from "../../page/admin/entrepreneurs/create/createEntrepreneurs.jsx";
import QuestionEntrepreneurs from "../../page/admin/entrepreneurs/create/questionEntrepreneurs";
import EditQuestionEntrepreneurs from "../../page/admin/entrepreneurs/edit/editQuestionEntrepreneurs";
import AccountingEntrepreneur from "../../page/admin/entrepreneurs/show/accountingEntrepreneurs";
import AddAccountingEntrepreneur from "../../page/admin/entrepreneurs/create/addAccountingEntrepreneurs";
import ProductImageEntrepreneurs from "../../page/admin/entrepreneurs/create/productImageEntrepreneurs";
import EditEntrepreneurs from "../../page/admin/entrepreneurs/edit/editEntrepreneurs.jsx";
import MainEntrepreneurs from "../../page/admin/entrepreneurs/show/mainEntrepreneurs.jsx";
import InfoEntrepreneurs from "../../page/admin/entrepreneurs/show/infoEntrepreneurs.jsx";
import EntrepreneursList from "../../page/admin/entrepreneurs/show/entrepreneursList.jsx";
import ExpendIncome from "../../page/admin/entrepreneurs/show/expendIncome.jsx";
import EntrepreneursDetail from "../../page/admin/entrepreneurs/entrepreneurs-detail/entrepreneursDetail.jsx";


export const adminRoute = [
    {

        name: "Dashboard",
        path: "/",
        icon: <AiOutlineBarChart />,
        layout: TypesLayout.adminLayout,
        sidebar: true,
        exact: true,
        component: Dashboard,
        subSidebar: [
            {
                name: "Personal",
                path: "/",
                exact: true,
                component: PersonalDashboard,
            },
            {
                name: "Community",
                path: "/community",
                exact: true,
                component: CommunityDashboard,
            },
        ],
    },
    {
        name: "Datacollector",
        path: "/dataCollectorList",
        icon: <AiFillSnippets />,
        layout: TypesLayout.adminLayout,
        sidebar: true,
        exact: true,
        component: DataCollectorList
    },
    {
        name: "Population",
        path: "/population",
        icon: <MdPeople />,
        layout: TypesLayout.adminLayout,
        sidebar: true,
        exact: true,
        component: Personal
    },
    {
        name: "Users",
        path: "/userManage",
        icon: <BsFillPersonFill />,
        layout: TypesLayout.adminLayout,
        sidebar: true,
        exact: true,
        component: UserManage
    },
    {
        name: "Entrepreneurs",
        path: "/entrepreneurs",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: true,
        exact: true,
        component: Entrepreneurs
    },
    {
        name: "Entrepreneurs",
        path: "/entrepreneurs/entrepreneurs-detail/:personalId",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: EntrepreneursDetail
    },
    {
        name: "Entrepreneurs",
        path: "/entrepreneurs/list",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: EntrepreneursList
    },
    {
        name: "Search Entrepreneurs",
        path: "/entrepreneurs/search",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: SearchEntrepreneurs
    },
    {
        name: "Create Entrepreneurs",
        path: "/entrepreneurs/create",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: CreateEntrepreneurs
    },
    {
        name: "Create Entrepreneurs",
        path: "/entrepreneurs/income-expense-list",
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: ExpendIncome
    },
    {
        name: "Edit Entrepreneurs",
        path: "/entrepreneurs/edit/:personalId",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: EditEntrepreneurs
    },
    {
        name: "Main Entrepreneurs",
        path: "/entrepreneurs/main/:personalId",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: MainEntrepreneurs
    },
    {
        name: "Question Entrepreneurs",
        path: "/entrepreneurs/question/:personalId/:round",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: QuestionEntrepreneurs
    },
    {
        name: "Question Entrepreneurs",
        path: "/entrepreneurs/question/edit/:personalId/:round",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: EditQuestionEntrepreneurs
    },
    {
        name: "Accounting Entrepreneurs",
        path: "/entrepreneurs/accounting/:personalId",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: AccountingEntrepreneur
    },
    {
        name: "Accounting Entrepreneurs",
        path: "/entrepreneurs/accounting-list/:personalId",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: AddAccountingEntrepreneur
    },
    {
        name: "Info Entrepreneurs",
        path: "/entrepreneurs/info",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: InfoEntrepreneurs
    },
    {
        name: "Product Image Entrepreneurs",
        path: "/entrepreneurs/product-image/:personalId",
        icon: <BsPersonLinesFill />,
        layout: TypesLayout.adminLayout,
        sidebar: false,
        exact: true,
        component: ProductImageEntrepreneurs
    },
    {
        name: "Create User",
        path: "/CreateUser",
        icon: <RiCommunityFill />,
        layout: TypesLayout.none,
        sidebar: false,
        exact: true,
        component: CreateUser
    },
    {
        name: "Edit User",
        path: "/EditUser",
        icon: <RiCommunityFill />,
        layout: TypesLayout.none,
        sidebar: false,
        exact: true,
        component: EditUser
    },
    {
        name: "Detail dataCollector",
        path: "/ShowDetailDataCollector/:id",
        icon: <BsListUl />,
        layout: TypesLayout.none,
        sidebar: false,
        exact: true,
        component: DataCollector
    },

]