import React from 'react'
import { ApiFetch } from '../../../../config/api'
import { FetchInterface, Method } from '../../../../enums/enum'
import PaginationSelftInput from '../pagination/pagination'
import TableDetial from './tableDetial/tableDetial'

function TableUser({ token, userInfo, setPaginatePageInputByAdmin, paginatePageInputByAdmin }) {
  const [userData, setUserData] = React.useState()
  const [loading, setLoading] = React.useState(true)

  async function getDashboard(page) {
    setLoading(true)
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/checker-dashboard-input-by-admin/${userInfo.userId}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: { page: page }
      })
      if (res.status === 200) {
        console.log('test',res.data);
        setLoading(false)
        setUserData(res.data.inputByAdmin)
        setPaginatePageInputByAdmin(page)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  React.useEffect(() => {
    getDashboard(paginatePageInputByAdmin)
  }, [])
  return (
    <>
      {!!userData && userData.data.length > 0 && (
      <div className='TableBox'>
        <TableDetial
          loading={loading}
          userData={userData.data}
          pagidata={userData}
          page={paginatePageInputByAdmin}
        />
        <PaginationSelftInput
          pagiData={userData}
          getDashboard={getDashboard}
          page={paginatePageInputByAdmin}
        />
      </div>
      )}
      {!!userData && userData.data.length <= 0 && !loading && (
      <h1>
        ไม่พบข้อมูล
      </h1>
      )}
    </>

  )
}
export default TableUser
