import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
function UnityCommunity({setData,data,index,item}) {
 
  return (
    <Row>
          <Col md={3}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ประเภทแหล่งกำจัดขยะในชุมชน</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={item.unity_type}
                onChange={(e) => {
                  let updateData = { ...data };
                  updateData.third.weast_resource[index].unity_type =
                    e.target.value;
                  setData(updateData);
                }}
              >
                <option></option>
                <option value="หลุมฝั่งกลบ">หลุมฝั่งกลบ</option>
                <option value="เตาเผา">เตาเผา</option>
                <option value="อื่นๆ">อื่นๆ</option>
              </Form.Select>
            </Form.Group>
          </Col>
          {item.unity_type === "อื่นๆ" && (
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ระบุชื่อ อื่นๆ</Form.Label>
                <Form.Control
                  type="text"
                  name="resource_address"
                  value={item.unity_other}
                  onChange={(e) => {
                    let updateData = { ...data };
                    updateData.third.weast_resource[index].unity_other =
                      e.target.value;
                    setData(updateData);
                  }}
                />
              </Form.Group>
            </Col>
          )}
          <Col md={2}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>จำนวน</Form.Label>
              <Form.Control
                type="number"
                mix={0}
                name="resource_address"
                value={item.unity_amount}
                onChange={(e) => {
                  let updateData = { ...data };
                  updateData.third.weast_resource[index].unity_amount =
                    e.target.value;
                  setData(updateData);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
  )
}

export default UnityCommunity