import React from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PersonalTableDashboard from "../personalTableDashboard/personalTableDashboard";
import { FetchInterface, Method } from "../../enums/enum";
import { ApiFetch } from "../../config/api";
import { useAuthContextState } from "../../context/auth/authContext/authContextState";

const LineChart = React.forwardRef(function LineChart({data,address},ref) {

  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  React.useImperativeHandle(ref, () => ({
    async getPersonalInfoAllInEachofYear() {
      setModalShow(true);
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/personal-table-info/religion/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
          [FetchInterface.PARAMS]: null,
        });
        if (res.status === 200) {
          setTableDataForFilter(res.data);
          setTableData({
            header: { datasetTitle: "ชายและหญิง", title: "ทุกศาสนา" },
            data: res.data,
          });
        }
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    },
  }));
  return (
    <>
    <div style={{ height: "40vh" }}>
    <Line
      plugins={[ChartDataLabels]}
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            align: "end",
            labels: {
              generateLabels: (chart) => {
                return chart.data.datasets[0].label.map((label, index) => ({
                  text: label,
                  strokeStyle: data.backgroundColor[index],
                  fillStyle: data.backgroundColor[index],
              
                }));
              },
            },
          },
          datalabels: {
            color: "#6e6e6e",
            anchor: "end",
            align: "top",
            font: {
              weight: "bold",
            },
          },
        },
      }}
      data={{
        labels: !!data?.labels ? [...data?.labels] : [],
        datasets: [
            {
                label:!!data?.datasets[0]?.label ? [...data?.datasets[0]?.label] : [],
                data: !!data?.datasets[0]?.data ? [...data?.datasets[0]?.data] : [],
                fill: false,
                backgroundColor: !!data?.datasets[0]?.backgroundColor ? [...data?.datasets[0]?.backgroundColor] : [],
                tension: 0.5,
            },
        ],
    }}
    />
    </div>
    <PersonalTableDashboard
        title={`ข้อมูลรายละเอียด เพศ ${tableData.header.datasetTitle} ประเภทที่พักอาศัย ${tableData.header.title}`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
}) 
export default LineChart
