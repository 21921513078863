import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// import Actions from "../../../component/formUser/actions/actions";
import { apiConfig, ApiFetch } from "../../../config/api";
import { useAuthContextState } from "../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../enums/enum";
import DataCollector from "./dataCollectorInfo/dataCollectorInfo";

function DataCollectorList() {
  const [dataCollectorList, setDataCollectorList] = React.useState([]);
  const [show, setShow] = React.useState({state:false,id:""});
  // const history = useHistory();
  const handleClose = () => setShow({state:false,id:""});
  const handleShow = (id) => setShow({state:true,id:id});
  const { token } = useAuthContextState();
  async function getDataCollectorApi() {
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: "auth/getDataCollectListByAdmin",
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
      });
      if (res.status === 200) {
        setDataCollectorList(res.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  React.useEffect(() => {
    getDataCollectorApi();
  }, []);
  return (
    <Container>
      <div className="title">
        <h2>Datacollector</h2>
      </div>
      <div className="content">
        {dataCollectorList.length > 0 &&
          dataCollectorList.map((data, inx) => (
            <div
              key={inx}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className="card-items">
                {data.data.length > 0 &&
                  data.data.map((data, inx) => (
                    <div
                      key={inx}
                      className="userItem"
                      onClick={
                        () => handleShow(data.id)
                        /* history.push({
                          pathname: `/ShowDetailDataCollector/${data.id}`,
                        }) */
                      }
                    >
                      <div className="Avatar">
                        {!!data.url ? (
                          <img
                            alt={data.first_name}
                            width={"100%"}
                            src={`${apiConfig.url}/image/${data.url}`}
                          />
                        ) : (
                          <span style={{color:"white"}} >
                            {!!data.first_name &&
                              data.first_name.charAt(0).toUpperCase()}
                          </span>
                        )}
                      </div>
                      <div className="infomation">
                        <span className="name-lastname">
                          {data.first_name} {data.last_name}
                        </span>
                        <span className="email">{data.user_type}</span>
                        <span className="role">{data.user_email}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
      <Modal show={show.state} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>ข้อมูลการเพิ่มรายชื่อประชากร</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataCollector id={show.id} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
export default DataCollectorList;
const Container = styled.div`
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    border-radius: 10px;
    background-color: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    padding: 0 15px 0 15px;
    height: 80px;
    width: 93%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addUser {
    }
  }
  .content {
    height: 90%;
    display: flex;
    margin-top: 20px;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    .card-items {
      display: grid;
      padding-left: 10px;
      justify-content: center;
      grid-template-columns: 25% 25% 25% 25%;
      .userItem {
        width: 290px;
        height: 100px;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: center;
        border-radius: 10px;
        border: solid transparent;
        margin: 0px 15px 15px 0px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        &:hover {
          border: solid #38afba;
          cursor: pointer;
          transition: all 0.3s;
        }
        .Avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          object-fit: contain;
          background-color: #38afba;
        }
        .infomation {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 0 0 10px 10px;
          width: auto;
          height: 100%;
          .name-lastname {
            font-weight: bold;
          }
          .role {
            font-weight: bold;
          }
        }
      }
    }
  }
`;
