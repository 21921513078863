import React from "react";
import styled from "styled-components";

export default function SubSidebar({ name, path, handleNavigate, parentName }) {
  
  return (
    <Main>
      <button onClick={() => handleNavigate(path, parentName)}>{name}</button>
      <div className="underline" />
    </Main>
  );
}
const Main = styled.li`
  width: min-content;
  position: relative;
  button {
    border: none;
    background-color: white;
  }
  .underline {
    position: absolute;
    bottom: 0px;
    width: 0px;
  }
  &:hover {
    .underline {
      transition: all 0.3s;
      position: absolute;
      height: 3px;
      border-radius: 20px;
      width: 100%;
      background-color: black;
    }
  }
`;
