import React from "react";
import { Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import AdminLayout from "../layout/adminLayout/adminLayout";
import IndexLayout from "../layout/indexLayout/indexLayout";
import { getBarMenu, getRoutes } from "./routeManage/routeManage";
import { useAuthContextState } from "../context/auth/authContext/authContextState";
import { TypesLayout } from "../enums/enum";

function RouteLink() {
  const { auth, userInfo } = useAuthContextState();
  // const  auth = true
  // const  userInfo = {role:'admin',name:'audi'}
  const indexRoutes = getRoutes("index");
  const routes = getRoutes(userInfo.role);
  const barMenu = getBarMenu(userInfo.role);
  return (
    <Switch>
      {!auth &&
        indexRoutes.map((routes, inx) =>
          routes.layout === TypesLayout.none ? (
            !!routes.redirectTo ? (
              <Route
                key={routes.path}
                exact={routes.exact}
                path={routes.path}
                component={() => (
                  <Redirect
                    to={{
                      pathname: routes.redirectTo,
                      state: { from: routes.path },
                    }}
                  />
                )}
              />
            ) : (
              <Route
                key={routes.path}
                exact={routes.exact}
                path={routes.path}
                component={routes.component}
              />
            )
          ) : (
            <Route
              key={routes.path}
              exact={routes.exact}
              path={routes.path}
              component={() => <IndexLayout routes={indexRoutes} key={inx} />}
            />
          )
        )}
      {!!auth &&
        routes.map((routesData, inx) =>
          routesData.layout === TypesLayout.none ? (
            !!routesData.redirectTo ? (
              <Route
                key={routesData.path}
                exact={routesData.exact}
                path={routesData.path}
                component={() => (
                  <Redirect
                    to={{
                      pathname: routesData.redirectTo,
                      state: { from: routesData.path },
                    }}
                  />
                )}
              />
            ) : (
              <Route
                key={routesData.path}
                exact={routesData.exact}
                path={routesData.path}
                component={routesData.component}
              />
            )
          ) : !!routesData.subSidebar ? (
            routesData.subSidebar.map((subRoute) => (
              <Route
                key={subRoute.path}
                exact={subRoute.exact}
                path={subRoute.path}
                component={() => (
                  <AdminLayout
                    key={inx}
                    routes={routes}
                    sidebar={barMenu}
                  />
                )}
              />
            ))
          ) : (
            <Route
              key={routesData.path}
              exact={routesData.exact}
              path={routesData.path}
              component={() => (
                <AdminLayout
                  key={inx}
                  routes={routes}
                  sidebar={barMenu}
                />
              )}
            />
          )
        )}
    </Switch>
  );
}

export default React.memo(RouteLink);
