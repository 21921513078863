import React from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap';
import styled from 'styled-components'
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, Line } from "react-chartjs-2";
import { ApiFetch, apiConfig } from '../../../../config/api';
import Card from 'react-bootstrap/Card';
import { FetchInterface, Method } from '../../../../enums/enum';
import LoadingPage from '../../../../component/loadingPage/loadingPage';
import { useParams } from "react-router-dom";
import { ThaiMonth } from '../../../../enums/enum';
import { useHistory } from "react-router-dom";

function EntrepreneursDetail() {
    const history = useHistory();

    const params = useParams();
    const [fullYear, setFullYear] = React.useState("0")
    const [loading, setLoading] = React.useState(false)
    const [ecotiveJourney, setEcotiveJourney] = React.useState([])
    const [businessInformation, setBusinessInformation] = React.useState({})
    const [businessProductImage, setBusinessProductImage] = React.useState([])
    const [businessGrowth, setBusinessGrowth] = React.useState({
        datasets: [],
        backgroundColor: [],
    })
    const d = new Date();
    let year = d.getFullYear();
    const customDataLabels = {
        id: "customDataLabels",
        afterDatasetsDraw(chart) {
            const {
                ctx,
            } = chart;
            const data =
                !!chart.config._config.options.pointReferenceLetters.references ? chart.config._config.options.pointReferenceLetters.references : [];
            chart.data.datasets.forEach((_dataset, i) => {
                chart.getDatasetMeta(i).data.forEach((_dataPoint, inx) => {
                    ctx.font = "bold 12px sans-serif";
                    ctx.fillStyle = "#0e006c";
                    ctx.textAlign = "center";
                    const yCenter =
                        chart.getDatasetMeta(i).data[inx].y +
                        chart.getDatasetMeta(i).data[inx]?.height -
                        chart.getDatasetMeta(i).data[inx]?.height / 2;
                    const xCenter = chart.getDatasetMeta(i).data[inx].x;
                    !!data[i].average && data[i].average[inx] > 0 && ctx.fillText(`${data[i].average[inx]}%`, xCenter, yCenter);
                });
                ctx.save();
            });
        },
    };
    async function getBusinessInformation(personalId) {
        try {
            setLoading(true)
            const payload = {
                personalId
            }
            const { data: res } = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/business-information/${personalId}`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.PARAMS]: payload
            });
            console.log('getBusinessInformation', res.data);
            if (res.status === 200) {
                setBusinessInformation(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("error here ", error);
        }
    }
    async function getBusinessProductImage(personalId) {
        try {

            setLoading(true)
            const res = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/product-image/${personalId}`,
                [FetchInterface.METHOD]: Method.GET,
            });
            console.log("Images", res)
            if (res.status === 200) {
                console.log("Images ", res.data)
                setBusinessProductImage(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("error here ", error);
        }
    }
    async function getEcotiveJourney(personalId) {
        try {
            setLoading(true)
            const payload = {
                personalId,
            }
            const { data: res } = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/ecotive-entrepreneur-journey`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.PARAMS]: payload
            });
            console.log('ecotiveJourney response', res.data.data.length);
            if (res.status === 200) {
                setEcotiveJourney(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    async function getBusinessGrowth(personalId, fullYear) {
        try {
            setFullYear(fullYear)
            const payload = {
                personalId,
                year: fullYear,
            }
            const { data: res } = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/ecotive-entrepreneur-business-growth`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.PARAMS]: payload,
            });

            console.log('setBusinessGrowth', res);
            if (res.status === 200) {
                res.data.labels = res.data.labels.map(value => ThaiMonth[value])
                setBusinessGrowth(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    React.useEffect(async () => {
        if (params?.personalId) {
            await getBusinessInformation(params.personalId)
            await getBusinessProductImage(params.personalId)
            await getEcotiveJourney(params.personalId)
            await getBusinessGrowth(params.personalId, year)
        }
    }, [])
    return (
        <Main >
            <LoadingPage
                borderRadius={"5px"}
                loading={loading}
            />
            <div className='content-box'>
                <Row>
                    <Col Col md={12}>
                        <div className='d-flex justify-content-end'>
                            <Button variant="primary"
                                onClick={() => { history.push(`/entrepreneurs/main/${params.personalId}`); }}>
                                จัดการข้อมูลผู้ประกอบการ
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={12}>
                        <Row>
                            <Col md={3}>
                                <div>
                                    <h5>ฐานข้อมูลผู้ประกอบการในโครงการ</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ overflowY: 'auto', maxHeight: '80vh', minHeight: '20vh' }}>
                            <Col md={12} >
                                <Row className="row">
                                    <Col
                                        md={3}
                                    >
                                        <Card style={{ width: '100%', borderRadius: "15px" }}>
                                            {businessInformation.file_path ? <Card.Img
                                                variant="top"
                                                style={{ padding: '5px', height: '100%', width: '100%', margin: '0 auto' }}
                                                src={`${apiConfig.url}/${businessInformation.file_path}`}

                                            /> :
                                                <Card.Img
                                                    variant="top"
                                                    style={{ padding: '20px', height: '100%', width: '50%', margin: '0 auto' }}
                                                    src={require("../../../../assets/images/image.png")}

                                                />}

                                            <Card.Body>
                                                <Card.Title>{businessInformation.title}{businessInformation.first_name} {businessInformation.last_name} <br></br><br></br> {businessInformation.level}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col
                                        md={9}
                                    >
                                        {/* <p>
                                        Name: {businessInformation?.business_name}
                                    </p> */}
                                        <p>
                                            <b>Address of the business:</b>บ้านเลขที่ {businessInformation?.address_no} หมู่ {businessInformation?.address_mooban}  ต.{businessInformation?.tambol_name} อ.{businessInformation?.amphur_name} จ.{businessInformation?.province_name}
                                        </p>
                                        <p>
                                            <b>Tel:</b> {businessInformation?.tel}
                                        </p>
                                        {/* <p>
                                        Business Description: {businessInformation?.business_detail}
                                    </p> */}
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ overflowY: 'auto', maxHeight: '80vh', minHeight: '20vh' }}>
                    <Col md={12}>
                        <Row>
                            <Col md={3}>
                                <Row >
                                    <h5>
                                        Business
                                    </h5>
                                </Row>
                                <Row>
                                    {businessProductImage?.length > 0 && businessProductImage.map((item) => {
                                        return (
                                            <Col md={12}
                                                key={item.id}
                                            >
                                                <div
                                                    className='mb-4'
                                                    style={{
                                                        borderRadius: '10px',
                                                        height: '250px',
                                                        overflow: 'hidden',
                                                        backgroundColor: '#EBF5F4'
                                                    }}
                                                >
                                                    <img src={`${apiConfig.url}/${item.file_path}`} width="100%" />
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={4}>
                                        <h5>
                                        Business Growth ({fullYear})
                                        </h5>
                                    </Col>
                                    <Col md={4}>
                                        <Row>
                                            <Col md={3}>
                                                <p className='text-center'>เลือกปี</p>
                                            </Col>
                                        
                                            <Col>
                                            <Form.Select className='w-50' aria-label="Default select example" onChange={(d) => {
                                                getBusinessGrowth(params.personalId, d.target.value)
                                            }}>
                                                <option></option>
                                                {!!businessGrowth?.year && businessGrowth.year.map(data => {
                                                    return <option>{data}</option>
                                                })}
                                            </Form.Select>
                                            </Col>
                                        
                                        </Row>
                                        
                                    </Col>
                                    
                                </Row>
                                <div style={{ height: "40vh", padding: "20px" }}>
                                    <Line
                                        plugins={[ChartDataLabels]}
                                        options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    align: "end",
                                                },
                                                datalabels: {
                                                    color: "#6e6e6e",
                                                    anchor: "end",
                                                    align: "top",
                                                    font: {
                                                        weight: "bold",
                                                    },
                                                },
                                            },
                                        }}
                                        data={{
                                            labels: !!businessGrowth?.labels ? [...businessGrowth?.labels] : [],
                                            datasets: businessGrowth?.datasets.length > 0 ? [...businessGrowth?.datasets] : [],
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row className="mt-3" style={{ height: "fit-content" }} >
                    <Col md={3}>
                        <Row  >
                            <h5>Ecotive Journey</h5>
                        </Row>
                        <Row className="ecotive-journey-level">
                            <Col md={12} style={{ borderRadius: '10px', backgroundColor: '#EBF5F4', padding: "10px" }}>
                                Level : {businessInformation?.level}
                            </Col>
                        </Row>
                        <Row className="ecotive-journey" >
                            <Col md={12} style={{ borderRadius: '10px', backgroundColor: '#EBF5F4', padding: "10px" }}>
                                <span className="ecotive-journey-title">
                                    <b>หลักสูตรที่อบรมในโครงการ ecotive</b>
                                </span>
                                <div className="ecotive-journey-content-box">
                                    {ecotiveJourney?.title?.map((item, key) =>
                                        <span>
                                            <b>หลักสูตรที่{key + 1}:</b> {item}
                                        </span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={9} style={{ display: "flex", flexFlow: "column" }}>
                        <Row  >
                            <h5>Ecotive Overall Skills Matrix</h5>
                        </Row>
                        <Row style={{ flex: 1 }} >
                            <div style={{ width: "100%", height: "100%" }}>
                                <Bar
                                    width={"100%"}
                                    plugins={[ChartDataLabels, customDataLabels]}
                                    options={{
                                        pointReferenceLetters: {
                                            display: false,
                                            references: ecotiveJourney?.data
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        layout: {
                                            padding: 15,
                                        },
                                        scales: {
                                            x: {
                                                grace: 1,
                                                beginAtZero: true,
                                                grid: {
                                                    display: true,
                                                },
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false,
                                                },
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display:
                                                    ecotiveJourney?.data ? true : false,
                                                align: "start",
                                                position: "bottom",
                                            },
                                            datalabels: {
                                                color: "#6e6e6e",
                                                anchor: "end",
                                                align: "top",
                                                font: {
                                                    weight: "bold",
                                                },
                                            },
                                            tooltip: {
                                                enabled: false,
                                            },
                                        },
                                    }}
                                    data={{
                                        labels: !!ecotiveJourney?.title ? [...ecotiveJourney.title.map((value, key) => `หลักสูตรที่ ${key + 1}`)] : [],
                                        datasets: !!ecotiveJourney?.data ? [...ecotiveJourney.data] : [],
                                    }}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row >
            </div>
        </Main >
    )
}

export default EntrepreneursDetail
const Main = styled.div`
    position: relative;
    overflow-x: hidden;
    height:100%;
    padding:20px;
    .content-box{
        padding:20px;
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow-y: auto;
        .ecotive-journey{
            padding: 10px;
            min-height:150px;
            border-radius: 15px;
            .ecotive-journey-title{
                font-weight: 400;
                margin-bottom: 10px;
            }
            .ecotive-journey-content-box{
                margin-top: 10px;
                margin-left: 20px;
                display: flex;
                flex-flow: column;
            }
        }
        .ecotive-journey-level{
            padding: 10px;
            min-height:50px;
            border-radius: 15px;
            
        }
        .ecotive-entrepreneur{
            overflow-y: auto;
            max-height:80vh;
        }
    }
`
