import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { FetchInterface, Method } from "../../../enums/enum";
import { ApiFetch, apiConfig } from "../../../config/api";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import LoadingPage from "../../../component/loadingPage/loadingPage";

export default function Entrepreneurs() {
    const history = useHistory();
    const [loading, setLoading] = React.useState(false)
    const [businessVillage, setBusinessVillage] = React.useState([])
    const [ecotiveEntrepreneurs, setEcotiveEntrepreneurs] = React.useState([])
    const [ecotiveJourney, setEcotiveJourney] = React.useState([])
    const [ecotiveEntrepreneursLevel, setEcotiveEntrepreneursLevel] = React.useState('ทั้งหมด')

    async function getBusinessVillage() {
        try {
            setLoading(true)
            const { data: res } = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/business-village`,
                [FetchInterface.METHOD]: Method.GET,
            });
            console.log(res.data)
            if (res.status === 200) {
                setBusinessVillage(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("error here ", error);
        }
    }
    async function getEcotiveEntrepreneurs(level) {
        try {
            const payload = {
                level
            }
            setLoading(true)
            const { data: res } = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/ecotive-entrepreneurs`,
                [FetchInterface.METHOD]: Method.GET,
                [FetchInterface.PARAMS]: payload
            });
            console.log('ecotiveEntrepreneurs response', res);
            if (res.status === 200) {
                setEcotiveEntrepreneurs(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    async function getEcotiveJourney() {
        try {
            setLoading(true)
            const { data: res } = await ApiFetch({
                [FetchInterface.PATH]: `entrepreneur/ecotive-entrepreneur-journey`,
                [FetchInterface.METHOD]: Method.GET,
            });
            console.log('ecotiveJourney response', res);
            if (res.status === 200) {
                setEcotiveJourney(res.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    const customDataLabels = {
        id: "customDataLabels",
        afterDatasetsDraw(chart, args, pluginOptions) {
            const {
                ctx,
            } = chart;
            const data =
                !!chart.config._config.options.pointReferenceLetters.references ? chart.config._config.options.pointReferenceLetters.references : [];
            chart.data.datasets.forEach((_dataset, i) => {
                chart.getDatasetMeta(i).data.forEach((_dataPoint, inx) => {
                    ctx.font = "bold 12px sans-serif";
                    ctx.fillStyle = "#0e006c";
                    ctx.textAlign = "center";
                    const yCenter =
                        chart.getDatasetMeta(i).data[inx].y +
                        chart.getDatasetMeta(i).data[inx]?.height -
                        chart.getDatasetMeta(i).data[inx]?.height / 2;
                    const xCenter = chart.getDatasetMeta(i).data[inx].x;
                    !!data[i].average && data[i].average[inx] > 0 && ctx.fillText(`${data[i].average[inx]}%`, xCenter, yCenter);
                });
                ctx.save();
            });
        },
    };
    React.useEffect(() => {
        getBusinessVillage()
        getEcotiveEntrepreneurs(ecotiveEntrepreneursLevel)
        getEcotiveJourney()
    }, [])
    React.useEffect(() => {
        getEcotiveEntrepreneurs(ecotiveEntrepreneursLevel)
    }, [ecotiveEntrepreneursLevel])

    return (
        <Main >
            <LoadingPage
                borderRadius={"5px"}
                loading={loading}
            />
            <div className="content-box">
                <Row className="mb-4">
                    <Col md={12}>
                        <Row>
                            <Col md={4}>
                                <div>
                                    <h5>ฐานข้อมูลผู้ประกอบการในโครงการ</h5>
                                </div>
                            </Col>
                            <Col md={8}>
                                <div >
                                    <PositionButton>

                                        <Button variant="primary"
                                            onClick={() => { history.push("/entrepreneurs/list"); }}>
                                            จัดการข้อมูลผู้ประกอบการ
                                        </Button>
                                    </PositionButton>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ overflowY: 'auto', maxHeight: '80vh', minHeight: '20vh' }}>
                            <Col md={12} >
                                <Row className="row">
                                    {businessVillage?.map((item) =>
                                        <Col md={4} style={{ marginBottom: '20px' }} >
                                            <div
                                                style={{
                                                    borderRadius: '10px',
                                                    height: '250px',
                                                    overflow: 'hidden',
                                                    backgroundColor: 'white'
                                                }}
                                            >
                                                <img src={`${apiConfig.url}/${item.file_path}`} width="100%" />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-4 ecotive-entrepreneur">
                    <Col md={12} >
                        <Row >
                            <div >
                                <h5>Ecotive Entrepreneurs : {ecotiveEntrepreneursLevel}</h5>
                            </div>
                        </Row>
                        <Row >
                            <Col md={3}>
                                <Row
                                    className="d-flex"
                                    style={{
                                        display: "flex",
                                        flexFlow: "column",
                                        justifyContent: 'space-between',
                                        height: '200px', rowGap: '10px'
                                    }}>
                                    <div>
                                        <Button
                                            variant="info w-100 p-3"
                                            style={{
                                                borderRadius: '15px',
                                                backgroundColor: '#117c86',
                                                color: 'white'
                                            }}
                                            onClick={() => setEcotiveEntrepreneursLevel('ทั้งหมด')}
                                        >
                                            All
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            variant="info w-100 p-3"
                                            style={{
                                                borderRadius: '15px',
                                                backgroundColor: '#0C4E54',
                                                color: 'white'
                                            }}
                                            onClick={() => setEcotiveEntrepreneursLevel('ระดับเริ่มต้น')}
                                        >
                                            Level: 1 Beginner
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            variant="info w-100 p-3"
                                            style={{ borderRadius: '15px', backgroundColor: '##61C2CB' }}
                                            onClick={() => setEcotiveEntrepreneursLevel('ระดับกลาง')}
                                        >Level: 2 Intermediate</Button>
                                    </div>
                                    <div>
                                        <Button
                                            variant="info w-100 p-3"
                                            style={{ borderRadius: '15px', backgroundColor: '#38AFBA' }}
                                            onClick={() => setEcotiveEntrepreneursLevel('ระดับดำเนินการ')}
                                        >
                                            Level: 3 Advance
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                            <Col md={9}>
                                <div className="d-flex justify-content-md-start flex-wrap justify-content-center">
                                    {ecotiveEntrepreneurs.length > 0 && ecotiveEntrepreneurs?.map((item) =>
                                        <Card className="m-1" style={{ width: '250px', borderRadius: "15px" }}>
                                            <Card.Img
                                                variant="top"
                                                style={{ padding: '10px', height: '100%', width: '100%', margin: '0 auto' }}
                                                src={!!item?.file_path ? `${apiConfig.url}/${item?.file_path}` : require("../../../assets/images/image.png")}

                                            />
                                            <Card.Body>
                                                <Card.Title>{item.title}.{item.first_name} {item.last_name} <br></br> ({item.level})</Card.Title>
                                                <Button size="sm" variant="primary" onClick={() => history.push({ pathname: `/entrepreneurs/entrepreneurs-detail/${item.id}` })}>ข้อมูลเพิ่มเติม</Button>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col md={3}>
                        <Row >
                            <h5>Ecotive Journey</h5>
                        </Row>
                        <Row className="ecotive-journey" >
                            <Col md={12} style={{ borderRadius: '10px', backgroundColor: '#EBF5F4', padding: "10px" }}>
                                <span className="ecotive-journey-title">
                                    <b>หลักสูตรที่อบรมในโครงการ ecotive</b>
                                </span>
                                <div className="ecotive-journey-content-box">
                                    {ecotiveJourney?.title?.map((item, key) =>
                                        <span>
                                            <b>หลักสูตรที่{key + 1}:</b> {item}
                                        </span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={9} style={{ display: "flex", flexFlow: "column" }}>
                        <Row >
                            <h5>Ecotive Overall Skills Matrix</h5>
                        </Row>
                        <Row style={{ flex: 1 }}>
                            <div style={{ width: "100%", height: "100%" }}>
                                <Bar
                                    width={"100%"}
                                    plugins={[ChartDataLabels, customDataLabels]}
                                    options={{
                                        pointReferenceLetters: {
                                            display: false,
                                            references: ecotiveJourney?.data
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        layout: {
                                            padding: 15,
                                        },
                                        scales: {
                                            x: {
                                                grace: 1,
                                                beginAtZero: true,
                                                grid: {
                                                    display: true,
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false,
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display:
                                                    !!ecotiveJourney?.data ? true : false,
                                                align: "start",
                                                position: "bottom",
                                            },
                                            datalabels: {
                                                color: "#6e6e6e",
                                                anchor: "end",
                                                align: "top",
                                                font: {
                                                    weight: "bold",
                                                },
                                            },
                                            tooltip: {
                                                enabled: false,
                                            },
                                        },
                                    }}
                                    data={{
                                        labels: !!ecotiveJourney?.title ? [...ecotiveJourney.title.map((value, key) => `หลักสูตรที่ ${key + 1}`)] : [],
                                        datasets: !!ecotiveJourney?.data ? [...ecotiveJourney.data] : [],
                                    }}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Main>
    )
}
const Main = styled.div`
position: relative;
    overflow-x: hidden;
    height:100%;
    padding:20px;
    .content-box{
        padding:20px;
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow-y: auto;
        .ecotive-journey{
            padding: 10px;
            min-height:150px;
            border-radius: 15px;
            .ecotive-journey-title{
                font-weight: 400;
                margin-bottom: 10px;
            }
            .ecotive-journey-content-box{
                margin-top: 10px;
                margin-left: 20px;
                display: flex;
                flex-flow: column;
            }
        }
        .ecotive-entrepreneur{
            overflow-y: auto;
            max-height:80vh;
            min-height:40vh;
        }
    }
`
const PositionButton = styled.div`
    display: flex;
    justify-content: flex-end;
`

