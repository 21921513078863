import { initialState } from "../authContext/inintialState";

const reducerState =(
    state = initialState,
    action) => {

    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                auth:true,
                token:action.payload['token'],
                userInfo:action.payload['userInfo'] 
            }    
        case 'LOGOUT':
            return {
                auth:false,
                token:{
                    accessToken:"",
                },
                userInfo: {
                    userId: "",
                    image: "",
                    name: "",
                    lastname: "",
                    role: "",           
                    address: "",           
                  },
            };
        default:
            return state;
    }
}
export default reducerState