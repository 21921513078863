import React from "react";
import styled from "styled-components";
import { ApiFetch } from "../../../../../../config/api";
import { useAuthContextState } from "../../../../../../context/auth/authContext/authContextState";
import { FetchInterface, Method } from "../../../../../../enums/enum";
import PersonalTableDashboard from "../../../../../personalTableDashboard/personalTableDashboard";
// import { welfareMockData } from '../../../../../mock/dashboard/personal/welfare'

export default function DataTable({ data, address, auth }) {
  // const data = welfareMockData.dataTable;
  const [modalShow, setModalShow] = React.useState(false);
  const [tableDataForFilter, setTableDataForFilter] = React.useState({});
  const { token } = useAuthContextState();
  const [tableData, setTableData] = React.useState({
    header: "",
    data: [],
  });
  async function PersonalInfomation(
    education,
    ageRange,
    gender,
    welfare,
    welfareName
  ) {
    setModalShow(true);
    const titleData = {
      education: education,
      ageRange: ageRange,
      gender: gender,
      welfare: welfare,
      welfareName: welfareName,
    };
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/welfare-personal-table-info/dataTable/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });
      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({
          header: {
            datasetTitle: "-",
            title: `${welfare} เพศ ${gender} ช่วงอายุ ${ageRange} ระดับการศึกษา ${education}`,
          },
          data: res.data,
        });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }
  return (
    <>
      <div style={{ height: "43.5vh", overflowY: "auto" }}>
        <Main>
          <div className="table-head">
            <div className="table-title">
              {!!data?.title &&
                data.title.map((title, inx) => (
                  <span className="text-title" key={inx}>
                    {title}
                  </span>
                ))}
            </div>
          </div>
          <div className="table-body">
            <div className="table-content">
              {!!data?.data &&
                data.data.map((welfare, inx) => (
                  <WelfareType key={inx}>
                    <div className="welfare-title">
                      {welfare.welfareTypeTitle}{" "}
                    </div>
                    <div className="welfareName-list">
                      {!!welfare.welfareNameList &&
                        welfare.welfareNameList.map((welfareName, inx) => (
                          <WelfareName
                            key={inx}
                            welfareNameTitle={welfareName.welfareNameTitle}
                          >
                            <div className="welfareName-title">
                              {welfareName.welfareNameTitle}
                            </div>
                            <div className="gender-list">
                              {!!welfareName?.genderList &&
                                welfareName.genderList.map((gender, inx) => (
                                  <Gender
                                    key={inx}
                                    genderTitle={gender.genderTitle}
                                  >
                                    <div className="gender-title">
                                      {gender.genderTitle}
                                    </div>
                                    <div className="ageRange-list">
                                      {!!gender?.ageRangeList &&
                                        gender.ageRangeList.map(
                                          (ageRange, inx) => (
                                            <AgeRange
                                              key={inx}
                                              ageRangeTitle={
                                                ageRange.ageRangeTitle
                                              }
                                            >
                                              <div className="ageRange-title">
                                                {ageRange.ageRangeTitle}
                                              </div>
                                              <div className="education-list">
                                                {!!ageRange?.educationList &&
                                                  ageRange.educationList.map(
                                                    (education, inx) => (
                                                      <EducationLevel
                                                        auth={auth}
                                                        educationTitle={
                                                          education.educationTitle
                                                        }
                                                        key={inx}
                                                      >
                                                        <div className="education-title">
                                                          {
                                                            education.educationTitle
                                                          }
                                                        </div>
                                                        <div
                                                          className="count"
                                                          onClick={() =>
                                                            auth &&
                                                            PersonalInfomation(
                                                              education.educationTitle,
                                                              ageRange.ageRangeTitle,
                                                              gender.genderTitle,
                                                              welfare.welfareTypeTitle,
                                                              welfareName.welfareNameTitle
                                                            )
                                                          }
                                                        >
                                                          <div className="count-title">
                                                            {education.count}
                                                          </div>
                                                        </div>
                                                        <div className="money">
                                                          <div className="money-title">
                                                            {education.money}
                                                          </div>
                                                        </div>
                                                        <div className="average">
                                                          <div className="average-title">
                                                            {education.average}
                                                          </div>
                                                        </div>
                                                      </EducationLevel>
                                                    )
                                                  )}
                                              </div>
                                            </AgeRange>
                                          )
                                        )}
                                    </div>
                                  </Gender>
                                ))}
                            </div>
                          </WelfareName>
                        ))}
                    </div>
                  </WelfareType>
                ))}
            </div>
          </div>
        </Main>
      </div>
      <PersonalTableDashboard
        title={`ข้อมูลรายละเอียด สวัสดิการ ${tableData.header.title}`}
        tableData={tableData}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setTableData={setTableData}
        tableDataForFilter={tableDataForFilter}
      />
    </>
  );
}
const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .table-head {
    top: 0;
    height: 35px;
    display: grid;
    position: sticky;
    align-items: center;
    background-color: #38afba;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .table-title {
      display: grid;
      grid-template-columns: 20% 16% 10% 10% 15% 10% 11% 8%;
      .text-title {
        padding-left: 5px;
      }
    }
  }
  .table-body {
    border-left: solid 1.6px #aaaaaa;
    border-right: solid 1.6px #aaaaaa;
    width: 100%;
    display: flex;
    .table-content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;
const WelfareType = styled.div`
  width: 100%;
  display: flex;
  border: ${({ border }) => border && "solid"};
  .welfare-title {
    width: 20%;
    padding-top: 5px;
    padding-left: 5px;
    border-bottom: solid 1.6px #aaaaaa;
  }
  .welfareName-list {
    width: 80%;
  }
`;
const WelfareName = styled.div`
  display: flex;
  width: 100%;
  .welfareName-title {
    width: 20%;
    display: flex;
    padding-top: 5px;
    padding-left: 5px;
    border-left: ${({ welfareNameTitle }) =>
      !!welfareNameTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .gender-list {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
`;
const Gender = styled.div`
  width: 100%;
  display: flex;
  .gender-title {
    width: 17%;
    display: flex;
    padding-top: 5px;
    padding-left: 5px;
    border-left: ${({ genderTitle }) => !!genderTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .ageRange-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const AgeRange = styled.div`
  width: 100%;
  display: flex;
  .ageRange-title {
    width: 20%;
    padding-top: 5px;
    padding-left: 5px;
    border-left: ${({ ageRangeTitle }) =>
      !!ageRangeTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .education-list {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
`;
const EducationLevel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  .education-title {
    width: 45%;
    padding-top: 5px;
    padding-left: 5px;
    border-left: ${({ educationTitle }) =>
      !!educationTitle && "solid 1.6px #aaaaaa"};
    border-bottom: solid 1.6px #aaaaaa;
  }
  .count {
    width: 30%;
    display: flex;
    &:hover {
      ${({ auth }) =>
        !!auth &&
        `cursor: pointer;
      background-color: #f0f0f0;`};
    }
    .count-title {
      width: 100%;
      padding-top: 5px;
      padding-left: 5px;
      border-left: solid 1.6px #aaaaaa;
      border-bottom: solid 1.6px #aaaaaa;
    }
  }
  .money {
    display: flex;
    width: 35%;
    .money-title {
      width: 100%;
      padding-top: 5px;
      padding-left: 5px;
      border-left: solid 1.6px #aaaaaa;
      border-bottom: solid 1.6px #aaaaaa;
    }
  }
  .average {
    width: 25%;
    display: flex;
    .average-title {
      width: 100%;
      padding-top: 5px;
      padding-left: 5px;
      border-left: solid 1.6px #aaaaaa;
      border-bottom: solid 1.6px #aaaaaa;
    }
  }
`;
