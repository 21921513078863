import React from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

/* const barData = {
  men: {
    infant: 1,
    child: 5,
    teen: 56,
    earlyLabour: 58,
    labour: 85,
    retired: 96,
    elderly: 78,
  },
  girl: {
    infant: 1,
    child: 5,
    teen: 56,
    earlyLabour: 58,
    labour: 85,
    retired: 96,
    elderly: 78,
  },
}; */

function EducationLevaelhistoryData({ educationLeveDetialData }) {
  const data = new Date();
  const dataBar = {
    labels: [
      data.getFullYear() + 543 - 0,
      data.getFullYear() + 543 - 1,
      data.getFullYear() + 543 - 2,
      data.getFullYear() + 543 - 3,
      data.getFullYear() + 543 - 4,
      data.getFullYear() + 543 - 5,
      data.getFullYear() + 543 - 6,
      data.getFullYear() + 543 - 7,
      data.getFullYear() + 543 - 8,
      data.getFullYear() + 543 - 9,
      data.getFullYear() + 543 - 10,
      data.getFullYear() + 543 - 11,
      data.getFullYear() + 543 - 12,
      data.getFullYear() + 543 - 13,
      data.getFullYear() + 543 - 14,
      data.getFullYear() + 543 - 15,
      data.getFullYear() + 543 - 16,
      data.getFullYear() + 543 - 17,
      data.getFullYear() + 543 - 18,
      data.getFullYear() + 543 - 19,
    ],
    datasets: [
      {
        tension: 0.5,
        data:
          !!educationLeveDetialData.data &&
          !educationLeveDetialData.data.every((data) => data === 0)
            ? [...educationLeveDetialData.data]
            : null,
        backgroundColor: ["#7746B4"],
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        align: "end",
      },
      datalabels: {
        color: "#383838",
        anchor: "end",
        align: "top",
      },
    },
  };
  return (
    <Line
      plugins={[ChartDataLabels]}
      style={{ height: "100%" }}
      options={options}
      data={dataBar}
    />
  );
}

export default EducationLevaelhistoryData;
