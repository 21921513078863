import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
/* const data = {
  community:{group:''}
} */
export default function Search({ data, setData, filterData }) {
  function SearchTex(event, title, mooban) {
    event.preventDefault();
    const searchWord = event.target.value;
    const updateData = { ...data };
    updateData.first[title][event.target.name] = searchWord;
    if (!searchWord) {
      updateData.first.community.group = filterData;
      updateData.first[title]["community_name"] = "";
      setData(updateData);
    } else {
      const newFilter = filterData.filter((value) => {
        return value.group === Number(searchWord);
      });
      if (!!mooban) {
        updateData.first[title]["community_name"] = mooban;
      } else {
        const newMooban = data.first.community.group.find(
          (item) => item.group === Number(searchWord)
        )?.mooban;
        updateData.first[title]["community_name"] = !!newMooban
          ? newMooban
          : "";
      }
      setData(updateData);
      if (newFilter === "") {
        updateData.first.community.group = "";
        setData(updateData);
      } else {
        updateData.first.community.group = newFilter;
        setData(updateData);
      }
    }
  }
  return (
    <>
      <Form.Group className="mb-3" controlId="">
        <Form.Label>หมู่ที่</Form.Label>
        <Form.Control
          type="number"
          min="0"
          name="community_moo"
          // disabled={data.first.community.group.length === 0}
          value={data.first.community.community_moo}
          onChange={(e) => SearchTex(e, "community")}
        />
      </Form.Group>
      <div style={{position: "relative"}}>
        <ContentFilterData>
          {!!data.first.community.group &&
            !data.first.community.community_name &&
            data.first.community.group.map((item, index) => (
              <button
                key={index}
                name="community_moo"
                value={item.group}
                onClick={(e) => SearchTex(e, "community", item.mooban)}
              >
                หมู่ที่.{item.group} หมู่บ้าน.{item.mooban}
              </button>
            ))}
        </ContentFilterData>
      </div>
    </>
  );
}
const ContentFilterData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: -10px;
  background-color: #fff;
  button {
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #ccc;
    &:hover {
      background-color: #ccc;
    }
  }
`;
