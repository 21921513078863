import React from 'react'
import { Link } from 'react-router-dom';
import  styled  from 'styled-components';

function Page404() {
    return (
        <Content>
            <div className='imgPage'>
                <img 
                    alt='404Img'
                    width={"90%"}
                    src={require("../../assets/images/6373669.jpg")}
                />
            </div>
            <div class="infoText">
                <h2>We can't find that page</h2>
                <p>We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's behalf.</p>
                <Link className='back' to={"/"} >Home</Link >
            </div>
        </Content>

    )
}

export default Page404
const Content = styled.div`
    width: 100vw;
    height: 95vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    .imgPage{
        overflow: hidden;
        width:50%;
        height:auto;
    }
    .infoText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .back{
            border-radius: 10px;
            text-align: center;
            border: solid;
            width: 120px;
            height: 35px;
            text-decoration: none;
    }
    }
`