import React from "react";
import { Col, Form, Row } from "react-bootstrap";

function TouristAttraction({
  setData,
  data,
  main_index,
  sub_index,
  subStructureResourceHandleChange,
  isEdit,
  item
}) {
  console.log("TouristAttraction",item);
  return (
    <>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>
            {!isEdit && <label
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                let updateData = { ...data };
                updateData.third.structure_resource[main_index].data.splice(
                  sub_index,
                  1
                );
                setData(updateData);
              }}
            >
              X
            </label>}{" "}
            {sub_index + 1}. ชื่อสถานที่
          </Form.Label>
          <Form.Control
            type="text"
            name="resource_name"
            value={
              item.resource_name
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>รายละเอียดสถานที่ (ถ้ามี)</Form.Label>
          <Form.Control
            type="text"
            name="detail"
            value={
              item.detail
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ปีที่ก่อตั้งขึ้น (พ.ศ.)</Form.Label>
          <Form.Control
            type="number"
            name="resource_year"
            min="1000"
            value={
              item.resource_year
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ตำแหน่งที่ตั้ง</Form.Label>
          <Form.Control
            type="text"
            name="resource_address"
            placeholder="รายละเอียดคร่าว ๆ"
            value={
              item.resource_address
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ละติจูด</Form.Label>
          <Form.Control
            type="number"
            name="latitude"
            placeholder=""
            value={
              data.third.structure_resource[main_index].data[sub_index].latitude
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>ลองจิจูด</Form.Label>
          <Form.Control
            type="number"
            name="longitude"
            placeholder=""
            value={
              data.third.structure_resource[main_index].data[sub_index]
                .longitude
            }
            onChange={(e) =>
              subStructureResourceHandleChange(e, main_index, sub_index)
            }
          />
        </Form.Group>
      </Col>
      <Row>
        <Col md={2}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>เจ้าของพื้นที่</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={
                data.third.structure_resource[main_index].data[sub_index]
                  .resource_type
              }
              onChange={(e) => {
                let updateData = { ...data };
                updateData.third.structure_resource[main_index].data[
                  sub_index
                ].resource_type = e.target.value;
                setData(updateData);
              }}
            >
              <option></option>
              <option value="บุคคล">บุคคล</option>
              <option value="ชุมชน">ชุมชน</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {data.third.structure_resource[main_index].data[sub_index]
          .resource_type === "บุคคล" ? (
          <>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>คำนำหน้า (เจ้าของพื้นที่)</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={
                    data.third.structure_resource[main_index].data[sub_index]
                      .leader_title
                  }
                  onChange={(e) => {
                    let updateData = { ...data };
                    updateData.third.structure_resource[main_index].data[
                      sub_index
                    ].leader_title = e.target.value;
                    setData(updateData);
                  }}
                >
                  <option></option>
                  <option value="นาย">นาย</option>
                  <option value="นาง">นาง</option>
                  <option value="นาง">นางสาว</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ชื่อ (เจ้าของพื้นที่)</Form.Label>
                <Form.Control
                  type="text"
                  name="resource_address"
                  value={
                    data.third.structure_resource[main_index].data[sub_index]
                      .leader_firstname
                  }
                  onChange={(e) => {
                    let updateData = { ...data };
                    updateData.third.structure_resource[main_index].data[
                      sub_index
                    ].leader_firstname = e.target.value;
                    setData(updateData);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>นามสกุล (เจ้าของพื้นที่)</Form.Label>
                <Form.Control
                  type="text"
                  name="resource_address"
                  value={
                    data.third.structure_resource[main_index].data[sub_index]
                      .leader_lastname
                  }
                  onChange={(e) => {
                    let updateData = { ...data };
                    updateData.third.structure_resource[main_index].data[
                      sub_index
                    ].leader_lastname = e.target.value;
                    setData(updateData);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>เบอร์ติดต่อ (เจ้าของพื้นที่)</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  name="resource_address"
                  value={
                    data.third.structure_resource[main_index].data[sub_index]
                      .leader_tell
                  }
                  onChange={(e) => {
                    let updateData = { ...data };
                    updateData.third.structure_resource[main_index].data[
                      sub_index
                    ].leader_tell = e.target.value;
                    setData(updateData);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>email (เจ้าของพื้นที่)</Form.Label>
                <Form.Control
                  type="text"
                  name="resource_address"
                  value={
                    data.third.structure_resource[main_index].data[sub_index]
                      .leader_email
                  }
                  onChange={(e) => {
                    let updateData = { ...data };
                    updateData.third.structure_resource[main_index].data[
                      sub_index
                    ].leader_email = e.target.value;
                    setData(updateData);
                  }}
                />
              </Form.Group>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
}

export default TouristAttraction;
