import axios from 'axios'
import React from 'react'
import Swal from 'sweetalert2'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import ImageUplaod from './imageUplaod/imageUplaod'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useAuthContextState } from '../../context/auth/authContext/authContextState'
import { apiConfig, ApiFetch } from '../../config/api'
import { FetchInterface, Method } from '../../enums/enum'

function FormUser() {
    const location = useLocation();
    const history = useHistory()
    // const params = useParams()
    let form = new FormData()
    const { token } = useAuthContextState()
    const [province, setProvince] = React.useState([])
    const [amphur, setAmphur] = React.useState([])
    const [tambol, setTambol] = React.useState([])
    const [address, setAddress] = React.useState({
        province: null,
        amphur: null,
        tambol: null,
        mooban: null,
    })
    const [values, setValues] = React.useState({
        title: '',
        name: '',
        lastname: '',
        national_id: '',
        email: '',
        tel: '',
        gender: '',
        date_birth: '',
        role: '',
        status: '',
        password: '',
        confirmPassword: '',
        image: ''
    })
    function handleValue(event) {
        setValues({ ...values, [event.target.name]: event.target.value })
    }
    
    async function getUser(id){
      try {
        const res = await ApiFetch({
          [FetchInterface.PATH]: `auth/get-user-by-id/${id}`,
          [FetchInterface.METHOD]: Method.GET,
          [FetchInterface.TOKEN]: token.accessToken,
        });
        console.log(res.data);
        if (res.status === 200) {
          setValues({
            title: res.data.title,
            name: res.data.name,
            lastname: res.data.lastname,
            national_id: res.data.national_id,
            email: res.data.email,
            tel: res.data.tel,
            role: res.data.role,
            gender:res.data.gender,
            status:res.data.status
        })
    }
      } catch (error) {
        Swal.fire({
          title: "เกิดข้อผิดพลาดในระหว่างเชื่อมต่อ",
          text: error,
          icon: "error",
        })
      }
    }
    async function save(event) {
        event.preventDefault()
        Object.entries(values).forEach((value) => {
            if (value[0] === 'image') {
                form.append(value[0], value[1])
            } else {
                form.append(value[0], value[1])
            }
        })
        Object.entries(address).forEach((value) => {
            form.append(value[0], value[1])
        })
        try {
            const res = await ApiFetch({
                [FetchInterface.PATH]: 'auth/create_user_by_admin',
                [FetchInterface.METHOD]: Method.POST,
                [FetchInterface.TOKEN]: token.accessToken,
                [FetchInterface.DATA]: form
            })
            if (res.status === 200) {
                console.log('res', res);
                Swal.fire({
                    title: 'สำเร็จ',
                    text: 'บันทึกข้อมูลสำเร็จ',
                    icon: 'success',
                }).then(() => {
                    history.push('/user')
                })
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'error',
                text: error,
                icon: 'error',
            })
        }
    }
    //ดึงค่าจังหวัดจากหลังบ้าน
    const getProvince = async () => {
        const result = await axios(apiConfig.url + '/api/provinces');
        if (result.status === 200) {
            setProvince(result.data)
        }
    }

    //ดึงค่าอำเภอจากหลังบ้าน
    const getAmphur = async (id_province) => {
        const result = await axios(apiConfig.url + '/api/amphurs/' + id_province);
        if (result.status === 200) {
            setAmphur(result.data)
        }
    }

    //ดึงค่าตำบลจากหลังบ้าน
    const getTambol = async (id_amphur) => {
        const result = await axios(apiConfig.url + '/api/tambols/' + id_amphur);
        if (result.status === 200) {
            setTambol(result.data)
        }
    }
    //ดึงค่าหมู่จากหลังบ้าน
    const getGroupt = async (group_id) => {
        const result = await axios(apiConfig.url + '/api/get-group-village/' + group_id);
        if (result.status === 200) {
        }
    }
    React.useEffect(() => {
        getProvince()
       
        if (!!location.state?.id && location.state?.state ==='edit') {
            getUser(location.state.id)
        }    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Main>
            <Container style={{ height: "90%" }}>
                <div className="main-box" >
                    <div className="box-image" >
                        <div className='image-content' >
                            <ImageUplaod values={values} setValues={setValues} />
                        </div>
                    </div>
                    <div className='box-form' >
                        <Form className='form-submit' onSubmit={(e) => save(e)} >
                            <div className='form-content-box' >
                                <Row >
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="title">คำนำหน้า</Form.Label>
                                            <Form.Select name='title' required value={values.title} size='sm' id="title" onChange={(e) => handleValue(e)} >
                                                <option></option>
                                                <option value={'นาย'} >นาย</option>
                                                <option value={'นาง'} >นาง</option>
                                                <option value={'นางสาว'} >นางสาว</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>ชื่อ</Form.Label>
                                            <Form.Control required value={values.name} size='sm' name='name' type="text" onChange={(e) => handleValue(e)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>นามสกุล</Form.Label>
                                            <Form.Control required value={values.lastname} size='sm' name="lastname" type="text" onChange={(e) => handleValue(e)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                  
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>อีเมล์</Form.Label>
                                            <Form.Control required value={values.email} size='sm' type='text' name="email" onChange={(e) => handleValue(e)} />
                                        </Form.Group>
                                    </Col>
                          
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>เพศ</Form.Label>
                                            <Form.Select size='sm' required value={values.gender} name='gender' id="gender" onChange={(e) => handleValue(e)} >
                                                <option></option>
                                                <option value={'ชาย'} >ชาย</option>
                                                <option value={'หญิง'} >หญิง</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                  
                                </Row>
                                {/* //------------------------------------------------------------------------------------------------------------------------ */}
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="role">Role</Form.Label>
                                            <Form.Select size='sm' required={true} value={values.role} id="role" name='role' onChange={(e) => handleValue(e)} >
                                                <option></option>
                                                <option value={'admin'}>Admin</option>
                                                <option value={'manager'}>Manager</option>
                                                <option value={'dataCollector'}>Data Collector</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="status">สถานะ</Form.Label>
                                            <Form.Select size='sm' value={values.status} required={true} id="status" name='status' onChange={(e) => handleValue(e)} >
                                                <option></option>
                                                <option value={'open'}>เปิดใช้</option>
                                                <option value={'close'}>ปิดใช้</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>รหัสผ่าน</Form.Label>
                                            <Form.Control size='sm' name="password" required={true} type="text" onChange={(e) => handleValue(e)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
                                            <Form.Control size='sm' name="confirmPassword" type="text" onChange={(e) => handleValue(e)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <Row
                                className="justify-content-end"
                                style={{
                                    padding: "0 10px 0 10px"
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "15px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        backgroundColor: "white",
                                        borderRadius: "8px"
                                    }}
                                >
                                    <div style={{
                                        padding: "10px",
                                        width: "170px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "white",
                                    }} >
                                        <Button size='sm' onClick={() => { history.goBack() }} variant="outline-primary">ย้อนกลับ</Button>
                                        <Button size='sm' variant="primary" type="submit">
                                            บันทึก
                                        </Button>
                                    </div>
                                </div>


                            </Row>
                        </Form>
                    </div>
                </div>
            </Container>
        </Main>
    )
}

export default FormUser
const Main = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #EBF4FA;
    .main-box{
        display: flex;
        justify-content: center;
        width:100%;
        height: 100%;
        .box-image{
            display: flex;
            justify-content: center;
            height: 100%;
            width: 45%;
            .image-content{
                width: 95%;
                height: 100%;
                display: flex;
                justify-content: center;
                padding:"0 0 5px";
            }
        }
        .box-form{
            width: 45%;
            height: 100%;
            display: flex;
            justify-content: center;
            .form-submit{
                width: 100%;
                .form-content-box{
                width: 100%;
                height: 90%;
                background-color: white;
                padding: 20px;
                border-radius: 10px;
            }
            }
        }
    }
`
const Address = styled(Col)`
    font-size: 14px;
` 