import React from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { section } from '../enums/enum'
import { useHistory } from 'react-router-dom'
import { PersonalContext } from '../context/personal'
import { Spinner, Form, Container, Row, Col, Button } from 'react-bootstrap'
//cmponent
import Stepper from '../component/stepper/Stepper'
import Welcome from '../component/PersonalForm/Welcome'
import SectionOne from '../component/PersonalForm/insertComponent/sectionOne'
import SectionSixth from '../component/PersonalForm/insertComponent/sectionSixth'
import SectionFifth from '../component/PersonalForm/insertComponent/sectionFifth'
import SectionThird from '../component/PersonalForm/insertComponent/sectionThird'
import SectionFourth from '../component/PersonalForm/insertComponent/sectionFourth'
import SectionSecond from '../component/PersonalForm/insertComponent/sectionSecond'
import { useAuthContextState } from '../../src/context/auth/authContext/authContextState'
import { apiConfig } from '../config/api'

function Personal() {
  const { userInfo } = useAuthContextState()
  const {
    dataReferent,
    setDataReferent,
    data,
    setData,
    parentId,
    setParentId,
    personalId,
    setPersonalId,
  } = React.useContext(PersonalContext);
  const [textSubmit, setTextSubmit] = React.useState('บันทึกข้อมูล')
  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(null)
  const title = ['ข้อมูลทั่วไป', 'ข้อมูลทางด้านการศึกษา', 'ประสบการณ์การทำงาน', 'การฝึกอบรม และ ทักษะความสามารถ', 'ข้อมูลครอบครัว', 'ข้อมูลเกี่ยวกับการได้รับการสนับสนุนจากภาครัฐและการมีส่วนร่วมในชุมชน']
  const history = useHistory()
  const [noneData, setNoneData] = React.useState({
    first:false,
    second:false,
    third:false,
    fourth:false,
    fifth:false,
    sixth:false,
  })
  React.useEffect(() => {
    setLoading(false)
  }, [loading]);
  if (loading) {
    return (
      <div className="text-center"><Spinner variant="secondary" animation="grow" style={{ width: "300px", height: "300px", marginTop: "50px" }} /></div>
    )
  }
  const PageShow = (index) => {
    setDataReferent(!!index ? section[index] : section[0])
    switch (index) {
      case null:
        return <Welcome setPage={setPage} />
      case 0:
        return <SectionOne />
      case 1:
        return <SectionSecond />
      case 2:
        return <SectionThird />
      case 3:
        return <SectionFourth />
      case 4:
        return <SectionFifth />
      case 5:
        return <SectionSixth />
      default:
        return <Welcome setPage={setPage} />
    }
  }
  const onSubmitForm = async (e) => {
    e.preventDefault()
    let values = {}
    try {
    if (dataReferent === section[0]) {
    const { isConfirmed } = await Swal.fire({
        title: 'ต้องการบันทึกข้อมูลทั่วไปหรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'บันทึกข้อมูล',
      })
        if (!isConfirmed) return false
            setTextSubmit('กำลังบันทึกข้อมูล')
            if(!!userInfo.userId){
              values = {
                first: data.first,
                consent: data.consentForm,
                id: userInfo.userId,
                personalId: personalId,
              };
            }else{
              values = {
                first: data.first,
                consent: data.consentForm,
                personalId: personalId,
              };
            }          
            delete values[`${dataReferent}`]["saved"];
            console.log('values',values);
            let response = await axios.post(`${apiConfig.url}/api/submit_form/personal`, values);
            console.log('respond',response);
            if (!response) throw new Error('เกิดข้อผิดพลาด')
            if(response.status){
              let updateData = { ...data, };
            updateData.first.information = response.data
            setPersonalId(response.data.id);
            Swal.fire({
              icon: 'success',
              title: 'บันทึกข้อมูลสำเร็จ',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              if (!!response.data.id) {
                setParentId(response.data.id)
              }
              setData({ ...data, [`${dataReferent}`]: { ...data[`${dataReferent}`], ...data[`${dataReferent}`], saved: true } })
              setData(updateData)
              setPage(page + 1)
              setTextSubmit('บันทึกข้อมูล')
            })
            }
    } else {
      if (dataReferent === section[1]) {
        values = {
          parentId: parentId,
          [`${dataReferent}`]: data[`${dataReferent}`],
          address:data.first.address
        }
      }else{
        values = {
          parentId: parentId,
          [`${dataReferent}`]: data[`${dataReferent}`]
        }
      }
      delete values[`${dataReferent}`]["saved"];
        console.log('values',values);
        let response = await axios.post(apiConfig.url + 'api/submit_form/personal', values)
        if (!response) throw new Error('')
        if(response.status){
          await Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            if (page === title.length - 1) {
              setData({
                first: {
                  information: {
                    title: '',
                    first_name: '',
                    last_name: '',
                    date_birth: '',
                    religion: '',
                    main_nation: '',
                    gender: '',
                    marital_status: '',
                    father_id: '',
                    father_name: '',
                    father_surname: '',
                    mothor_id: '',
                    mother_name: '',
                    mothor_surname: '',
                  },
                  address: {
                    house_number: '',
                    address_no: '',
                    address_mooban: '',
                    address_group: '',
                    tambol_id: null,
                    amphur_id: null,
                    province_id: null,
                  },
                  occupation: {
                    main: {
                      job: '',
                      place: '',
                      salary: 0
                    },
                    additional: {
                      status: false,
                      data: []
                    }
                  },
                  garden: {
                    have: false,
                    data: []
                  },
                  physicalImpairment: {
                    body: {
                      status: false,
                      name: '',
                      date: '',
                      description: '',
                      hospitalName: '',
                      hospitalId: ''
                    },
                    other: {
                      status: false,
                      name: '',
                      date: '',
                      description: '',
                      hospitalId: ''
                    }
                  },
                  communityRole: [],
                  saved: false
                },
                second: {
                  education: [
                    {
                      id: 0,
                      level: null,
                      start: null,
                      end: null,
                      status: null,
                      schoolName: null
                    }
                  ],
                  saved: false
                }
                ,
                third: {
                  experience: [
                    {
                      id: 0,
                      occupation: null,
                      job_start: null,
                      job_end: null,
                      job_detail: null,
                      bussinessId: null,
                      leaveReason: null,
                    }
                  ],
                  saved: false
                }
                ,
                fourth: {
                  train: [],
                  language: [],
                  skill: [],
                  saved: false
                },
                fifth: {
                  family: [],
                  saved: false
                },
                sixth: {
                  now: [],
                  before: [],
                  other: [],
                  saved: false
                },
              })
              history.push('/');
            }
            setTextSubmit('บันทึกข้อมูล')
            setData({ ...data, [`${dataReferent}`]: { ...data[`${dataReferent}`], ...data[`${dataReferent}`], saved: true } })
          })
        }
      }
      } catch (e) {
        setTextSubmit('บันทึกข้อมูล')
        Swal.fire({
          icon: 'error',
          title: 'บันทึกข้อมูลสำเร็จไม่สำเร็จ',
          text:e.message,
          showConfirmButton: true,
        })
      }
  }
  return (
    <Form className="mt-4" onSubmit={(e) => onSubmitForm(e)}>
      {!!page &&
        <Stepper page={page} setPage={setPage} list={title} />
      }
      <Container style={{ minHeight: "80vh", maxHeight: "auto" }}>
        <div style={{ minHeight: "80vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          {PageShow(page)}
          {(page === 0 || page > 0) &&
            <div>
              <Row>
                {page > 0 &&
                  <Col md={12}>
                    <div className="d-flex justify-content-start">
                      <Form.Check
                        onChange={() => setNoneData({...noneData,[`${dataReferent}`]:!noneData[`${dataReferent}`]})}
                        type='checkbox'
                        label='ไม่มีข้อมูลส่วนนี้ / ไม่เพิ่มข้อมูลส่วนนี้'
                        checked={noneData[`${dataReferent}`]}
                        disabled={data[`${dataReferent}`].saved}
                        value={data[`${dataReferent}`].saved}
                        name={dataReferent}
                        id={dataReferent}
                      />
                    </div>
                  </Col>
                }
                <Col md={12}>
                  <div className="d-flex justify-content-end">
                    <Button variant="success"
                      disabled={!!data[`${dataReferent}`].saved || noneData[`${dataReferent}`]}
                      style={{ marginTop: '30px', marginBottom: '10px', width: '100%' }}
                      type="submit"
                    >
                      {textSubmit}
                    </Button>
                  </div>
                </Col>
                {page > 0 &&
                  <>
                    <Col md={6} xs={6}>
                      <div className="d-flex justify-content-end">
                        <Button variant="info"
                          disabled={page === 0}
                          style={{ marginTop: '10px', marginBottom: '20px', width: '100%' }}
                          onClick={() => setPage(page - 1)}
                        >
                          ย้อนกลับ
                        </Button>
                      </div>
                    </Col>
                    <Col md={6} xs={6}>
                      <div className="d-flex justify-content-end">
                        <Button variant="info"
                          disabled={title.length - 1 === page || (!data[`${dataReferent}`].saved && !noneData[`${dataReferent}`]) }
                          style={{ marginTop: '10px', marginBottom: '20px', width: '100%' }}
                          onClick={() => setPage(page + 1)}
                        >
                          ถัดไป
                        </Button>
                      </div>
                    </Col>
                  </>
                }
                {(page === 0 && data[`${dataReferent}`].saved) &&
                    <Col md={12} xs={6}>
                        <div className="d-flex justify-content-end">
                          <Button variant="info"
                            disabled={title.length - 1 === page || !data[`${dataReferent}`].saved || noneData[`${dataReferent}`]}
                            style={{ marginTop: '10px', marginBottom: '20px', width: '100%' }}
                            onClick={() => setPage(page + 1)}
                          >
                            ถัดไป
                          </Button>
                        </div>
                      </Col>
                      }
              </Row>
            </div>}
        </div>
      </Container>
    </Form>

  )
}


export default Personal