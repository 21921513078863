import axios from "axios";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { apiConfig } from "../../../../config/api";
import SectionThird from "../../../CommunityForm/insertComponent/sectionThird/sectionThird";
import EditModal from "../editModal/editModal";
import Utilities from "./utilities/utilities";
import Weast from "./weast/weast";

function InfoUnityResource({ communityId, auth }) {

  const [unityResourceData,setUnityResourceData] = React.useState([])
  const [editState, setEditState] = React.useState(false);
  const [modalForm, setModalForm] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  console.log("unityResourceData", unityResourceData);
  function handleEdit(unityResourceData) {
    setEditData({
      parentId: communityId,
      ...unityResourceData,
    });
    setEditState(true);
    setModalForm(true);
  }
  function handleAdd(unityResourceData) {
    setEditState(false);
    setModalForm(true);
    setEditData({
      parentId: communityId,
      ...unityResourceData,
    });
  }
  async function getDataApi(communityId) {
    if (!communityId) {
      setUnityResourceData("")
      return false;
    }
    try {
      const res = await axios.get(`${apiConfig.url}/api/community_dashboard/resource_other/${communityId}`)
      if (res.status === 200) {
        setUnityResourceData(res.data)
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(()=>{
    getDataApi(communityId)
  },[communityId])
  return (
    <div>
      {modalForm ? (
        <EditModal
          editDataResult={editData}
          setModalForm={setModalForm}
          editState={editState}
          section={
            <SectionThird
              data={editData}
              setData={setEditData}
              isEdit={editState}
            />
          }
        />
      ) : (
        <Row>
          {unityResourceData.length > 0 &&
            unityResourceData.map((resourceItem, inx) => (
              <React.Fragment key={inx}>
                {!!resourceItem.third?.weast_resource && (
                  <Weast
                    resourceItem={resourceItem}
                    handleEdit={handleEdit}
                    handleAdd={handleAdd}
                    auth={auth}
                  />
                )}
                {!!resourceItem.third?.utilities_resource && (
                  <Utilities
                    resourceItem={resourceItem}
                    handleEdit={handleEdit}
                    handleAdd={handleAdd}
                    auth={auth}
                  />
                )}
              </React.Fragment>
            ))}
        </Row>
      )}
    </div>
  );
}
export default InfoUnityResource;
