import _ from "lodash"
import React from 'react'
import axios from 'axios';
import { Container, Form, Row, Col } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";
import { WatDatePicker } from 'thaidatepicker-react'
import ModalOccupation from '../occupation/createOccupation'
import ModalOccupationPlace from "../occupation/createPlace"
import CommunityRole from '../../../dummy/community_role'
import { apiConfig } from "../../../config/api";

function SectionOne({ showOnlyProps = false }) {
  const { data, setData } = React.useContext(PersonalContext);

  //state ข้อมูล dropdown ศาสนา
  const religion = [
    { text: "อิสลาม", value: "อิสลาม" },
    { text: "พุทธ", value: "พุทธ" },
    { text: "คริสต์", value: "คริสต์" },
  ];

  //state ข้อมูล dropdown สัญชาติ
  const national = [
    { text: "ไทย", value: "ไทย" },
    { text: "มาเลย์", value: "มาเลย์" },
  ];

  //state ข้อมูลที่อยู่ดึงมาจาก db เก็บที่นี้
  const [province, setProvince] = React.useState([]);
  const [amphur, setAmphur] = React.useState([]);
  const [tambol, setTambol] = React.useState([]);
  const [occupation, setOccupation] = React.useState([]);
  const [hospital, setHospital] = React.useState([]);
  const [community, setCommunity] = React.useState([]);
  const [business, setBusiness] = React.useState([]);

  //state จัดการ modal สร้างข้อมูลอาชีพใหม่
  const [createOccupation, setCreateOccupation] = React.useState({
    status: false,
    index: null,
    section: 1, //ไว้ระบุว่าเป็นข้อมูลอาชีพหลัก หรือ ข้อมูลอาชีพเสริมและที่เท่าไร
  });

  //state จัดการ modal สร้างข้อมูลสถานประการใหม่
  const [createPlace, setCreatePlace] = React.useState({
    status: false,
    main: false, //ไว้ระบุว่าเป็นข้อมูลอาชีพหลัก หรือ ข้อมูลอาชีพเสริมและที่เท่าไร
  });

  //state จัดการ modal สร้างข้อมูลโรงพยาบาลใหม่
  /* const [createHospital, setCreateHospital] = React.useState({
    status: false,
    main: false  //ไว้ระบุว่าเป็นข้อมูลพกพร่องรา่งกาย หรือ ข้อมูลพกพร่องด้านอื่นๆ
  }) */

  //ดึงค่าจังหวัดจากหลังบ้าน
  const getProvince = async () => {
    const result = await axios(apiConfig.url+ "/api/provinces");
    setProvince(result.data);
  };

  //ดึงค่าอำเภอจากหลังบ้าน
  const getAmphur = async (id_province) => {
    const result = await axios(apiConfig.url + "/api/amphurs/" + id_province);
    setAmphur(result.data);
  };

  //ดึงค่าตำบลจากหลังบ้าน
  const getTambol = async (id_amphur) => {
    const result = await axios(apiConfig.url + "/api/tambols/" + id_amphur);
    setTambol(result.data);
  };

  //ดึงค่าอาชีพทั้งหมด
  const getOccupation = async () => {
    const result = await axios.get(apiConfig.url + "/api/occupations");
    setOccupation(result.data);
  };

  //ดึงค่าสถานประกอบการทั้งหมด
  const getBusiness = async () => {
    const result = await axios.get(apiConfig.url + "/api/business");
    setBusiness(result.data);
  };

  const getHospital = async () => {
    const result = await axios.get(apiConfig.url + "/api/hospitals");
    setHospital(result.data);
  };

  const getCommunity = async () => {
    const result = await axios.get(apiConfig.url + "/api/community_dashboard");
    setCommunity(result.data);
    console.log(result.data);
  };

  //จะทำเมื่อเริ่มเปิดหน้านี้
  React.useEffect(() => {
    getProvince();
    getOccupation();
    getBusiness();
    getCommunity();
    if (data.first.address.tambol_id && data.first.address.amphur_id) {
      setData({ ...data, first: { ...data.first, ...data.first } });
      getAmphur(data.first.address.province_id);
      getTambol(data.first.address.amphur_id);
    }
    if (data.first.information.active) {
      let updateData = { ...data };
      setData(updateData);
    } else {
      let updateData = { ...data };
      updateData.first.saved = false;
      setData(updateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*   ฟังก์ชั่นเรียกใช้เมื่อเซตค่า ศาสนา สัญชาติ */
  const handleChange = (e) => {
    if (e.target.name === "religion") {
      setData({
        ...data,
        first: {
          ...data.first,
          information: { ...data.first.information, religion: e.target.value },
        },
      });
    } else {
      setData({
        ...data,
        first: {
          ...data.first,
          information: {
            ...data.first.information,
            main_nation: e.target.value,
          },
        },
      });
    }
  };

  //เมื่อเลือกจังหวัดทำฟังก์ชั่นนี้
  const provinceChange = (e) => {
    setAmphur([]);
    setTambol([]);
    if (e.target.value !== "") {
      setData({
        ...data,
        first: {
          ...data.first,
          address: {
            ...data.first.address,
            province_id: parseInt(e.target.value),
          },
        },
      });
      getAmphur(e.target.value);
    }
  };

  //เมื่อเลือกอำเภอทำฟังก์ชั่นนี้
  const amphurChange = (e) => {
    if (e.target.value !== "") {
      setData({
        ...data,
        first: {
          ...data.first,
          address: {
            ...data.first.address,
            amphur_id: parseInt(e.target.value),
          },
        },
      });
      getTambol(e.target.value);
    }
  };

  //เมื่อเลือกตำบลทำฟังก์ชั่นนี้
  const tambolChange = (e) => {
    if (e.target.value !== "") {
      setData({
        ...data,
        first: {
          ...data.first,
          address: {
            ...data.first.address,
            tambol_id: parseInt(e.target.value),
          },
        },
      });
    }
  };

  /*   ฟังก์ชั่นเซตค่า วันเกิด */
  const birthDate = (christDate, buddhistDate) => {
    setData({
      ...data,
      first: {
        ...data.first,
        information: { ...data.first.information, date_birth: christDate },
      },
    });
  };

  /*   ฟังก์ชั่นเซตค่า วันที่เข้ารักษา body */
  const physicalImpairmentBodyDate = (christDate, buddhistDate) => {
    let updateData = { ...data };
    updateData.first.physicalImpairment.body.date = christDate;
    setData(updateData);
  };

  /*   ฟังก์ชั่นเซตค่า วันที่เข้ารักษา other */
  const physicalImpairmentOtherDate = (christDate, buddhistDate) => {
    let updateData = { ...data };
    updateData.first.physicalImpairment.other.date = christDate;
    setData(updateData);
  };

  //ฟังก์ชั่นการติ้กเลือกมีข้อมูลอาชีพเสริม ข้อมูลอาชีพ
  const addOccupatoinAdditional = () => {
    let updateData = { ...data };
    if (data.first.occupation.additional.status === true) {
      updateData.first.occupation.additional.status = false;
      updateData.first.occupation.additional.data = [];
    } else {
      updateData.first.occupation.additional.status = true;
      updateData.first.occupation.additional.data.push({
        job: "",
        place: "",
        salary: 0,
      });
    }
    setData(updateData);
  };
  const searchHospital = _.debounce(async (e) => {
    try {
      let response = await axios.get(`${apiConfig.url}/api/hospital?name=${e.target.value}`);
      setHospital(response.data);
    } catch (err) {
      console.log(err);
    }
  }, 700);

  return (
    <Container>
      {/* Modal ของหน้าสร้างข้อมูลอาชีพ */}
      {createOccupation.status ? (
        <ModalOccupation
          createOccupation={createOccupation}
          setCreateOccupation={setCreateOccupation}
          getOccupation={getOccupation}
        />
      ) : null}

      {/*  Modal ของหน้าสร้างข้อมูลสถานประกอบการใหม่ */}
      {createPlace.status ? (
        <ModalOccupationPlace
          createPlace={createPlace}
          setCreatePlace={setCreatePlace}
          getBusiness={getBusiness}
        />
      ) : null}

      {/* ----------------------ข้อมูลส่วนตัว แถว 1--------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4">ข้อมูลทั่วไป</h5>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>รหัสบัตรประจำตัวประชาชน 13 หลัก</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="number"
              min={0}
              placeholder="รหัสบัตรประจำตัวประชาชน"
              value={data.first.information.national_id}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      national_id: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>คำนำหน้าชื่อ</Form.Label>
            <Form.Select
              disabled={showOnlyProps}
              aria-label="Default select example"
              value={data.first.information.title}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      title: e.target.value,
                    },
                  },
                });
              }}
            >
              <option value=""></option>
              <option value="เด็กชาย">เด็กชาย</option>
              <option value="เด็กชาย">เด็กหญิง</option>
              <option value="นาย">นาย</option>
              <option value="นาง">นาง</option>
              <option value="นางสาว">นางสาว</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อ</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              placeholder="กรอกชื่อ"
              value={data.first.information.first_name}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      first_name: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>นามสกุล</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              placeholder="กรอกนามสกุล"
              value={data.first.information.last_name}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      last_name: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* ----------------------ข้อมูลส่วนตัว แถว 2---------------------------- */}
      <Row>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>วันเดือนปีเกิด</Form.Label>
            <br></br>
            <WatDatePicker
              value={data.first.information.date_birth}
              inputStyle={{ height: "38px", width: "auto" }}
              onChange={birthDate}
              dateFormat={"yyyy-MM-dd"}
              displayFormat={"DD MMMM YYYY"}
              clearable={true}
              readOnly={showOnlyProps}
              maxDate={"2030-1-1"}
              yearBoundary={99}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>ศาสนา</Form.Label>
            <input
              disabled={showOnlyProps}
              type="text"
              list="religion"
              name="religion"
              value={data.first.information.religion}
              className="form-control"
              onChange={(e) => handleChange(e)}
            />
            <datalist id="religion">
              {religion.map((item, key) => (
                <option key={key} value={item.value} />
              ))}
            </datalist>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>สัญชาติ</Form.Label>
            <input
              disabled={showOnlyProps}
              type="text"
              list="main_nation"
              name="main_nation"
              value={data.first.information.main_nation}
              className="form-control"
              onChange={(e) => handleChange(e)}
            />
            <datalist id="main_nation">
              {national.map((item, key) => (
                <option key={key} value={item.value} />
              ))}
            </datalist>
          </Form.Group>
        </Col>
      </Row>

      {/* ----------------------ข้อมูลส่วนตัว แถว 3---------------------------- */}
      <Row>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>เพศ</Form.Label>

            {["radio"].map((type) => (
              <div key={`gender-${type}`} className="mb-3">
                <Form.Check
                  disabled={showOnlyProps}
                  checked={
                    data.first.information.gender === "ชาย" ? true : false
                  }
                  inline
                  label="ชาย"
                  name="sex"
                  type={type}
                  value="ชาย"
                  id={`gender-${type}-1`}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          gender: "ชาย",
                        },
                      },
                    });
                  }}
                />
                <Form.Check
                  disabled={showOnlyProps}
                  checked={
                    data.first.information.gender === "หญิง" ? true : false
                  }
                  inline
                  label="หญิง"
                  name="sex"
                  type={type}
                  id={`gender-${type}-2`}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          gender: "หญิง",
                        },
                      },
                    });
                  }}
                />
              </div>
            ))}
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>สถานะ</Form.Label>
            {["radio"].map((type) => (
              <div key={`status-${type}`} className="mb-3">
                <Form.Check
                  disabled={showOnlyProps}
                  checked={
                    data.first.information.marital_status === "โสด"
                      ? true
                      : false
                  }
                  inline
                  label="โสด"
                  name="status"
                  type={type}
                  id={`status-${type}-1`}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          marital_status: "โสด",
                        },
                      },
                    });
                  }}
                />
                <Form.Check
                  disabled={showOnlyProps}
                  checked={
                    data.first.information.marital_status === "สมรส"
                      ? true
                      : false
                  }
                  inline
                  label="สมรส"
                  name="status"
                  type={type}
                  id={`status-${type}-2`}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          marital_status: "สมรส",
                        },
                      },
                    });
                  }}
                />
                <Form.Check
                  disabled={showOnlyProps}
                  checked={
                    data.first.information.marital_status === "หย่า"
                      ? true
                      : false
                  }
                  inline
                  label="หย่า"
                  name="status"
                  type={type}
                  id={`status-${type}-2`}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          marital_status: "หย่า",
                        },
                      },
                    });
                  }}
                />
                <Form.Check
                  disabled={showOnlyProps}
                  checked={
                    data.first.information.marital_status === "หม้าย"
                      ? true
                      : false
                  }
                  inline
                  label="หม้าย"
                  name="status"
                  type={type}
                  id={`status-${type}-2`}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          marital_status: "หม้าย",
                        },
                      },
                    });
                  }}
                />
              </div>
            ))}
          </Form.Group>
        </Col>

        {/* ----------------------แถว คู่สมรส---------------------------- */}
        {data.first.information.marital_status === "สมรส" ? (
          <Row>
            <Col md={12}>
              <h5 className="mb-4">ข้อมูลคู่สมรส</h5>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>รหัสประจำตัวประชาชน (คู่สมรส)</Form.Label>
                <Form.Control
                  disabled={showOnlyProps}
                  type="text"
                  placeholder="กรอกรหัสประจำตัวประชาชน"
                  value={data.first.information.spouse_id}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          spouse_id: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>ชื่อ (คู่สมรส)</Form.Label>
                <Form.Control
                  disabled={showOnlyProps}
                  type="text"
                  placeholder="กรอกชื่อ"
                  value={data.first.information.spouse_name}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          spouse_name: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>นามสกุล (คู่สมรส)</Form.Label>
                <Form.Control
                  disabled={showOnlyProps}
                  type="text"
                  placeholder="กรอกชื่อ"
                  value={data.first.information.spouse_surname}
                  onChange={(e) => {
                    setData({
                      ...data,
                      first: {
                        ...data.first,
                        information: {
                          ...data.first.information,
                          spouse_surname: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        ) : null}
      </Row>
      <hr></hr>
      {/* ----------------------ข้อมูลการติดต่อ---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4 mt-4">ข้อมูลการติดต่อ</h5>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>เบอร์ติดต่อ</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="number"
              min="1"
              value={
                data.first.information.tell
                  ? data.first.information.tell
                  : data.first.information.tel_no
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      tell: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              value={data.first.information.email}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      email: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr></hr>
      {/* ----------------------ข้อมูลที่อยู่---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4 mt-4">ข้อมูลที่อยู่</h5>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>ประเภทที่พักอาศัย</Form.Label>
            {/*  <Form.Control type="text" 
              value={data.first.address.address_no}
              onChange={(e) => {

                setData({
                  ...data,
                  first: { ...data.first, address: { ...data.first.address, address_no: e.target.value } }
                })
              }} /> */}
            <Form.Select
              disabled={showOnlyProps}
              aria-label="Default select example"
              value={
                data.first.address.address_type
                  ? data.first.address.address_type
                  : data.first.address.house_type
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    address: {
                      ...data.first.address,
                      address_type: e.target.value,
                    },
                  },
                });
              }}
            >
              <option></option>
              <option value="เช่าบ้าน/ที่อยู่อาศัย">
                เช่าบ้าน/ที่อยู่อาศัย
              </option>
              <option value="มีที่อยู่อาศัยเป็นของตัวเอง">
                มีที่อยู่อาศัยเป็นของตัวเอง
              </option>
              <option value="ไม่มีบ้าน/ที่อยู่อาศัย">
                ไม่มีบ้าน/ที่อยู่อาศัย
              </option>
              <option value="อยู่ในสถาสงเคราะห์ของรัฐ">
                อยู่ในสถาสงเคราะห์ของรัฐ
              </option>
              <option value="อาศัยอยู่กับครอบครัว">อาศัยอยู่กับครอบครัว</option>
              <option value="อาศัยอยู่ผูอื่น">อาศัยอยู่ผูอื่น</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>เลขทะเบียนบ้าน 11 หลัก</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              value={data.first.address.house_number}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    address: {
                      ...data.first.address,
                      house_number: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>บ้านเลขที่</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              value={data.first.address.address_no}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    address: {
                      ...data.first.address,
                      address_no: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>หมู่บ้าน</Form.Label>
            <input
              disabled={showOnlyProps}
              list="browsers"
              name="browser"
              id="browser"
              className="form-control"
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    address: {
                      ...data.first.address,
                      address_mooban: e.target.value,
                    },
                  },
                });
              }}
            ></input>
            <datalist id="browsers">
              {community.map((item) => {
                return <option key={item.id} value={item.community_name} />;
              })}
            </datalist>
            {/* <Form.Control type="text" 
              value={data.first.address.address_mooban}
              onChange={(e) => {
                setData({
                  ...data,
                  first: { ...data.first, address: { ...data.first.address, address_mooban: e.target.value } }
                })
              }} /> */}
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>หมู่</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="number"
              value={data.first.address.address_group}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    address: {
                      ...data.first.address,
                      address_group: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>จังหวัด</Form.Label>
            <Form.Select
              disabled={showOnlyProps}
              aria-label="Default select example"
              value={data.first.address.province_id}
              onChange={(e) => provinceChange(e)}
            >
              <option></option>
              {province.map((province) => {
                return (
                  <option key={province.value} value={province.value}>
                    {province.text}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>อำเภอ</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={data.first.address.amphur_id}
              onChange={(e) => amphurChange(e)}
              disabled={
                (amphur.length === 0 && data.first.address.amphur_id == null) ||
                showOnlyProps
                  ? true
                  : false
              }
            >
              <option></option>
              {amphur.map((amphur) => {
                return (
                  <option key={amphur.value} value={amphur.value}>
                    {amphur.text}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label>ตำบล</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => tambolChange(e)}
              value={data.first.address.tambol_id}
              disabled={
                (tambol.length === 0 &&
                  !data.first.address.tambol_id == null) ||
                showOnlyProps
                  ? true
                  : false
              }
            >
              <option></option>
              {tambol.map((tambol) => {
                return (
                  <option key={tambol.value} value={tambol.value}>
                    {tambol.text}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <hr></hr>
      {/* ----------------------ข้อมูลอาชีพ---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4 mt-4">ข้อมูลอาชีพ</h5>
        </Col>
        <Col md={2} className="mb-3">
          <div className="d-flex justify-content-around">
            <Form.Check
              disabled={showOnlyProps}
              checked={data.first.occupation.status === "ว่างงาน"}
              name={"occupation-status"}
              type="radio"
              label={`ว่างงาน`}
              id={`occupation-status`}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    occupation: {
                      ...data.first.occupation,
                      status: "ว่างงาน",
                    },
                  },
                });
              }}
            />
            <Form.Check
              disabled={showOnlyProps}
              checked={data.first.occupation.status === "มีอาชีพ"}
              name={"occupation-status"}
              type="radio"
              label={`มีอาชีพ`}
              id={`occupation-status`}
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    occupation: {
                      ...data.first.occupation,
                      status: "มีอาชีพ",
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      {data.first.occupation.status === "มีอาชีพ" ? (
        <Row>
          <Col md={12}>
            <Row>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>อาชีพหลัก</Form.Label>
                  <Row>
                    <Col md={12}>
                      <Form.Select
                        disabled={showOnlyProps}
                        aria-label="Default select example"
                        value={
                          data.first.occupation.main.job
                            ? data.first.occupation.main.job
                            : data.first.occupation.main.occupation_name
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            first: {
                              ...data.first,
                              occupation: {
                                ...data.first.occupation,
                                main: {
                                  ...data.first.occupation.main,
                                  job: e.target.value,
                                },
                              },
                            },
                          });
                        }}
                      >
                        <option></option>
                        {occupation.map((item, key) => (
                          <option key={key} value={item.occupation_name}>
                            {item.occupation_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    {/* <Col md={4}> <Button variant="success"
                    onClick={() => setCreateOccupation({ index: null, status: true, section:1 })}>
                    เพิ่ม</Button></Col> */}
                  </Row>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>ชื่อสถานประกอบการ</Form.Label>

                  <input
                    disabled={showOnlyProps}
                    type="text"
                    className="form-control"
                    placeholder="บ้าน, โรงงาน, บริษัท, อื่นๆ ระบุ"
                    value={data.first.occupation.main.place}
                    onChange={(e) => {
                      setData({
                        ...data,
                        first: {
                          ...data.first,
                          occupation: {
                            ...data.first.occupation,
                            main: {
                              ...data.first.occupation.main,
                              place: e.target.value,
                            },
                          },
                        },
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Select
                    disabled={showOnlyProps}
                    aria-label="Default select example"
                    value={
                      data.first.occupation.main.place_province
                        ? data.first.occupation.main.place_province
                        : data.first.occupation.main.province_id
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        first: {
                          ...data.first,
                          occupation: {
                            ...data.first.occupation,
                            main: {
                              ...data.first.occupation.main,
                              place_province: e.target.value,
                            },
                          },
                        },
                      });
                    }}
                  >
                    <option></option>
                    {province.map((province) => {
                      return (
                        <option key={province.value} value={province.value}>
                          {province.text}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>รายได้เฉลี่ยนต่อเดือน (บาท)</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    type="number"
                    min={0}
                    value={
                      data.first.occupation.main.salary
                        ? data.first.occupation.main.salary
                        : data.first.occupation.main.job_salary
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        first: {
                          ...data.first,
                          occupation: {
                            ...data.first.occupation,
                            main: {
                              ...data.first.occupation.main,
                              salary: e.target.value,
                            },
                          },
                        },
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Form.Check
              disabled={showOnlyProps}
              checked={data.first.occupation.additional.data.length > 0}
              className="mb-3"
              inline
              label="อาชีพเสริม"
              name="group1"
              type="checkbox"
              id={`work-checkbox-1`}
              onChange={() => addOccupatoinAdditional()}
            />
            <Row>
              {data.first.occupation.additional.data.map((d, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>อาชีพเสริม</Form.Label>
                        <Row>
                          <Col md={12}>
                            <Form.Select
                              disabled={showOnlyProps}
                              aria-label="Default select example"
                              value={
                                data.first.occupation.additional.data[index].job
                                  ? data.first.occupation.additional.data[index]
                                      .job
                                  : data.first.occupation.additional.data[index]
                                      .occupation_name
                              }
                              onChange={(e) => {
                                let updateData = { ...data };
                                updateData.first.occupation.additional.data[
                                  index
                                ].job = e.target.value;
                                setData(updateData);
                              }}
                            >
                              <option></option>
                              {occupation.map((item, key) => (
                                <option key={key} value={item.occupation_name}>
                                  {item.occupation_name}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                          {/*  <Col md={4}> <Button variant="success"
                          onClick={() => setCreateOccupation({ index: index, status: true, section: 1 })}>
                          เพิ่ม</Button></Col> */}
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>สถานประกอบการ</Form.Label>
                        <input
                          disabled={showOnlyProps}
                          type="text"
                          list="place_main"
                          className="form-control"
                          placeholder="บ้าน, โรงงาน, บริษัท, อื่นๆ ระบุ"
                          value={
                            data.first.occupation.additional.data[index].place
                          }
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.first.occupation.additional.data[
                              index
                            ].place = e.target.value;
                            setData(updateData);
                          }}
                        />
                        <datalist id="place_main">
                          {business.map((item, key) => (
                            <option key={key} value={item.business_name} />
                          ))}
                        </datalist>
                        {/*  <Col md={4}> <Button variant="success"
                        onClick={() => setCreatePlace({ index: index, status: true, section: 1 })}>
                        เพิ่ม</Button></Col> */}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>จังหวัด</Form.Label>
                        <Form.Select
                          disabled={showOnlyProps}
                          aria-label="Default select example"
                          value={
                            data.first.occupation.additional.data[index]
                              .place_province
                              ? data.first.occupation.additional.data[index]
                                  .place_province
                              : data.first.occupation.additional.data[index]
                                  .province_id
                          }
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.first.occupation.additional.data[
                              index
                            ].place_province = e.target.value;
                            setData(updateData);
                          }}
                        >
                          <option></option>
                          {province.map((province) => {
                            return (
                              <option
                                key={province.value}
                                value={province.value}
                              >
                                {province.text}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>รายได้เฉลี่ยนต่อเดือน</Form.Label>
                        <Form.Control
                          disabled={showOnlyProps}
                          type="text"
                          min={0}
                          value={
                            data.first.occupation.additional.data[index].salary
                              ? data.first.occupation.additional.data[index]
                                  .salary
                              : data.first.occupation.additional.data[index]
                                  .job_salary
                          }
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.first.occupation.additional.data[
                              index
                            ].salary = e.target.value;
                            setData(updateData);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </React.Fragment>
                );
              })}
              {!showOnlyProps &&
              data.first.occupation.additional.data.length > 0 ? (
                <Col>
                  <div
                    className="btn btn-success"
                    onClick={() => {
                      let updateData = { ...data };
                      updateData.first.occupation.additional.data.push({
                        job: "",
                        place: "",
                        salary: 0,
                      });
                      setData(updateData);
                    }}
                  >
                    เพิ่มข้อมูลอาชีพเสริม
                  </div>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      ) : null}
      <hr />
      {/* ----------------------ข้อมูลพิ้นที่ทำกิน---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4 mt-4">ข้อมูลพื้นที่ทำกิน</h5>
        </Col>
        <Col md={12}>
          <Form.Check
            disabled={showOnlyProps}
            checked={data.first.garden.have}
            inline
            label="มีพื้นที่ทำกิน "
            name="garden"
            type="checkbox"
            id={`work-checkbox-1`}
            onChange={(e) => {
              let updateData = { ...data };
              updateData.first.garden.have = e.target.checked;
              if (e.target.checked) {
                updateData.first.garden.data.push({
                  name: "",
                  area: 0,
                  type: "",
                  salary: 0,
                });
              } else {
                updateData.first.garden.data = [];
              }
              setData(updateData);
            }}
          />
        </Col>
        <Col md={12} className="mt-2">
          {data.first.garden.data.map((d, index) => {
            return (
              <React.Fragment key={index}>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{index + 1}. ประเภทสวน</Form.Label>
                      <Form.Select
                        disabled={showOnlyProps}
                        aria-label="Default select example"
                        value={
                          data.first.garden.data[index].name
                            ? data.first.garden.data[index].name
                            : data.first.garden.data[index].garden_name
                        }
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.garden.data[index].name =
                            e.target.value;
                          setData(updateData);
                        }}
                      >
                        {![
                          "สวนยางพารา",
                          "สวนผลไม้",
                          "สวนผสม",
                          "สวนปาล์ม",
                        ].includes(
                          data.first.garden.data[index].garden_name
                        ) && <option>อื่นๆ</option>}
                        <option></option>
                        <option value="สวนยางพารา">สวนยางพารา</option>
                        <option value="สวนผลไม้">สวนผลไม้</option>
                        <option value="สวนผสม">สวนผสม</option>
                        <option value="สวนปาล์ม">สวนปาล์ม</option>
                        <option value="อื่นๆ">อื่นๆ</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {data.first.garden.data[index].name === "อื่นๆ" ||
                  !["สวนยางพารา", "สวนผลไม้", "สวนผสม", "สวนปาล์ม"].includes(
                    data.first.garden.data[index].garden_name
                  ) ? (
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>ระบุชื่อสวน</Form.Label>
                        <Form.Control
                          disabled={showOnlyProps}
                          type="text"
                          value={
                            data.first.garden.data[index].other_name
                              ? data.first.garden.data[index].other_name
                              : data.first.garden.data[index].garden_name
                          }
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.first.garden.data[index].other_name =
                              e.target.value;
                            setData(updateData);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>พื้นที่สวน (ไร่)</Form.Label>
                      <Form.Control
                        disabled={showOnlyProps}
                        type="number"
                        value={
                          data.first.garden.data[index].area
                            ? data.first.garden.data[index].area
                            : data.first.garden.data[index].garden_area
                        }
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.garden.data[index].area =
                            e.target.value;
                          setData(updateData);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>เจ้าของพื้นที่</Form.Label>
                      <Form.Select
                        disabled={showOnlyProps}
                        aria-label="Default select example"
                        value={
                          data.first.garden.data[index].type
                            ? data.first.garden.data[index].type
                            : data.first.garden.data[index].garden_type
                        }
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.garden.data[index].type =
                            e.target.value;
                          setData(updateData);
                        }}
                      >
                        <option></option>
                        <option value="พื้นที่ของตัวเอง">
                          พื้นที่ของตัวเอง
                        </option>
                        <option value="พื้นที่จากการเช่า">
                          พื้นที่จากการเช่า
                        </option>
                        <option value="พื้นที่ของบิดา-มารดา">
                          พื้นที่ของบิดา-มารดา
                        </option>
                        <option value="พื้นที่ผู้มีอุปการะ">
                          พื้นที่ผู้มีอุปการะ
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>รายได้เฉลี่ยนต่อปี (บาท)</Form.Label>
                      <Form.Control
                        disabled={showOnlyProps}
                        type="number"
                        value={
                          data.first.garden.data[index].salary
                            ? data.first.garden.data[index].salary
                            : data.first.garden.data[index].salary
                        }
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.garden.data[index].salary =
                            e.target.value;
                          setData(updateData);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>คำนำหน้า (เจ้าของพื้นที่)</Form.Label>
                      <Form.Select
                        disabled={showOnlyProps}
                        aria-label="Default select example"
                        value={
                          data.first.garden.data[index].title
                            ? data.first.garden.data[index].title
                            : data.first.garden.data[index].title
                        }
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.garden.data[index].title =
                            e.target.value;
                          setData(updateData);
                        }}
                      >
                        <option></option>
                        <option value="นาย">นาย</option>
                        <option value="นาง">นาง</option>
                        <option value="นางสาว">นางสาว</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>ชื่อ (เจ้าของพื้นที่)</Form.Label>
                      <Form.Control
                        disabled={showOnlyProps}
                        type="text"
                        value={
                          data.first.garden.data[index].first_name
                            ? data.first.garden.data[index].first_name
                            : data.first.garden.data[index].first_name
                        }
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.garden.data[index].first_name =
                            e.target.value;
                          setData(updateData);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>นามสกุล (เจ้าของพื้นที่)</Form.Label>
                      <Form.Control
                        disabled={showOnlyProps}
                        type="text"
                        value={
                          data.first.garden.data[index].last_name
                            ? data.first.garden.data[index].last_name
                            : data.first.garden.data[index].last_name
                        }
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.garden.data[index].last_name =
                            e.target.value;
                          setData(updateData);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <hr></hr>
                </Row>
              </React.Fragment>
            );
          })}
        </Col>
        {!showOnlyProps && data.first.garden.data.length > 0 ? (
          <Col md={12}>
            <div
              className="btn btn-success"
              onClick={() => {
                let updateData = { ...data };
                updateData.first.garden.data.push({
                  name: "",
                  area: 0,
                  type: "",
                  salary: 0,
                });
                setData(updateData);
              }}
            >
              เพิ่มข้อมูลพื้นที่ทำกิน
            </div>
          </Col>
        ) : null}
      </Row>

      <hr></hr>
      {/* ----------------------ข้อมูลอื่นๆ ความบกพร่อง---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4 mt-4">ข้อมูลอื่นๆ</h5>
        </Col>
        <Col md={12}>
          <Form.Check
            disabled={showOnlyProps}
            checked={
              data.first.physicalImpairment.body
                ? data.first.physicalImpairment.body.status
                : false
            }
            inline
            label="ความบกพร่องทางด้านร่างกาย (ถ้ามี) "
            name="group1"
            type="checkbox"
            id={`work-checkbox-1`}
            onChange={(e) => {
              setData({
                ...data,
                first: {
                  ...data.first,
                  physicalImpairment: {
                    ...data.first.physicalImpairment,
                    body: {
                      ...data.first.physicalImpairment.body,
                      status: !data.first.physicalImpairment.body.status,
                    },
                  },
                },
              });
            }}
          />
          {data.first.physicalImpairment.body.status ? (
            <Row>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>ความบกพร่อง</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    type="text"
                    value={
                      data.first.physicalImpairment.body.name
                        ? data.first.physicalImpairment.body.name
                        : data.first.physicalImpairment.body.health_name
                    }
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.first.physicalImpairment.body.name =
                        e.target.value;
                      setData(updateData);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>วันที่รับการรักษา</Form.Label>
                  <br></br>
                  <WatDatePicker
                    value={
                      data.first.physicalImpairment.body.date
                        ? data.first.physicalImpairment.body.date
                        : data.first.physicalImpairment.body.date_healthcare
                    }
                    inputStyle={{ height: "38px", width: "auto" }}
                    onChange={physicalImpairmentBodyDate}
                    dateFormat={"yyyy-MM-dd"}
                    displayFormat={"DD MMMM YYYY"}
                    clearable={true}
                    disabled={showOnlyProps}
                    readOnly={false}
                    maxDate={"2030-1-1"}
                    yearBoundary={99}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>ข้อมูลการรักษา</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    as="textarea"
                    rows={4}
                    value={
                      data.first.physicalImpairment.body.description
                        ? data.first.physicalImpairment.body.description
                        : data.first.physicalImpairment.body.desc_healthcare
                    }
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.first.physicalImpairment.body.description =
                        e.target.value;
                      setData(updateData);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>โรงพยาบาลที่รักษา</Form.Label>
                  <Row>
                    <Col md={12}>
                      <input
                        disabled={showOnlyProps}
                        type="text"
                        list="hospital_main"
                        className="form-control"
                        value={
                          data.first.physicalImpairment.body.hospitalName
                            ? data.first.physicalImpairment.body.hospitalName
                            : data.first.physicalImpairment.body.hospital_name
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            first: {
                              ...data.first,
                              physicalImpairment: {
                                ...data.first.physicalImpairment,
                                body: {
                                  ...data.first.physicalImpairment.body,
                                  hospitalName: e.target.value,
                                },
                              },
                            },
                          });
                          searchHospital(e);
                        }}
                      />
                      <datalist id="hospital_main">
                        {hospital.map((item, key) => (
                          <option key={item.id} value={item.hospital_name} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </Col>
        <Col md={12}>
          <Form.Check
            disabled={showOnlyProps}
            checked={
              data.first.physicalImpairment.other
                ? data.first.physicalImpairment.other.status
                : false
            }
            inline
            label="ความบกพร่องทางด้านอื่น ๆ (ถ้ามี) "
            name="group1"
            type="checkbox"
            id={`work-checkbox-1`}
            onChange={(e) => {
              setData({
                ...data,
                first: {
                  ...data.first,
                  physicalImpairment: {
                    ...data.first.physicalImpairment,
                    other: {
                      ...data.first.physicalImpairment.other,
                      status: !data.first.physicalImpairment.other.status,
                    },
                  },
                },
              });
            }}
          />
          {data.first.physicalImpairment.other.status ? (
            <Row>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>ความบกพร่อง</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    type="text"
                    value={
                      data.first.physicalImpairment.other.name
                        ? data.first.physicalImpairment.other.name
                        : data.first.physicalImpairment.other.health_name
                    }
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.first.physicalImpairment.other.name =
                        e.target.value;
                      setData(updateData);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>วันที่รับการรักษา</Form.Label>
                  <br></br>
                  <WatDatePicker
                    value={
                      data.first.physicalImpairment.other.date
                        ? data.first.physicalImpairment.other.date
                        : data.first.physicalImpairment.other.date_healthcare
                    }
                    inputStyle={{ height: "38px", width: "auto" }}
                    onChange={physicalImpairmentOtherDate}
                    dateFormat={"yyyy-MM-dd"}
                    displayFormat={"DD MMMM YYYY"}
                    clearable={true}
                    disabled={showOnlyProps}
                    readOnly={false}
                    maxDate={"2030-1-1"}
                    yearBoundary={99}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>ข้อมูลการรักษา</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    as="textarea"
                    rows={4}
                    value={
                      data.first.physicalImpairment.other.description
                        ? data.first.physicalImpairment.other.description
                        : data.first.physicalImpairment.other.desc_healthcare
                    }
                    onChange={(e) => {
                      let updateData = { ...data };
                      updateData.first.physicalImpairment.other.description =
                        e.target.value;
                      setData(updateData);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>โรงพยาบาลที่รักษา</Form.Label>
                  <Row>
                    <Col md={12}>
                      <input
                        disabled={showOnlyProps}
                        type="text"
                        list="hospital_main"
                        className="form-control"
                        value={
                          data.first.physicalImpairment.other.hospitalName
                            ? data.first.physicalImpairment.other.hospitalName
                            : data.first.physicalImpairment.other.hospital_name
                        }
                        onChange={(e) => {
                          setData({
                            ...data,
                            first: {
                              ...data.first,
                              physicalImpairment: {
                                ...data.first.physicalImpairment,
                                other: {
                                  ...data.first.physicalImpairment.other,
                                  hospitalName: e.target.value,
                                },
                              },
                            },
                          });
                          searchHospital(e);
                        }}
                      />
                      <datalist id="hospital_main">
                        {hospital.map((item, key) => (
                          <option key={item.id} value={item.hospital_name} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      <hr></hr>
      {/* ----------------------ข้อมูลบทบาทหน้าที่ในชุมชน---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4 mt-4">บทบาทหน้าที่การทำงานในชุมชน (ถ้ามี)</h5>
        </Col>
        {CommunityRole.map((item) => {
          return (
            <Col md={3} key={item.name}>
              <Form.Check
                disabled={showOnlyProps}
                checked={
                  !!data.first.communityRole.find(
                    (e) => e.name === item.name
                  ) &&
                  data.first.communityRole.find((e) => e.name === item.name)
                    .status
                }
                onChange={(e) => {
                  let updateData = { ...data };
                  let find = updateData.first.communityRole.find(
                    (x) => x.name === item.name
                  );
                  if (find) {
                    find.status = !find.status;
                  } else {
                    updateData.first.communityRole.push({
                      name: item.name,
                      status: true,
                    });
                  }
                  setData(updateData);

                  /*  updateData.first.communityRole = [...data.first.communityRole, { ...item, status: !item.status }] */
                }}
                type={"checkbox"}
                id={`community-role`}
                label={item.name}
              />
            </Col>
          );
        })}
      </Row>
      <hr></hr>
      {/* ----------------------ข้อมูลปราชญ์ชาวบ้าน---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="mb-4 mt-4">ข้อมูลปราชญ์ชาวบ้าน</h5>
        </Col>
        <Col md={12}>
          <Form.Check
            disabled={showOnlyProps}
            checked={data.first.sage.have}
            inline
            label="มีความรู้ความสามารถเฉพาะด้าน"
            name="garden"
            type="checkbox"
            id={`work-checkbox-1`}
            onChange={(e) => {
              let updateData = { ...data };
              updateData.first.sage.have = e.target.checked;
              if (!!updateData.first.sage.have) {
                updateData.first.sage.data.push({ type: "", name: "" });
              } else {
                updateData.first.sage.data = [];
              }
              setData(updateData);
            }}
          />
        </Col>
        <Col md={12} className="mt-2">
          {data.first.sage.data.map((d, index) => {
            return (
              <React.Fragment key={index}>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{index + 1}. ด้านความสามารถ</Form.Label>
                      <Form.Select
                        disabled={showOnlyProps}
                        aria-label="Default select example"
                        value={data.first.sage.data[index].type}
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.sage.data[index].type =
                            e.target.value;
                          setData(updateData);
                        }}
                      >
                        {!![
                          "ด้านเกษตรกรรม",
                          "ด้านอุตสาหกรรมและหัตถกรรม",
                          "ด้านการแพทย์แผนไทย",
                          "ด้านศิลปกรรม",
                          "ด้านโภชนาการ",
                        ].includes(data.first.sage.data[index].type) && (
                          <option>อื่นๆ</option>
                        )}
                        <option></option>
                        <option value="ด้านเกษตรกรรม">ด้านเกษตรกรรม</option>
                        <option value="ด้านอุตสาหกรรมและหัตถกรรม">
                          ด้านอุตสาหกรรมและหัตถกรรม
                        </option>
                        <option value="ด้านการแพทย์แผนไทย">
                          ด้านการแพทย์แผนไทย
                        </option>
                        <option value="ด้านศิลปกรรม">ด้านศิลปกรรม</option>
                        <option value="ด้านโภชนาการ">ด้านโภชนาการ</option>
                        <option value="อื่นๆ">อื่นๆ</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {data.first.sage.data[index].type === "อื่นๆ" ? (
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>ระบุด้านอื่นๆ</Form.Label>
                        <Form.Control
                          disabled={showOnlyProps}
                          type="text"
                          value={data.first.sage.data[index].other_type}
                          onChange={(e) => {
                            let updateData = { ...data };
                            updateData.first.sage.data[index].other_type =
                              e.target.value;
                            setData(updateData);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        รายละเอียด (เกี่ยวกับอะไร, ทำอะไรได้)
                      </Form.Label>
                      <Form.Control
                        disabled={showOnlyProps}
                        type="text"
                        value={data.first.sage.data[index].name}
                        onChange={(e) => {
                          let updateData = { ...data };
                          updateData.first.sage.data[index].name =
                            e.target.value;
                          setData(updateData);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <hr></hr>
                </Row>
              </React.Fragment>
            );
          })}
        </Col>
        {!showOnlyProps && data.first.sage.data.length > 0 ? (
          <Col md={12}>
            <div
              className="btn btn-success"
              onClick={() => {
                let updateData = { ...data };
                updateData.first.sage.data.push({ type: "", name: "" });
                setData(updateData);
              }}
            >
              เพิ่มข้อมูล
            </div>
          </Col>
        ) : null}
      </Row>

      <hr></hr>
      {/* ----------------------ข้อมูลบิดา-มารดา---------------------------- */}
      <Row>
        <Col md={12}>
          <h5 className="my-4">ข้อมูลบิดา-มารดา</h5>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>รหัสประจำตัวประชาชน(บิดา)</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="number"
              placeholder="รหัสประจำตัวประชาชน"
              min={0}
              value={
                data.first.information.fatherId
                  ? data.first.information.fatherId
                  : data.first.information.father_id
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      fatherId: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อ(บิดา)</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              placeholder="กรอกชื่อ"
              value={
                data.first.information.fatherName
                  ? data.first.information.fatherName
                  : data.first.information.father_name
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      fatherName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>นามสกุล(บิดา)</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              placeholder="กรอกนามสกุล"
              value={
                data.first.information.fatherSurname
                  ? data.first.information.fatherSurname
                  : data.first.information.father_surname
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      fatherSurname: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>รหัสประจำตัวประชาชน(มารดา)</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="number"
              placeholder="รหัสประจำตัวประชาชน"
              min={0}
              value={
                data.first.information.mathorId
                  ? data.first.information.mathorId
                  : data.first.information.mothor_id
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      mathorId: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อ(มารดา)</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              placeholder="กรอกชื่อ"
              value={
                data.first.information.mathorName
                  ? data.first.information.mathorName
                  : data.first.information.mother_name
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      mathorName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>นามสกุล(มารดา)</Form.Label>
            <Form.Control
              disabled={showOnlyProps}
              type="text"
              placeholder="กรอกนามสกุล"
              value={
                data.first.information.mothorSurname
                  ? data.first.information.mothorSurname
                  : data.first.information.mothor_surname
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first: {
                    ...data.first,
                    information: {
                      ...data.first.information,
                      mothorSurname: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      {/* <Row>
        <Col className="d-flex justify-content-end">
          <div className="btn btn-success"
            style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
            onClick={() => submitData()}>
            {textSubmit === 'กำลังบันทึกข้อมูล' ? <Spinner size="sm" animation="border" variant="light" />:null}{textSubmit}
          </div>
        </Col>
      </Row> */}
    </Container>
  );
}

export default SectionOne