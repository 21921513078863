import React from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { BsGridFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { Button, Row, Container, Col } from "react-bootstrap";
import { RiCommunityLine, RiAccountBoxLine } from "react-icons/ri";
import { AiOutlineBarChart } from "react-icons/ai";

function Home() {
  const history = useHistory();
  const isdesktop = useMediaQuery({ minWidth: 992 });
  const styles = {
    button: {
      minWidth: "220px",
      height: "60px",
      borderRadius: "8px",
      backgroundColor: "#38AFBA",
    },
  };
  return (
    <Main>
      <div
        className="banner"
        style={{
          backgroundImage:
            "url(" + require("../../src/assets/images/1.jpg") + ")",
        }}
      >
        <div className="banner-title">
          <div className="banner-title-content">
            <h1>
              <b>ecotive</b>
            </h1>
            <span>
              วิจัยแลพัฒนาระบบนิเวศเชิงสร้างสรรค์
              <br />
              เพื่อการเป็นผู้ประกอบการรายย่อย
              <br />
              สู่โมเดลพึ่งตนเอง จังหวัดปัตตานี
            </span>
            <br />
            <Button
              size="lg"
              onClick={() => history.push("/login")}
              style={{
                display: "flex",
                alignItems: "center",
                width: "150px",
              }}
            >
              เข้าสู่ระบบ
              <BsArrowRight style={{ marginLeft: "10px" }} />
            </Button>
          </div>
          <div className="banner-title-filter" />
        </div>
        <div className="section">
          <div className="tab-section">
            <Button
              onClick={() => history.push("/personal-form")}
              className="tab-section-item"
            >
              <RiAccountBoxLine
                fontSize={"32px"}
                style={{ marginRight: "10px" }}
              />
              แบบฟอร์มข้อมูลบุคคล
            </Button>
            <div class="vr" />
            <Button
              className="tab-section-item"
              onClick={() => history.push("/community-form")}
            >
              <RiCommunityLine
                fontSize={"32px"}
                style={{ marginRight: "10px" }}
              />
              แบบฟอร์มข้อมูลชุมชน
            </Button>
            <div class="vr" />
            <Button
              className="tab-section-item"
              onClick={() => history.push("/dashboard")}
            >
              <AiOutlineBarChart
                fontSize={"36px"}
                style={{ marginRight: "10px" }}
              />
              {/* <IconDash /> */}
              ข้อมูลภาพรวม
            </Button>
          </div>
        </div>
        <div className="filter" />
      </div>
    </Main>
  );
}
{
  /* <Row>
            <Col md={7}  >
                <Container >
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            <Contain >
                                <h2>
                                    เลือกแบบฟอร์มสำหรับป้อนข้อมูลเข้าสู่ระบบ
                                </h2>
                                <Row xs={12} md={12} lg={12} className={isdesktop ? "mt-5" : 'mt-3'}>
                                    <p className="text-center"><Link to='/personal-form'><Button variant="white" className={isdesktop ? "w-45" : 'w-100'} style={styles.button}>
                                        <span className="d-flex justify-content-center text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-clipboard2-data mx-2" viewBox="0 0 16 16">
                                                <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                                                <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                                                <path d="M10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1Z" />
                                            </svg> แบบฟอร์มข้อมูลบุคคล</span></Button></Link></p>

                                </Row>
                                <Row xs={12} md={12} lg={12} className={isdesktop ? "mt-2" : 'mt-3'}>
                                    <p className="text-center"><Link to='/community-form'><Button variant="white" className={isdesktop ? "w-45" : 'w-100'} style={styles.button}>
                                        <span className="d-flex justify-content-center text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-clipboard2-data mx-2" viewBox="0 0 16 16">
                                                <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                                                <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                                                <path d="M10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1Z" />
                                            </svg> แบบฟอร์มข้อมูลชุมชน</span></Button></Link></p>
                                </Row>
                                <Row xs={12} md={12} lg={12} className={isdesktop ? "mt-2" : 'mt-3'}>
                                    <p className="text-center">
                                        <Link to='/dashboard' style={{textDecoration:"none"}}>
                                            <BoxContent variant="white" style={styles.button}>
                                                <div className='boxText'>
                                                    <IconDash />
                                                    ข้อมูลภาพรวม
                                                </div>
                                            </BoxContent>
                                        </Link></p>
                                </Row>
                            </Contain>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <ImageSide isdesktop={{isdesktop}} >
                <img
                    src={require('../../src/assets/images/aerial-view-businessman-using-computer-laptop.jpg')}
                    style={{ objectFit: "cover", width: "1220px", height: "100%" }}
                />
            </ImageSide>
        </Row> */
}
export default Home;
const Main = styled.div`
  display: grid;
  justify-items: center;
  box-sizing: content-box;;
  height: 100%;
  .banner {
    box-sizing: content-box;;
    position: relative;
    width: 100%;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: flex-end;
    justify-content: center;
    .filter {
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #4f4f4f42;
    }
    .banner-title {
      left: 0;
      top: 18%;
      position: absolute;
      z-index: 2;
      /* margin-left: 50px; */
      overflow: hidden;
      height: 45%;
      width: 40%;
      border-bottom: solid 5px white;
      .banner-title-content {
        position: relative;
        z-index: 2;
        margin-left: 50px;
        padding: 10px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: center;
        span {
          font-size: 28px;
          color: #eaeaea;
        }
      }
      .banner-title-filter {
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        position: absolute;
        background-color: #2323239f;
        /* backdrop-filter: blur(8px); */
      }
    }
    .section {
      box-sizing: content-box;;
      /* background-color: #e4e4e4; */
      background-color: white;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 15vh;
      width: 70%;
      .tab-section {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 80%;
        width: 100%;
        .tab-section-item {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          min-width: 245px;
          height: 100%;
          border: none;
          color: black;
          /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
          &:hover {
            background-color: #e3e3e3;
          }
        }
      }
    }
  }

  /* .body {
    width: 100%;
    height: 40vh;
    background-color: #38afba;
  } */
`;
const Contain = styled(Container)`
  height: 90vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
`;
const ImageSide = styled(Col)`
  overflow: hidden;
  padding: 0;
  ${(isDesktop) => isDesktop.isDesktop && "display:none"};
`;

const IconDash = styled(BsGridFill)`
  font-size: 24px;
  margin-right: 10px;
`;
const BoxContent = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  .boxText {
    color: white;
    width: 85%;
    display: flex;
  }
`;
