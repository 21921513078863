import React from 'react'
import { BrowserRouter, HashRouter } from 'react-router-dom';
import './App.css';
import RouteLink from './Router/RouteLink'
import ContextProvider from './context/providerComposer'

const AuthContext = React.createContext();
function App() {
  return (
    <div className="App">
      <ContextProvider>
        <HashRouter>
          <RouteLink />
        </HashRouter>
      </ContextProvider>
    </div>
  );
}
export { AuthContext }
export default App;
