import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { CgClose } from "react-icons/cg";

function CommunityImage({ data, setData, main_index, sub_index, structure }) {
  console.log("CommunityImage", data);
  async function handleUploadeFile(event, imgIndex) {
    const file = event.target.files[0];
    const resizedFile = await ResizeImage(file);
    const base64File = await convertToBase64(resizedFile);
    let updateData = { ...data };
    updateData.third.structure_resource[main_index].data[sub_index].image[
      imgIndex
    ].url = base64File;
    setData(updateData);
  }

  function ResizeImage(imageFile) {
    return new Promise((resolve, reject) => {
      const imageFilname = imageFile.name;
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          //------------- Resize img code ----------------------------------
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = 500;
          var MAX_HEIGHT = 500;
          var width = img.width;
          var height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob(
            (blob) => {
              const file = new File([blob], imageFilname, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(file);
            },
            "image/jpeg",
            1
          );
        };
        img.onerror = (error) => {
          reject("error", error);
          return false;
        };
      };
    });
  }
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileRender = new FileReader();
      fileRender.readAsDataURL(file);
      fileRender.onload = () => {
        resolve(fileRender.result);
      };
      fileRender.onerror = (error) => {
        reject(error);
      };
    });
  }
  return (
    <Row className="mb-3">
      {structure.data[sub_index].image.map((item, imgIndex) => {
        return (
          <>
            <Row key={imgIndex} className="mb-3" md={12}>
              <Col md={6}>
                <Form.Group
                  style={{width:"70%"}}
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{imgIndex + 1}. รูปภาพ</Form.Label>
                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Small file input example</Form.Label>
                    <Form.Control
                      onChange={(event) => handleUploadeFile(event, imgIndex)}
                      type="file"
                      size="sm"
                    />
                  </Form.Group>
                  {/* <Form.Control
                  type="text"
                  name="resource_address"
                  placeholder="url"
                  value={
                    data.third.structure_resource[main_index].data[sub_index]
                      .image[imgIndex].url
                  }
                  onChange={(e) => {
                    let updateData = { ...data };
                    updateData.third.structure_resource[main_index].data[
                      sub_index
                    ].image[imgIndex].url = e.target.value;
                    setData(updateData);
                  }}
                /> */}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={"auto"}>
                    <ImageStyle
                      image={item.url}
                      src={!!item?.url ? item.url : ""}
                    />
                  </Col>
                  <Col md={"auto"}>
                    <DeleteBox
                      onClick={() => {
                        let updateData = { ...data };
                        updateData.third.structure_resource[main_index].data[
                          sub_index
                        ].image.splice(imgIndex, 1);
                        setData(updateData);
                      }}
                    >
                      <CgClose className="DeleteIcon" fontSize={"24px"} />
                    </DeleteBox>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
          </>
        );
      })}
      <Row>
        <Col md={12}>
          <Button
            variant="success"
            onClick={() => {
              let updateData = { ...data };
              updateData.third.structure_resource[main_index].data[
                sub_index
              ].image.push({ url: "" });
              setData(updateData);
            }}
          >
            เพิ่มรูปภาพ
          </Button>
        </Col>
      </Row>
    </Row>
  );
}

export default CommunityImage;
const ImageStyle = styled.img`
  transition: all 0.3s;
  height: ${({ image }) => (!!image ? "100%" : "0px")};
`;
const DeleteBox = styled.div`
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 50%;
  border: solid 2px #a00000;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transition: all 0.3s;
    cursor: pointer;
    background-color: #a00000;
    border: solid #a00000;
    .DeleteIcon {
      transition: all 0.3s;
      color: white;
    }
  }
  .DeleteIcon {
    color: #a00000;
  }
`;
