import React from 'react'
import axios from 'axios';
import { PersonalContext } from "../../../context/personal";
import ModalOccupation from '../occupation/createOccupation'
import ModalOccupationPlace from "../occupation/createPlace"
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { apiConfig } from '../../../config/api';

function SectionThird({ showOnlyProps=false, personalIdProps }) {
  const { data, setData } = React.useContext(PersonalContext);
  const { personalId, setPersonalId } = React.useContext(PersonalContext);
  const [occupation, setOccupation] = React.useState([]);
  const [business, setBusiness] = React.useState([]);

  //state ข้อมูลที่อยู่ดึงมาจาก db เก็บที่นี้
  const [province, setProvince] = React.useState([]);

  //ดึงค่าจังหวัดจากหลังบ้าน
  const getProvince = async () => {
    const result = await axios(apiConfig.url + "/api/provinces");
    setProvince(result.data);
  };

  //state จัดการ modal สร้างข้อมูลอาชีพใหม่
  const [createOccupation, setCreateOccupation] = React.useState({
    status: false,
    index: null, //ไว้ระบุว่าเป็นข้อมูลอาชีพหลัก หรือ ข้อมูลอาชีพเสริมและที่เท่าไร
    section: 3,
  });

  //state จัดการ modal สร้างข้อมูลสถานประการใหม่
  const [createPlace, setCreatePlace] = React.useState({
    status: false,
    main: false, //ไว้ระบุว่าเป็นข้อมูลอาชีพหลัก หรือ ข้อมูลอาชีพเสริมและที่เท่าไร
  });
  //ดึงค่าอาชีพทั้งหมด
  const getOccupation = async () => {
    const result = await axios.get(apiConfig.url + "/api/occupations");
    setOccupation(result.data);
  };

  //ดึงค่าสถานประกอบการทั้งหมด
  const getBusiness = async () => {
    const result = await axios.get(apiConfig.url + "/api/business");
    setBusiness(result.data);
  };

  const addRow = () => {
    let updateData = { ...data };
    let lengthData = updateData.third.experience.length;
    updateData.third.experience.push({
      ids: lengthData + 1,
      job: null,
      job_start: null,
      job_end: null,
      job_detail: null,
      bussinessId: null,
      leaveReason: null,
    });
    setData(updateData);
  };

  //ลบข้อมูลแถวที่เลือก
  const deleteRow = (index) => {
    const filterData = data.third.experience.filter((e) => e.ids !== index);
    setData({ ...data, third: { experience: filterData } });
  };
  const handleChange = (e, index) => {
    let updateData = { ...data };
    updateData.third.experience[index] = {
      ...updateData.third.experience[index],
      [e.target.name]: e.target.value,
    };
    setData(updateData);
  };

  const getInfo = async () => {
    const result = await axios.get(apiConfig.url + "/api/personal/get_job/" + personalId);
    let updateData = { ...data };
    updateData.third.experience = result.data.map((item) => {
      return { ...item, isSaved: true };
    });
    console.log(result.data);
    setData(updateData);
  };

  //จะทำเมื่อเริ่มเปิดหน้านี้
  React.useEffect(() => {
    getOccupation();
    getBusiness();
    getProvince();
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (personalIdProps) {
      setPersonalId(personalIdProps);
      getInfo();
    }
  }, []);

  const rowData = data.third.experience.map((row, index) => {
    return (
      <React.Fragment key={index}>
        {/*  <td><Button variant="danger" onClick={() => deleteRow(row.id)}>X</Button></td> */}
        <Row>
          {!showOnlyProps && (
            <Col md={1} className="pt-4">
              <Button
                variant="danger"
                disabled={data.third.experience[index].isSaved}
                onClick={() => deleteRow(row.ids)}
              >
                X
              </Button>
            </Col>
          )}
          <Col md={3}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{index + 1}. อาชีพ</Form.Label>
              <Form.Select
                disabled={showOnlyProps}
                aria-label="Default select example"
                name="job"
                value={
                  data.third.experience[index].job
                    ? data.third.experience[index].job
                    : data.third.experience[index].occupation_name
                }
                onChange={(e) => handleChange(e, index)}
              >
                <option></option>
                {occupation.map((item, key) => (
                  <option key={key} value={item.occupation_name}>
                    {item.occupation_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {/* <Col md={4}> <Button variant="success"
                            onClick={() => setCreateOccupation({ index:index, status: true, section: 3 })}>
                            เพิ่ม</Button></Col> */}

          <Col md={2}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ปีที่เริ่มทำงาน (พ.ศ.)</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="number"
                name="job_start"
                value={data.third.experience[index].job_start}
                onChange={(e) => handleChange(e, index)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ปีที่ออกจากงาน (พ.ศ.)</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="number"
                name="job_end"
                value={data.third.experience[index].job_end}
                onChange={(e) => handleChange(e, index)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ลักษณะงาน</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="text"
                name="job_detail"
                value={data.third.experience[index].job_detail}
                onChange={(e) => handleChange(e, index)}
              />
            </Form.Group>
          </Col>
          <Col md={1} className="pt-4"></Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>สถานที่ทำงาน</Form.Label>
              <input
                disabled={showOnlyProps}
                type="text"
                list="business"
                className="form-control"
                value={data.third.experience[index].place}
                name="place"
                onChange={(e) => handleChange(e, index)}
              />
              <datalist id="business">
                {business.map((item, key) => (
                  <option key={key} value={item.business_name} />
                ))}
              </datalist>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ที่ตั้งสถานที่ทำงาน</Form.Label>
              <Form.Select
                disabled={showOnlyProps}
                aria-label="Default select example"
                name="place_type"
                value={data.third.experience[index].place_type}
                onChange={(e) => handleChange(e, index)}
              >
                <option></option>
                <option value="ในประเทศ">ในประเทศ</option>
                <option value="ต่างประเทศ">ต่างประเทศ</option>
              </Form.Select>
            </Form.Group>
          </Col>
          {data.third.experience[index].place_type === "ในประเทศ" ? (
            <Col md={3}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>จังหวัด</Form.Label>
                <Form.Select
                  disabled={showOnlyProps}
                  aria-label="status"
                  name="province"
                  value={
                    data.third.experience[index].province
                      ? data.third.experience[index].province
                      : data.third.experience[index].province_id
                  }
                  onChange={(e) => handleChange(e, index)}
                >
                  <option></option>
                  {province.map((item, key) => {
                    return (
                      <option key={key} value={item.value}>
                        {item.text}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          ) : null}
          {data.third.experience[index].place_type === "ต่างประเทศ" ? (
            <Col md={3}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ประเทศ</Form.Label>
                <Form.Control
                  disabled={showOnlyProps}
                  type="text"
                  name="country"
                  value={data.third.experience[index].country}
                  onChange={(e) => handleChange(e, index)}
                />
              </Form.Group>
            </Col>
          ) : null}
          {/*  <Col md={4}> <Button variant="success"
                            onClick={() => setCreatePlace({ index: index, status: true, section: 3 })}>
                            เพิ่ม</Button></Col> */}
          <Col md={1} className="pt-4"></Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>สาเหตุที่ออก</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="text"
                name="leaveReason"
                value={
                  data.third.experience[index].leaveReason
                    ? data.third.experience[index].leaveReason
                    : data.third.experience[index].leave_reason
                }
                onChange={(e) => handleChange(e, index)}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr></hr>
      </React.Fragment>
    );
  });

  return (
    <Container>
      {createOccupation.status ? (
        <ModalOccupation
          createOccupation={createOccupation}
          setCreateOccupation={setCreateOccupation}
          getOccupation={getOccupation}
        />
      ) : null}
      {createPlace.status ? (
        <ModalOccupationPlace
          createPlace={createPlace}
          setCreatePlace={setCreatePlace}
          getBusiness={getBusiness}
        />
      ) : null}
      <h5 className="mb-4 mt-4">ข้อมูลประวัติการทำงาน</h5>

      {rowData}

      {!showOnlyProps &&(<div
        className="btn btn-primary w-100"
        style={{ marginTop: "30px", marginBottom: "30px", width: "200px" }}
        onClick={addRow}
      >
        เพิ่มแถวข้อมูล
      </div>)}
      {/* <Row>
                <Col md={6}>
                    <div className="d-flex justify-content-start">
                        <div className="btn btn-info"
                        style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
                            onClick={() => setPage(page - 1)}>
                            กลับ
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="d-flex justify-content-end">
                        <div className="btn btn-info"
                            style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
                            onClick={() => setPage(page + 1)}>
                            ถัดไป
                        </div>
                    </div>
                </Col>
            </Row> */}
    </Container>
  );
}

export default SectionThird