import React, { cloneElement } from "react"
import { PersonalContextProvider } from "./personal"
import { CommunityContextProvider } from "./community"
import { AuthContextProvider } from "./auth/authContext/authContextState"

function ProviderComposer({ contexts, children }) {
    return contexts.reduce(
        (kids, parent) =>
            cloneElement(parent, {
                children: kids
            }),
        children
    )
}
export default function ContextProvider({ children }) {
    return (
        <ProviderComposer
            // add providers to array of contexts
            contexts={[<PersonalContextProvider />, <CommunityContextProvider />,<AuthContextProvider />]}
            >
            {children}
        </ProviderComposer>
    )
}