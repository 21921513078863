import React from "react";
import styled from "styled-components";
import LoadingAnimation from "../loadingAnimation/loading";

export default function LoadingPage({loading,backGroundColor,animatioColor,borderRadius}) {
  return (
    <Loading loading={loading} backGroundColor={backGroundColor} borderRadius={borderRadius}>
      <div className="content">
        <LoadingAnimation color={animatioColor} />
      </div>
    </Loading>
  );
}

const Loading = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(10px);
  position: absolute;
  transition: all 0.5s ease-in-out;
  background-color:${(props) => props.backGroundColor ? props.backGroundColor : "rgba(255, 255, 255, 0.5)"};
  border-radius:${(props) => !!props.borderRadius && props.borderRadius};
  opacity: ${(props) => props.loading ? "1" : "0"};
  visibility:${(props) => props.loading ? "visible" : "hidden"};
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
