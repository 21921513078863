import React from "react";
import axios from "axios";
import styled from "styled-components";
import TableLayout from "../table/table";
import { Button, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ApiFetchFile } from "../../config/api";
import { useTransition, animated } from "react-spring";
import { FetchInterface, Method } from "../../enums/enum";
import ModalDashBoardTable from "../dashboard/personal/modal/ModalDashBoardTable";
import { useAuthContextState } from "../../context/auth/authContext/authContextState";

export default function PersonalTableDashboard({
  title,
  tableData,
  modalShow,
  setTableData,
  setModalShow,
  tableDataForFilter,
  disableFilter,
}) {
  const [modalShowPersonalDetial, setModalShowPersonalDetial] =
    React.useState(false);
  const [personalId, setPersonalId] = React.useState(false);
  const { token } = useAuthContextState();

  function handlefilterSalary(e) {
    const filtered = tableDataForFilter.data.filter((personal) => {
      return personal.salary == e.target.value;
    });
    setTableData({ ...tableData, data: { ...tableData.data, data: filtered } });
  }

  function handleClose() {
    setModalShow(false);
    /* setTableData({
      header: "",
      data: [],
    }); */
  }
  async function handleExportExcel(data, title) {
    try {
      const res = await ApiFetchFile({
        [FetchInterface.PATH]: `auth/export-dashboard/${title}`,
        [FetchInterface.METHOD]: Method.POST,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.DATA]: data,
      });
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      axios.defaults.responseType = "application/json";
    } catch (error) {
      axios.defaults.responseType = "application/json";
      console.log(error);
    }
  }
  const transition = useTransition(modalShow, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 405, overflow: "hidden" },
    leave: {
      height: 0,
    },
  });
  return transition(
    (styles, item) =>
      item && (
        <animated.div style={styles}>
          <Header>
            <div className="start-info mt-3">
              <span className="table-title">{title}</span>
              {!disableFilter && (
                <div className="filter">
                  <Form.Select
                    className="mt-3"
                    style={{ width: "120px" }}
                    size="sm"
                    onChange={(e) => handlefilterSalary(e)}
                  >
                    <option hidden value="ช่วงรายได้">
                      ช่วงรายได้
                    </option>
                    <option value={"ต่ำกว่า 5,000"}>ต่ำกว่า 5,000</option>
                    <option value={"5,000 - 10,000"}>5,000 - 10,000</option>
                    <option value={"11,000 - 15,000"}>11,000 - 15,000</option>
                    <option value={"16,000 - 20,000"}>16,000 - 20,000</option>
                    <option value={"21,000 - 25,000"}>21,000 - 25,000</option>
                    <option value={"26,000 - 30,000"}>26,000 - 30,000</option>
                    <option value={"มากก่า 30,000"}>มากก่า 30,000</option>
                  </Form.Select>
                  {/*  <Button
                    className="mt-3"
                    onClick={() => handleExportExcel(tableData.data, title)}
                  >
                    Export excel file
                  </Button> */}
                </div>
              )}
            </div>
            <AiOutlineClose
              className="mt-3"
              onClick={() => handleClose()}
              fontSize="24px"
              cursor={"pointer"}
            />
          </Header>
          <TableContent>
            <TableLayout
              setModalShowPersonalDetial={setModalShowPersonalDetial}
              setPersonalId={setPersonalId}
              data={tableData.data}
            />
          </TableContent>
          <ModalDashBoardTable
            personalId={personalId}
            setPersonalId={setPersonalId}
            modalShowPersonalDetial={modalShowPersonalDetial}
            setModalShowPersonalDetial={setModalShowPersonalDetial}
          />
        </animated.div>
      )
  );
}
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1.5px #d4d4d4;
  padding: 5px 0 5px 0;
  .start-info {
    .table-title {
      font-size: 18px;
    }
    .filter {
      display: flex;
      margin-top: 5px;
      select {
        height: 30px;
      }
      button {
        background-color: green;
        margin-left: 10px;
        display: flex;
        align-items: center;
        height: 30px;
      }
    }
  }
`;
const TableContent = styled.div`
  height: 59%;
  overflow-y: auto;
  margin-top: 5px;
`;
