import React from 'react'
import axios from 'axios';
import { WatDatePicker } from 'thaidatepicker-react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";
import { apiConfig } from '../../../config/api';

function SectionFifth({ showOnlyProps = false, personalIdProps }) {
  const { data, setData } = React.useContext(PersonalContext);
  const { personalId, setPersonalId } = React.useContext(PersonalContext);
  //state ข้อมูลที่อยู่ดึงมาจาก db เก็บที่นี้
  const [province, setProvince] = React.useState([]);
  //state ข้อมูล dropdown ศาสนา
  const religion = [
    { text: "อิสลาม", value: "อิสลาม" },
    { text: "พุทธ", value: "พุทธ" },
    { text: "คริสต์", value: "คริสต์" },
  ];

  //state ข้อมูล dropdown สัญชาติ
  const national = [
    { text: "ไทย", value: "ไทย" },
    { text: "มาเลย์", value: "มาเลย์" },
  ];

  //ดึงค่าจังหวัดจากหลังบ้าน
  const getProvince = async () => {
    const result = await axios(apiConfig.url + "/api/provinces");
    setProvince(result.data);
  };

  //ดึงค่าอำเภอจากหลังบ้าน
  const getAmphur = async (id_province) => {
    const result = await axios(apiConfig.url + "/api/amphurs/" + id_province);
    return result.data;
  };

  //ดึงค่าตำบลจากหลังบ้าน
  const getTambol = async (id_amphur) => {
    const result = await axios(apiConfig.url + "/api/tambols/" + id_amphur);
    return result.data;
  };

  const getInfo = async () => {
    let result = await axios.get(apiConfig.url + "/api/personal/get_family/" + personalId);
    if (result.data.length > 0) {
      let updateData = { ...data };
      updateData.fifth.family = result.data;
      setData(updateData);
      data.fifth.family.forEach((item, index) => {
        provinceChange(item.province_id, index);
        amphurChange(item.amphur_id, index);
      });
    }
  };

  //จะทำเมื่อเริ่มเปิดหน้านี้
  React.useEffect(() => {
    getInfo();
    getProvince();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (personalIdProps) {
      setPersonalId(personalIdProps);
      getInfo();
    }
  }, []);

  const AddFormInput = () => {
    setData({
      ...data,
      fifth: { family: [...data.fifth.family, {}] },
    });
  };

  //เมื่อเลือกจังหวัดทำฟังก์ชั่นนี้
  const provinceChange = async (e, index) => {
    if (!!e.target) {
      if (e.target.value !== "") {
        let updateData = { ...data };
        updateData.fifth.family[index].id_province = e.target.value;
        let amphur = await getAmphur(e.target.value);
        updateData.fifth.family[index].amphur = amphur;
        setData(updateData);
      }
    } else {
      let updateData = { ...data };
      updateData.fifth.family[index].id_province = e;
      let amphur = await getAmphur(e);
      updateData.fifth.family[index].amphur = amphur;
      setData(updateData);
    }
  };

  //เมื่อเลือกอำเภอทำฟังก์ชั่นนี้
  const amphurChange = async (e, index) => {
    if (!!e.target) {
      if (e.target.value !== "") {
        let updateData = { ...data };
        updateData.fifth.family[index].id_amphur = e.target.value;
        let tambol = await getTambol(e.target.value);
        updateData.fifth.family[index].tambol = tambol;
        setData(updateData);
      }
    } else {
      let updateData = { ...data };
      updateData.fifth.family[index].id_amphur = e;
      let tambol = await getTambol(e);
      updateData.fifth.family[index].tambol = tambol;
      setData(updateData);
    }
  };

  //เมื่อเลือกตำบลทำฟังก์ชั่นนี้
  const tambolChange = (e, index) => {
    if (!!e.target) {
      if (e.target.value !== "") {
        let updateData = { ...data };
        updateData.fifth.family[index].id_tambol = e.target.value;
        setData(updateData);
      }
    } else {
      let updateData = { ...data };
      updateData.fifth.family[index].id_tambol = e;
      setData(updateData);
    }
  };

  /*   ฟังก์ชั่นเรียกใช้เมื่อเซตค่า ศาสนา สัญชาติ */
  const handleChange = (e, index) => {
    let updateData = { ...data };
    updateData.fifth.family[index][e.target.name] = e.target.value;
    setData(updateData);
  };

  /*   ฟังก์ชั่นเซตค่า วันเกิด */
  const birthDate = (christDate, buddhistDate, index) => {
    let updateData = { ...data };
    updateData.fifth.family[index].date_birth = christDate;
    setData(updateData);
  };

  const SetValue = (key, value, index) => {
    let updateData = { ...data };
    updateData.fifth.family[index] = {
      ...data.fifth.family[index],
      [key]: value,
    };
    setData(updateData);
  };
  const DeleteValue = (index) => {
    let updateData = { ...data };
    let filterData = updateData.fifth.family.filter((e, num) => {
      if (num !== index) {
        return e;
      }
    });
    updateData.fifth.family = filterData;
    setData(updateData);
  };
  /* const calAge = (index) => {
        if (data.fifth.family[index].birthDateGLO) {
            let today = new Date();
            let birthDate = new Date(data.fifth.family[index].birthDateGLO);
            let age = today.getFullYear() - birthDate.getFullYear();
            return age + " ปี"
        }
        return 0 + " ปี"

    } */

  const ShowFormInput = data.fifth.family.map((e, index) => {
    return (
      <React.Fragment key={index}>
        {/* ----------------------แถว 1---------------------------- */}
        <Row>
          <Col md={12}>
            <h5 className="mb-4 mt-4">
              สมาชิกคนที่ {index + 1}{" "}
              {!data.fifth.family[index].id ||
                (!showOnlyProps && (
                  <div
                    className="btn btn-danger"
                    onClick={() => DeleteValue(index)}
                  >
                    ลบ
                  </div>
                ))}{" "}
            </h5>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>คำนำหน้าชื่อ</Form.Label>
              <Form.Select
                disabled={showOnlyProps}
                aria-label="Default select example"
                value={data.fifth.family[index].title}
                onChange={(e) => SetValue("title", e.target.value, index)}
              >
                <option value=""></option>
                <option value="เด็กชาย">เด็กชาย</option>
                <option value="เด็กหญิง">เด็กหญิง</option>
                <option value="นาย">นาย</option>
                <option value="นาง">นาง</option>
                <option value="นางสาว">นางสาว</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>ชื่อ</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="text"
                placeholder="กรอกชื่อ"
                required
                value={data.fifth.family[index].first_name}
                onChange={(e) => SetValue("first_name", e.target.value, index)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>นามสกุล</Form.Label>
              <Form.Control
                disabled={showOnlyProps}
                type="text"
                placeholder="กรอกนามสกุล"
                required
                value={data.fifth.family[index].last_name}
                onChange={(e) => SetValue("last_name", e.target.value, index)}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* ----------------------แถว 2---------------------------- */}
        <Row>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>วันเดือนปีเกิด</Form.Label>
              <br></br>
              <WatDatePicker
                value={data.fifth.family[index].date_birth}
                inputStyle={{ height: "38px", width: "auto" }}
                onChange={(christDate, buddhistDate) =>
                  birthDate(christDate, buddhistDate, index)
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD MMMM YYYY"}
                clearable={true}
                readOnly={false}
                maxDate={"2030-1-1"}
                yearBoundary={99}
                disabled={showOnlyProps}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>ศาสนา</Form.Label>
              <input
                disabled={showOnlyProps}
                type="text"
                list="religion"
                name="religion"
                className="form-control"
                value={data.fifth.family[index].religion}
                onChange={(e) => handleChange(e, index)}
              />
              <datalist id="religion">
                {religion.map((item, key) => (
                  <option key={key} value={item.text} />
                ))}
              </datalist>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>สัญชาติ</Form.Label>
              <input
                disabled={showOnlyProps}
                type="text"
                list="nationality"
                name="nationality"
                className="form-control"
                value={data.fifth.family[index].nationality}
                onChange={(e) => handleChange(e, index)}
              />
              <datalist id="nationality">
                {national.map((item, key) => (
                  <option key={key} value={item.text} />
                ))}
              </datalist>
            </Form.Group>
          </Col>
        </Row>
        {/* ----------------------แถว 3---------------------------- */}
        <Row>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>เพศ</Form.Label>
              {["radio"].map((type) => (
                <div key={`gender-${type}`} className="mb-3">
                  <Form.Check
                    disabled={showOnlyProps}
                    checked={
                      data.fifth.family[index].gender === "ชาย" ? true : false
                    }
                    inline
                    label="ชาย"
                    name={`gender-${index}`}
                    type={type}
                    value="ชาย"
                    id={`gender-${type}-1`}
                    required
                    onChange={(e) => SetValue("gender", "ชาย", index)}
                  />
                  <Form.Check
                    disabled={showOnlyProps}
                    checked={
                      data.fifth.family[index].gender === "หญิง" ? true : false
                    }
                    inline
                    label="หญิง"
                    name={`gender-${index}`}
                    type={type}
                    id={`gender-${type}-2`}
                    required
                    onChange={(e) => SetValue("gender", "หญิง", index)}
                  />
                </div>
              ))}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>สถานะ</Form.Label>
              {["radio"].map((type) => (
                <div key={`marital_status-${type}`} className="mb-3">
                  <Form.Check
                    disabled={showOnlyProps}
                    checked={
                      data.fifth.family[index].marital_status === "โสด"
                        ? true
                        : false
                    }
                    inline
                    label="โสด"
                    name={`marital_status-${index}`}
                    type={type}
                    id={`marital_status-${type}-1`}
                    required
                    onChange={(e) => SetValue("marital_status", "โสด", index)}
                  />
                  <Form.Check
                    disabled={showOnlyProps}
                    checked={
                      data.fifth.family[index].marital_status === "แต่งงาน"
                        ? true
                        : false
                    }
                    inline
                    label="แต่งงาน"
                    name={`marital_status-${index}`}
                    type={type}
                    id={`marital_status-${type}-2`}
                    required
                    onChange={(e) =>
                      SetValue("marital_status", "แต่งงาน", index)
                    }
                  />
                  <Form.Check
                    disabled={showOnlyProps}
                    checked={
                      data.fifth.family[index].marital_status === "หย่า"
                        ? true
                        : false
                    }
                    inline
                    label="หย่า"
                    name={`marital_status-${index}`}
                    type={type}
                    id={`marital_status-${type}-2`}
                    required
                    onChange={(e) => SetValue("marital_status", "หย่า", index)}
                  />
                  <Form.Check
                    disabled={showOnlyProps}
                    checked={
                      data.fifth.family[index].marital_status === "หม้าย"
                        ? true
                        : false
                    }
                    inline
                    label="หม้าย"
                    name={`marital_status-${index}`}
                    type={type}
                    id={`marital_status-${type}-2`}
                    required
                    onChange={(e) => SetValue("marital_status", "หม้าย", index)}
                  />
                </div>
              ))}
            </Form.Group>
          </Col>
          <Col md={3}>
            {data.fifth.family[index].id ? (
              <Form.Group className="mb-3">
                <Form.Label>การอาศัยอยู่</Form.Label>
                {["radio"].map((type) => (
                  <div key={`gender-${type}`} className="mb-3">
                    <Form.Check
                      disabled={data.fifth.family[index].id || showOnlyProps}
                      checked={
                        data.fifth.family[index].haveAddress === false ||
                        (data.fifth.family[index].address_id ===
                          data.first.information.address_id &&
                          data.fifth.family[index].haveAddress === false) ||
                        data.fifth.family[index].address_id ===
                          data.first.information.address_id
                          ? true
                          : false
                      }
                      inline
                      label="อยู่บ้านเดียวกัน"
                      name={`address-${index}`}
                      type={type}
                      value="ชาย"
                      id={`gender-${type}-1`}
                      required
                      onChange={(e) => SetValue("haveAddress", false, index)}
                    />
                    <Form.Check
                      disabled={data.fifth.family[index].id || showOnlyProps}
                      checked={
                        data.fifth.family[index].haveAddress === true ||
                        data.fifth.family[index].address_id !==
                          data.first.information.address_id
                          ? true
                          : false
                      }
                      inline
                      label="อยู่ละคนบ้าน"
                      name={`address-${index}`}
                      type={type}
                      id={`gender-${type}-2`}
                      required
                      onChange={(e) => SetValue("haveAddress", true, index)}
                    />
                  </div>
                ))}
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <Form.Label>การอาศัยอยู่</Form.Label>
                {["radio"].map((type) => (
                  <div key={`gender-${type}`} className="mb-3">
                    <Form.Check
                      disabled={showOnlyProps}
                      checked={
                        data.fifth.family[index].haveAddress === false ||
                        (data.fifth.family[index].address_id ===
                          data.first.information.address_id &&
                          data.fifth.family[index].haveAddress === false)
                          ? true
                          : false
                      }
                      inline
                      label="อยู่บ้านเดียวกัน"
                      name={`address-${index}`}
                      type={type}
                      value="ชาย"
                      id={`gender-${type}-1`}
                      required
                      onChange={(e) => SetValue("haveAddress", false, index)}
                    />
                    <Form.Check
                      disabled={showOnlyProps}
                      checked={
                        data.fifth.family[index].haveAddress === true
                          ? true
                          : false
                      }
                      inline
                      label="อยู่ละคนบ้าน"
                      name={`address-${index}`}
                      type={type}
                      id={`gender-${type}-2`}
                      required
                      onChange={(e) => SetValue("haveAddress", true, index)}
                    />
                  </div>
                ))}
              </Form.Group>
            )}
          </Col>
          {data.fifth.family[index].haveAddress === true ||
          (data.fifth.family[index].address_id &&
            data.fifth.family[index].address_id !==
              data.first.information.address_id) ? (
            <React.Fragment>
              <Col md={12}>
                <h5 className="mb-4 mt-4">ข้อมูลที่อยู่</h5>
              </Col>
              <Col md={1}>
                <Form.Group className="mb-3">
                  <Form.Label>บ้านเลขที่</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    type="text"
                    required
                    value={
                      data.fifth.family[index].no
                        ? data.fifth.family[index].no
                        : data.fifth.family[index].address_no
                    }
                    onChange={(e) => SetValue("no", e.target.value, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={1}>
                <Form.Group className="mb-3">
                  <Form.Label>หมู่</Form.Label>
                  <Form.Control
                    disabled={showOnlyProps}
                    type="number"
                    required
                    value={
                      data.fifth.family[index].moo
                        ? data.fifth.family[index].moo
                        : data.fifth.family[index].address_group
                    }
                    onChange={(e) => SetValue("moo", e.target.value, index)}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>จังหวัด</Form.Label>
                  <Form.Select
                    disabled={showOnlyProps}
                    aria-label="Default select example"
                    value={
                      data.fifth.family[index].id_province
                        ? data.fifth.family[index].id_province
                        : data.fifth.family[index].province_id
                    }
                    onChange={(e) => provinceChange(e, index)}
                  >
                    <option></option>
                    {province.map((province) => {
                      return (
                        <option key={province.value} value={province.value}>
                          {province.text}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>อำเภอ</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={
                      data.fifth.family[index].id_amphur
                        ? data.fifth.family[index].id_amphur
                        : data.fifth.family[index].amphur_id
                    }
                    onChange={(e) => amphurChange(e, index)}
                    disabled={
                      data.fifth.family[index].amphur ||
                      data.fifth.family[index].id ||
                      showOnlyProps
                        ? false
                        : true
                    }
                  >
                    <option></option>
                    {data.fifth.family[index].amphur
                      ? data.fifth.family[index].amphur.map((amphur) => {
                          return (
                            <option key={amphur.value} value={amphur.value}>
                              {amphur.text}
                            </option>
                          );
                        })
                      : null}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>ตำบล</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={
                      data.fifth.family[index].id_tambol
                        ? data.fifth.family[index].id_tambol
                        : data.fifth.family[index].tambol_id
                    }
                    onChange={(e) => tambolChange(e, index)}
                    disabled={
                      data.fifth.family[index].tambol || showOnlyProps
                        ? false
                        : true
                    }
                  >
                    <option></option>
                    {data.fifth.family[index].tambol
                      ? data.fifth.family[index].tambol.map((tambol) => {
                          return (
                            <option key={tambol.value} value={tambol.value}>
                              {tambol.text}
                            </option>
                          );
                        })
                      : null}
                  </Form.Select>
                </Form.Group>
              </Col>
            </React.Fragment>
          ) : null}
        </Row>
        <hr></hr>
      </React.Fragment>
    );
  });

  return (
    <Container>
      {ShowFormInput}
      <p className="text-center">
       {!showOnlyProps &&(<div
          className="btn btn-primary"
          style={{ marginTop: "30px", marginBottom: "30px", width: "200px" }}
          onClick={AddFormInput}
        >
          เพิ่มข้อมูลสมาชิกครอบครัว
        </div>)}
      </p>
      {/* <Row>
                <Col md={6}>
                    <div className="d-flex justify-content-start">
                        <div className="btn btn-info"
                            style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
                            onClick={() => setPage(page - 1)}>
                            กลับ
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="d-flex justify-content-end">
                        <div className="btn btn-info"
                            style={{ marginTop: '30px', marginBottom: '30px', width: '100%' }}
                            onClick={() => setPage(page + 1)}>
                            ถัดไป
                        </div>
                    </div>
                </Col>
            </Row> */}
    </Container>
  );
}

export default SectionFifth