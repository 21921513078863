import axios from "axios";
import React from "react";
import styled from "styled-components";
import { apiConfig } from "../../../../config/api";
import { useAuthContextState } from "../../../../context/auth/authContext/authContextState";
import ExcelFileDownload from "./excelFileDownload/excelFileDownload";

function AddressInfomation({ address }) {
  const [addressInfomationData, setAddressInfomationData] = React.useState({});
  const { token } = useAuthContextState();
  async function getAddressInfomation() {
    setAddressInfomationData({});
    if (!address.tambol) return false;
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/get-address-infomation/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`
      );
      if (res.status === 200) {
        setAddressInfomationData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getAddressInfomation();
  }, [address.tambol, address.group]);
  return (
    <Contai>
      <div className="heade">
        <div className="location">ข้อมูลประชากรใน {address.addressName}</div>
        {!!token.accessToken && (
          <>
            {/* <div className="d-flex" style={{ height: "80%" }}>
              <div className="vr" />
            </div> */}
            <ExcelFileDownload address={address} />
          </>
        )}
      </div>
      <div className="main">
        <div className="poulation-sum">
          <span>จำนวนประชากรตามทะเบียนราษฎร์ </span>
          <span className="content-poulation-sum">
            {!!addressInfomationData.population
              ? `${addressInfomationData.population} คน`
              : "ไม่มีข้อมูล"}{" "}
          </span>
          <HorizonLine>
            <div className="line" />
          </HorizonLine>
          <div className="village-count">
            <span>จำนวนครัวเรือนตามทะเบียนราษฎร์</span>
            <span className="content-village-count">
              {!!addressInfomationData?.household
                ? `${addressInfomationData.household} หลังคาเรือน`
                : "ไม่มีข้อมูล"}{" "}
            </span>
          </div>
        </div>
        <div className="d-flex" style={{ height: "90%" }}>
          <div className="vr" />
        </div>
        <div className="poulation-sum-in-database">
          <span>จำนวนรายชื่อที่บันทึกอยู่ในระบบ</span>
          <span className="content-poulation-sum-in-database">
            {!!addressInfomationData.population_in_database
              ? `${addressInfomationData.population_in_database} คน`
              : "ไม่มีข้อมูล"}
          </span>
          <HorizonLine>
            <div className="line" />
          </HorizonLine>
          <div className="village-count">
            <span>จำนวนครัวเรือนที่บันทึกในระบบ</span>
            <span className="content-village-count">
              {!!addressInfomationData?.household_in_database
                ? `${addressInfomationData.household_in_database} หลังคาเรือน`
                : "ไม่มีข้อมูล"}{" "}
            </span>
          </div>
        </div>
        <div className="d-flex" style={{ height: "90%" }}>
          <div className="vr" />
        </div>
        <div className="poulation-average">
          <span>แสดงผลคิดเป็นร้อยละ</span>
          <span className="content-poulation-average-in-database">
            {!!addressInfomationData?.population_in_database_percent
              ? `${addressInfomationData.population_in_database_percent.toFixed(
                  2
                )}  ของจำนวนประชากรตามทะเบียนราษฎร์`
              : "ไม่มีข้อมูล"}{" "}
          </span>
          <HorizonLine>
            <div className="line" />
          </HorizonLine>
          <span>แสดงผลคิดเป็นร้อยละ</span>
          <span className="content-poulation-average-in-database">
            {!!addressInfomationData?.household_in_database_percent
              ? `${addressInfomationData.household_in_database_percent.toFixed(
                  2
                )}  ของจำนวนหลังคาเรือนตามทะเบียนราษฎร์
            `
              : "ไม่มีข้อมูล"}{" "}
          </span>
        </div>
      </div>
    </Contai>
  );
}
export default AddressInfomation;
const Contai = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .heade {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    .location {
      width: 74%;
      padding: 5px 5px 5px 15px;
      font-weight: bold;
    }
    .files-download {
      height: 85%;
      width: 26%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      select {
        margin-left: 7px;
        width: 69%;
        height: 30px;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 25%;
        background-color: green;
      }
    }
  }
  .main {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    .poulation-sum {
      display: flex;
      height: 80%;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .content-poulation-sum {
        font-weight: bold;
      }
    }
    .poulation-sum-in-database {
      display: flex;
      height: 80%;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .content-poulation-sum-in-database {
        font-weight: bold;
      }
    }
    .poulation-average {
      display: flex;
      height: 80%;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .content-poulation-average-in-database {
        font-weight: bold;
      }
    }
    .village-count {
      display: flex;
      height: 80%;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .content-village-count {
        font-weight: bold;
      }
    }
  }
`;
const HorizonLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 100%;
  margin: 10px;
  .line {
    width: 90%;
    background-color: #b4b4b4;
    height: 1px;
  }
`;
