import React from "react";
import Chart from "./chart";
import { Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { apiConfig } from "../../../../config/api";
function InfoOccupation({communityId}) {
  const [data,setData] = React.useState("")
  console.log("InfoOccupationData",data);
  async function getDataApi(communityId) {
    setData("")
    if (!communityId) {
      return false;
    }
    try {
      const res = await axios.get(`${apiConfig.url}/api/community_dashboard/job/${communityId}`)
      if (res.status === 200) {
        setData(res.data)
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(()=>{
    getDataApi(communityId)
  },[communityId])
  return (
    !!data && (
      <Row>
        <Col>
        <div style={{width:"100%",height:"55vh"}} >
          <Chart occupation={data} />
        </div>
        </Col>
        <Col>
          <Table responsive="md">
            <thead>
              <tr>
                <th>อาชีพ</th>
                <th>จำนวน </th>
              </tr>
            </thead>
            <tbody>
              {data.title.length > 0 && data.title.map((item,inx) => {
                return (
                  <tr key={inx}>
                    <td>{item}</td>
                    <td>{data.data[inx]} คน</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  );
}

export default InfoOccupation;
