import React from 'react'
import styled from 'styled-components'
import Loading from '../../../../../component/loadingAnimation/loading'
import {Button} from 'react-bootstrap'
import { BsPencilSquare } from "react-icons/bs";
import ModalDashBoardTable from '../../../../../component/dashboard/personal/modal/ModalDashBoardTable'
function TableDetial({ userData, loading,pagidata,page }) {
  const [modalShowPersonalDetial, setModalShowPersonalDetial] = React.useState(false);
  const [personalId, setPersonalId] = React.useState(false);

  const selectForUpdate = ({id})=>{
    setPersonalId(id);
    setModalShowPersonalDetial(true)
  }
  return (
    <>
      <TableUserData bordered responsive>
        <thead style={{ backgroundColor: "#38AFBA", color: "white" }}>
          <tr>
            <th>#</th>
            <th>หมายเลขบัตรประชาชน</th>
            <th>ชื่อ</th>
            <th>นามสกุล</th>
            <th>จัดการข้อมูล</th>
          </tr>
        </thead>
        <tbody>
          {!!userData &&
            userData.map((data, inx) => (
              <tr key={inx}>
                <td>
                  {inx + pagidata.per_page * page - pagidata.per_page + 1}
                </td>
                <td>{data.nationalId}</td>
                <td>{data.name}</td>
                <td>{data.lastname}</td>
                <td style={{textAlign:"center",width:"10%"}} >
                  <Button
                    variant="warning"
                    onClick={() => selectForUpdate(data)}
                  >
                    <BsPencilSquare />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
        <ModalDashBoardTable
          isEdit={true}
          personalId={personalId}
          setPersonalId={setPersonalId}
          modalShowPersonalDetial={modalShowPersonalDetial}
          setModalShowPersonalDetial={setModalShowPersonalDetial}
        />
      </TableUserData>
    </>
  );
}
export default TableDetial
const TableUserData = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  transition: all .2s ;
tr th,
tr td {
  border-right: 1px solid #38AFBA;
  border-bottom: 1px solid #38AFBA;
  padding: 5px;
}

tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th {
  background: #38AFBA;
  text-align: left;
  border-top: solid 1px #38AFBA;
}

/* top-left border-radius */
tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
`
const LoadingTable = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff35;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  transition: all .5s;
  visibility: ${({loading}) => !loading.loading ? "hidden" : "visible"};
  opacity: ${({loading}) => !loading.loading ? "0" : "1"};
  .loadingBox{
    position: absolute;
    width: 20%;
    height: 10%;
    top: 40%;
    left: 40%;
  }
`