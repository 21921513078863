import React from "react";
import { Modal, Button } from "react-bootstrap";
import SectionSixth from "../../../CommunityForm/insertComponent/sectionSixth";
import EditModal from "../editModal/editModal";

function Popup({
  setModalForm,
  editState,
  setEditState,
  modalShow,
  dataSelect,
  setModalShow,
  handleEdit,
  editData,
  setEditData,
  modalForm,
  auth
}) {
  console.log("infoSupportPopupData", dataSelect);
  function handleClose() {
    setModalShow(false);
    setEditState(false);
  }
  return (
    <div>
      {modalForm ? (
        <EditModal
          editState={editState}
          editDataResult={editData}
          setModalForm={setModalForm}
          setModalShow={setModalShow}
          section={
            <SectionSixth
              isEdit={editState}
              data={editData}
              setData={setEditData}
            />
          }
        />
      ) : (
        <Modal
          onHide={() => handleClose()}
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              ข้อมูลการได้รับสนับสนุนจาก{" "}
              {dataSelect.sixth.financial[0].org_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              ชื่อองกรณ์: <b>{dataSelect.sixth.financial[0].org_name}</b>
            </h5>
            <h5>
              ประเภทองกรณ์: <b>{dataSelect.sixth.financial[0].org_type}</b>
            </h5>
            <h5>
              ที่ตั้งองกรณ์:{" "}
              <b>
                {dataSelect.sixth.financial[0].org_address}{" "}
                {!!dataSelect.sixth.financial[0].province_name &&
                  "จังหวัด " + dataSelect.sixth.financial[0].province_name}{" "}
                {!!dataSelect.sixth.financial[0].country &&
                  "ประเทศ " + dataSelect.sixth.financial[0].country}
              </b>
            </h5>
            <h5 className="mt-3">
              งบประมาณที่ได้รับการสนับสนุน:
              <ul className="mt-3">
                {dataSelect.sixth.financial[0].resource_money.map((item) => {
                  return (
                    <li key={item.id}>
                      <p>งบประมาณที่ได้รับ {item.money} บาท</p>{" "}
                      <p>ปีที่ได้รับ {item.term}</p>
                    </li>
                  );
                })}
              </ul>
            </h5>
            <hr />
            <h5>ผู้ประสานงานกลุ่ม</h5>
            <h5>
              {dataSelect.sixth.financial[0].leader_title}{" "}
              {dataSelect.sixth.financial[0].leader_firstname}{" "}
              {dataSelect.sixth.financial[0].leader_lastname} | เบอร์ติดต่อ:{" "}
              {dataSelect.sixth.financial[0].leader_tell} | Email:{" "}
              {dataSelect.sixth.financial[0].leader_email}
            </h5>
            <hr />
            <h5>ผู้ประสานงานองค์กรณ์</h5>
            <h5>
              {dataSelect.sixth.financial[0].contact_title}{" "}
              {dataSelect.sixth.financial[0].contact_firstname}{" "}
              {dataSelect.sixth.financial[0].contact_lastname} | เบอร์ติดต่อ:{" "}
              {dataSelect.sixth.financial[0].contact_tell} | Email:{" "}
              {dataSelect.sixth.financial[0].contact_email}
            </h5>
          </Modal.Body>
          <Modal.Footer>
            {auth && (
              <Button onClick={() => handleEdit(dataSelect)}>แก้ไข</Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Popup;
