import React from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";

function Train({
  train,
  setTrain,
  typeProject,
  trainCommunity,
  showOnlyProps = false
}) {
  const { data, setData } = React.useContext(PersonalContext);

  const addNewTrain = () => {
    let updatedTrain = { ...data };
    updatedTrain.fourth.train.push({});
    setData(updatedTrain);
  };
  const deleteTrain = (index) => {
    let updatedTrain = { ...data };
    updatedTrain.fourth.train.splice(index, 1);
    setData(updatedTrain);
  };

  const handleChange = (e, index) => {
    let updatedTrain = { ...data };
    updatedTrain.fourth.train[index][e.target.name] = e.target.value;
    setData(updatedTrain);
  };
  return (
    <>
      <h5 className="mb-4 mt-4">การฝึกอบรม</h5>
      {data.fourth.train.map((d, index) => {
        return (
          <React.Fragment key={index}>
            <p className="mb-2 mt-4">
              {!showOnlyProps && (
                <label
                  onClick={() => deleteTrain(index)}
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                >
                  X
                </label>
              )}{" "}
              {index + 1}. โครงการ
            </p>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
                <Row>
                  <Col md={2}>
                    <Form.Check
                      disabled={data.fourth.train[index].id}
                      checked={
                        data.fourth.train[index].train_type ===
                        "โครงการนอกพื้นที่หมู่บ้าน"
                      }
                      type={"radio"}
                      name={`train-${index}`}
                      onChange={(e) => {
                        let updatedTrain = { ...data };
                        updatedTrain.fourth.train[index].train_type =
                          "โครงการนอกพื้นที่หมู่บ้าน";
                        setData(updatedTrain);
                      }}
                      label={`โครงการนอกพื้นที่หมู่บ้าน`}
                      id={`disabled-default`}
                    />
                  </Col>
                  <Col md={2}>
                    <Form.Check
                      disabled={data.fourth.train[index].id}
                      checked={
                        data.fourth.train[index].train_type ===
                        "โครงการในพื้นที่หมู่บ้าน"
                      }
                      type={"radio"}
                      name={`train-${index}`}
                      onChange={(e) => {
                        let updatedTrain = { ...data };
                        updatedTrain.fourth.train[index].train_type =
                          "โครงการในพื้นที่หมู่บ้าน";
                        setData(updatedTrain);
                      }}
                      label={`โครงการในพื้นที่หมู่บ้าน`}
                      id={`disabled-default`}
                    />
                  </Col>
                </Row>
              </div>
            </Form.Group>
            {data.fourth.train[index].train_type ===
            "โครงการในพื้นที่หมู่บ้าน" ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>เลือกโครงการ</Form.Label>
                <Form.Select
                  disabled={showOnlyProps}
                  aria-label="Default select example"
                  name="project_id"
                  value={data.fourth.train[index].project_id}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option></option>
                  {trainCommunity.map((d, index) => {
                    return (
                      <option key={index} value={d.id}>
                        {d.project_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            ) : null}
            {data.fourth.train[index].train_type ===
            "โครงการนอกพื้นที่หมู่บ้าน" ? (
              <Row>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ชื่อโครงการ</Form.Label>
                    <Form.Control
                      disabled={showOnlyProps}
                      type="text"
                      name="project_name"
                      value={data.fourth.train[index].project_name}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ประเภทโครงการ</Form.Label>
                    <Form.Select
                      disabled={showOnlyProps}
                      aria-label="Default select example"
                      name="typeproject"
                      value={
                        data.fourth.train[index].typeproject
                          ? data.fourth.train[index].typeproject
                          : data.fourth.train[index].project_type
                      }
                      onChange={(e) => handleChange(e, index)}
                    >
                      <option></option>
                      {typeProject.map((d, index) => {
                        return (
                          <option key={index} value={d.typeproject_name}>
                            {d.typeproject_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                {data.fourth.train[index].typeproject === "ประเภทอื่น ๆ" ? (
                  <Col md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>ชื่อประเภทโครงการอื่น ๆ</Form.Label>
                      <Form.Control
                        disabled={showOnlyProps}
                        type="text"
                        min={0}
                        name="typeproject_other"
                        value={data.fourth.train[index].typeproject_other}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Form.Group>
                  </Col>
                ) : null}
                <Col md={2}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ปีที่เริ่มโครงการ (พ.ศ.)</Form.Label>
                    <Form.Control
                      disabled={showOnlyProps}
                      type="number"
                      min={0}
                      name="project_begin"
                      value={data.fourth.train[index].project_begin}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ปีที่สิ้นสุดเริ่มโครงการ (พ.ศ.)</Form.Label>
                    <Form.Control
                      disabled={showOnlyProps}
                      type="number"
                      min={0}
                      name="project_end"
                      value={data.fourth.train[index].project_end}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : null}
          </React.Fragment>
        );
      })}
      {!showOnlyProps &&(<Button variant="success" onClick={() => addNewTrain()}>
        เพิ่มข้อมูลการฝึกอบรม
      </Button>)}
    </>
  );
}

export default Train