import React from 'react'

function Description({chartData,pathname,section,getDataTable}) {
  return (
    <div className="Description">
    {!!chartData &&
      chartData.title.map((title, inx) => (
        <div className="box" key={inx}>
          <div
            className="dot"
            style={{
              backgroundColor: !!chartData ? chartData.color[inx] : "#FFFF",
            }}
          />
          <div className="text">
            <span
              onClick={() => getDataTable(pathname, section, title, null)}
              className="text-title"
            >
              {title}
            </span>
            <div className="text-cont">
              {!!chartData && chartData.data[inx]} คน ร้อยละ{" "}
              {chartData.averageCount[inx]
              }{" "}
              
            </div>
          </div>
        </div>
      ))}
  </div>
  )
}

export default Description