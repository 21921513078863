import { createContext, useContext, useReducer } from "react"
import reducerState from "../reducer/reducer"
import { signIn, signOut } from "../reducer/action";
import { initialState } from './inintialState';
const StateContext = createContext()
const DispatchContext = createContext({dispatch:() => null, _signIn: () => null, _signOut: () => null })
const AuthContextProvider = (props) => {
    const [state,dispatch] = useReducer(reducerState,initialState)
    const DispatchValue = { 
        _signIn: signIn(dispatch),
        _signOut: signOut(dispatch),
    }
    return(
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={DispatchValue} >
                {props.children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}
const useAuthContextState =()=> {
    const context = useContext(StateContext);
    if (context === undefined) throw new Error(":::: ! ::::");
    return context;
  }
  const useAuthContextDispatch =()=> {
    const context = useContext(DispatchContext);
    if (context === undefined) throw new Error(":::: ! ::::");
    return context;
  }
export {AuthContextProvider, useAuthContextState,useAuthContextDispatch };