import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import UnityCommunity from "./unityCommunity/unityCommunity";
import UnityOther from "./unityOther/unityOther";

function WeastResource({
  item,
  structureResourceHandleChange,
  data,
  setData,
  index,
}) {
  return (
    <Col md={12}>
      <Form.Group className="mb-3" controlId="">
        <Form.Check
          type={"checkbox"}
          checked={item.checked === true}
          name="checked"
          onChange={(e) =>
            structureResourceHandleChange(e, "weast_resource", index)
          }
          id={`default-checkbox`}
          label={`${item.unity_name}`}
        />
      </Form.Group>
      {item.unity_name === "แหล่งกำจัดขยะในชุมชน"
        ? (item.checked === true) && (
            <UnityCommunity
              item={item}
              index={index}
              data={data}
              setData={setData}
            />
          )
        : (item.checked === true) && (
            <UnityOther
              item={item}
              index={index}
              data={data}
              setData={setData}
            />
          )}
    </Col>
  );
}

export default WeastResource;
