import React, { useState, createContext } from "react";

// Create Context Object
export const CommunityContext = createContext();


// Create a provider for components to consume and subscribe to changes
export const CommunityContextProvider = (props) => {
    const [data, setData] = useState({
        first: {
            saved: false,
            community: {
                community_name: "",
                community_area: 0,
                community_history: "",
                area: 0,
                house: 0,
                population: 0,
                tambol_id: null,
                amphur_id: null,
                province_id: null,
                tambol: [],
                amphur: [],
                province: [],
                group: [],
            },
            leader: {
                title: "",
                first_name: "",
                last_name: "",
                tel_no: "",
                leader_start: "",
                leader_end: "",
            }
        },
        second: {
            saved: false,
            river: [],
            tree: []
        },
        third: {
            saved: false,
            structure_resource: [],
            weast_resource: [],
            utilities_resource: [],
        },
        fourth: {
            training: [],
            saved: false,
        },
        fifth: {
            social: [],
            saved: false,
        },
        sixth: {
            financial: [],
            saved: false,
        },
    })
    const [dataReferent, setDataReferent] = useState("first")
    const [parentId, setParentId] = useState(null)
    return (
        <CommunityContext.Provider value={{ data, setData, setDataReferent, dataReferent, parentId, setParentId }}>
            {props.children}
        </CommunityContext.Provider>
    )
}