import axios from "axios";
import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { apiConfig } from "../../../../config/api";
import LoadingPage from "../../../loadingPage/loadingPage";
import WelfareDetial from "./welfareDetial/welfareDetial";
import WelfareOverview from "./welfareOverview/welfareOverview";

export default function Welfare({ address,auth,loadingPageCoload }) {
  const [data, setData] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const overviewRef = React.useRef();
  async function getWelfareInfo() {
    if (!address.tambol) return false;
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/get-welfare-section/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`
      );
      if (res.status === 200) {
        console.log("welfareInfo", res.data);
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "error",
        text: error,
        icon: "error",
      });
    }
  }
  React.useEffect(() => {
    setData("");
    getWelfareInfo();
  }, [address.group, address.tambol]);
  return (
    <Main>
      <div className="overview">
        <LoadingPage loading={loading} animatioColor={loadingPageCoload} />
      <div className="welfare-overview-title">
        <div style={{width:"70%"}}>
          <span>ข้อมูลสวัสดิการ</span>
          <br />
          {!!data.average ? (
            <span>
              แสดงผลคิดเป็นร้อยละ {!!data.average ? data.average.toFixed(2) : 0} %
              จากข้อมูลประชากรตามทะเบียนราษฎร์
            </span>
          ) : (
            "-"
          )}
        </div>
        {auth && <Button
          style={{height:"35px"}}
          onClick={() => overviewRef.current.getPersonalInfoAllAgeRange()}
          size="sm"
        >
          รายชื่อประชากร
        </Button>}
      </div>
        <WelfareOverview ref={overviewRef} address={address} data={data.overview} />
        </div>
        <div className="welfare-content">
            <WelfareDetial loading={loading} loadingPageCoload={loadingPageCoload} auth={auth} address={address} data={data}  />
        </div>
    </Main>
  );
}

const Main = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 39.5% 59.5%;
  grid-template-areas: 
  "overview welfare-content";
  grid-gap: 10px;
  .overview{
    width: 100%;
    padding: 10px;
    position: relative;
    border-radius: 5px;
    height: fit-content;
    grid-area: overview;
    background-color: #FFFF;
    .welfare-overview-title {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #e6e6e6;
  }
  }
  .welfare-content{
    width: 100%;
    grid-area: welfare-content;
  }
`
