import React from "react";
import Row from "react-bootstrap/Row";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import SectionOne from "../../../../PersonalForm/insertComponent/sectionOne";
import SectionSecond from "../../../../PersonalForm/insertComponent/sectionSecond";
import SectionThird from "../../../../PersonalForm/insertComponent/sectionThird";
import SectionFourth from "../../../../PersonalForm/insertComponent/sectionFourth";
import SectionFifth from "../../../../PersonalForm/insertComponent/sectionFifth";
import SectionSixth from "../../../../PersonalForm/insertComponent/sectionSixth";
import axios from "axios";
import { PersonalContext } from "../../../../../context/personal";
import { apiConfig } from "../../../../../config/api";

function PersonalDetial({
  personalData,
  setLoading,
  isEdit = false,
  radioValue,
  setRadioValue,
}) {
  const { data, setData, setPersonalId } =
    React.useContext(PersonalContext);
  const [showPageInfo, setShowPageInfo] = React.useState(false);

  const radios = [
    { name: "ข้อมูลทั่วไป", value: "1" },
    { name: "ข้อมูลการศึกษา", value: "2" },
    { name: "ข้อมูลประสบการณ์ทำงาน", value: "3" },
    { name: "การฝึกอบรม และ ทักษะความสามารถ", value: "4" },
    { name: "ข้อมูลครอบครัว", value: "5" },
    {
      name: "ข้อมูลเกี่ยวกับการได้รับการสนับสนุนจากภาครัฐและการมีส่วนร่มในชุมชน",
      value: "6",
    },
  ];

  const showForm = () => {
    switch (radioValue) {
      case "1":
        return <SectionOne showOnlyProps={!isEdit && true} />;
      case "2":
        return (
          <SectionSecond
            personalIdProps={personalData}
            showOnlyProps={!isEdit && true}
          />
        );
      case "3":
        return (
          <SectionThird
            personalIdProps={personalData}
            showOnlyProps={!isEdit && true}
          />
        );
      case "4":
        return (
          <SectionFourth
            personalIdProps={personalData}
            showOnlyProps={!isEdit && true}
          />
        );
      case "5":
        return (
          <SectionFifth
            personalIdProps={personalData}
            showOnlyProps={!isEdit && true}
          />
        );
      case "6":
        return (
          <SectionSixth
            personalIdProps={personalData}
            showOnlyProps={!isEdit && true}
          />
        );
    }
  };

  React.useEffect(async () => {
    try {
      let response = await axios.get(
        `${apiConfig.url}/api/personal/get_personal/${personalData}`
      );
      let updateData = { ...data };
      console.log(response.data);
      if (response.data.occupation.length > 0) {
        updateData.first.occupation.status = "มีอาชีพ";
        updateData.first.occupation.main = response.data.occupation.find(
          (item) => item.job_status === "อาชีพหลัก"
        );
        updateData.first.occupation.additional.data.status = true;
        updateData.first.occupation.additional.data =
          response.data.occupation.filter(
            (item) => item.job_status === "อาชีพเสริม"
          );
        if (updateData.first.occupation.additional.data.length > 0)
          updateData.first.occupation.additional.status = true;
      }
      if (response.data.garden.length > 0) {
        updateData.first.garden.have = true;
        updateData.first.garden.data = response.data.garden;
      }
      if (response.data.health.length > 0) {
        updateData.first.physicalImpairment.body = response.data.health.find(
          (item) => item.type_name === "ความบกพร่องทางด้านร่างกาย"
        );
        if (updateData.first.physicalImpairment.body)
          updateData.first.physicalImpairment.body.status = true;
        else {
          updateData.first.physicalImpairment.body = {
            status: false,
            name: "",
            date: "",
            description: "",
            hospitalName: "",
            hospitalId: "",
          };
        }
        updateData.first.physicalImpairment.other = response.data.health.find(
          (item) => item.type_name === "ความบกพร่องทางด้านอื่น ๆ"
        );
        if (updateData.first.physicalImpairment.other)
          updateData.first.physicalImpairment.other.status = true;
        else {
          updateData.first.physicalImpairment.other = {
            status: false,
            name: "",
            date: "",
            description: "",
            hospitalName: "",
            hospitalId: "",
          };
        }
      }
      if (response.data.role.length > 0) {
        updateData.first.communityRole = response.data.role.map((item) => {
          return {
            id: item.id,
            name: item.role_name,
            status: true,
          };
        });
      }
      if (response.data.sage.length > 0) {
        updateData.first.sage.have = true;
        updateData.first.sage.data = response.data.sage.map((item) => {
          return {
            id: item.id,
            name: item.sage_name,
            type: item.sage_type,
          };
        });
      }
      updateData.first.information = response.data.personal;
      updateData.first.address = response.data.address;
      setPersonalId(response.data.personal.id);
      setData(updateData);
      setLoading(false);
      setShowPageInfo(true);
    } catch (err) {
      console.log(err);
    }
  }, [personalData]);

  return (
    <>
      <header>
        <Row>
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-success"}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Row>
      </header>
      <body>
        {!!personalData?.data && personalData.data}
        <div className="mt-4">{!!showPageInfo && showForm()}</div>
      </body>
    </>
  );
}

export default PersonalDetial;
