import React from "react";
import styled from "styled-components";
const tabItemData = [
  "ข้อมูลชุมชน",
  "ข้อมูลทรัพยากรธรรมชาติ",
  "ข้อมูลทรัพยากรโครงสร้างของชุมชน",
  "ข้อมูลทรัพยากรโครงสร้างอื่นๆ ของชุมชน",
  "ข้อมูลการประกอบอาชีพของบุคคลในชุมชน",
  "ข้อมูลปราชน์ชาวบ้านในชุมชน",
  "ข้อมูลกลุ่มต่างๆภายในชุมชน",
  "ข้อมูลการได้รับสนับสนุน",
];
function selectTap({ target, changeTab, disable }) {
  return (
    <div className="d-flex">
      {tabItemData.map((item, index) => {
        return (
          <TapBoxItem
            disabled={disable}
            nonId={disable}
            active={target === index}
            key={index}
            onClick={() => changeTab(index)}
          >
            <span
              style={{
                fontSize: "15px",
                zIndex: "4",
                color: target !== index && "white",
              }}
            >
              {item}
            </span>
            {target === index && index !== tabItemData.length - 1 && (
              <>
                <div className="btt-line" />
                <div className="btt-rotate" />
              </>
            )}
            {target + 1 === index && index !== tabItemData.length && (
              <>
                <div className="bts" />
              </>
            )}
          </TapBoxItem>
        );
      })}
    </div>
  );
}

const TapBoxItem = styled.button`
  box-shadow: rgba(99, 99, 99, 0.2) 10px 2px -8px 0px;
  border: none;
  &:hover {
    transition: all 0.2s;
    ${(props) =>
      !props.nonId
        ? props.active
          ? "background-color: white;cursor: pointer;"
          : `background-color: #2e939c;cursor: pointer;.bts {
      transition: all 0.2s;
      background-color: #2e939c;
    }`
        : ""};
  }
  ${(props) =>
    !props.nonId
      ? props.active
        ? "background-color:white"
        : `background-color:#38afba;.bts {
    z-index: 3;
    height: 55%;
    width: 25px;
    border-bottom-left-radius: 90%;
    background-color: #38afba;
    position: absolute;
    left: 21px;
    bottom: -3px;
    transform: rotate(-18deg);
  }`
      : props.active
      ? "background-color:white"
      : `background-color:#40cad6;.bts {
    z-index: 3;
    height: 55%;
    width: 25px;
    border-bottom-left-radius: 90%;
    background-color: #40cad6;
    position: absolute;
    left: 21px;
    bottom: -3px;
    transform: rotate(-18deg);
  }`};
  z-index: ${(props) => props.active && "2"};
  display: flex;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-left: 25px;
  padding-right: 30px;
  min-width: 150px;
  height: 60px;
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
  .btt-line {
    z-index: 2;
    height: 50%;
    width: 70px;
    background-color: #ffffff;
    position: absolute;
    right: -30px;
    bottom: -5px;
  }
  .btt-rotate {
    z-index: 3;
    height: 60%;
    width: 70px;
    background-color: #ffffff;
    position: absolute;
    right: -8px;
    transform: rotate(-23deg);
    bottom: -5px;
  }
  
`;
export default selectTap;
