import React from "react";
import Swal from "sweetalert2";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { apiConfig, ApiFetch } from "../../../config/api";
import { FetchInterface, Method } from "../../../enums/enum";

import { useAuthContextState } from "../../../context/auth/authContext/authContextState";
import Actions from "../../../component/formUser/actions/actions";
function UserManage() {
  const history = useHistory();
  const { token } = useAuthContextState();
  const [userList, setUserList] = React.useState([]);
  async function getUserListByAdmin() {
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: "auth/get-user-by-admin",
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
      });
      setUserList(res.data);
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาดในระหว่างเชื่อมต่อ",
        text: error,
        icon: "error",
      });
      console.log(error);
    }
  }

  React.useEffect(() => {
    getUserListByAdmin();
  }, []);
  return (
    <Container>
      <div className="title">
        <h2>User Management</h2>
        <div className="addUser">
          <Button
            onClick={() => {
              history.push("/CreateUser");
            }}
            variant="outline-primary"
          >
            เพิ่มผู้ใช้
          </Button>
        </div>
      </div>
      <div className="content">
        {userList.length > 0 &&
          userList.map((data, inx) => (
            <div
              key={inx}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className="card-items">
                {data.data.length > 0 &&
                  data.data.map((data, inx) => (
                    <div
                      key={inx}
                      className="userItem" /* onClick={() => history.push({
                      pathname: `/ShowDetailDataCollector`,
                      state: { id: data.id, first_name: data.first_name, last_name: data.last_name},
                    })} */
                    >
                      <div className="Avatar">
                        {!!data.url ? (
                          <img
                            alt={data.first_name}
                            width={"100%"}
                            src={`${apiConfig.url}/image/${data.url}`}
                          />
                        ) : (
                          <span>
                            {!!data.first_name &&
                              data.first_name.charAt(0).toUpperCase()}
                          </span>
                        )}
                      </div>
                      <div className="infomation">
                        <span className="name-lastname">
                          {data.first_name} {data.last_name}
                        </span>
                        <span className="email">{data.user_type}</span>
                        <span className="role">{data.user_email}</span>
                      </div>
                      <div className="user-actions">
                        <Actions
                          data={data}
                          getUserListByAdmin={getUserListByAdmin}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </Container>
  );
}

export default UserManage;
const Container = styled.div`
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    border-radius: 10px;
    background-color: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    padding: 0 15px 0 15px;
    height: 80px;
    width: 93%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addUser {
    }
  }
  .content {
    height: 90%;
    display: flex;
    margin-top: 20px;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    .card-items {
      display: grid;
      padding-left: 10px;
      justify-content: center;
      grid-template-columns: 25% 25% 25% 25%;
      .userItem {
        width: 290px;
        height: 100px;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: center;
        border-radius: 10px;
        /* border: solid transparent; */
        margin: 0px 15px 15px 0px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .user-actions {
          top: 0px;
          width: 0%;
          right: 0px;
          height: 100%;
          overflow: hidden;
          position: absolute;
          visibility: hidden;
          transition: ease-in 0.2s;
          background-color: #38afba;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:hover {
          .user-actions {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            width: 30%;
            visibility: visible;
          }
        }
        .Avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          object-fit: contain;
          background-color: #38afba;
        }
        .infomation {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 0 0 10px 10px;
          width: auto;
          height: 100%;
          .name-lastname {
            font-weight: bold;
          }
          .role {
            font-weight: bold;
          }
        }
      }
    }
  }
`;
