import axios from "axios";
import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { apiConfig } from "../../../../config/api";
import { LongdoMap, map, longdo } from "../../../../longdo-map/LongdoMap";
import ModalPopup from "./popup";

function InfoOtherResource({ communityId, auth }) {
  const [dataShow, setDataShow] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalForm, setModalForm] = React.useState(false);
  const [editState, setEditState] = React.useState(false);
  const [name, setName] = React.useState("");
  const [editData, setEditData] = React.useState("");
  const [showId, setShowId] = React.useState("");
  console.log("InfoOtherResourceData", dataShow);
  const mapKey = "22fd72356e6bf6d0712a24217df8fc4c";
  const getLatLong = () => {
    let value = [];
    if (dataShow) {
      dataShow.forEach((OtherResourceItem) => {
        OtherResourceItem.data.length > 0 &&
          OtherResourceItem.data.forEach((dataItem) => {
            value.push({
              lon: dataItem.longitude,
              lat: dataItem.latitude,
              title: dataItem.resource_name,
            });
          });
      });
    }

    return value;
  };
  const initMap = () => {
    let place = getLatLong();
    console.log("place", place);
    place.forEach((item) => {
      var marker = new longdo.Marker(
        { lon: item.lon, lat: item.lat },
        {
          detail: item.title,
          size: { width: 200, height: 50 },
          closable: false,
        }
      );
      map.Overlays.add(marker);
    });
  };
  const showDetail = (id, item) => {
    setModalShow(true);
    setShowId(id);
    setName(item);
  };
  function handleAdd(name) {
    console.log("infoGroupPopupHandleAdd");
    setEditData({
      parentId: communityId,
      third: {
        structure_resource: [
          {
            name: name,
            checked: true,
            data: [],
          },
        ],
      },
    });
    setEditState(false);
    setModalForm(true);
    setModalShow(true);
  }
  function handleEdit(detail) {
    console.log("handleEditinfoGroupPopupHandleAdd", detail);
    setEditData({
      parentId: communityId,
      ...detail,
    });
    setEditState(true);
    setModalForm(true);
  }
  async function getDataApi(communityId) {
    if (!communityId) {
      setDataShow("")
      return false;
    }
    try {
      const res = await axios.get(
        `${apiConfig.url}/api/community_dashboard/resource/${communityId}`
      );
      if (res.status === 200) {
        setDataShow(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getDataApi(communityId);
  }, [communityId]);
  return (
    <>
      {!!modalShow && (
        <ModalPopup
          auth={auth}
          editData={editData}
          editState={editState}
          setEditState={setEditState}
          modalShow={modalShow}
          setModalForm={setModalForm}
          setModalShow={setModalShow}
          setEditData={setEditData}
          modalForm={modalForm}
          handleEdit={handleEdit}
          name={name}
          setShowId={setShowId}
          showId={showId}
        />
      )}
      {!!dataShow.length > 0 && (
        <>
          {dataShow.map((item,inx) => {
            return (
              <div style={{ marginTop: "15px" }} key={inx}>
                <h5>
                  <b>{item.typeresource_name}</b>{" "}
                  <small>จำนวน {item.data.length}</small>
                </h5>
                <ItemList>
                  {item.data.map((list, inx) => {
                    return (
                      <div
                        key={list.id}
                        className="item"
                        onClick={() =>
                          showDetail(list.id, item.typeresource_name)
                        }
                      >
                        <span>
                          {inx + 1}.{list.resource_name}
                        </span>{" "}
                      </div>
                    );
                  })}
                </ItemList>
                {auth && (
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => handleAdd(item.typeresource_name)}
                  >
                    เพิ่มข้อมูล
                  </Button>
                )}

                <br />
                <hr />
              </div>
            );
          })}
          <div style={{ height: "500px" }}>
            <h5>แผนที่</h5>
            <LongdoMap
              id="longdo-map"
              mapKey={mapKey}
              callback={() => initMap()}
            />
          </div>
        </>
      )}
    </>
  );
}
const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .item {
    border: solid 2px transparent;
    border-radius: 10px;
    width: 25%;
    padding: 5px;
    &:hover {
      transition: all 0.3s;
      cursor: pointer;
      border: solid 2px #ff99ce;

      background-color: #fff5fa;
    }
  }
`;
export default InfoOtherResource;
