import React from "react";
import styled from "styled-components";

export default function TableLayout({ data,setModalShowPersonalDetial,setPersonalId }) {
  function handleOpenPersonalInfo(id) {
    setPersonalId(id)
    setModalShowPersonalDetial(true)
  }
  return (
    <div >
      <TableUserData striped bordered hover size="sm">
        <thead>
          <tr>
            {!!data.title &&
              data.title.length > 0 &&
              data.title.map((item, index) => <th key={index}>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {!!data.data &&
            data.data.length > 0 &&
            data.data.map((item, index) => (
              <tr onClick={() => handleOpenPersonalInfo(item.id)} key={index}>
                {!!item.data &&
                  item.data.map((item, index) => <td key={index}>{!!item.subName ? item.subName.map((data,inx)=> (
                    <>
                    {data},<br />
                    </>
                  )) : item}</td>)}
              </tr>
            ))}
        </tbody>
      </TableUserData>
    </div>
  );
}
const TableUserData = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  transition: all 0.2s;

  tr {
    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }
  }
  th,
  tr td {
    font-size: 15px;
    border-right: 1px solid #38afba;
    border-bottom: 1px solid #38afba;
    padding: 3px;
  }

  tr th:first-child,
  tr td:first-child {
    border-left: 1px solid #38afba;
  }
  tr th {
    background: #38afba;
    text-align: left;
    border-top: solid 1px #38afba;
  }

  /* top-left border-radius */
  tr:first-child th:first-child {
    border-top-left-radius: 5px;
  }

  /* top-right border-radius */
  tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }

  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }
`;
