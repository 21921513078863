import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FetchInterface, Method } from "../../enums/enum";
import { ApiFetch } from "../../config/api";
import { useAuthContextState } from "../../context/auth/authContext/authContextState";
export default function BarChart({
  data,
  pathname,
  section,
  setModalShow,
  setTableData,
  address,
  setTableDataForFilter,
  setDisableFilter,
  filterDataName,
}) {
  const { token } = useAuthContextState();
  async function getDataTable(pathname, section, title, datasetTitle) {
    if (!token.accessToken) return false;
    if (!!setDisableFilter) {
      setDisableFilter(true);
    }
    setTableData({ header: title, data: [] });
    const titleData = {
      title: title,
      filterDataName: !!filterDataName ? filterDataName : "",
      datasetTitle: !!datasetTitle ? datasetTitle : "",
    };
    setModalShow(true);
    setTableData({ header: titleData, data: [] });
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/${pathname}/${section}/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method.GET,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: titleData,
      });
      if (res.status === 200) {
        setTableDataForFilter(res.data);
        setTableData({ header: titleData, data: res.data });
      }
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      console.log(error);
    }
  }

  // console.log('pathname ===',pathname,data.data);
  const customDataLabels = {
    id: "customDataLabels",
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
        scales: { x, y },
      } = chart;
      const data =
        !!chart.config._config.options.pointReferenceLetters.references ? chart.config._config.options.pointReferenceLetters.references : [];
        chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((dataPoint, inx) => {
          ctx.font = "bold 12px sans-serif";
          ctx.fillStyle = "#0e006c";
          ctx.textAlign = "center";
          const yCenter =
            chart.getDatasetMeta(i).data[inx].y +
            chart.getDatasetMeta(i).data[inx]?.height -
            chart.getDatasetMeta(i).data[inx]?.height / 2;
          const xCenter = chart.getDatasetMeta(i).data[inx].x;
          !!data[i].average && data[i].average[inx] > 0 && ctx.fillText(`${data[i].average[inx]}%`, xCenter, yCenter);
        });
        ctx.save();
      });
    },
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Bar
        width={"100%"}
        plugins={[ChartDataLabels, customDataLabels]}
        options={{
          onClick(click, element, chart) {
            const index = element[0].index;
            const datasetIndex = element[0].datasetIndex;
            const datasetTitle = chart.config.data.datasets[datasetIndex].label;
            const title = chart.config.data.labels[index];
            getDataTable(pathname, section, title, datasetTitle);
          },
          pointReferenceLetters: {
            display: false,
            references: data?.data
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: 15,
          },
          scales: {
            x: {
              grace: 1,
              beginAtZero: true,
              grid: {
                display: true,
              },
            },
            y: {
              display: false,
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display:
                !!data?.data && data?.data[0].label.length > 0 ? true : false,
              align: "start",
              position: "left",
            },
            datalabels: {
              color: "#6e6e6e",
              anchor: "end",
              align: "top",
              font: {
                weight: "bold",
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        }}
        data={{
          labels: !!data?.title ? [...data.title] : [],
          datasets: !!data?.data ? [...data.data] : [],
        }}
      />
    </div>
  );
}
