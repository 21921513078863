import React from 'react'
import { Button, Modal, Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { BsPencilSquare } from "react-icons/bs";
import PaginationSelftInput from '../../../dataCollector/dashboard/pagination/pagination';
import { ApiFetch } from '../../../../config/api';
import { FetchInterface, Method } from '../../../../enums/enum';
import { useAuthContextState } from '../../../../context/auth/authContext/authContextState';
import Swal from 'sweetalert2'

function ExpendIncome() {
  const [userData, setUserData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [openModal, setOpenModal] = React.useState(false)
  const [address, setAddress] = React.useState({
    provice: null,
    amphur: null,
    tambol: null,
    group: null,
  })
  const { token } = useAuthContextState()
  async function getUser(page) {
    try {
      const res = await ApiFetch({
        [FetchInterface.PATH]: `auth/get-personal-by-address/${address.provice}/${address.amphur}/${address.tambol}/${address.group}`,
        [FetchInterface.METHOD]: Method,
        [FetchInterface.TOKEN]: token.accessToken,
        [FetchInterface.PARAMS]: { page: page }
      })
      if (res.status === 200) {
        setUserData(res.data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const month = [
    {
      name: 'มกราคม',
      value: 1,
    },
    {
      name: 'กุมภาพันธ์',
      value: 2,
    },
    {
      name: 'มีนาคม',
      value: 3,
    },
    {
      name: 'เมษายน',
      value: 4,
    },
    {
      name: 'พฤษภาคม',
      value: 5,
    },
    {
      name: 'มิถุนายน',
      value: 6,
    },
    {
      name: 'กรกฎาคม',
      value: 7,
    },
    {
      name: 'สิงหาคม',
      value: 8,
    },
    {
      name: 'กันยายน',
      value: 9,
    },
    {
      name: 'ตุลาคม',
      value: 10,
    },
    {
      name: 'พฤศจิกายน',
      value: 11,
    },
    {
      name: 'ธันวาคม',
      value: 12,
    },
  ]
  const years = []
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        text: error.message,
        showConfirmButton: true,
      }).then(() => console.log(error))
    }
  }
  function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 9
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }
  React.useEffect(() => {
    getUser(1)
    var currentYear = new Date().getFullYear();
  }, [address.tambol])
  return (
    <Main>
      <Container>
        <div className="title">
          <div className='select'>
            รายการ รายรับขรายจ่าย
          </div>
          <div>
            <Button variant="primary" onClick={() => setOpenModal(true)}>เพิ่มข้อมูล</Button>
          </div>
          <Modal
            show={openModal}
            onHide={() => setOpenModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>เพิ่มข้อมูลรายรับขรายจ่าย</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className="justify-content-md-center p-3">
                <Col md='12'>
                  <Row className="justify-content-md-center">
                    <Col md='6'>
                      
                      <Form.Group className="mb-3">
                            <Form.Label>ปี</Form.Label>
                            <Form.Select aria-label="Default select example" >
                              {generateArrayOfYears().map((item) => {
                                return (
                                  <option key={item} value={item}>{item}</option>
                                )
                              })}
                            </Form.Select>
                          </Form.Group>
                    </Col>
                    <Col md='6'>
                          <Form.Group className="mb-3">
                            <Form.Label>เดือน</Form.Label>
                            <Form.Select aria-label="Default select example" >
                              {month.map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>{item.name}</option>
                                )
                              })}
                            </Form.Select>
                          </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='6'>
                      <Form.Group className="mb-3">
                            <Form.Label>รายรับ</Form.Label>
                            <Form.Control
                            required
                            type="number"
                            />
                        </Form.Group>
                    </Col>
                    <Col md='6'>
                      
                      <Form.Group className="mb-3">
                            <Form.Label>รายจ่าย</Form.Label>
                            <Form.Control
                            required
                            type="number"
                            />
                        </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" >เพิ่มข้อมูล</Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="content">
          <TableUserData bordered responsive>
            <thead style={{ backgroundColor: "#38AFBA", color: "white" }}>
              <tr>
                <th>#</th>
                <th>เดือน</th>
                <th>ปี</th>
                <th>รายรับ</th>
                <th>รายจ่าย</th>
              </tr>
            </thead>
            <tbody>
              {!!userData &&
                userData.map((data, inx) => (
                  <tr key={inx}>
                    <td>
                      {inx + userData.per_page * userData.current_page - userData.per_page + 1}
                    </td>
                    <td>{data.nationalId}</td>
                    <td>{data.name}</td>
                    <td>{data.lastname}</td>
                    <td style={{ textAlign: "center", width: "10%" }} >
                      <Button
                        variant="warning"
                      >
                        <BsPencilSquare />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableUserData>
          {userData?.data?.length > 0 &&
            <PaginationSelftInput
              pagiData={userData}
              getDashboard={getUser}
              page={userData.current_page}
            />}

        </div>
      </Container>
    </Main>
  )
}
export default ExpendIncome
const Main = styled.div`
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .title {
    border-radius: 10px;
    background-color: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    padding: 0 15px 0 15px;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select{
      width: 50%;
    }
  }
  .content {
    width: 100%;
    height: 82vh;
    display: flex;
    padding: 10px;
    margin-top: 15px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }
`
const TableUserData = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  transition: all .2s ;
tr th,
tr td {
  border-right: 1px solid #38AFBA;
  border-bottom: 1px solid #38AFBA;
  padding: 5px;
}

tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th:first-child,
tr td:first-child {
  border-left: 1px solid #38AFBA;
}
tr th {
  background: #38AFBA;
  text-align: left;
  border-top: solid 1px #38AFBA;
}

/* top-left border-radius */
tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
`