import React from 'react'
import { useAlert } from 'react-alert'
import { Modal, Button, Form, } from 'react-bootstrap'
import { PersonalContext } from "../../../context/personal";

function CreateOrg({ createOrg, setCreateOrg, getOrg}) {
    const alert = useAlert()
    const { data, setData } = React.useContext(PersonalContext);
    const [org, setOrg] = React.useState({})

    const handleChange = (e) => {
        let updateData = {
            ...org,
            [e.target.name]:e.target.value
        }
        setOrg(updateData)
    }

    const handleSubmit = async() => {
        try{
            /* let result = await axios.post(api +'welfareorg/create',org) */
            let updateData = { ...data }
            if (createOrg.section ===1){
                updateData.sixth.now[createOrg.index]={
                    ...updateData.sixth.now[createOrg.index],
                    org:org.name
                }
            }
            if (createOrg.section === 2){
                updateData.sixth.before[createOrg.index] = {
                    ...updateData.sixth.before[createOrg.index],
                    org: org.name
                }
            }
            if (createOrg.section === 3) {
                updateData.sixth.other[createOrg.index] = {
                    ...updateData.sixth.other[createOrg.index],
                    org: org.name
                }
            }
            setData(updateData)
            getOrg()
            setCreateOrg({
                ...createOrg,status:false
            })
            alert.show('บันทึกข้อมูลหน่วยงานองค์กรณ์สำเร็จ!', {
                type: 'success',
            })
        }catch(e){
            console.log(e)
        }
    }
  return (
      <>
          <Modal
              show={createOrg.status}
              onHide={() => setCreateOrg({ ...createOrg, status: !createOrg.status })}
              backdrop="static"
              keyboard={false}
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>เพิ่มข้อมูลหน่วยงานองค์กรณ์</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form.Group className="mb-3" >
                      <Form.Label>ชื่อหน่วยงานองค์กรณ์</Form.Label>
                      <Form.Control type="text" name="name" onChange={(e) => handleChange(e)}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                      <Form.Label>ประเภทหน่วยงานองค์กรณ์</Form.Label>
                      <Form.Select aria-label="Default select example"
                          name="type" onChange={(e) => handleChange(e)}>
                          <option value=""></option>
                          <option value="หน่วยงานภาครัฐ">หน่วยงานภาครัฐ</option>
                          <option value="หน่วยงานภาคเอกชน">หน่วยงานภาคเอกชน</option>
                      </Form.Select>
                  </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={() => handleSubmit()}>เพิ่มข้อมูล</Button>
              </Modal.Footer>
          </Modal>
      </>
  )
}

export default CreateOrg