import React from 'react'
import logo from '../eco.png'
import  styled  from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Navbar, Container, /* Button */} from 'react-bootstrap';

function AppBar() {
    const history = useHistory()
    return (
        <Navbar style={{ background: "#38AFBA" }} expand="lg" variant="light">
            <Container fluid>
                <AppbarLogo onClick={() => history.push('/')}><img src={logo} alt="Logo" style={{ width: '170px', }} />
                </AppbarLogo>
                {/* <Button onClick={() => history.push("/login")} style={{width:"100px"}} variant="primary">Login</Button> */}
            </Container>
        </Navbar>
    )
}

export default AppBar
const AppbarLogo = styled(Navbar.Brand)`
    &:hover{
        cursor: pointer;
    }
`